import {
    GET_STATE_REQUEST,GET_STATE_SUCCESS,GET_STATE_FAILURE
   } from '../Types'  

    
    
  const initialState = {  
    loading: false, 
    noFoundData:false, 
    states:[],
    error: ''  
   
  }  
    
  const reducer = (state = initialState, action) => {  
    
    switch (action.type) {  
        case GET_STATE_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false
        }  
      case GET_STATE_SUCCESS:
        return {  
          loading: false,  
          states: action.payload,  
          noFoundData:false,
          error: ''           
        }  
       
      case GET_STATE_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          states: [],  
          error: action.payload  
        }  
        
      default: return state  
    }  
  }  
    
  export default reducer  