
 import apiService from "../../Services/ApiServices"
 import {  
  GET_STATE_REQUEST,GET_STATE_SUCCESS,GET_STATE_FAILURE
 } from '../Types'  
 
 export const getStateList = (countryId) =>  {
    try {
      return (dispatch) => {  
        dispatch(getStateRequest())  
        let url = `/State/all/${countryId}`;
        apiService.getData(url)
            .then(res => {
              const states = res.data  
              if (res.data) {
                dispatch(getStateSuccess(states))  
            }
            else {
                dispatch(getStateFailure('err'))
            }
              
            }).catch(err =>{ dispatch(getStateFailure(err)) });
      }  
    } catch (err) {
      
    }
  };
  export const getStateListByAccount = (AccountId) =>  {
    try {
      return (dispatch) => {  
        dispatch(getStateRequest())  
        let url = `/Account/states/${AccountId}`;
        apiService.getData(url)
            .then(res => {
              const states = res.data  
              if (res.data) {
                dispatch(getStateSuccess(states))  
            }
            else {
                dispatch(getStateFailure('err'))
            }
              
            }).catch(err =>{ dispatch(getStateFailure(err)) });
      }  
    } catch (err) {
      
    }
  };

export const getStateRequest = () => {  
    return {  
      type: GET_STATE_REQUEST  
    }  
  }  
    
  export const getStateSuccess = states => {  
    return {  
      type: GET_STATE_SUCCESS,  
      payload: states  
    }  
  } 
    
  export const getStateFailure = error => {  
    return {  
      type: GET_STATE_FAILURE,  
      payload: error  
    }  
  }  