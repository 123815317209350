import React, { useState ,useEffect} from "react";
import {Link,Breadcrumbs,MenuItem,TextField,Button,Card,Box, Grid, InputAdornment, Typography } from "@mui/material";
import DefaultInput from "../../Component/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation, Link as RouterLink } from "react-router-dom";
import ApiServices from "../../Services/ApiServices";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../Component/Button";
import { regexConstant, FileSizes } from "../../shared/constants";
import "react-toastify/dist/ReactToastify.css";
import { getCountryList } from "../../Redux/Country/CountryAction";
import { getStateList } from "../../Redux/State/StateAction";
import { getCityList } from "../../Redux/City/CityAction";
import {
  getStoreDetails,
  saveStore,
  updateStore,
  getLogoImageData,
} from "../../Redux/Store/StoreAction";
import { styles } from "../../assets/scss/AddItem";

export default function BasicTable() {
  const [inputField, setInputField] = useState({
    StoreName: "",
    ContactPerson: "",
    Email: "",
    PrimaryPhoneNo: "",
    SecondaryPhoneNumber: "",
    Address: "",
    country: "",
    State: "",
    City: "",
    CityId: "",
    StoreType: "",
    Longitude: "",
    Latitude: "",
    GSTIN: "",
    PinCode: "",
    StoreSize: "",
    StoreCode: "",
    accountName: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [logoImage, setLogoImage] = useState("");
  const [logoImageURL, setLogoUrl] = useState(null);
  const [previewURL, setPreviewUrl] = useState(null);
  const [selectedFileName, setFileName] = useState("");
  const [country, setCountry] = useState([]);
  const [State, setState] = useState([]);
  const [City, setCity] = useState([]);
  const dispatch = useDispatch();
  const countryData = useSelector((s) => s.countryReducer);
  const stateData = useSelector((s) => s.stateReducer);
  const cityData = useSelector((s) => s.cityReducer);
  const storeDetail = useSelector((s) => s.storeReducer);
  const [getAccountId, setAccountId] = useState();
  const [currentUserId, setCurrentUserId] = useState({});
  const [accountSelected, setAccountSelected] = useState(true);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  // get all country
  useEffect(() => {
    dispatch(getCountryList());
  }, []);
  useEffect(() => {
    if (countryData) {
      setCountry(countryData.countries);
    }
  }, [countryData]);
  // get all States for selected country
  useEffect(() => {
    if (stateData) {
      setState(stateData.states);
    }
  }, [stateData]);
  // get all city for selected state
  useEffect(() => {
    if (cityData) {
      setCity(cityData.cities);
    }
  }, [cityData]);
  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const params = useParams();
  const search = useLocation().search;
  const navigate = useNavigate();
  const resetData = () => {
    setInputField({
      StoreName: "",
      ContactPerson: "",
      Email: "",
      PrimaryPhoneNo: "",
      SecondaryPhoneNumber: "",
      Address: "",
      StoreSize: "",
      country: "",
      State: "",
      City: "",
      CityId: "",
      StoreTypeId: "",
      StoreType: "",
      Longitude: "",
      Latitude: "",
      GSTIN: "",
      StoreCode: "",
    });
    if (params.storeId && storeId) {
      // updateData();
      navigate(`/app/ManageStore/${params.id}`);
      // window.location.reload();
      // navigate(`/app/ManageStore/${storeId}`);
    } else {
      // saveStoreDetails();
      // navigate(`/app/ManageStore/${params.accountId}`)
      navigate(`/app/ManageStore/${params.id}`);
      // window.location.reload();
    }
  };

  let formData = new FormData();
  formData.append("StoreName", inputField.StoreName);
  formData.append("StoreSize", inputField.StoreSize);
  formData.append("SecondaryPhoneNo", inputField.SecondaryPhoneNumber);
  formData.append("Email", inputField.Email);
  formData.append("ContactPerson", inputField.ContactPerson);
  formData.append("PrimaryPhoneNo", inputField.PrimaryPhoneNo);
  formData.append("CityId", inputField.CityId);
  formData.append("Country", inputField.country);
  formData.append("Address", inputField.Address);
  formData.append("GSTIN", inputField.GSTIN);
  formData.append("Latitude", inputField.Latitude);
  formData.append("Longitude", inputField.Longitude);
  formData.append("PinCode", inputField.PinCode);
  formData.append("StoreTypeId", inputField.StoreTypeId);
  formData.append("StoreCode", inputField.StoreCode);

  const [storeId, setStoreId] = useState();
  if (params.storeId) {
    formData.append("AccountId", storeId);
  } else if (getAccountId == "") {
    formData.append("AccountId", params.id);
  } else {
    // formData.append('AccountId', params.accountId)
    formData.append("AccountId", getAccountId);
  }
  useEffect(() => {
    if (params.storeId) {
      // getStoreData()
      dispatch(getStoreDetails(params.storeId));
    }
    if (params.accountId) {
      getAllStoreType(params.accountId);
    }
  }, []);

  useEffect(() => {
    if (storeDetail.storeDetails) {
      var accInfo = storeDetail.storeDetails;

      if (Object.keys(accInfo).length > 0) {
        setStoreId(accInfo.AccountId);
        var countryId = accInfo.City.State.CountryId;
        dispatch(getStateList(countryId));
        var stateId = accInfo.City.StateId;
        dispatch(getCityList(stateId));
        var StoreTypeId = accInfo.StoreType.AccountId;
        getAllStoreType(StoreTypeId);
        setInputField({
          AccountId: accInfo.AccountId != null ? accInfo.AccountId : "",
          accountName: accInfo.AccountId != null ? accInfo.AccountId : "",
          StoreName: accInfo.StoreName != null ? accInfo.StoreName : "",
          ContactPerson:
            accInfo.ContactPerson != null ? accInfo.ContactPerson : "",
          Email: accInfo.Email != null ? accInfo.Email : "",
          // StoreType: accInfo.StoreType.Name != null ? accInfo.StoreType.Name : "",
          PrimaryPhoneNo:
            accInfo.PrimaryPhoneNo != null ? accInfo.PrimaryPhoneNo : "",
          SecondaryPhoneNumber:
            accInfo.SecondaryPhoneNo != null ? accInfo.SecondaryPhoneNo : "",
          Address: accInfo.Address != null ? accInfo.Address : "",
          country:
            accInfo.City.State.CountryId != null
              ? accInfo.City.State.CountryId
              : "",
          State: accInfo.City.StateId != null ? accInfo.City.StateId : "",
          City: accInfo.City.Id != null ? accInfo.City.Id : "",
          CityId: accInfo.City.Id != null ? accInfo.City.Id : "",
          PinCode: accInfo.PinCode != null ? accInfo.PinCode : "",
          StoreSize: accInfo.StoreSize != null ? accInfo.StoreSize : "",
          Latitude: accInfo.Latitude != null ? accInfo.Latitude : "",
          Longitude: accInfo.Longitude != null ? accInfo.Longitude : "",
          StoreType: accInfo.StoreType.Id != null ? accInfo.StoreType.Id : "",
          StoreCode: accInfo.StoreCode != null ? accInfo.StoreCode : "",
          StoreTypeId: accInfo.StoreType.Id != null ? accInfo.StoreType.Id : "",
          GSTIN: accInfo.GSTIN != null ? accInfo.GSTIN : "",
        });
        setPreviewUrl("data:image/jpeg;base64," + accInfo.ImageData);
      }
    } else if (storeDetail.result == 200) {
      if (params.storeId && storeId) {
        toast("Store update successfully");
        setTimeout(() => {
          navigate(`/app/ManageStore/${storeId}`);
        }, 1000);
      } else {
        toast("Store save successfully");
        setTimeout(() => {
          navigate(`/app/ManageStore/${params.id}`);
        }, 1000);
      }
    } else if (storeDetail.imageData) {
      const imageUrl = storeDetail.imageData.FileData;
      setPreviewUrl("data:image/png;base64," + imageUrl);
    } else if (storeDetail.error.status == 404) {
      toast(storeDetail.error.data);
    } else if (storeDetail.error.status == 401) {
      toast(storeDetail.error.data);
    } else if (storeDetail.error.status == 403) {
      toast(storeDetail.error.data);
    } else if (storeDetail.error.status == 400) {
      toast(storeDetail.error.data);
    } else if (storeDetail.error.status == undefined) {
    } else {
      if (storeDetail.error != "") {
        toast(storeDetail.error);
      }
      toast("Something went wrong. Please try again later. ");
    }
  }, [storeDetail]);

  //validation Type

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    let formIsValid = true;
    if (values.StoreName.length < 3) {
      errors.StoreName = "Please enter greater then 3 character";
      formIsValid = false;
    }
    if (values.StoreName.length > 30) {
      errors.StoreName = "Please enter less than 30 character";
      formIsValid = false;
    }
    if (!regexConstant.regexForSpec.test(values.StoreName)) {
      errors.StoreName = "Please enter valid store name";
      formIsValid = false;
    }
    if (!values.StoreName) {
      errors.StoreName = "Store Name is required!";
      formIsValid = false;
    }
    if (values.ContactPerson.length < 3) {
      errors.ContactPerson = "Please enter greater then 3 character";
      formIsValid = false;
    }
    if (values.ContactPerson.length > 30) {
      errors.ContactPerson = "Please enter less than 30 character";
      formIsValid = false;
    }
    if (!regexConstant.regexForSpec.test(values.ContactPerson)) {
      errors.ContactPerson = "Please enter valid contact persone name";
      formIsValid = false;
    }
    if (!values.ContactPerson) {
      errors.ContactPerson = "Contact Person is required!";
      formIsValid = false;
    }
    if (!regexConstant.regex.test(values.Email)) {
      errors.Email = "Email is not valid!";
      formIsValid = false;
    }
    if (!values.Email) {
      errors.Email = "Email is required!";
      formIsValid = false;
    }
    if (!regexConstant.onlyTennumber.test(values.PrimaryPhoneNo)) {
      errors.PrimaryPhoneNo = "Please enter 10 digit number!";
      formIsValid = false;
    }
    if (!regexConstant.NumberRegex.test(values.PrimaryPhoneNo)) {
      errors.PrimaryPhoneNo = "Please enter a valid primary phone number";
      formIsValid = false;
    }
    if (!values.PrimaryPhoneNo) {
      errors.PrimaryPhoneNo = "Primary Phone No is required!";
      formIsValid = false;
    }
    if (!values.StoreType) {
      errors.StoreType = "Store Type  is required!";
      formIsValid = false;
    }
    if (values.StoreSize.length > 5) {
      errors.StoreSize = "Please enter less than 5 character";
      formIsValid = false;
    }
    if (!values.StoreSize) {
      errors.StoreSize = "Store Size is required!";
      formIsValid = false;
    }
    if (values.StoreSize && !regexConstant.regexNumber.test(values.StoreSize)) {
      errors.StoreSize = "Please enter numbers only";
      formIsValid = false;
    }
    if (!values.country) {
      errors.country = "Country is required!";
      formIsValid = false;
    }
    if (
      values.PinCode &&
      !regexConstant.pincodeValidRegex.test(values.PinCode)
    ) {
      errors.PinCode = "Please enter valid pincode";
      formIsValid = false;
    }
    if (values.PinCode && !regexConstant.numberOnly.test(values.PinCode)) {
      errors.PinCode = "Please enter number only";
      formIsValid = false;
    }
    if (values.PinCode.length > 6) {
      errors.PinCode = "Please enter 6 digit pincode";
      formIsValid = false;
    }

    if (!values.State) {
      errors.State = "State is required!";
      formIsValid = false;
    }

    if (!values.City) {
      errors.City = "City is required!";
      formIsValid = false;
    }
    if (!regexConstant.regexForSpec.test(values.Address)) {
      errors.Address = "Please enter valid address";
      formIsValid = false;
    }
    if (values.Address.length < 3) {
      errors.Address = "Please enter greater then 3 character";
      formIsValid = false;
    }
    if (values.Address.length > 50) {
      errors.Address = "Please enter less than 50 character";
      formIsValid = false;
    }
    if (!values.Address) {
      errors.Address = "Address is required!";
      formIsValid = false;
    }
    if (values.GSTIN && !regexConstant.GstRegex.test(values.GSTIN)) {
      errors.GSTIN = "Invalid GST Number.";
      formIsValid = false;
    }
    if (
      values.SecondaryPhoneNumber &&
      !regexConstant.NumberRegex.test(values.SecondaryPhoneNumber)
    ) {
      errors.SecondaryPhoneNumber =
        "Please enter a valid secondary phone number";
      formIsValid = false;
    }
    if (
      values.SecondaryPhoneNumber &&
      !regexConstant.onlyTennumber.test(values.SecondaryPhoneNumber)
    ) {
      errors.SecondaryPhoneNumber =
        "Please enter a valid secondary phone number";
      formIsValid = false;
    }
    if (values.Latitude && !regexConstant.LatLongRegex.test(values.Latitude)) {
      errors.Latitude = "Please enter valid latitude";
      formIsValid = false;
    }
    if (
      values.Longitude &&
      !regexConstant.LatLongRegex.test(values.Longitude)
    ) {
      errors.Longitude = "Please enter valid longitude";
      formIsValid = false;
    }
    setFormErrors(errors);
    return formIsValid;
  };
  // Close here
  const saveStoreDetails = () => {
    dispatch(saveStore(formData));
  };
  // Update Store
  const updateData = () => {
    if (logoImage != "") {
      formData.append("file", logoImage);
    }
    dispatch(updateStore(formData, params.storeId));
  };
  const location = useLocation();
  const submitForm = (e) => {
    e.preventDefault();

    if (validate(inputField)) {
      setIsSubmit(true);

      if (params.storeId && storeId) {
        updateData();
      } else {
        saveStoreDetails();
      }
    }
  };
  // using for state
  const getAllStateInput = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      country: e.target.value,
      State: "",
      CityId: "",
      City: "",
    }));
    dispatch(getStateList(e.target.value));
    dispatch(getCityList(e.target.value));
  };
  // using for city
  const getAllCityInput = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      CityId: e.target.value,
      City: e.target.value,
    }));
  };
  // get all City
  const getCityByState = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      State: e.target.value,
      CityId: "",
      City: "",
    }));
    dispatch(getCityList(e.target.value));
  };
  // close all city
  const getAllStore = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      StoreTypeId: e.target.value,
      StoreType: e.target.value,
    }));
  };
  const [getUserRoles, setUserRoles] = useState("");
  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    setAccountId(userDetail.accountId);
    if (userDetail.userRole == 1) {
      getAllStoreType(params.id);
    } else {
      getAllStoreType(userDetail.accountId);
    }
    setUserRoles(userDetail.userRole);
    getAllAccount();
  }, []);
  // Store Type
  const [StoreType, setStoreType] = useState([]);
  const getAllStoreType = (StoreTypeId) => {
    ApiServices.getData(`/StoreType/all/${StoreTypeId}`)
      .then((res) => {
        setStoreType(res.data);
      })
      .catch((e) => {
        return e;
      });
  };
  // File Uploaded
  if (logoImage != null) {
    formData.append("file", logoImage);
  }
  const getLogoImage = (storeId, e) => {
    dispatch(getLogoImageData(storeId));
  };
  //Upload logo
  const [fileError, setFileError] = useState("");
  const uploadImage = (e) => {
    setFileError("");
    let file = e.target.files[0];
    var idxDot = file.name.lastIndexOf(".") + 1;
    var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
    if (extFile == "jpg" || extFile == "png") {
      if (file.type.indexOf("image") == -1) {
        setFileError("Invalid File!");
        return false;
      } else if (file.size >= FileSizes.sizeFiveMb) {
        setFileError("Please select a file less than 5 mb");
        return false;
      } else {
        setLogoImage(file);
        setFileName(file.name);
        setPreviewUrl(URL.createObjectURL(file));
      }
    } else {
      setFileError("Only jpg/jpeg, png files are allowed!");
    }
  };

  const [acoountData, setAccountData] = useState([]);
  const getAllAccountId = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      // Roles: event.target.value
      StoreTypeId: e.target.value,
      accountName: e.target.value,
    }));
    setCurrentUserId(e.target.value);
    getAllStoreType(e.target.value);
  };
  const getAllAccount = () => {
    ApiServices.getData(`/Account/all/dropdown`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((res) => {});
  };
  return (
    <div style={{ marginBottom: "30px" }}>
      <ToastContainer autoClose={1000} />
      {/* --------------btn section----------- */}
      <Grid container sx={{ marginLeft: "-5px" }}>
        <Grid item xs={12} sm={12} md={11}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Box
               {...styles.tab}
              >
                <Box
                 {...styles.tabInside}
                >
                  {storeId ? "Update Store" : "New Store"}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
          <DefaultButton
            color="warning"
            variant="contained"
            size="small"
            Text="Back"
          />
        </Grid>
      </Grid>
{/* -----------frorm----------- */}
      <form onSubmit={submitForm}>
        <Card className="border-radius-8 width-100" sx={{ marginTop: "-25px" }}>
          <div className="p-4">
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="StoreName"
                    label="Store Name / Brand Name *"
                    variant="standard"
                    name="StoreName"
                    value={inputField.StoreName}
                    onChange={inputsHandler}
                    placeHolder="Store Name"
                  />
                  <div className="errors">{formErrors.StoreName}</div>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="ContactPerson"
                    label="Contact Person *"
                    variant="standard"
                    name="ContactPerson"
                    value={inputField.ContactPerson}
                    onChange={inputsHandler}
                    placeHolder="Contact Person"
                  />
                  <div className="errors">{formErrors.ContactPerson}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="Email"
                    label="Email Id *"
                    variant="standard"
                    name="Email"
                    value={inputField.Email}
                    onChange={inputsHandler}
                    placeHolder="Email Id"
                  />
                  <div className="errors">{formErrors.Email}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <DefaultInput
                        id="PrimaryPhoneNo"
                        label="Primary Phone Number *"
                        variant="standard"
                        name="PrimaryPhoneNo"
                        value={inputField.PrimaryPhoneNo}
                        inputProps={{ maxLength: 10 }}
                        onChange={inputsHandler}
                        placeHolder="Primary Phone Number"
                      />
                      <div className="errors">{formErrors.PrimaryPhoneNo}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <DefaultInput
                        id="SecondaryPhoneNumber"
                        label="Secondary Phone Number"
                        variant="standard"
                        name="SecondaryPhoneNumber"
                        value={inputField.SecondaryPhoneNumber}
                        onChange={inputsHandler}
                        inputProps={{ maxLength: 10 }}
                        placeHolder="Secondary Phone Number"
                      />
                      <div className="errors">
                        {formErrors.SecondaryPhoneNumber}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="Address"
                    label="Address*"
                    multiline
                    name="Address"
                    rows={3}
                    value={inputField.Address}
                    onChange={inputsHandler}
                  />
                  <div className="errors">{formErrors.Address}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        label="Store Type *"
                        name="StoreType"
                        value={inputField.StoreType}
                        onChange={getAllStore}
                      >
                        {StoreType.map((option) => (
                          <MenuItem key={option.id} value={option.Id}>
                            {option.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <div className="errors">{formErrors.StoreType}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <DefaultInput
                        id="StoreSize"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              Sq ft
                            </InputAdornment>
                          ),
                        }}
                        label="Store Size *"
                        variant="standard"
                        name="StoreSize"
                        value={inputField.StoreSize}
                        onChange={inputsHandler}
                        placeHolder="Store Size"
                      />
                      <div className="errors">{formErrors.StoreSize}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        label="Select Country *"
                        value={inputField.country}
                        onChange={getAllStateInput}
                      >
                        {country.map((option) => (
                          <MenuItem key={option.value} value={option.Id}>
                            {option.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <div className="errors">{formErrors.country}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        label="State *"
                        value={inputField.State}
                        onChange={getCityByState}
                      >
                        {State.map((option) => (
                          <MenuItem key={option.Id} value={option.Id}>
                            {option.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <div className="errors">{formErrors.State}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        label="City *"
                        value={inputField.City}
                        onChange={getAllCityInput}
                      >
                        {City.map((option) => (
                          <MenuItem key={option.Id} value={option.Id}>
                            {option.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <div className="errors">{formErrors.City}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <DefaultInput
                        id="PinCode"
                        label="Pincode"
                        variant="standard"
                        name="PinCode"
                        value={inputField.PinCode}
                        onChange={inputsHandler}
                        inputProps={{ maxLength: 6 }}
                        placeHolder="Pincode"
                      />
                      <div className="errors">{formErrors.PinCode}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                      <DefaultInput
                        id="GSTIN"
                        label="GSTIN"
                        variant="standard"
                        name="GSTIN"
                        value={inputField.GSTIN}
                        onChange={inputsHandler}
                        inputProps={{ maxLength: 15 }}
                        placeHolder="GSTIN"
                      />
                      <div className="errors">{formErrors.GSTIN}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <DefaultInput
                        id="Latitude"
                        label="Latitude"
                        variant="standard"
                        name="Latitude"
                        value={inputField.Latitude}
                        onChange={inputsHandler}
                        placeHolder="Latitude"
                      />
                      <div className="errors">{formErrors.Latitude}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <DefaultInput
                        id="Longitude"
                        label="Longitude"
                        variant="standard"
                        name="Longitude"
                        value={inputField.Longitude}
                        onChange={inputsHandler}
                        placeHolder="Longitude"
                      />
                      <div className="errors">{formErrors.Longitude}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div class="drop_box">
                    <Grid item xs={12} sm={12} md={12} className="mb-12">
                      <label htmlFor="contained-button-file">
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          id="logoImage"
                          onChange={uploadImage}
                        />
                        <label
                          for="logoImage"
                          class="btn btn-warning"
                          tabindex="0"
                        >
                          Upload Store Logo
                        </label>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}></Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {previewURL != null ? (
                        <div className="img-section" for="logoImage">
                          <img src={previewURL} width="200" height="100" />{" "}
                        </div>
                      ) : null}
                    </Grid>
                    <div className="text-align-center">
                      <p>Files Supported: JPG, PNG</p>
                    </div>
                  </div>
                  <div className="errors">{fileError}</div>
                  {/* <label htmlFor="contained-button-file">
                                                <input type="file" className="custom-file-input" accept="image/*" id="logoImage" onChange={uploadImage} />
                                                <div className='errors'>{fileError}</div>
                                            </label> */}
                </Grid>
              </Grid>
            </div>
            <div className="buttonSecton">
              <div className="mx-3" style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  {...styles.SaveBtn}
                  className="btn-orange "
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </form>
    </div>
  );
}
