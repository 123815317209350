import React, { useState } from "react";
import { Box, TextField, Card, Button, Grid } from "@mui/material";
import ApiServices from "../../Services/ApiServices";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DefaultButton from "../../Component/Button";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function ConfirmPassword() {
  const [hidden, setHidden] = useState(true);
  const [confrimPass, setConfirmPass] = useState(true);
  const navigate = useNavigate();
  const resetData = () => {
    navigate(`/app/ManageAccount`);
  };

  const valiadtionSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current Password is mendatory"),
    password: Yup.string()
      .required("Password is mendatory")
      .min(3, "Password must be at 3 char long"),
    conformPassword: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      conformPassword: "",
    },
    validationSchema: valiadtionSchema,
    onSubmit: (values) => {
      ApiServices.saveData(`/User/changePassword`, {
        currentPassword: values.currentPassword,
        newPassword: values.password,
      })
        .then((res) => {
          if (res.status == 200) {
            toast("Password update successfully");
            setTimeout(() => {
              navigate(`/app/ManageAccount`);
            }, 1000);
          } else if (res.error.status == 404) {
            toast(res.error.data);
          } else if (res.error.status == 401) {
            toast(res.error.data);
          } else if (res.error.status == 403) {
            toast(res.error.data);
          }
        })
        .catch((res) => {});
    },
  });

  const { handleSubmit, handleChange, handleBlur, errors, touched, values } =
    formik;

  const toggleShow = (e) => {
    setHidden(!hidden);
  };
  const confirmToggleShow = (e) => {
    setConfirmPass(!confrimPass);
  };

  return (
    <Box className="mt-5">
      <ToastContainer autoClose={1000} />
      <Grid container sx={{ marginLeft: "-5px " }}>
        <Grid item xs={12} sm={12} md={11}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  color: " #838181",
                  fontFamily: "Lato",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  background: " #fff",
                  padding: " 0.8rem 0",
                  paddingBottom: "2.5rem",
                  border: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "3px solid orange !important",
                    width: "100%!important",
                  }}
                >
                  Change Password
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
          <DefaultButton
            color="warning"
            variant="contained"
            size="small"
            Text="Back"
          />
        </Grid>
      </Grid>
      {/* ------------------Form start-------------------- */}
      <form onSubmit={handleSubmit}>
        <Card className="border-radius-8 width-100">
          <Box className="p-4">
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Box className="form-group">
                  <TextField
                    id="CurrentPassword"
                    size="small"
                    label="Current Password"
                    name="CurrentPassword"
                    type="text"
                    placeholder="Enter Current Password"
                    // {...register("CurrentPassword")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currentPassword}
                  />
                  {errors.currentPassword && touched.currentPassword ? (
                    <Box className="errors">{errors.currentPassword}</Box>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="form-group">
                  <TextField
                    label="New Password"
                    id="password"
                    size="small"
                    name="password"
                    type={hidden ? "password" : "text"}
                    placeholder="Enter New password"
                    // {...register("password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <Box className="password-button">
                    <Button onClick={toggleShow}>
                      {hidden ? <VisibilityOff /> : <Visibility />}
                    </Button>
                  </Box>
                  {errors.password && touched.password ? (
                    <Box className="errors">{errors.password}</Box>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="form-group">
                  <TextField
                    label="Confirm Password"
                    id="confirmPwd"
                    size="small"
                    name="conformPassword"
                    type={confrimPass ? "password" : "text"}
                    placeholder="Enter confirm password"
                    // {...register("confirmPwd")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.conformPassword}
                  />
                  <Box className="password-button">
                    <Button onClick={confirmToggleShow}>
                      {confrimPass ? <VisibilityOff /> : <Visibility />}
                    </Button>
                  </Box>
                  {errors.conformPassword && touched.conformPassword ? (
                    <Box className="errors">{errors.conformPassword}</Box>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
            <Box className="buttonSecton" style={{ marginTop: "20px" }}>
              <Box className="mx-3">
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{
                    padding: "0.7rem 4rem",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                  className="btn-orange "
                >
                  Save
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  onClick={resetData}
                  sx={{
                    padding: "0.7rem 4rem",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                  className="btn-orange "
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      </form>
    </Box>
  );
}
