import { useEffect } from "react";
import { Checkbox } from "@material-ui/core";
import { useState } from "react";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import ApiServices from "../../Services/ApiServices";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import DefaultButton from "../../Component/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
export default function App() {
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState({
    ViewAccount: false,
    AddEditAccount: false,
    ActiveInactiveAccount: false,
  });
  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);

  // Store
  const [checkedAllStore, setCheckedAllStore] = useState(false);
  const [checkedStore, setCheckedStore] = useState({
    ViewStore: false,
    AddEditStore: false,
    ActiveInactiveStore: false,
  });
  const toggleCheckStore = (inputName) => {
    setCheckedStore((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAllStore = (value) => {
    setCheckedAllStore(value);
    setCheckedStore((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  useEffect(() => {
    let allCheckedStore = true;
    for (const inputName in checkedStore) {
      if (checkedStore[inputName] === false) {
        allCheckedStore = false;
      }
    }
    if (allCheckedStore) {
      setCheckedAllStore(true);
    } else {
      setCheckedAllStore(false);
    }
  }, [checkedStore]);
  // Terminal
  const [checkedAllTerminal, setCheckedAllTerminal] = useState(false);
  const [checkedTerminal, setCheckedTerminal] = useState({
    ViewTerminal: false,
    AddEditTerminal: false,
    ActiveInactiveTerminal: false,
  });
  const toggleCheckTerminal = (inputName) => {
    setCheckedTerminal((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAllTerminal = (value) => {
    setCheckedAllTerminal(value);
    setCheckedTerminal((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  useEffect(() => {
    let allCheckedTerminal = true;
    for (const inputName in checkedTerminal) {
      if (checkedTerminal[inputName] === false) {
        allCheckedTerminal = false;
      }
    }
    if (allCheckedTerminal) {
      setCheckedAllTerminal(true);
    } else {
      setCheckedAllTerminal(false);
    }
  }, [checkedTerminal]);

  // License
  const [checkedAllLicense, setCheckedAllLicense] = useState(false);
  const [checkedLicense, setCheckedLicense] = useState({
    ViewLicense: false,
    AddEditLicense: false,
    ActiveInactiveLicense: false,
  });
  const toggleCheckLicense = (inputName) => {
    setCheckedLicense((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAllLicense = (value) => {
    setCheckedAllLicense(value);
    setCheckedLicense((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  useEffect(() => {
    let allCheckedLicense = true;
    for (const inputName in checkedLicense) {
      if (checkedLicense[inputName] === false) {
        allCheckedLicense = false;
      }
    }
    if (allCheckedLicense) {
      setCheckedAllLicense(true);
    } else {
      setCheckedAllLicense(false);
    }
  }, [checkedLicense]);
  // Category
  const [checkedAllCategory, setCheckedAllCategory] = useState(false);
  const [checkedCategory, setCheckedCategory] = useState({
    ViewCategory: false,
    AddEditCategory: false,
    ActiveInactiveCategory: false,
  });
  const toggleCheckCategory = (inputName) => {
    setCheckedCategory((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAllCategory = (value) => {
    setCheckedAllCategory(value);
    setCheckedCategory((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  useEffect(() => {
    let allCheckedCategory = true;
    for (const inputName in checkedCategory) {
      if (checkedCategory[inputName] === false) {
        allCheckedCategory = false;
      }
    }
    if (allCheckedCategory) {
      setCheckedAllCategory(true);
    } else {
      setCheckedAllCategory(false);
    }
  }, [checkedCategory]);

  // SubCategory
  const [checkedAllSubCategory, setCheckedAllSubCategory] = useState(false);
  const [checkedSubCategory, setCheckedSubCategory] = useState({
    ViewSubCategory: false,
    AddEditSubCategory: false,
    ActiveInactiveSubCategory: false,
  });
  const toggleCheckSubCategory = (inputName) => {
    setCheckedSubCategory((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAllSubCategory = (value) => {
    setCheckedAllSubCategory(value);
    setCheckedSubCategory((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  useEffect(() => {
    let allCheckedSubCategory = true;
    for (const inputName in checkedSubCategory) {
      if (checkedSubCategory[inputName] === false) {
        allCheckedSubCategory = false;
      }
    }
    if (allCheckedSubCategory) {
      setCheckedAllSubCategory(true);
    } else {
      setCheckedAllSubCategory(false);
    }
  }, [checkedSubCategory]);
  // Products
  const [checkedAllProducts, setCheckedAllProducts] = useState(false);
  const [checkedProducts, setCheckedProducts] = useState({
    ViewProducts: false,
    AddEditProducts: false,
    ActiveInactiveProducts: false,
  });
  const toggleCheckProducts = (inputName) => {
    setCheckedProducts((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAllProducts = (value) => {
    setCheckedAllProducts(value);
    setCheckedProducts((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  useEffect(() => {
    let allCheckedProducts = true;
    for (const inputName in checkedProducts) {
      if (checkedProducts[inputName] === false) {
        allCheckedProducts = false;
      }
    }
    if (allCheckedProducts) {
      setCheckedAllProducts(true);
    } else {
      setCheckedAllProducts(false);
    }
  }, [checkedProducts]);
  // User
  const [checkedAllUser, setCheckedAllUser] = useState(false);
  const [checkedUser, setCheckedUser] = useState({
    ViewUser: false,
    AddEditUser: false,
    ActiveInactiveUser: false,
  });
  const toggleCheckUser = (inputName) => {
    setCheckedUser((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAllUser = (value) => {
    setCheckedAllUser(value);
    setCheckedUser((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  useEffect(() => {
    let allCheckedUser = true;
    for (const inputName in checkedUser) {
      if (checkedUser[inputName] === false) {
        allCheckedUser = false;
      }
    }
    if (allCheckedUser) {
      setCheckedAllUser(true);
    } else {
      setCheckedAllUser(false);
    }
  }, [checkedUser]);
  // common method for all
  useEffect(() => {
    getPermissionRole();
  }, []);
  const params = useParams();
  const getPermissionRole = () => {
    ApiServices.getData(`/UserPermission/${params.id}`).then((res) => {
      setChecked({
        ViewAccount: res.data.ViewAccount,
        AddEditAccount: res.data.AddEditAccount,
        ActiveInactiveAccount: res.data.ActiveInactiveAccount,
      });
      setCheckedStore({
        ViewStore: res.data.ViewStore,
        AddEditStore: res.data.AddEditStore,
        ActiveInactiveStore: res.data.ActiveInactiveStore,
      });
      setCheckedTerminal({
        ViewTerminal: res.data.ViewTerminal,
        AddEditTerminal: res.data.AddEditTerminal,
        ActiveInactiveTerminal: res.data.ActiveInactiveTerminal,
      });
      setCheckedUser({
        ViewUser: res.data.ViewUser,
        AddEditUser: res.data.AddEditUser,
        ActiveInactiveUser: res.data.ActiveInactiveUser,
      });
      setCheckedLicense({
        ViewLicense: res.data.ViewLicense,
        AddEditLicense: res.data.AddEditLicense,
        ActiveInactiveLicense: res.data.ActiveInactiveLicense,
      });
      setCheckedCategory({
        ViewCategory: res.data.ViewCategory,
        AddEditCategory: res.data.AddEditCategory,
        ActiveInactiveCategory: res.data.ActiveInactiveCategory,
      });
      setCheckedSubCategory({
        ViewSubCategory: res.data.ViewSubCategory,
        AddEditSubCategory: res.data.AddEditSubCategory,
        ActiveInactiveSubCategory: res.data.ActiveInactiveSubCategory,
      });
      setCheckedProducts({
        ViewProducts: res.data.ViewProducts,
        AddEditProducts: res.data.AddEditProducts,
        ActiveInactiveProducts: res.data.ActiveInactiveProducts,
      });
    });
  };
  const permissions = {
    ...checked,
    ...checkedStore,
    ...checkedTerminal,
    ...checkedLicense,
    ...checkedUser,
    ...checkedCategory,
    ...checkedSubCategory,
    ...checkedProducts,
  };
  const navigate = useNavigate();
  const submit = () => {
    ApiServices.saveData(`/UserPermission/create`, {
      userId: params.id,
      permissions: permissions,
    }).then((res) => {
      navigate(-1);
    });
  };
  return (
    <div style={{ alignItems: "center", margin: 10 }}>
      <div className="text-align-end">
        <DefaultButton
          color="warning"
          variant="contained"
          size="small"
          startIcon={<ArrowBackIosNewIcon />}
          Text="Back"
        />
      </div>
      <Card style={{ width: "100%" }} className="border-radius-20 mt-10 main">
        <div
          style={{ backgroundColor: "#dfdfdf", fontWeight: "bold" }}
          className="text-align-center p-5"
        >
          Users & Permissions
        </div>
        <Grid container spacing={1}>
          <Grid container item xs={12} sm={12} md={3}>
            <div style={{ padding: "3%" }}>
              <div className="config-main-label">Account</div>
              <div>
                <input
                  type="checkbox"
                  onChange={(event) => selectAll(event.target.checked)}
                  checked={checkedAll}
                />
                <label>All</label>
              </div>
              <div className="mx-20">
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ViewAccount"
                    onChange={() => toggleCheck("ViewAccount")}
                    checked={checked["ViewAccount"]}
                  />
                  <label>View</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="AddEditAccount"
                    onChange={() => toggleCheck("AddEditAccount")}
                    checked={checked["AddEditAccount"]}
                  />
                  <label>Add/Edit</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ActiveInactiveAccount"
                    onChange={() => toggleCheck("ActiveInactiveAccount")}
                    checked={checked["ActiveInactiveAccount"]}
                  />
                  <label>InActive</label>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} sm={12} md={3}>
            <div style={{ padding: "3%" }}>
              <div className="config-main-label">User</div>
              <div>
                <input
                  type="checkbox"
                  onChange={(event) => selectAllUser(event.target.checked)}
                  checked={checkedAllUser}
                />
                <label>All</label>
              </div>
              <div className="mx-20">
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ViewUser"
                    onChange={() => toggleCheckUser("ViewUser")}
                    checked={checkedUser["ViewUser"]}
                  />
                  <label>View</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="AddEditUser"
                    onChange={() => toggleCheckUser("AddEditUser")}
                    checked={checkedUser["AddEditUser"]}
                  />
                  <label>Add/Edit</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ActiveInactiveUser"
                    onChange={() => toggleCheckUser("ActiveInactiveUser")}
                    checked={checkedUser["ActiveInactiveUser"]}
                  />
                  <label>InActive</label>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} sm={12} md={3}>
            <div style={{ padding: "3%" }}>
              <div className="config-main-label">Store</div>
              <div>
                <input
                  type="checkbox"
                  onChange={(event) => selectAllStore(event.target.checked)}
                  checked={checkedAllStore}
                />
                <label>All</label>
              </div>
              <div className="mx-20">
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ViewStore"
                    onChange={() => toggleCheckStore("ViewStore")}
                    checked={checkedStore["ViewStore"]}
                  />
                  <label>View</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="AddEditStore"
                    onChange={() => toggleCheckStore("AddEditStore")}
                    checked={checkedStore["AddEditStore"]}
                  />
                  <label>Add/Edit</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ActiveInactiveStore"
                    onChange={() => toggleCheckStore("ActiveInactiveStore")}
                    checked={checkedStore["ActiveInactiveStore"]}
                  />
                  <label>InActive</label>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} sm={12} md={3}>
            <div style={{ padding: "3%" }}>
              <div className="config-main-label">Terminal</div>
              <div>
                <input
                  type="checkbox"
                  onChange={(event) => selectAllTerminal(event.target.checked)}
                  checked={checkedAllTerminal}
                />
                <label>All</label>
              </div>
              <div className="mx-20">
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ViewTerminal"
                    onChange={() => toggleCheckTerminal("ViewTerminal")}
                    checked={checkedTerminal["ViewTerminal"]}
                  />
                  <label>View</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="AddEditTerminal"
                    onChange={() => toggleCheckTerminal("AddEditTerminal")}
                    checked={checkedTerminal["AddEditTerminal"]}
                  />
                  <label>Add/Edit</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ActiveInactiveTerminal"
                    onChange={() =>
                      toggleCheckTerminal("ActiveInactiveTerminal")
                    }
                    checked={checkedTerminal["ActiveInactiveTerminal"]}
                  />
                  <label>InActive</label>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} sm={12} md={3}>
            <div style={{ padding: "3%" }}>
              <div className="config-main-label">License</div>
              <div>
                <input
                  type="checkbox"
                  onChange={(event) => selectAllLicense(event.target.checked)}
                  checked={checkedAllLicense}
                />
                <label>All</label>
              </div>
              <div className="mx-20">
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ViewLicense"
                    onChange={() => toggleCheckLicense("ViewLicense")}
                    checked={checkedLicense["ViewLicense"]}
                  />
                  <label>View</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="AddEditLicense"
                    onChange={() => toggleCheckLicense("AddEditLicense")}
                    checked={checkedLicense["AddEditLicense"]}
                  />
                  <label>Add/Edit</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ActiveInactiveLicense"
                    onChange={() => toggleCheckLicense("ActiveInactiveLicense")}
                    checked={checkedLicense["ActiveInactiveLicense"]}
                  />
                  <label>InActive</label>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} sm={12} md={3}>
            <div style={{ padding: "3%" }}>
              <div className="config-main-label">Category</div>
              <div>
                <input
                  type="checkbox"
                  onChange={(event) => selectAllCategory(event.target.checked)}
                  checked={checkedAllCategory}
                />
                <label>All</label>
              </div>
              <div className="mx-20">
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ViewCategory"
                    onChange={() => toggleCheckCategory("ViewCategory")}
                    checked={checkedCategory["ViewCategory"]}
                  />
                  <label>View</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="AddEditCategory"
                    onChange={() => toggleCheckCategory("AddEditCategory")}
                    checked={checkedCategory["AddEditCategory"]}
                  />
                  <label>Add/Edit</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ActiveInactiveCategory"
                    onChange={() =>
                      toggleCheckCategory("ActiveInactiveCategory")
                    }
                    checked={checkedCategory["ActiveInactiveCategory"]}
                  />
                  <label>InActive</label>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} sm={12} md={3}>
            <div style={{ padding: "3%" }}>
              <div className="config-main-label">Sub Category</div>
              <div>
                <input
                  type="checkbox"
                  onChange={(event) =>
                    selectAllSubCategory(event.target.checked)
                  }
                  checked={checkedAllSubCategory}
                />
                <label>All</label>
              </div>
              <div className="mx-20">
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ViewSubCategory"
                    onChange={() => toggleCheckSubCategory("ViewSubCategory")}
                    checked={checkedSubCategory["ViewSubCategory"]}
                  />
                  <label>View</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="AddEditSubCategory"
                    onChange={() =>
                      toggleCheckSubCategory("AddEditSubCategory")
                    }
                    checked={checkedSubCategory["AddEditSubCategory"]}
                  />
                  <label>Add/Edit</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ActiveInactiveSubCategory"
                    onChange={() =>
                      toggleCheckSubCategory("ActiveInactiveSubCategory")
                    }
                    checked={checkedSubCategory["ActiveInactiveSubCategory"]}
                  />
                  <label>InActive</label>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} sm={12} md={3}>
            <div style={{ padding: "3%" }}>
              <div className="config-main-label">Products</div>
              <div>
                <input
                  type="checkbox"
                  onChange={(event) => selectAllProducts(event.target.checked)}
                  checked={checkedAllProducts}
                />
                <label>All</label>
              </div>
              <div className="mx-20">
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ViewProducts"
                    onChange={() => toggleCheckProducts("ViewProducts")}
                    checked={checkedProducts["ViewProducts"]}
                  />
                  <label>View</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="AddEditProducts"
                    onChange={() => toggleCheckProducts("AddEditProducts")}
                    checked={checkedProducts["AddEditProducts"]}
                  />
                  <label>Add/Edit</label>
                </div>
                <div className="mt-10">
                  <input
                    type="checkbox"
                    name="ActiveInactiveProducts"
                    onChange={() =>
                      toggleCheckProducts("ActiveInactiveProducts")
                    }
                    checked={checkedProducts["ActiveInactiveProducts"]}
                  />
                  <label>InActive</label>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="text-align-center">
          <div className="my-15">
            <Button
              variant="contained"
              type="submit"
              size="small"
              onClick={submit}
              className="border-radius-20 btn-orange w-100"
              style={{ width: "40%" }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
