import React, { useState, useEffect, } from "react";
import {
  IconButton,
  TextField,
  Button,
  Box,
  Tabs,
  Grid,
  Avatar,
  TablePagination,
  CircularProgress,
  Backdrop,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  Tab,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddAltRounded from "@mui/icons-material/PersonAddAltRounded";
import SearchIcon from "@material-ui/icons/Search";
import { userRoles, regexConstant } from "../../shared/constants";
import { toast, ToastContainer } from "react-toastify";
import {
  getDataByCategoryDetails,
  getInactiveDataByPagination,
} from "../../Redux/Category/CategoryAction";
import apiService from "../../Services/ApiServices";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import editImg from "../../assets/Images/edit.png";
import Loader from "../../Component/Loader";
import DeactiveDialog from "../../Component/DeactiveDialog";
import ActivateDialog from "../../Component/ActivateDialog";
import ApplyDialog from "../../Component/ApplyDialog/ApplyDialog";
// ----------TablePanel-------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`,
  };
}

const pageState = {
  skip: 0,
  top: 10,
  isDeleted: false,
  query: "",
  accountId: "",
};
const useStyles = makeStyles({
  tableCell: {
    "&.MuiTableCell-root": {
      border: "none!important",
      padding: "10px",
    },
  },
});
export default function BasicTable() {
  // constants
  const classes = useStyles();
  const dispatch = useDispatch();
  const categoryData = useSelector((s) => s.categoryReducer);
  console.log("categoryData",categoryData)
  const { loading } = useSelector((state) => ({
    loading: state.categoryReducer.loading,
  }));
  const label = { inputProps: { "aria-label": "Switch demo" } };

  // data for table
  const [getData, setData] = useState([]);
  const [InactiveData, setInactiveData] = useState([]);
  // account data
  const [acoountData, setAccountData] = useState([]);
  const [accountValue, setAccountValue] = useState("");

  // loading
  const [isLoading, setIsLoading] = useState(false);
  const [noFoundData, setnoFoundData] = useState(true);

  // search field
  const [inputValue, setInputValue] = useState("");
  const [search, setSearch] = useState("");
  const [handleIcon, setIcon] = useState(true);

  // pegination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const [totalItemData, setTotalItemData] = useState(1);
  const [currentUserDetails, setCurrentUser] = useState({});

  // Modal Section
  const [open, setOpen] = useState(false);
  const [ModalDataId, setModalDataId] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [MenuDataId, setMenuDataId] = useState();

  // from userRoll
  const [currentUserRole, setRole] = useState("");
  const [value, setValue] = useState(0);
  const [getPermission, setPermission] = useState({});

  // --------intial List Call----------------
  const intialListCall = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByCategoryDetails(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  // -------------UseEffects-------------
  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    let newObj = {};
    userDetail.permissions.forEach((element) => {
      newObj[`${element}`] = element;
    });
    setPermission(newObj);
    if (userDetail) {
      setRole(userDetail.userRole);
      if (userDetail.userRole != userRoles.SuperAdmin) {
        pageState.accountId = userDetail.accountId;
      }
    }
    pageState.skip = 0;
    pageState.top = 10;
    pageState.accountId="";
    dispatch(getDataByCategoryDetails(pageState));
    intialListCall();
  }, []);

  useEffect(() => {
    if (!categoryData.noFoundData) {
      if (Boolean(categoryData.isAccountActive) === true) {
        setData(categoryData.category.Data);
        setTotalItemData(
          categoryData.category.TotalItems
            ? categoryData.category.TotalItems
            : 1
        );
      } else if (categoryData.isAccountActive == false) {
        setInactiveData(categoryData.category.Data);
        setTotalItemData(
          categoryData.category.TotalItems
            ? categoryData.category.TotalItems
            : 1
        );
      }
      setIsLoading(false);
      setnoFoundData(true);
    } else {
      setIsLoading(false);
      setnoFoundData(false);
    }
  }, [categoryData]);

  useEffect(() => {
    getAllAccount();
  }, []);

  // -------------Pegination event-----------------
  const handleChange = (event, newValue) => {
    const indexValue = event.target.id.split("-")[2];
    setValue(newValue);
    if (indexValue > 0) {
      pageState.isDeleted = true;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      setRowsPerPage(parseInt(pageState.top));
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      pageState.isDeleted = false;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      dispatch(getDataByCategoryDetails(pageState));
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByCategoryDetails(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);

    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getDataByCategoryDetails(pageState));
    }
  };

  // --------Search events-----------------
  const resetInputField = (e) => {
    setSearch("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    setInputValue("");
    setRowsPerPage(parseInt(pageState.top));
    setInputValue("");
    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getDataByCategoryDetails(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };
  const handleUserInput = (e) => {
    if (e.target.value) {
      setIcon(false);
    }
    if (e.target.value === "") {
      setIcon(true);
    }
    setInputValue(e.target.value);
  };
  const SearchData = () => {
    console.log("search call")
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    pageState.query = inputValue;
    console.log("pageState.query",pageState.query)
    if (
      pageState.query == "" ||
      !regexConstant.searchSpace.test(pageState.query)
    ) {
      toast("Please Enter the search key");
    }

    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByCategoryDetails(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  // -----------Model event------------
  const handleOpenModal = (e) => {
    setOpen(true);
    setModalDataId(e.target.id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteDataId = () => {
    console.log("ModalDataId", ModalDataId);
    apiService
      .deleteData(`/AccountCategory/${ModalDataId}`)
      .then((res) => {
        setOpen(false);
        setIsLoading(true);
        dispatch(getDataByCategoryDetails(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
  const getActiveDataById = () => {
    apiService
      .saveData(`/AccountCategory/activate`, { categoryId: ModalDataId })
      .then((res) => {
        setOpen(false);
        dispatch(getInactiveDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
  // -------------Action menu evnr--------------
  const OpenMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuDataId(event.currentTarget.id);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // ----------Account data------
  const getAllAccount = () => {
    apiService
      .getData(`/Account/all/dropdown`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((res) => {});
  };
  const getAccountId = (e) => {
    setAccountValue(e.target.value);
    pageState.accountId = e.target.value;
  };
  //  ----------filter and reset event----------
  const accountFilter = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    if (accountValue) {
      pageState.accountId = accountValue;
    }
    if(pageState.accountId === ""){
      setOpenApply(true)
    }
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByCategoryDetails(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  const resetData = (e) => {
    setSearch("");
    setAccountValue("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    pageState.accountId = "";
    setRowsPerPage(parseInt(pageState.top));
    // setInputValue("");
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByCategoryDetails(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };
  const [openApply, setOpenApply] = React.useState(false);
  const handleCloseApply =()=>{
    setOpenApply(false)
  }

  return (
    <div>
      <div>
        {/* -----------Header section------------- */}
        <div className="justify-content-space">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer }}
            open={isLoading}
          >
            <CircularProgress style={{ color: "#ea7626" }} />
          </Backdrop>
          <div className="poppins-bold">Manage Category</div>
          {getPermission.AddEditCategory == "AddEditCategory" ? (
            <div>
              <div className="text-align-end showMobile my-8">
                <Link to="/app/AddCategory" className="textDecoration">
                  <Button>
                    <Button>
                      <Avatar
                        sx={{ border: "1px solid #9747FF" }}
                        style={{ backgroundColor: "white" }}
                      >
                        <PersonAddAltRounded sx={{ color: "#9747FF" }} />
                      </Avatar>
                    </Button>
                  </Button>
                </Link>
              </div>
              <div className="showINWindow">
                <Link to="/app/AddCategory" className="textDecoration">
                  <Button
                    variant="outlined"
                    size="small"
                    className="border-radius-20 roboto"
                    style={{
                      boxShadow: 3,
                      borderRadius: "15px",
                      background: "#fff",
                      color: "#8B8D9D",
                      border: "none",
                      fontWeight: "100 !important",
                      padding: "5px 16px",
                    }}
                  >
                    <AddIcon
                      color="warning"
                      className="mx-5"
                      sx={{ color: "#9747FF" }}
                    />
                    ADD CATEGORY
                  </Button>
                </Link>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {/*  ------------------Filter section-----------*/}
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={5}>
            <div>
              <div className="pt-10 search-section">
                <div>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={11}>
                      <TextField
                        fullWidth
                        id="userName"
                        size="small"
                        type="Text"
                        onBlur={e => setSearch(e.target.value)}
                        value={inputValue}
                        onChange={handleUserInput}
                        classes={{ root: classes.customTextField }}
                        placeholder="Search Here"
                        sx={{
                          boxShadow: 5,
                          borderRadius: "10px",
                          background: "white",
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none !important",
                            },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="set-icon-right"
                              position="start"
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={resetInputField}
                                color="warning"
                              >
                                {handleIcon ? <SearchIcon /> : <CancelIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyPress={(e) => {
                          console.log(e.key)
                          if (e.key === "Enter") {
                            SearchData();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                      <div className="mt-5">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={SearchData}
                          className="border-radius-20 btn-orange w-100"
                        >
                          Search
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <div>
              <div className=" pt-10">
                <Box>
                  <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={value}
                    key={value}
                    onChange={handleChange}
                    aria-label="horizontal tabs example"
                  >
                    <Tab
                      label="Active"
                      {...a11yProps(0)}
                      className="roboto-700"
                    />
                    <Tab
                      label="InActive"
                      {...a11yProps(1)}
                      className="roboto-700"
                    />
                  </Tabs>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>

        {currentUserRole == 1 ? (
          <div>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={12} sm={12} md={3}>
                <div>
                  <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    label="Account"
                    name="accountWithId"
                    onChange={getAccountId}
                    value={accountValue}
                    sx={{
                      boxShadow: 5,
                      borderRadius: "10px",
                      background: "white",
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "none !important",
                        },
                    }}
                  >
                    {acoountData.map((option) => (
                      <MenuItem key={option.AccountName} value={option.Id}>
                        {option.AccountName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className="gridButtonSection">
                  <div className="mx-3">
                    <Button
                      variant="contained"
                      onClick={accountFilter}
                      size="small"
                      className="border-radius-20 btn-orange w-100"
                    >
                      Apply
                    </Button>
                  </div>
                  <div className="mx-3">
                    <div>
                      <Button
                        variant="contained"
                        onClick={resetData}
                        size="small"
                        className="border-radius-20 btn-orange w-100"
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* ----------Table section--------- */}
      <div>
        {/* -------------table pannel 0-----------*/}
        <TabPanel value={value} index={0} key={0}>
          <TableContainer component={Paper} className="mt-10 border-radius-20">
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Sr no</TableCell>
                  <TableCell className={classes.tableCell}>
                    Category Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Description
                  </TableCell>
                  {getPermission.AddEditCategory == "AddEditCategory" ? (
                    <TableCell align="center" className={classes.tableCell}>
                      Edit
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableCell}></TableCell>
                  )}
                  <TableCell align="center" className={classes.tableCell}>
                    Status
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loader open={loading} />
              ) : (
                <>
                  {noFoundData ? (
                    <TableBody>
                      {getData?.map((row, index) => {
                        return (
                          <TableRow key={row.Id} className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              {index + 1 + pageState.skip}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.Name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.Description}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {getPermission.AddEditCategory ==
                              "AddEditCategory" ? (
                                <div
                                  className="buttonSecton"
                                  style={{ marginTop: "0px" }}
                                >
                                  <div className="submitButton">
                                    <Link
                                      to={{
                                        pathname: `/app/updateAddCategory/${row.Id}`,
                                      }}
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        color="warning"
                                      >
                                        <img src={editImg} />
                                      </IconButton>
                                    </Link>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="center"
                            >
                              <DeactiveDialog
                                id={row.Id.toString()}
                                handleClickOpen={handleOpenModal}
                                open={open}
                                text="Category"
                                subtext="category"
                                handleClickClose={handleClose}
                                handleClickSave={deleteDataId}
                              />
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.tableCell}
                            >
                              <div className="Menu-section">
                                {getPermission.ViewSubCategory ==
                                "ViewSubCategory" ? (
                                  <div>
                                    <Button
                                      id={row.Id}
                                      onClick={handleClick}
                                      aria-controls={
                                        OpenMenu ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        OpenMenu ? "true" : undefined
                                      }
                                    >
                                      <MoreVertIcon sx={{ color: "#9747FF" }} />
                                    </Button>
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={OpenMenu}
                                      elevation={1}
                                      style={{ borderRadius: 20 }}
                                      onClose={handleCloseMenu}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <Link
                                        to={{
                                          pathname: `/app/SubCategory/${MenuDataId}`,
                                        }}
                                        className="textDecoration"
                                      >
                                        <MenuItem
                                          className="border-radius-20"
                                          style={{ color: "#000" }}
                                        >
                                          Manage Sub-Category
                                        </MenuItem>
                                      </Link>
                                    </Menu>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <div className="text-align-center font-18 font-bold py-15">
                      No data found...
                    </div>
                  )}
                </>
              )}
            </Table>
            <TablePagination
              component="div"
              count={totalItemData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </TabPanel>
        {/* -------------table pannel 1-----------*/}
        <TabPanel value={value} index={1} key={1}>
          <TableContainer component={Paper} className="mt-10 border-radius-20">
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>sr no</TableCell>
                  <TableCell className={classes.tableCell}>
                    Category Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Description
                  </TableCell>
                  <TableCell className={classes.tableCell}>Status</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loader open={loading} />
              ) : (
                <>
                  {noFoundData ? (
                    <TableBody>
                      {InactiveData?.map((row, index) => {
                        return (
                          <TableRow key={row.Name}>
                            <TableCell className={classes.tableCell}>
                              {index + 1 + pageState.skip}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.Name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.Description}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="center"
                            >
                              <ActivateDialog
                                id={row.Id.toString()}
                                handleClickOpen={handleOpenModal}
                                open={open}
                                text="Category"
                                subtext="category"
                                handleClickClose={handleClose}
                                handleClickSave={getActiveDataById}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <div className="text-align-center font-18 font-bold py-15">
                      No data found...
                    </div>
                  )}
                </>
              )}
            </Table>
            <TablePagination
              component="div"
              count={totalItemData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </TabPanel>
      </div>
      <ApplyDialog handleCloseApply={handleCloseApply} openApply={openApply}  />
    </div>
  );
}
