export  const styles = {
    tab:{
        sx:{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            color: " #838181",
            fontFamily: "Lato",
            fontWeight: "bold",
            borderRadius: "8px",
            background: " #fff",
            padding: " 0.8rem 0",
            paddingBottom: "2.5rem",
            border: "none",
          }
    },
    tabInside:{
        sx:{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderLeft: "3px solid orange !important",
            width: "100%!important",
          }

    },
    SaveBtn:{
        sx:{
            padding: "0.7rem 4rem",
            fontWeight: 400,
            fontSize: "15px",
          }
    }
}