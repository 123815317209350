import {  
    GET_COUNTRY_REQUEST,GET_COUNTRY_SUCCESS,GET_COUNTRY_FAILURE,
    GET_STATE_REQUEST,GET_STATE_SUCCESS,GET_STATE_FAILURE,
    GET_CITY_REQUEST,GET_CITY_SUCCESS,GET_CITY_FAILURE, GET_ACCOUNT_FAILURE
   } from '../Types'  
   
  
    
  const initialState = {  
    loading: false, 
    noFoundData:false, 
    countries:[],
    error: ''  
   
  }  
    
  const reducer = (state = initialState, action) => {  
    
    switch (action.type) {  
      case GET_COUNTRY_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false
        }  
      case GET_COUNTRY_SUCCESS:
        return {  
          loading: false,  
          countries: action.payload,  
          noFoundData:false,
          error: ''           
        }  
       
      case GET_ACCOUNT_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          countries: [],  
          error: action.payload  
        }  
        
      default: return state  
    }  
  }  
    
  export default reducer  