import {
  GET_USER_REQUEST,
  GET_ACTIVE_USER_SUCCESS,
  GET_INACTIVE_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USERDETAILS_REQUEST,
  GET_USERDETAILS_SUCCESS,
  GET_USERDETAILS_FAILURE,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  GET_STORE_USER_REQUEST,
  GET_USERDETAILSPROFILE_REQUEST,
  GET_USERDETAILSPROFILE_SUCCESS,
  GET_USERDETAILSPROFILE_FAILURE,
} from "../Types";

const initialState = {
  loading: false,
  noFoundData: false,
  user: {},
  error: "",
  isAccountActive: true,
  UserDetails: {},
  UserDetailsProfile: {},
  result: "",
  imageData: {},
  getUserValue: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
      };
    case GET_ACTIVE_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
        noFoundData: false,
        error: "",
        isAccountActive: true,
      };
    case GET_STORE_USER_REQUEST:
      return {
        loading: false,
        user: action.payload,
        noFoundData: false,
        error: "",
        isAccountActive: false,
        getUserValue: true,
      };
    case GET_INACTIVE_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
        noFoundData: false,
        error: "",
        isAccountActive: false,
      };
    case GET_USER_FAILURE:
      return {
        loading: false,
        noFoundData: true,
        user: {},
        error: action.payload,
      };
    case GET_USERDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
      };
    case GET_USERDETAILS_SUCCESS:
      return {
        loading: false,
        UserDetails: action.payload,
        noFoundData: false,
        error: "",
      };
    case GET_USERDETAILS_FAILURE:
      return {
        loading: false,
        noFoundData: true,
        UserDetails: {},
        error: action.payload,
      };
    case GET_USERDETAILSPROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
      };
    case GET_USERDETAILSPROFILE_SUCCESS:
      return {
        loading: false,
        UserDetailsProfile: action.payload,
        noFoundData: false,
        error: "",
      };
    case GET_USERDETAILSPROFILE_FAILURE:
      return {
        loading: false,
        noFoundData: true,
        UserDetailsProfile: {},
        error: action.payload,
      };

    case SAVE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
        error: "",
      };
    case SAVE_USER_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        noFoundData: false,
        error: "",
      };
    case SAVE_USER_FAILURE:
      return {
        loading: false,
        noFoundData: true,
        result: action.payload,
        error: action.payload.response,
      };

    default:
      return state;
  }
};

export default reducer;
