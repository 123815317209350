
import apiService from "../../Services/ApiServices"
import {  
 GET_DEVICEMODEL_REQUEST,GET_DEVICEMODEL_SUCCESS,GET_DEVICEMODEL_FAILURE
} from '../Types'  

export const getDeviceModelList = (stateId) =>  {
   try {
     return (dispatch) => {  
       dispatch(getDeviceModelRequest())  
       let url = `/Terminal/allModels`;
       apiService.getData(url)
           .then(res => {
             const deviceModelList = res.data.Data 
             if (res.data) {
               dispatch(getDeviceModelSuccess(deviceModelList))  
           }
           else {
               dispatch(getDeviceModelFailure('err'))
           }
             
           }).catch(err =>{ dispatch(getDeviceModelFailure(err)) });
     }  
   } catch (err) {
     
   }
 };

export const getDeviceModelRequest = () => {  
   return {  
     type: GET_DEVICEMODEL_REQUEST  
   }  
 }  
   
 export const getDeviceModelSuccess = deviceModelList => {  
   return {  
     type: GET_DEVICEMODEL_SUCCESS,  
     payload: deviceModelList  
   }  
 } 
   
 export const getDeviceModelFailure = error => {  
   return {  
     type: GET_DEVICEMODEL_FAILURE,  
     payload: error  
   }  
 }  