import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  Card,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  Button,
} from "@mui/material";

export default function BasicTable() {
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState({
    nr1: false,
    nr2: false,
    nr3: false,
  });
  // for store
  const [checkedAllStore, setCheckedAllStore] = useState(false);
  const [checkedStore, setCheckedStore] = useState({
    nr4: false,
    nr5: false,
    nr6: false,
  });
  // for Staff
  const [checkedAllStaff, setCheckedAllStaff] = useState(false);
  const [checkedStaff, setCheckedStaff] = useState({
    nr7: false,
    nr8: false,
    nr9: false,
  });
  /* ################################################ */
  /* #### TOGGLES checK STATE BASED ON inputName #### */
  /* ################################################ */

  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  const toggleCheckStore = (inputName) => {
    setCheckedStore((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const toggleCheckStaff = (inputName) => {
    setCheckedStaff((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  /* ###################################################### */
  /* #### CHECKS OR UNCHECKS ALL FROM SELECT ALL CLICK #### */
  /* ###################################################### */

  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  // Store
  const selectAllStore = (value) => {
    setCheckedAllStore(value);
    setCheckedStore((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  // Staff
  const selectAllStaff = (value) => {
    setCheckedAllStaff(value);
    setCheckedStaff((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };

  /* ############################################# */
  /* #### EFFECT TO CONTROL CHECKED_ALL STATE #### */
  /* ############################################# */

  // IF YOU CHECK BOTH INDIVIDUALLY. IT WILL ACTIVATE THE checkedAll STATE
  // IF YOU UNCHECK ANY INDIVIDUALLY. IT WILL DE-ACTIVATE THE checkAll STATE

  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);
  // for store
  useEffect(() => {
    let allCheckedStore = true;
    for (const inputName in checkedStore) {
      if (checkedStore[inputName] === false) {
        allCheckedStore = false;
      }
    }
    if (allCheckedStore) {
      setCheckedAllStore(true);
    } else {
      setCheckedAllStore(false);
    }
  }, [checkedStore]);

  // Staff
  useEffect(() => {
    let allCheckedStaff = true;
    for (const inputName in checkedStaff) {
      if (checkedStaff[inputName] === false) {
        allCheckedStaff = false;
      }
    }
    if (allCheckedStaff) {
      setCheckedAllStaff(true);
    } else {
      setCheckedAllStaff(false);
    }
  }, [checkedStaff]);

  /* ########################## */
  /* #### RETURN STATEMENT #### */
  /* ########################## */

  return (
    <div>
      <div className="justify-content-space">
        <div>
          <div className="poppins-bold">New Configuration</div>
        </div>
      </div>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        className="mt-one"
      >
        <Grid item xs={12} sm={12} md={3}>
          <Card className=" border-radius-20 width-100">
            <div className="text-align-center p-5 font-weight-bold bg-white">
              POS Configuration
            </div>
            <div className="p-4">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Only Vision AI"
                    color="warning"
                    className="mt-ten"
                    control={<Radio color="warning" />}
                    label="Only Vision AI"
                  />
                  <FormControlLabel
                    value="Only Manual"
                    className="mt-ten"
                    control={<Radio color="warning" />}
                    label="Only Manual"
                  />
                  <FormControlLabel
                    value="Both Vision Ai & Manual"
                    className="mt-ten"
                    control={<Radio color="warning" />}
                    label="Both Vision Ai & Manual"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Card className="border-radius-20 width-100">
            <div className="text-align-center p-5 font-weight-bold bg-white">
              Roles & Permission
            </div>
            <div className="p-4">
              <Grid container spacing={1}>
                <Grid container item xs={12} sm={12} md={4}>
                  <div>
                    <div className="config-main-label">Account Admin</div>
                    <div className="App mt-5">
                      <div>
                        <input
                          type="checkbox"
                          onChange={(event) => selectAll(event.target.checked)}
                          checked={checkedAll}
                        />
                        <label className="config-label">All</label>
                      </div>
                      <div className="mx-20">
                        <div className="mt-ten">
                          <input
                            type="checkbox"
                            name="nr1"
                            onChange={() => toggleCheck("nr1")}
                            checked={checked["nr1"]}
                          />
                          <label className="config-label">Manage Stores</label>
                        </div>
                        <div className="mt-ten">
                          <input
                            type="checkbox"
                            name="nr2"
                            onChange={() => toggleCheck("nr2")}
                            checked={checked["nr2"]}
                          />
                          <label className="config-label">Manage Users</label>
                        </div>
                        <div className="mt-ten">
                          <input
                            type="checkbox"
                            name="nr2"
                            onChange={() => toggleCheck("nr3")}
                            checked={checked["nr3"]}
                          />
                          <label className="config-label">
                            Manage Accounts
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid container item xs={12} sm={12} md={4}>
                  <div>
                    <div className="config-main-label">Store Manager</div>
                    <div className="App mt-5">
                      <div>
                        <input
                          type="checkbox"
                          onChange={(event) =>
                            selectAllStore(event.target.checked)
                          }
                          checked={checkedAllStore}
                        />
                        <label className="config-label">All</label>
                      </div>
                      <div className="mx-20">
                        <div className="mt-ten">
                          <input
                            type="checkbox"
                            name="nr1"
                            onChange={() => toggleCheckStore("nr4")}
                            checked={checkedStore["nr4"]}
                          />
                          <label className="config-label">Manage Stores</label>
                        </div>
                        <div className="mt-ten">
                          <input
                            type="checkbox"
                            name="nr2"
                            onChange={() => toggleCheckStore("nr5")}
                            checked={checkedStore["nr5"]}
                          />
                          <label className="config-label">Manage Users</label>
                        </div>
                        <div className="mt-ten">
                          <input
                            type="checkbox"
                            name="nr2"
                            onChange={() => toggleCheckStore("nr6")}
                            checked={checkedStore["nr6"]}
                          />
                          <label className="config-label">
                            Manage Accounts
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid container item xs={12} sm={12} md={4}>
                  <div>
                    <div className="config-main-label">Store Staff</div>
                    <div className="App mt-5">
                      <div>
                        <input
                          type="checkbox"
                          onChange={(event) =>
                            selectAllStaff(event.target.checked)
                          }
                          checked={checkedAllStaff}
                        />
                        <label className="config-label">All</label>
                      </div>
                      <div className="mx-20">
                        <div className="mt-ten">
                          <input
                            type="checkbox"
                            name="nr1"
                            onChange={() => toggleCheckStaff("nr7")}
                            checked={checkedStaff["nr7"]}
                          />
                          <label className="config-label">Manage Stores</label>
                        </div>
                        <div className="mt-ten">
                          <input
                            type="checkbox"
                            name="nr2"
                            onChange={() => toggleCheckStaff("nr8")}
                            checked={checkedStaff["nr8"]}
                          />
                          <label className="config-label">Manage Users</label>
                        </div>
                        <div className="mt-ten">
                          <input
                            type="checkbox"
                            name="nr2"
                            onChange={() => toggleCheckStaff("nr9")}
                            checked={checkedStaff["nr9"]}
                          />
                          <label className="config-label">
                            Manage Accounts
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
      <div className="buttonSecton">
        <div className="mx-3">
          <Button
            variant="contained"
            size="small"
            className="border-radius-20 btn-orange w-100"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
