import React from 'react';
import Login from '../Page/AuthPages/Login'
import ManageAccount from "../Page/Account/ManageAccount"
import ManageUser from "../Page/User/ManageUser"
import ManageStore from "../Page/Store-manage/ManageStore"
import ManageTerminal from "../Page/Terminal/ManageTerminal"
import NewUsers from "../Page/User/NewUsers"
import NewAccounts from "../Page/Account/NewAccount"
import NewStore from "../Page/Store-manage/NewStore"
import CreateTerminal from "../Page/Terminal/CreateTerminal"
import CreateLicense from "../Page/License/CreateLincense"
import NewLicense from "../Page/License/NewLicense"
import License from "../Page/License/License"
import AddCategory from "../Page/Category/AddCategory"
import AddSubCategory from "../Page/SubCategory/AddSubCategory"
import AddItem from "../Page/Product/AddItem"
import Configuration from "../Page/Configuration/Configuration"
import MainDashbaord from '../Page/Dashboard/MainDashboard';
import ChangePassword from "../Page/AuthPages/ChangePassword";
import Profile from "../Page/Profile/Profile";
import Category from "../Page/Category/Category";
import SubCategory from "../Page/SubCategory/SubCategory"
import Product from "../Page/Product/Product"
import ReportsDashboard from "../Page/Report/ReprotsDashboard";
import ItemWiseReports from "../Page/Report/ItemWiseReports"
import ItemNotReconReports from "../Page/Report/ExtraReports/ItemNotReconReports"
import LabelGeneratReports from "../Page/Report/ExtraReports/LabelGeneratReports"
import MixeItemReports from "../Page/Report/ExtraReports/MixeItemReports"
import WeightChangeReports from "../Page/Report/ExtraReports/WeightChangeReports"
import CallHelpReports from "../Page/Report/ExtraReports/CallHelpReports"
import RestMobileNo from "../Page/AuthPages/ResetMobileNo"
import ResetPassword from "../Page/AuthPages/ResetPassword"
import SetOtp from "../Page/AuthPages/SetOtp"
import ItemWiseDetailsReports from "../Page/ReportsExtra/ItemWiseDetailsReports"
import ItemNotRecogDetails from "../Page/ItemNotRecogDetails"
import LabelGeneratDetailsReports from "../Page/Report/ExtraReports/LabelGeneratDetailsReports"
import MixeItemDetailsReports from "../Page/Report/ExtraReports/MixeItemDetailsReports"
import WeightChangeDetailsReports from "../Page/Report/ExtraReports/WeightChangeDetailsReports"
import CallHelpDetailsReports from "../Page/Report/ExtraReports/CallHelpDetailsReports"
import RolePermission from "../Page/RolePermision/RolePermission"
import MapItems from "../Page/GAIMapping/MapItems"
import { useRoutes, Navigate } from "react-router-dom";
import Termscondition from '../Page/Termscondition';

function Router() {
  const auth = localStorage.getItem('accessToken')
  return useRoutes([
    {
      path: '/',
      element: <Login />,
    },
    {
      path: '/RestMobileNo',
      element: <RestMobileNo />,
    },
    {
      path: 'SetOtp',
      element: <SetOtp />,
    },
    {
      path: '/ResetPassword',
      element: <ResetPassword />,
    },
    {
      path: '/Terms&Conditon',
      element: <Termscondition />,
    },

    {
      path: '/app',
      element: auth ? <MainDashbaord /> : <Navigate to="/" />,

      children: [
        {
          path: 'ManageAccount',
          name: "Account",
          element: <ManageAccount />,
        },
        {
          path: 'NewAccounts',
          name: "New Account",
          element: <NewAccounts />,
        },
        {
          path: 'UpdateAccounts/:id',
          element: <NewAccounts />,
        },

        {
          path: 'ManageUser/:userId',
          element: <ManageUser />,
        },
        {
          path: 'ManageUser',
          element: <ManageUser />,
        },
        {
          path: 'NewUsers/:newUserId',
          element: <NewUsers />,
        },
        {
          path: 'UpdateUsers/:id',
          element: <NewUsers />,
        },
        {
          path: 'ManageStore/:id',
          element: <ManageStore />,
        },
        {
          path: 'ManageStore',
          element: <ManageStore />,
        },
        {
          path: 'NewStore/:id',
          element: <NewStore />,
        },
        {
          path: 'NewStore/:accountId',
          element: <NewStore />,
        },
        {
          path: 'UpdateStores/:storeId',
          element: <NewStore />,
        },
        {
          path: 'ManageTerminal/:terminalId',
          element: <ManageTerminal />,
        },
        {
          path: 'CreateTerminal/:createTerminalId',
          element: <CreateTerminal />,
        },
        {
          path: 'UpdateTerminal/:id',
          element: <CreateTerminal />,
        },
        {
          path: 'Configuration',
          element: <Configuration />,
        },
        {
          path: 'License/:licenseId',
          element: <License />,
        },
        {
          path: 'License',
          element: <License />,
        },
        {
          path: 'NewLicense',
          element: <NewLicense />,
        },
        {
          path: 'CreateLicense/:createLicenseId',
          element: <CreateLicense />,
        },
        {
          path: 'UpdateLicense/:updateLicenseId',
          element: <CreateLicense />,
        },
        {
          path: 'AddCategory',
          element: <AddCategory />,
        },
        {
          path: 'AddItem',
          element: <AddItem />,
        },
        {
          path: 'updateAddItem/:productId',
          element: <AddItem />,
        },
        {
          path: 'Product',
          element: <Product />,
        },
        {
          path: 'ChangePassword',
          element: <ChangePassword />,
        },
        {
          path: 'Profile',
          element: <Profile />,
        },
        {
          path: 'Category',
          element: <Category />,
        },
        {
          path: 'updateAddCategory/:categoryId',
          element: <AddCategory />,
        },
        {
          path: 'SubCategory/:subCategory',
          element: <SubCategory />,
        },
        {
          path: 'AddSubCategory/:createSubcategoryId',
          element: <AddSubCategory />,
        },
        {
          path: 'updateAddSubCategory/:subcategoryId',
          element: <AddSubCategory />,
        },
        {
          path: 'ReportsDashboard',
          element: <ReportsDashboard />,
        },
        {
          path: 'ItemWiseReports',
          element: <ItemWiseReports />,
        },
        {
          path: 'ItemWiseDetailsReports/:id',
          element: <ItemWiseDetailsReports />
        },
        {
          path: 'ItemNotReconReports',
          element: <ItemNotReconReports />,
        },
        {
          path: 'ItemNotRecogDetails/:id',
          element: <ItemNotRecogDetails />,
        },
        {
          path: 'LabelGeneratReports',
          element: <LabelGeneratReports />,
        },
        {
          path: 'LabelGeneratDetailsReports/:id',
          element: <LabelGeneratDetailsReports />,
        },
        {
          path: 'MixeItemReports',
          element: <MixeItemReports />,
        },
        {
          path: 'MixeItemDetailsReports/:id',
          element: <MixeItemDetailsReports />,
        },
        {
          path: 'WeightChangeReports',
          element: <WeightChangeReports />,
        },
        {
          path: 'WeightChangeDetailsReports/:id',
          element: <WeightChangeDetailsReports />,
        },
        {
          path: 'CallHelpDetailsReports/:id',
          element: <CallHelpDetailsReports />,
        },
        {
          path: 'CallHelpReports',
          element: <CallHelpReports />,
        },
        {
          path: 'RolePermission/:id',
          element: <RolePermission />,
        },
        {
          path: "MapItems",
          element: <MapItems />
        },
      
      ],
    },

  ]);


}

export default Router;