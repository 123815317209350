import React, { useState, useEffect } from "react";
import DefaultButton from "../../Component/Button";
import {
  TextField,
  Button,
  Card,
  Box,
  Grid,
  MenuItem,
} from "@mui/material";
import DefaultInput from "../../Component/TextField";
import {
  saveSubCategory,
  getSubCategoryDetails,
  updateSubCategory,
  getLogoImageData,
} from "../../Redux/SubCategory/SubCategoryAction";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
import ApiServices from "../../Services/ApiServices";
import { styles } from "../../assets/scss/AddItem";

export default function BasicTable() {
  const SubCategoryDetail = useSelector((s) => s.subCategoryReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoImage, setLogoImage] = useState("");
  const [logoImageURL, setLogoUrl] = useState(null);
  const [previewURL, setPreviewUrl] = useState(null);
  const [selectedFileName, setFileName] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [Roles, setRoles] = React.useState("");
  const handleChange = (event) => {
    setRoles(event.target.value);
  };
  const [inputField, setInputField] = useState({
    SubCategoryName: "",
    Description: "",
    categoryId: "",
    categoryName: "",
  });
  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  let formData = new FormData();
  formData.append("Name", inputField.SubCategoryName);
  formData.append("Description", inputField.Description);
  formData.append("CategoryId", inputField.categoryId);

  if (logoImage != null) {
    formData.append("file", logoImage);
  }
  const getLogoImage = (subcategoryId) => {
    if (subcategoryId) {
      dispatch(getLogoImageData(subcategoryId));
    }
  };
  const params = useParams();

  // Update account
  const updateData = () => {
    if (logoImage != "") {
      formData.append("file", logoImage);
    }
    dispatch(updateSubCategory(formData, params.subcategoryId));
  };
  const [getAccountParamsId, setAccountParamsId] = useState("");
  useEffect(() => {
    if (params.subcategoryId) {
      dispatch(getSubCategoryDetails(params.subcategoryId));
    }
  }, []);
  const [currentUserRole, setRole] = useState();
  const [getAccountId, setGetAccountId] = useState();
  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    setRole(userDetail.userRole);
    setGetAccountId(userDetail.accountId);
    getAllAccount(userDetail.userRole, userDetail.accountId);
  }, []);

  useEffect(() => {
    if (SubCategoryDetail.subcategoryDetails) {
      var accInfo = SubCategoryDetail.subcategoryDetails;

      if (Object.keys(accInfo).length > 0) {
        setAccountParamsId(accInfo.AccountCategoryId);
        setInputField({
          SubCategoryName: accInfo.Name != null ? accInfo.Name : "",
          Description: accInfo.Description != null ? accInfo.Description : "",
          categoryName:
            accInfo.AccountCategory.Name != null
              ? accInfo.AccountCategory.Name
              : "",
          categoryId:
            accInfo.AccountCategoryId != null ? accInfo.AccountCategoryId : "",
        });
        if (accInfo.FilePath) {
          getLogoImage(accInfo.Id);
        }
      }
    } else if (SubCategoryDetail.result == 200) {
      if (params.subcategoryId && getAccountParamsId) {
        toast("Sub-Category update successfully");
        setTimeout(() => {
          navigate(`/app/SubCategory/${getAccountParamsId}`);
        }, 1000);
      } else {
        toast("Sub-Category save successfully");
        setTimeout(() => {
          navigate(`/app/SubCategory/${params.createSubcategoryId}`);
        }, 1000);
      }
    } else if (SubCategoryDetail.imageData) {
      const imageUrl = SubCategoryDetail.imageData.FileData;
      setPreviewUrl("data:image/png;base64," + imageUrl);
    } else if (SubCategoryDetail.error.status == 404) {
      toast(SubCategoryDetail.error.data);
    } else if (SubCategoryDetail.error.status == 401) {
      toast(SubCategoryDetail.error.data);
    } else if (SubCategoryDetail.error.status == 403) {
      toast(SubCategoryDetail.error.data);
    } else if (SubCategoryDetail.error.status == 400) {
      toast(SubCategoryDetail.error.data);
    } else if (SubCategoryDetail.error.status == undefined) {
    } else {
      if (SubCategoryDetail.error != "") {
        toast(SubCategoryDetail.error);
      }
    }
  }, [SubCategoryDetail]);

  const saveSubCategoryDetails = () => {
    dispatch(saveSubCategory(formData));
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    let formIsValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const letter = /^[a-zA-Z ]*$/;
    if (!values.categoryName) {
      errors.categoryName = "Category Name is required!";
      formIsValid = false;
    }
    if (!letter.test(values.SubCategoryName)) {
      errors.SubCategoryName = "Please enter alphabets only";
      formIsValid = false;
    }
    if (!values.SubCategoryName) {
      errors.SubCategoryName = "Sub Category Name is required!";
      formIsValid = false;
    }
    setFormErrors(errors);
    return formIsValid;
  };
  const submitForm = (e) => {
    e.preventDefault();
    if (validate(inputField)) {
      setIsSubmit(true);
      if (params.subcategoryId && getAccountParamsId) {
        updateData();
      } else {
        saveSubCategoryDetails();
      }
    }
  };
  //Upload logo
  const [fileError, setFileError] = useState("");
  const uploadImage = (e) => {
    setFileError("");
    let file = e.target.files[0];
    var idxDot = file.name.lastIndexOf(".") + 1;
    var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
    if (extFile == "jpg" || extFile == "png") {
      if (file.size > 1000000) {
        setFileError("Please upload a file smaller than 1 MB");
        return false;
      } else {
        setLogoImage(file);
        setFileName(file.name);
        setPreviewUrl(URL.createObjectURL(file));
      }
    } else {
      setFileError("Only jpg/jpeg, png files are allowed!");
    }
  };
  // reset Data
  const resetData = () => {
    setInputField({
      SubCategoryName: "",
      Description: "",
      categoryName: "",
    });
    if (params.subcategoryId && getAccountParamsId) {
      navigate(`/app/SubCategory/${getAccountParamsId}`);
    } else {
      navigate(`/app/SubCategory/${params.createSubcategoryId}`);
    }
  };
  const [categoryData, setCategoryData] = useState([]);
  const getAllCategoryId = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      categoryId: e.target.value,
      categoryName: e.target.value,
    }));
  };
  const getAllAccount = (obj, accountId) => {
    let url = "/AccountCategory/all";
    if (obj == 2) {
      url += `?accountId=${accountId}`;
    }
    ApiServices.getData(url)
      .then((res) => {
        setCategoryData(res.data.Data);
      })
      .catch((res) => {});
  };
  return (
    <div style={{ marginBottom: "30px" }}>
      <ToastContainer autoClose={1000} />
      {/* --------------btn section----------- */}
      <Grid container>
        <Grid item xs={12} sm={12} md={11}>
          <Grid container sx={{ marginLeft: "-5px " }}>
            <Grid item xs={12} sm={12} md={2}>
              <Box {...styles.tab}>
                <Box {...styles.tabInside}>
                  {params.subcategoryId
                    ? "Update  Sub Category"
                    : "New  Sub Category"}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
          <DefaultButton
            color="warning"
            variant="contained"
            size="small"
            Text="Back"
          />
        </Grid>
      </Grid>
      {/* ------------form section---------------- */}
      <form onSubmit={submitForm}>
        <Card className="border-radius-8 width-100" sx={{ marginTop: "-5px" }}>
          <div className="p-4">
            <div>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    // select
                    size="small"
                    label="Category *"
                    name="Category"
                    value={inputField.categoryName}
                    onChange={getAllCategoryId}
                  />
                  {/* --------To do----------- */}
                  {/* {categoryData?.map((option) => (
                      <MenuItem key={option.value} value={option.Id}>
                        {option.Name}
                      </MenuItem>
                    ))} 
                   </TextField> */}
                  <div className="errors">{formErrors.categoryName}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="SubCategoryName"
                    label="Sub Category Name *"
                    variant="standard"
                    name="SubCategoryName"
                    value={inputField.SubCategoryName}
                    onChange={inputsHandler}
                    placeHolder="Sub Category Name"
                  />
                  <div className="errors">{formErrors.SubCategoryName}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="Description"
                    label="Description"
                    variant="standard"
                    name="Description"
                    value={inputField.Description}
                    onChange={inputsHandler}
                    placeHolder="Description"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div class="drop_box">
                        <Grid item xs={12} sm={12} md={12} className="mb-12">
                          <label htmlFor="contained-button-file">
                            <input
                              type="file"
                              hidden
                              accept="image/png, image/jpeg"
                              id="logoImage"
                              onChange={uploadImage}
                            />
                            <label
                              for="logoImage"
                              class="btn btn-warning"
                              tabindex="0"
                            >
                              Upload Image
                            </label>
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}></Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          {previewURL != null ? (
                            <div className="img-section" for="logoImage">
                              <img src={previewURL} width="200" height="100" />{" "}
                            </div>
                          ) : null}
                        </Grid>
                        <div className="text-align-center">
                          <p>Files Supported: JPG, PNG</p>
                        </div>
                      </div>
                      <div className="errors">{fileError}</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className="buttonSecton">
              <div className="mx-3" style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  {...styles.SaveBtn}
                  className="btn-orange "
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </form>
    </div>
  );
}
