import React, { useState, useEffect } from "react";
import {
  Avatar,
  TablePagination,
  Grid,
  Collapse,
  Tab,
  Tabs,
  Box,
  Card,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import PersonAddAltRounded from "@mui/icons-material/PersonAddAltRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataByPagination,
  getInactiveDataByPagination,
} from "../../Redux/SubCategory/SubCategoryAction";
import apiService from "../../Services/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Component/Loader";
import editImg from "../../assets/Images/edit.png";
import { makeStyles } from "@material-ui/core/styles";
import DefaultButton from "../../Component/Button";
import { regexConstant } from "../../shared/constants";
import { toast } from "react-toastify";
import DeactiveDialog from "../../Component/DeactiveDialog";
import ActivateDialog from "../../Component/ActivateDialog";

// ------------Tab----------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}
function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`,
  };
}
const useStyles = makeStyles({
  tableCell: {
    "&.MuiTableCell-root": {
      border: "none!important",
      padding: "10px",
    },
  },
});
const pageState = {
  skip: 0,
  top: 10,
  isDeleted: false,
  query: "",
  categoryId: "",
  SubCategoryId: "",
};

export default function BasicTable() {
  const params = useParams();
  pageState.categoryId = params.subCategory;
  const classes = useStyles();
  const dispatch = useDispatch();
  const subsubcategoryData = useSelector((s) => s.subCategoryReducer);
  const { loading } = useSelector((state) => ({
    loading: state.subCategoryReducer.loading,
  }));
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const [isLoading, setIsLoading] = useState(false);
  const [getData, setData] = useState([]);
  const [InactiveData, setInactiveData] = useState([]);
  const [totalItemData, setTotalItemData] = useState(1);
  const [noFoundData, setnoFoundData] = useState(true);
  const [currentUserDetails, setCurrentUser] = useState({});
  const [search, setSearch] = useState("");
  const [handleIcon, setIcon] = useState(true);
  const [currentUserRole, setRole] = useState("");
  const [value, setValue] = useState(0);
  const [getPermission, setPermission] = useState({});
  const [open, setOpen] = React.useState(false);
  const [ModalDataId, setModalDataId] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [subcategoryData, setSubCategoryData] = useState([]);
  const [getCatId, setCatId] = useState([]);
  const [inputField, setInputField] = useState({
    categoryName: "",
    subCatergoryName: "",
  });

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    let newObj = {};
    userDetail.permissions.forEach((element) => {
      newObj[`${element}`] = element;
    });
    setPermission(newObj);
  }, []);

  useEffect(() => {
    pageState.top = 10;
    pageState.skip = 0;
    dispatch(getDataByPagination(pageState));
  }, []);

  useEffect(() => {
    if (!subsubcategoryData.noFoundData) {
      if (subsubcategoryData.isAccountActive == true) {
        setData(subsubcategoryData.subcategory.Data);
        setTotalItemData(
          subsubcategoryData.subcategory.TotalItems
            ? subsubcategoryData.subcategory.TotalItems
            : 1
        );
      } else if (subsubcategoryData.isAccountActive == false) {
        setInactiveData(subsubcategoryData.subcategory.Data);
        setTotalItemData(
          subsubcategoryData.subcategory.TotalItems
            ? subsubcategoryData.subcategory.TotalItems
            : 1
        );
      }
      setIsLoading(false);
      setnoFoundData(true);
    } else {
      setIsLoading(false);
      setnoFoundData(false);
    }
  }, [subsubcategoryData]);

  // ------------pegination events--------------
  const handleChange = (event, newValue) => {
    const indexValue = event.target.id.split("-")[2];
    setValue(newValue);
    if (indexValue > 0) {
      pageState.isDeleted = true;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      setRowsPerPage(parseInt(pageState.top));
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      pageState.isDeleted = false;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      dispatch(getDataByPagination(pageState));
    }
  };
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    if (value == 0) {
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);

    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      dispatch(getDataByPagination(pageState));
    }
  };

  // -------------Search events----------------
  const resetInputField = (e) => {
    setSearch("");
    setSearch("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setInputValue("");
    setnoFoundData(true);
    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };
  const handleUserInput = (e) => {
    if (e.target.value) {
      setIcon(false);
    }
    if (e.target.value === "") {
      setIcon(true);
    }
    setInputValue(e.target.value);
  };
  const SearchData = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    pageState.query = inputValue;
    if (
      pageState.query == "" ||
      !regexConstant.searchSpace.test(pageState.query)
    ) {
      toast("Please Enter the search key");
    }
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  // ------------- Model event-------------
  const handleOpenModal = (e) => {
    setOpen(true);
    setModalDataId(e.target.id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteDataId = (obj) => {
    apiService
      .deleteData(`/AccountSubCategory/${ModalDataId}`)
      .then((res) => {
        setOpen(false);
        setIsLoading(true);
        dispatch(getDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };

  //  ----------Getting data events--------------
  const getActiveDataById = (obj) => {
    apiService
      .saveData(`/AccountSubCategory/activate`, { subCategoryId: ModalDataId })
      .then((res) => {
        setOpen(false);
        dispatch(getInactiveDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
  const getAllSubCat = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      SubCategoryId: e.target.value,
      subCatergoryName: e.target.value,
    }));
  };

  //  ----------Filter and reset----------------
  const productFilter = (getProductSubCat, getProductCat) => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    pageState.categoryId = inputField.categoryId;
    pageState.SubCategoryId = inputField.SubCategoryId;
    pageState.productId = inputField.productId;
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  const resetData = (e) => {
    setSearch("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    pageState.categoryId = "";
    pageState.SubCategoryId = "";
    pageState.productId = "";
    inputField.categoryName = "";
    inputField.subCatergoryName = "";
    inputField.productName = "";
    setRowsPerPage(parseInt(pageState.top));
    setInputValue("");
    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };

  return (
    <Box>
      {/* -----------Header box------------- */}
      <Box>
        <Box className="text-align-end mb-10">
          <DefaultButton color="warning" variant="contained" Text="Back" />
        </Box>
        <Box className="justify-content-space">
          <Box className="poppins-bold">Manage Sub Category</Box>

          {getPermission.AddEditSubCategory == "AddEditSubCategory" ? (
            <Box style={{ marginLeft: "20px" }}>
              <Box className="text-align-end showMobile my-8">
                <Link to="/app/AddSubCategory" className="textDecoration">
                  <Button>
                    <Avatar
                      className="border-orange"
                      style={{ backgroundColor: "white" }}
                    >
                      <PersonAddAltRounded className="text-orange" />
                    </Avatar>
                  </Button>
                </Link>
              </Box>
              <Box className="showINWindow">
                <Link
                  to={{ pathname: `/app/AddSubCategory/${params.subCategory}` }}
                  className="textDecoration"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    className="border-radius-20 roboto"
                    style={{
                      boxShadow: 3,
                      borderRadius: "15px",
                      background: "#fff",
                      color: "#8B8D9D",
                      border: "none",
                      fontWeight: "100 !important",
                      padding: "5px 16px",
                    }}
                  >
                    <AddIcon
                      color="warning"
                      className="mx-5"
                      sx={{ color: "#9747FF" }}
                    />
                    ADD SUB CATEGORY
                  </Button>
                </Link>
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={5}>
            <Box>
              <Box className="pt-10 search-section">
                <Box>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={11}>
                      <TextField
                        fullWidth
                        id="userName"
                        size="small"
                        type="Text"
                        onBlur={(e) => setSearch(e.target.value)}
                        value={inputValue}
                        onChange={handleUserInput}
                        classes={{ root: classes.customTextField }}
                        placeholder="Search Here"
                        sx={{
                          boxShadow: 5,
                          borderRadius: "10px",
                          background: "white",
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none !important",
                            },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="set-icon-right"
                              position="start"
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={resetInputField}
                                color="warning"
                              >
                                {handleIcon ? <SearchIcon /> : <CancelIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            SearchData();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                      <Box className="mt-5">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={SearchData}
                          className="border-radius-20 btn-orange w-100"
                        >
                          Search
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Box>
              <Box className=" pt-10">
                <Box>
                  <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="horizontal tabs example"
                  >
                    <Tab
                      label="Active"
                      {...a11yProps(0)}
                      className="roboto-700"
                    />
                    <Tab
                      label="InActive"
                      {...a11yProps(1)}
                      className="roboto-700"
                    />
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* -------------Table box----------------- */}
      <Box>
        {/* -------------Table 0----------------- */}
        <TabPanel value={value} index={0}>
          <TableContainer component={Paper} className="mt-10 border-radius-20">
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow className="tableHeadCss">
                  <TableCell className={classes.tableCell}>Sr no</TableCell>
                  <TableCell className={classes.tableCell}>
                    Sub Category Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Category Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Description
                  </TableCell>
                  {getPermission.AddEditSubCategory == "AddEditSubCategory" ? (
                    <TableCell className={classes.tableCell} align="center">
                      Edit
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableCell}></TableCell>
                  )}
                  <TableCell className={classes.tableCell}>Status</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loader open={loading} />
              ) : (
                <>
                  {noFoundData ? (
                    <TableBody>
                      {getData?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCell}>
                            {index + 1 + pageState.skip}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.AccountCategory?.Name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Description}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {getPermission.AddEditSubCategory ==
                            "AddEditSubCategory" ? (
                              <Box
                                className="buttonSecton"
                                style={{ marginTop: "0px" }}
                              >
                                <Box className="submitButton">
                                  <Link
                                    to={{
                                      pathname: `/app/updateAddSubCategory/${row.Id}`,
                                    }}
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      color="warning"
                                    >
                                      {/* <EditIcon />
                                       */}
                                      <img src={editImg} />
                                    </IconButton>
                                  </Link>
                                </Box>
                              </Box>
                            ) : (
                              <Box></Box>
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <DeactiveDialog
                              id={row.Id.toString()}
                              handleClickOpen={handleOpenModal}
                              open={open}
                              text="Subcategory"
                              subtext="subcategory"
                              handleClickClose={handleClose}
                              handleClickSave={deleteDataId}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <Box className="text-align-center font-18 font-bold py-15">
                      No data found...
                    </Box>
                  )}
                </>
              )}
            </Table>
            <TablePagination
              component="Box"
              count={totalItemData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </TabPanel>
        {/* -------------Table 1----------------- */}
        <TabPanel value={value} index={1}>
          <TableContainer component={Paper} className="mt-10 border-radius-20">
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow className="tableHeadCss">
                  <TableCell className={classes.tableCell}>
                    Sub Category Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Category Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Description
                  </TableCell>
                  <TableCell className={classes.tableCell}>Status</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loader open={loading} />
              ) : (
                <>
                  {noFoundData ? (
                    <TableBody>
                      {InactiveData?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCell}>
                            {row.Name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.AccountCategory?.Name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Description}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <ActivateDialog
                              id={row.Id.toString()}
                              handleClickOpen={handleOpenModal}
                              open={open}
                              text="Subategory"
                              subtext="subcategory"
                              handleClickClose={handleClose}
                              handleClickSave={getActiveDataById}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <Box className="text-align-center font-18 font-bold py-15">
                      No data found...
                    </Box>
                  )}
                </>
              )}
            </Table>
            <TablePagination
              component="Box"
              count={totalItemData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </TabPanel>
      </Box>
    </Box>
  );
}
