import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Divider, Grid, TextField, MenuItem } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

 const ApplyDialog =(props)=> {
   
    return (
        <div>
           
            <Dialog
                onClose={props.handleCloseApply}
                // aria-labelledby="customized-dialog-title"
                open={props.openApply}
                sx={{
                     border:"1px solid red",
                    "& .MuiPaper-elevation24": {
                      boxShadow: "none!important",
                    },
                    " .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop":{
                      background:"rgba(0,0,0,0.5)!important"
                    },
                    "& .MuiBackdrop-root":{
                      opacity:"0.5!important"
                    }
                  }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" className="p-2" onClose={props.handleCloseApply}>
                 {/* Warning  */}
                </BootstrapDialogTitle>
                 {/* <DialogContent dividers> */}
                <DialogContent sx={{   }}>
                Please Select Filter Options first 
                </DialogContent>
              

                  
              
            </Dialog>
        </div>
    );
}

export default ApplyDialog;