import React, { useState } from 'react';


import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

export default function BasicTable(props) {
    
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer }}
                open={props.open}
                className="backdrop-circle"
            >
                <CircularProgress style={{ color: '#ea7626' }} />
            </Backdrop>
        </div>
    );
}
