// const {BASE_URL} = Config;
const BASE_URL = "https://vaiapi.quinta.co.in/api";
//const BASE_URL = "https://localhost:44355/api"
export const config = {
  BASE_URL : "https://vaiapi.quinta.co.in/api",
  AUTH_API_URL: `${BASE_URL}/Auth/login`,
  USER_API_URL:`${BASE_URL}/Auth/sendPin`,
  OTP_API_URL:`${BASE_URL}/Auth/validatePin`,
  NEWPASSWORD_API_URL:`${BASE_URL}/Auth/createNewPassword`
};

export const APP_BASE_URL = config.BASE_URL;