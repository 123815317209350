import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  useMediaQuery,
  MenuItem,
  MenuItemDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Grid,
  Box,
  Tab,
  Tabs,
  Card,
  Button,
  styled,
  IconButton,
  TablePagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Dialog,
  Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddAltRounded from "@mui/icons-material/PersonAddAltRounded";
import { Link } from "react-router-dom";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useLocation,
  Link as RouterLink,
} from "react-router-dom";
import apiService from "../../Services/ApiServices";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getDataByPagination } from "../../Redux/User/UserAction";
import { getInactiveDataByPagination } from "../../Redux/User/UserAction";
import { userRoles, regexConstant } from "../../shared/constants";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import { getStoreData } from "../../Redux/User/UserAction";
import { toast, ToastContainer } from "react-toastify";
import img from "../../assets/Images/key.png";
import editImg from "../../assets/Images/edit.png";
import DefaultButton from "../../Component/Button";
import Loader from "../../Component/Loader";
import DeactiveDialog from "../../Component/DeactiveDialog";
import ActivateDialog from "../../Component/ActivateDialog";
// Using for Dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  dialogPaper: {
    maxWidth: "900px !important",

    boxShadow: "none!important",
  },
  tableCell: {
    "&.MuiTableCell-root": {
      border: "none!important",
      padding: "10px",
    },
  },
});

const pageState = {
  skip: 0,
  top: 10,
  isDeleted: false,
  query: "",
  userId: "",
  accountId: "",
};

export default function BasicTable() {
  const theme = useTheme();
  const pathname = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.userReducer);
  const { loading } = useSelector((state) => ({
    loading: state.userReducer.loading,
  }));

  const [currentUserDetails, setCurrentUser] = useState({});
  pageState.userRole = currentUserDetails;
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [inputValue, setInputValue] = useState("");
  const [accountValue, setAccountValue] = useState("");
  const [ModalDataId, setModalDataId] = useState();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const [isLoading, setIsLoading] = useState(false);
  const [getData, setData] = useState([]);
  const [InactiveData, setInactiveData] = useState([]);
  const [search, setSearch] = useState("");
  const [totalItemData, setTotalItemData] = useState(1);
  const [noFoundData, setnoFoundData] = useState(true);
  const [currentUserId, setCurrentUserId] = useState({});
  const [currentUserRole, setRole] = useState("");
  const [acoountData, setAccountData] = useState([]);
  const [value, setValue] = useState(0);
  const [handleIcon, setIcon] = useState(true);
  const [getPermission, setPermission] = useState({});
  const [openTerminal, setOpenTerminal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [getDialogId, setDialogId] = useState(false);
  const [getTerminalId, setTerminalId] = useState("");
  const [assignOpenLicense, setAssignOpenLicense] = useState(false);
  const [assignOpenStore, setAssignOpenStore] = useState(false);
  const [allowLicense, setAllowLicense] = useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // intial List Call
  const intialListCall = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    if (value == 0) {
      // setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  useEffect(() => {
    getAllAccount();
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    let newObj = {};
    userDetail.permissions.forEach((element) => {
      newObj[`${element}`] = element;
    });
    setPermission(newObj);
    if (userDetail) {
      setRole(userDetail.userRole);
      if (userDetail.userRole != userRoles.SuperAdmin) {
        pageState.accountId = userDetail.accountId;
        pageState.skip = 0;
        pageState.top = 10;
        dispatch(getDataByPagination(pageState));
      } else {
        pageState.accountId = params.userId;
        pageState.skip = 0;
        pageState.top = 10;
        dispatch(getDataByPagination(pageState));
      }
    }
    intialListCall();
  }, []);
  const [getLicenseList, setLicenseData] = useState([]);

  useEffect(() => {
    if (!userData.noFoundData) {
      if (userData.isAccountActive == true) {
        setData(userData.user.Data);
        setTotalItemData(
          userData.user.TotalItems ? userData.user.TotalItems : 1
        );
      } else if (userData.getUserValue == true) {
        setLicenseData(userData.user.Data);
        setTotalItemData(
          userData.user.TotalItems ? userData.user.TotalItems : 1
        );
      } else if (userData.isAccountActive == false) {
        setInactiveData(userData.user.Data);
        setTotalItemData(
          userData.user.TotalItems ? userData.user.TotalItems : 1
        );
      } else {
        // setIsLoading(false);
        setnoFoundData(true);
      }
    } else {
      // setIsLoading(true);
      setnoFoundData(false);
    }
  }, [userData]);

  //  ----------Pegination event----------------
  const handleChange = (event, newValue) => {
    const indexValue = event.target.id.split("-")[2];
    setValue(newValue);
    if (indexValue > 0) {
      pageState.isDeleted = true;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      // setRowsPerPage(parseInt(pageState.top));
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      // setIsLoading(true);
      pageState.isDeleted = false;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      setRowsPerPage(parseInt(pageState.top));

      dispatch(getDataByPagination(pageState));
    }
  };
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    if (pageState.isDeleted) {
      setIsLoading(true);
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    }
  };

  const resetInputField = (e) => {
    setSearch("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;

    setRowsPerPage(parseInt(pageState.top));
    setInputValue("");
    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };

  const resetFilter = (e) => {
    setSearch("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    // pageState.accountId = "";
    setRowsPerPage(parseInt(pageState.top));
    setInputValue("");
    setAccountValue("");
    if (pageState.isDeleted) {
      pageState.accountId = "";
      // pageState.accountId = params.userId
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      pageState.accountId = params.userId;
      dispatch(getDataByPagination(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };
  const handleUserInput = (e) => {
    if (e.target.value) {
      setIcon(false);
    }
    if (e.target.value === "") {
      setIcon(true);
    }
    setInputValue(e.target.value);
  };
  // -------Model event----------------
  const handleOpenModal = (e) => {
    setOpen(true);
    setModalDataId(e.target.id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const SearchData = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    pageState.query = inputValue;
    if (
      pageState.query == "" ||
      !regexConstant.searchSpace.test(pageState.query)
    ) {
      toast("Please Enter the search key");
    }
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  // deleted data data by ID
  const deleteDataId = (obj) => {
    apiService
      .deleteData(`/User/${ModalDataId}`)
      .then((res) => {
        setOpen(false);
        dispatch(getDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
  // get active data data by ID
  const getActiveDataById = (obj) => {
    apiService
      .saveData(`/User/activate`, { userId: ModalDataId })
      .then((res) => {
        setOpen(false);
        setIsLoading(true);
        dispatch(getInactiveDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };

  const getAllAccount = () => {
    apiService
      .getData(`/Account/all/dropdown`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((res) => {});
  };
  const getAccountId = (e) => {
    setAccountValue(e.target.value);
    pageState.accountId = e.target.value;
  };

  const handleClickOpenDialog = (e) => {
    setDialogId(e.target.id);
    setTerminalId(e.target.value);
    pageState.accountId = e.target.id;
    pageState.userId = e.target.value;
    if (pageState.accountId) {
      dispatch(getStoreData(pageState));
    }
    // else {
    //     dispatch(getStoreData(pageState));
    // }
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setIsLoading(true);
    setOpenDialog(false);
    if (currentUserRole == 1) {
      pageState.accountId = params.userId;
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getDataByPagination(pageState));
    }
  };

  //  --------assignLicese Event----------------------
  const assignLiceseModal = (e) => {
    setAssignOpenLicense(true);
    setModalDataId(e.target.id);
  };

  const removeAssignStoreModal = (e) => {
    setAssignOpenStore(true);
    setModalDataId(e.target.id);
  };
  // ---------------store event-----------------
  const assignStore = (obj) => {
    apiService
      .saveData(`/User/assign/store`, {
        userId: getTerminalId,
        storeId: obj,
      })
      .then((res) => {
        setIsLoading(true);
        setAssignOpenLicense(false);
        dispatch(getStoreData(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
  const removeStore = (obj) => {
    apiService
      .saveData(`/User/remove/store`, {
        userId: getTerminalId,
        storeId: obj,
      })
      .then((res) => {
        setIsLoading(true);
        setAssignOpenStore(false);
        dispatch(getStoreData(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
  const handleCloseStore = () => {
    setAssignOpenLicense(false);
  };

  const AccountFilter = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    if (accountValue) {
      pageState.accountId = accountValue;
    }
    if (value == 0) {
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  return (
    <Box>
      {/* -----------Header box--------------- */}
      <Box>
        <ToastContainer autoClose={2000} />
        <Box className="justify-content-space">
          <Box className="poppins-bold">Manage User</Box>

          <Box>
            {currentUserRole == userRoles.SuperAdmin ? (
              <Box className="text-align-end mb-10">
                <DefaultButton
                  color="warning"
                  variant="contained"
                  size="small"
                  Text="Back"
                />
              </Box>
            ) : null}
            <Box className="text-align-end showMobile my-8">
              <Link
                to={{ pathname: `/app/NewUsers/${params.userId}` }}
                className="textDecoration"
              >
                <Button>
                  <Avatar
                    sx={{ border: "1px solid #9747FF" }}
                    style={{ backgroundColor: "white" }}
                  >
                    <PersonAddAltRounded sx={{ color: "#9747FF" }} />
                  </Avatar>
                </Button>
              </Link>
            </Box>
            {getPermission.AddEditUser == "AddEditUser" ? (
              <Box className="showINWindow">
                <Link
                  to={{ pathname: `/app/NewUsers/${params.userId}` }}
                  className="textDecoration"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    className="border-radius-20 roboto"
                    style={{
                      boxShadow: 3,
                      borderRadius: "15px",
                      background: "#fff",
                      color: "#8B8D9D",
                      border: "none",
                      fontWeight: "100 !important",
                      padding: "5px 16px",
                    }}
                  >
                    <AddIcon
                      color="warning"
                      className="mx-5"
                      sx={{ color: "#9747FF" }}
                    />
                    ADD NEW USER
                  </Button>
                </Link>
              </Box>
            ) : (
              <Box></Box>
            )}
          </Box>
        </Box>
        {/* --------------Filter and search------------ */}
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={5}>
            <Box>
              <Box className="pt-10 search-section">
                <Box>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={11}>
                      <TextField
                        fullWidth
                        id="userName"
                        size="small"
                        type="Text"
                        onBlur={(e) => setSearch(e.target.value)}
                        value={inputValue}
                        onChange={handleUserInput}
                        classes={{ root: classes.customTextField }}
                        placeholder="Search Here"
                        sx={{
                          boxShadow: 5,
                          borderRadius: "10px",
                          background: "white",
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none !important",
                            },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="set-icon-right"
                              position="start"
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={resetInputField}
                                color="warning"
                              >
                                {handleIcon ? <SearchIcon /> : <CancelIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            SearchData();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                      <Box className="mt-5">
                        <Button
                          variant="contained"
                          size="small"
                          className="border-radius-20 btn-orange w-100"
                          onClick={SearchData}
                        >
                          Search
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Box>
              <Box className=" pt-10">
                <Box>
                  <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="horizontal tabs example"
                  >
                    <Tab
                      label="Active"
                      {...a11yProps(0)}
                      className="roboto-700"
                    />
                    <Tab
                      label="InActive"
                      {...a11yProps(1)}
                      className="roboto-700"
                    />
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          {currentUserRole == userRoles.SuperAdmin ? (
            <Grid item xs={12} sm={12} md={2}>
              <Box>
                <TextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  label="Account"
                  name="accountWithId"
                  onChange={getAccountId}
                  value={accountValue}
                  sx={{
                    boxShadow: 5,
                    borderRadius: "10px",
                    background: "white",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none !important",
                      },
                  }}
                >
                  {acoountData.map((option) => (
                    <MenuItem key={option.AccountName} value={option.Id}>
                      {option.AccountName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
          ) : (
            ""
          )}

          {currentUserRole == userRoles.SuperAdmin ? (
            <Grid item xs={12} sm={12} md={2}>
              <Box className="gridButtonSection">
                <Box className="mx-3">
                  <Button
                    variant="contained"
                    onClick={AccountFilter}
                    size="small"
                    className="border-radius-20 btn-orange w-100"
                  >
                    Apply
                  </Button>
                </Box>

                <Box className="mx-3">
                  <Button
                    variant="contained"
                    size="small"
                    className="border-radius-20 btn-orange w-100"
                    onClick={resetFilter}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      {/* -------------Table box------------- */}
      <TableContainer component={Paper} className="mt-10 border-radius-20">
        <TabPanel value={value} index={0}>
          <Table
            className={classes.table}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow className="tableHeadCss">
                <TableCell className={classes.tableCell}>sr no</TableCell>
                <TableCell className={classes.tableCell}>User Name</TableCell>
                <TableCell className={classes.tableCell}>Role</TableCell>
                <TableCell className={classes.tableCell}>
                  Phone Number
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Status
                </TableCell>
                {getPermission.AddEditUser == "AddEditUser" ? (
                  <TableCell className={classes.tableCell} align="center">
                    Edit
                  </TableCell>
                ) : (
                  <TableCell className={classes.tableCell}></TableCell>
                )}
                <TableCell className={classes.tableCell} align="center">
                  User Access
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <Loader open={loading} />
            ) : (
              <>
                {noFoundData ? (
                  <TableBody>
                    {getData?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.tableCell}>
                          {index + 1 + pageState.skip}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.UserName}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.Role?.Name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.PhoneNumber}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          <DeactiveDialog
                            id={row.Id.toString()}
                            handleClickOpen={handleOpenModal}
                            open={open}
                            text="User"
                            subtext="user"
                            handleClickClose={handleClose}
                            handleClickSave={deleteDataId}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {getPermission.AddEditUser == "AddEditUser" ? (
                            <Box
                              className="buttonSecton"
                              style={{ marginTop: "0px" }}
                            >
                              <Box className="submitButton">
                                <Link
                                  to={{
                                    pathname: `/app/UpdateUsers/${row.Id}`,
                                  }}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    color="warning"
                                  >
                                    <img src={editImg} />
                                  </IconButton>
                                </Link>
                              </Box>
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={{ pathname: `/app/RolePermission/${row.Id}` }}
                            className="font-14"
                            underline="hover"
                          >
                            <img src={img} />
                          </Link>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {row.Role?.Name !== "SuperAdministrator" ? (
                            <Box>
                              <Button
                                variant="text"
                                id={row.AccountId}
                                value={row.Id}
                                onClick={handleClickOpenDialog}
                                sx={{ color: "#9747FF" }}
                              >
                                Assign Store
                              </Button>
                              <Dialog
                                fullScreen={fullScreen}
                                open={openDialog}
                                id={getDialogId}
                                aria-labelledby="responsive-dialog-title"
                                classes={{ paper: classes.dialogPaper }}
                              >
                                <Box className="sticky-header">
                                  <BootstrapDialogTitle
                                    id="customized-dialog-title"
                                    className="p-2"
                                    onClose={handleCloseDialog}
                                  >
                                    Assign Store
                                  </BootstrapDialogTitle>
                                </Box>
                                <Divider />
                                <Box className="m-2 margin-top-8">
                                  <TableContainer
                                    component={Paper}
                                    sx={{ maxHeight: 440 }}
                                    className="mt-10 border-radius-20"
                                  >
                                    <Table
                                      sx={{ minWidth: 650 }}
                                      stickyHeader
                                      aria-label="simple table"
                                      size="small"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell align="center">
                                            Store Name
                                          </TableCell>
                                          <TableCell align="center">
                                            Store Type
                                          </TableCell>
                                          <TableCell align="center">
                                            City,State
                                          </TableCell>
                                          <TableCell align="center">
                                            Contact Person
                                          </TableCell>
                                          <TableCell align="center"></TableCell>
                                        </TableRow>
                                      </TableHead>
                                      {noFoundData ? (
                                        <TableBody>
                                          {getLicenseList?.map((rowData) => (
                                            <TableRow
                                              key={rowData.StoreName}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  { border: 0 },
                                              }}
                                            >
                                              <TableCell align="center">
                                                {rowData.StoreName}
                                              </TableCell>
                                              <TableCell align="center">
                                                {rowData.StoreType?.Name}
                                              </TableCell>
                                              <TableCell align="center">
                                                {rowData.City?.Name},
                                                {rowData.City.State?.Name}
                                              </TableCell>
                                              <TableCell align="center">
                                                {rowData.ContactPerson}
                                              </TableCell>

                                              <TableCell align="center">
                                                {rowData.IsAssigned == false ? (
                                                  <Box>
                                                    <Button
                                                      variant="text"
                                                      className="text-orange margin-top-bottom-10"
                                                      id={rowData.Id}
                                                      onClick={
                                                        assignLiceseModal
                                                      }
                                                    >
                                                      Assign
                                                    </Button>

                                                    <Dialog
                                                      open={assignOpenLicense}
                                                      id={ModalDataId}
                                                      fullWidth
                                                      maxWidth="sm"
                                                      sx={{
                                                        "& .MuiPaper-elevation24":
                                                          {
                                                            boxShadow:
                                                              "none!important",
                                                          },
                                                        " .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop":
                                                          {
                                                            background:
                                                              "rgba(0,0,0,0.1)!important",
                                                          },
                                                        "& .MuiBackdrop-root": {
                                                          opacity:
                                                            "0.3!important",
                                                        },
                                                      }}
                                                    >
                                                      <BootstrapDialogTitle
                                                        id="customized-dialog-title"
                                                        onClose={
                                                          handleCloseStore
                                                        }
                                                      >
                                                        Store
                                                      </BootstrapDialogTitle>
                                                      <DialogContent
                                                        dividers
                                                        className="my-10"
                                                      >
                                                        <Typography
                                                          gutterBottom
                                                        >
                                                          Are you sure you want
                                                          to asssign this store.
                                                        </Typography>
                                                      </DialogContent>
                                                      <DialogActions>
                                                        <Button
                                                          onClick={() =>
                                                            assignStore(
                                                              ModalDataId
                                                            )
                                                          }
                                                          variant="contained"
                                                          className="border-radius-20 btn-orange w-100"
                                                        >
                                                          Yes
                                                        </Button>
                                                        <Button
                                                          onClick={
                                                            handleCloseStore
                                                          }
                                                          variant="contained"
                                                          className="border-radius-20 btn-orange w-100"
                                                        >
                                                          No
                                                        </Button>
                                                      </DialogActions>
                                                    </Dialog>
                                                  </Box>
                                                ) : (
                                                  <Box>
                                                    <Box>
                                                      <Button
                                                        variant="text"
                                                        className="text-orange margin-top-bottom-10"
                                                        id={rowData.Id}
                                                        onClick={
                                                          removeAssignStoreModal
                                                        }
                                                      >
                                                        Remove Store
                                                      </Button>
                                                      <Dialog
                                                        open={assignOpenLicense}
                                                        id={ModalDataId}
                                                        fullWidth
                                                        // BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.2)!important" } }}
                                                        maxWidth="sm"
                                                        sx={{
                                                          "& .MuiPaper-elevation24":
                                                            {
                                                              boxShadow:
                                                                "none!important",
                                                            },
                                                          " .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop":
                                                            {
                                                              background:
                                                                "rgba(0,0,0,0.1)!important",
                                                            },
                                                          "& .MuiBackdrop-root":
                                                            {
                                                              opacity:
                                                                "0.3!important",
                                                            },
                                                        }}
                                                      >
                                                        <BootstrapDialogTitle
                                                          id="customized-dialog-title"
                                                          onClose={
                                                            handleCloseStore
                                                          }
                                                        >
                                                          Store
                                                        </BootstrapDialogTitle>
                                                        <DialogContent
                                                          dividers
                                                          className="my-10"
                                                        >
                                                          <Typography
                                                            gutterBottom
                                                          >
                                                            Are you sure you
                                                            want to remove this
                                                            store.
                                                          </Typography>
                                                        </DialogContent>
                                                        <DialogActions>
                                                          <Button
                                                            onClick={() =>
                                                              removeStore(
                                                                ModalDataId
                                                              )
                                                            }
                                                            variant="contained"
                                                            className="border-radius-20 btn-orange w-100 margin-top-bottom-10"
                                                          >
                                                            Yes
                                                          </Button>
                                                          <Button
                                                            onClick={
                                                              handleCloseStore
                                                            }
                                                            variant="contained"
                                                            className="border-radius-20 btn-orange w-100"
                                                          >
                                                            No
                                                          </Button>
                                                        </DialogActions>
                                                      </Dialog>
                                                    </Box>
                                                  </Box>
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      ) : (
                                        <Box className="text-align-center font-18 font-bold py-15">
                                          No data found...
                                        </Box>
                                      )}
                                    </Table>
                                    <TablePagination
                                      component="Box"
                                      count={totalItemData}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onPageChange={handleChangePage}
                                      onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                      }
                                    />
                                  </TableContainer>
                                </Box>
                              </Dialog>
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <Box className="text-align-center font-18 font-bold py-15">
                    No data found...
                  </Box>
                )}
              </>
            )}
          </Table>
          <TablePagination
            component="Box"
            count={totalItemData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Table
            className={classes.table}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow className="tableHeadCss">
                <TableCell className={classes.tableCell}>Sr no</TableCell>
                <TableCell className={classes.tableCell}>User Name</TableCell>
                <TableCell className={classes.tableCell}>Role</TableCell>
                <TableCell className={classes.tableCell}>
                  Phone Number
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <Loader open={loading} />
            ) : (
              <>
                {noFoundData ? (
                  <TableBody>
                    {InactiveData?.map((row, index) => (
                      <TableRow key={row.Id}>
                        <TableCell className={classes.tableCell}>
                          {index + 1 + pageState.skip}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.UserName}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.Role?.Name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.PhoneNumber}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          <ActivateDialog
                            id={row.Id.toString()}
                            handleClickOpen={handleOpenModal}
                            open={open}
                            text="User"
                            subtext="user"
                            handleClickClose={handleClose}
                            handleClickSave={getActiveDataById}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <Box className="text-align-center font-18 font-bold py-15">
                    No data found...
                  </Box>
                )}
              </>
            )}
          </Table>
          <TablePagination
            component="Box"
            count={totalItemData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TabPanel>
      </TableContainer>
    </Box>
  );
}
