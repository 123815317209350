import {
    GET_DEVICEMODEL_REQUEST,GET_DEVICEMODEL_SUCCESS,GET_DEVICEMODEL_FAILURE
   } from '../Types'  

    
    
  const initialState = {  
    loading: false, 
    noFoundData:false, 
    deviceModelList:[],
    error: ''  
   
  }  
    
  const reducer = (state = initialState, action) => {  
    
    switch (action.type) {  
        case GET_DEVICEMODEL_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false
        }  
      case GET_DEVICEMODEL_SUCCESS:
        return {  
          loading: false,  
          deviceModelList: action.payload,  
          noFoundData:false,
          error: ''           
        }  
       
      case GET_DEVICEMODEL_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          deviceModelList: [],  
          error: action.payload  
        }  
        
      default: return state  
    }  
  }  
    
  export default reducer  