import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import DefaultInput from '../../Component/TextField';
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from '../../Component/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Grid } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from "react";
import { useLocation, Link as RouterLink } from 'react-router-dom'
import { saveTerminals, getTerminalDetails, updateTerminal } from '../../Redux/Terminal/TerminalAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { regexConstant } from '../../shared/constants';
import { getDeviceModelList } from '../../Redux/DeviceModel/DeviceModelAction';


export default function BasicTable() {
    const terminalDetail = useSelector((s) => s.terminalReducer);
    const deviceModelData = useSelector((s) => s.deviceModelReducer);
    const dispatch = useDispatch();
    const params = useParams();
    const [Roles, setRoles] = React.useState('Admin');

    const navigate = useNavigate();
    const [inputField, setInputField] = useState({
        terminalName: "",
        deviceModel: "",
    });
    const inputsHandler = (e) => {
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    useEffect(() => {
        if (params.id) {
            dispatch(getTerminalDetails(params.id));
        }
        dispatch(getDeviceModelList());
    }, []);
    const[getDeviceArray,setDeviceArray]=useState([])

    useEffect(() => {
        if (deviceModelData) {
            setDeviceArray(deviceModelData.deviceModelList)
        }
    }, [deviceModelData]);
    const [getStoreId, setStoreId] = useState()
    useEffect(() => {
        if (terminalDetail.TerminalDetails) {
            var accInfo = terminalDetail.TerminalDetails;
            setStoreId(accInfo.StoreId)
            if (Object.keys(accInfo).length > 0) {
                setInputField({
                    terminalName: accInfo.TerminalName != null ? accInfo.TerminalName : "",
                    deviceModel: accInfo.TerminalModelId != null ? accInfo.TerminalModelId : "",
                    storeId: accInfo.storeId
                })
            }
        }
        else if (terminalDetail.result == 200) {
            if (params.id && getStoreId) {
                toast("Terminal update successfully");
                setTimeout(() => {
                    navigate(`/app/ManageTerminal/${getStoreId}`);
                }, 1000)
            }
            else {

                toast("Terminal save successfully");
                setTimeout(() => {
                    navigate(`/app/ManageTerminal/${params.createTerminalId}`);
                }, 1000)
            }
        }
        else if (terminalDetail.error.status == 404) {
            toast(terminalDetail.error.data);
        }
        else if (terminalDetail.error.status == 401) {
            toast(terminalDetail.error.data);
        }
        else if (terminalDetail.error.status == 403) {
            toast(terminalDetail.error.data);
        }
        else if (terminalDetail.error.status == 400) {
            toast(terminalDetail.error.data);
        }
        else if (terminalDetail.error.status == undefined) {
        }
        else {
            toast("Something went wrong. Please try again later. ");
        }
    }, [terminalDetail]);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
        }
    }, [formErrors]);

    //Validate form
    const validate = (values) => {
        const errors = {};
        let formIsValid = true;
        if (values.terminalName.length < 3) {
            errors.terminalName = "Please enter greater then 3 character";
            formIsValid = false;
        }
        if (values.terminalName.length > 30) {
            errors.terminalName = "Please enter less than 30 character";
            formIsValid = false;
        }
        if (!regexConstant.regexForSpec.test(values.terminalName)) {
            errors.terminalName = "Please enter valid terminal name";
            formIsValid = false;
        }
        if (!values.terminalName) {
            errors.terminalName = "Terminal Name is required!";
            formIsValid = false;
        }
        if (!values.deviceModel) {
            errors.deviceModel = "Device Model is required!";
            formIsValid = false;
        }
        setFormErrors(errors)
        return formIsValid;
    };

    const resetData = () => {
        setInputField({
            DeviceModel: "",
            EnterTerminalName: "",
        })
        if (params.id && getStoreId) {
            navigate(`/app/ManageTerminal/${getStoreId}`);
        }
        else {
            navigate(`/app/ManageTerminal/${params.createTerminalId}`);
        }
    }
    const getAllDeviceModel =(e)=>{
        setInputField((prevState) => ({
            ...prevState,
            deviceModelId: e.target.value,
            deviceModel: e.target.value
        }));
    }
    // Save new account
    const saveAccountDetails = (result) => {
        dispatch(saveTerminals(
            {
                TerminalName: inputField.terminalName,
                terminalModelId: inputField.deviceModel,
                storeId: params.createTerminalId
            }
        ),);
    };
    // update terminal api
    // Update terminal
    var storeData = {
        storeId: getStoreId
    }
    const updateData = () => {
        dispatch(updateTerminal({
            TerminalName: inputField.terminalName,
            terminalModelId: inputField.deviceModel,
            storeId: getStoreId
        }, params.id));
    };
    // mention save method
    const submitForm = (e) => {
        e.preventDefault();
        if (validate(inputField)) {
            setIsSubmit(true);
            if (params.id && getStoreId) {
                updateData();
            }
            else {
                saveAccountDetails();
            }
        }

    }

    return (
        <div>
            <ToastContainer autoClose={1000} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                    <div className='justify-content-space'>
                        <div>
                            <div className='poppins-bold'>
                                {
                                    params.id && getStoreId ?
                                    <span>Update Terminal</span>
                                    :
                                    <span>New Terminal</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div role="presentation">
                        <Breadcrumbs aria-label="breadcrumb" className='text-black'>
                            {params.id && getStoreId ?
                                <Link color='inherit' component={RouterLink} to={{ pathname: `/app/ManageTerminal/${params.id && getStoreId}` }} underline="hover">
                                    Manage Terminal
                                </Link> : <Link color='inherit' component={RouterLink} to={{ pathname: `/app/ManageTerminal/${params.createTerminalId}` }} underline="hover">
                                    Manage Terminal
                                </Link>}
                            <Link color='inherit' className="textDecoration">
                                {params.id && getStoreId ? <span>Update Terminal</span> :
                                    <span>New Terminal</span>}
                            </Link>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div className='text-align-end '>
                        <DefaultButton color="warning" variant="contained" size="small" startIcon={<ArrowBackIosNewIcon />} Text="Back" />
                    </div>
                </Grid>
            </Grid>
            <form onSubmit={submitForm}>
                <Card className='mt-10 border-radius-20 width-100'>
                    <div className='text-align-center p-5 font-weight-bold bg-white'>{params.id && getStoreId ? <span>Update Terminal</span>
                        : <span>Add New Terminal</span>
                    }
                    </div>
                    <div className='p-4'>
                        <div>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <DefaultInput
                                        id="terminalName"
                                        label="Enter Terminal Name*"
                                        variant="standard"
                                        name="terminalName"
                                        value={inputField.terminalName}
                                        onChange={inputsHandler}
                                        placeHolder="Enter Terminal Name"
                                    />
                                    <div className='errors'>{formErrors.terminalName}</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="deviceModel"
                                        select
                                        size='small'
                                        label="Select device Model *"
                                        name='deviceModel'
                                        value={inputField.deviceModel}
                                        onChange={getAllDeviceModel}
                                    >
                                        {getDeviceArray.map((option) => (
                                            <MenuItem key={option.Name} value={option.Id}>
                                                {option.Name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='errors'>{formErrors.deviceModel}</div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className='buttonSecton'>
                            <div className='mx-3' style={{marginTop:"20px"}}>
                                <Button variant="contained" size="small" className='border-radius-20 btn-orange w-100' type='submit'>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </Card>
            </form>
        </div>
    );
}
