import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  MenuItem,
  TablePagination,
  Avatar,
  Tab,
  Tabs,
  Card,
  Box,
  Button,
  InputAdornment,
  TextField,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import PersonAddAltRounded from '@mui/icons-material/PersonAddAltRounded';
import { useDispatch, useSelector } from 'react-redux';
import { userRoles } from '../../shared/constants';
import { getDataByPagination, getInactiveDataByPagination } from '../../Redux/Product/ProductAction';
import { Link } from 'react-router-dom';
import apiService from '../../Services/ApiServices';
import Loader from '../../Component/Loader';
import editImg from '../../assets/Images/edit.png';
import DeactiveDialog from '../../Component/DeactiveDialog';
import ActivateDialog from '../../Component/ActivateDialog';
import ApplyDialog from '../../Component/ApplyDialog/ApplyDialog';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    'aria-controls': `horizontal-tabpanel-${index}`,
  };
}
const pageState = {
  skip: 0,
  top: 10,
  isDeleted: false,
  query: '',
  categoryId: '',
  SubCategoryId: '',
  productId: '',
  accountId: '',
};
const useStyles = makeStyles({
  tableCell: {
    '&.MuiTableCell-root': {
      border: 'none!important',
      padding: '10px',
    },
  },
  filterField: {
    boxShadow: 5,
    borderRadius: '10px',
    background: 'white',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
});
export default function BasicTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productData = useSelector((s) => s.productReducer);
  const { loading } = useSelector((state) => ({
    loading: state.productReducer.loading,
  }));

  const [inputValue, setInputValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const [isLoading, setIsLoading] = useState(false);
  const [getData, setData] = useState([]);
  const [InactiveData, setInactiveData] = useState([]);
  const [totalItemData, setTotalItemData] = useState(1);
  const [noFoundData, setnoFoundData] = useState(true);
  const [currentUserDetails, setCurrentUser] = useState({});
  const [getPermission, setPermission] = useState({});
  // Modal Section
  const [open, setOpen] = React.useState(false);
  const [ModalDataId, setModalDataId] = useState();

  // Input Field handler
  const [search, setSearch] = useState('');
  const [handleIcon, setIcon] = useState(true);
  const [currentUserRole, setRole] = useState('');
  // on click tab section
  const [value, setValue] = React.useState(0);
  // close on click tab section
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [inputField, setInputField] = useState({
    categoryName: '',
    subCatergoryName: '',
    productName: '',
  });
  const [getProductCat, productCat] = useState('');
  const [getProductSubCat, productSubCat] = useState('');
  const [categoryData, setCategoryData] = useState([]);
  const [getCatId, setCatId] = useState([]);
  const [subcategoryData, setSubCategoryData] = useState([]);
  const [getDataById, setDataById] = useState([]);
  const [acoountData, setAccountData] = useState([]);
  const [accountValue, setAccountValue] = useState('');


  
  // ----------intial List Call------------
  const intialListCall = () => {
    pageState.query = '';
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  // ----------useEffect call----------------
  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem('userDetails'));
    let newObj = {};
    userDetail.permissions.forEach((element) => {
      newObj[`${element}`] = element;
    });
    setPermission(newObj);
    setSearch('');
    pageState.query = '';
    pageState.top = 10;
    pageState.skip = 0;
    pageState.categoryId = '';
    pageState.SubCategoryId = '';
    pageState.productId = '';
    pageState.accountId = '';
    inputField.categoryName = '';
    inputField.subCatergoryName = '';
    inputField.productName = '';
    if (userDetail) {
      setRole(userDetail.userRole);
      if (userDetail.userRole != userRoles.SuperAdmin) {
        pageState.accountId = userDetail.accountId;
        pageState.skip = 0;
        pageState.top = 10;
        getAllAccountCategory(userDetail.accountId);
      } else {
        getAllAccountCategory();
      }
    }
    dispatch(getDataByPagination(pageState));
    intialListCall();
  }, []);

  useEffect(() => {
    if (!productData.noFoundData) {
      if (productData.isAccountActive == true) {
        setData(productData.product.Data);
        setTotalItemData(productData.product?.TotalItems || 1);
      } else if (productData.isAccountActive == false) {
        setInactiveData(productData.product.Data);
        setTotalItemData(productData.product?.TotalItems || 1);
      }
      // setIsLoading(false);
      setnoFoundData(true);
    } else {
      // setIsLoading(false);
      setnoFoundData(false);
    }
  }, [productData]);
  useEffect(() => {
    getAllAccount();
  }, []);

  // -------------Get account data-------------
  const getAllAccount = () => {
    apiService
      .getData(`/Account/all/dropdown`)
      .then((res) => {
        console.log('res of accunt dropdown', res);
        setAccountData(res.data);
      })
      .catch((res) => {});
  };
  // get active data data by ID
  const getActiveDataById = () => {
    apiService
      .saveData(`/AccountProduct/activate`, { productId: ModalDataId })
      .then((res) => {
        setOpen(false);
        dispatch(getInactiveDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };

  // -------Pegination event-------------
  const handleChange = (event, newValue) => {
    const indexValue = event.target.id.split('-')[2];
    setValue(newValue);
    if (indexValue > 0) {
      pageState.isDeleted = true;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      setRowsPerPage(parseInt(pageState.top));
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      // setIsLoading(true);
      pageState.isDeleted = false;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      dispatch(getDataByPagination(pageState));
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);

    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    }
  };
  // -----------Search event------------
  const resetInputField = (e) => {
    setSearch('');
    pageState.query = '';
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setInputValue('');
    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };
  const handleUserInput = (e) => {
    if (e.target.value) {
      setIcon(false);
      // setIcon(e.target.value)
    }
    if (e.target.value === '') {
      setIcon(true);
    }
    setInputValue(e.target.value);
  };
  const SearchData = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    pageState.query = search;
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  // -----------Model event-----------
  const handleOpenModal = (e) => {
    setOpen(true);
    setModalDataId(e.target.id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // deleted data data by ID
  const deleteDataId = (obj) => {
    apiService
      .deleteData(`/AccountProduct/${ModalDataId}`)
      .then((res) => {
        setOpen(false);
        setIsLoading(true);
        dispatch(getDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
  // --------------handle chnge of all fields--------------
  const getAccountId = (e) => {
    pageState.categoryId = '';
    pageState.SubCategoryId = '';
    pageState.productId = '';
    setInputField((prevState) => ({
      ...prevState,
      categoryId: '',
      categoryName: '',
      subCatergoryName: '',
      productName: '',
    }));

    setAccountValue(e.target.value);
    pageState.accountId = e.target.value;
    getAllAccountCategory(e.target.value);
  };
  const getAllCategoryId = (e) => {
    pageState.SubCategoryId = '';
    pageState.productId = '';
    setInputField((prevState) => ({
      ...prevState,
      categoryId: e.target.value,
      categoryName: e.target.value,
      subCatergoryName: '',
      productName: '',
      SubCategoryId: '',
      productId: '',
    }));
    getAllSubCategory(e.target.value);
  };
  const getAllSubCat = (e) => {
    pageState.productId = '';
    setInputField((prevState) => ({
      ...prevState,
      SubCategoryId: e.target.value,
      subCatergoryName: e.target.value,
      productName: '',
      productId: '',
    }));
    productFilterById(e.target.value);
  };
  const getAllProduct = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      productId: e.target.value,
      productName: e.target.value,
    }));
  };

  // --------Get data for dropdown evnts --------------
  const getAllAccountCategory = (obj) => {
    let url = `/AccountCategory/all`;
    if (obj !== undefined) {
      url += `?accountId=${obj}`;
    }
    apiService
      .getData(url)
      .then((res) => {
        setCategoryData(res.data.Data);
      })
      .catch((res) => {});
  };

  const getAllSubCategory = (Obj) => {
    apiService
      .getData(`/AccountSubCategory/allNames?categoryId=${Obj}`)
      .then((res) => {
        setSubCategoryData(res.data.Data);
      })
      .catch((res) => {});
  };
  const productFilterById = (Obj) => {
    apiService
      .getData(`/AccountProduct/all?subCategoryId=${Obj}`)
      .then((res) => {
        setDataById(res.data.Data);
      })
      .catch((res) => {});
  };

  // ------------Filter and reset evnt----------------
  const productFilter = (getProductSubCat, getProductCat) => {
   
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);

    if (accountValue) {
      pageState.accountId = accountValue;
    }
    if (inputField.categoryName) {
      pageState.categoryId = inputField.categoryId;
    }
    if (inputField.subCatergoryName) {
      pageState.SubCategoryId = inputField.SubCategoryId;
    }
    if (inputField.productName) {
      pageState.productId = inputField.productId;
    }
    if(pageState.accountId === ''){
     setOpenApply(true)
    }
    if (value == 0) {
      setIsLoading(true);
     
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }

  

    
  };
  const resetData = (e) => {
    setSearch('');
    pageState.query = '';
    pageState.top = 10;
    pageState.skip = 0;
    pageState.categoryId = '';
    pageState.SubCategoryId = '';
    pageState.productId = '';
    pageState.accountId="";
    inputField.categoryName = '';
    inputField.subCatergoryName = '';
    inputField.productName = '';
    setAccountValue('');
    setRowsPerPage(parseInt(pageState.top));
    setInputValue('');
    if (pageState.isDeleted) {
      console.log("pageState0000",pageState)
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      console.log("pageState",pageState)
      dispatch(getDataByPagination(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };

  const [openApply, setOpenApply] = React.useState(false);
  const handleCloseApply =()=>{
    setOpenApply(false)
  }
  
  return (
    <div>
      <div>
        <div className="justify-content-space">
          <div className="poppins-bold">Manage Product</div>
          {getPermission.AddEditProducts == 'AddEditProducts' ? (
            <div>
              <div className="text-align-end showMobile my-8">
                <Link to="/app/AddItem" className="textDecoration">
                  <Button>
                    <Avatar sx={{ border: '1px solid #9747FF' }} style={{ backgroundColor: 'white' }}>
                      <PersonAddAltRounded sx={{ color: '#9747FF' }} />
                    </Avatar>
                  </Button>
                </Link>
              </div>
              <div className="showINWindow">
                <Link to="/app/AddItem" className="textDecoration">
                  <Button
                    variant="outlined"
                    size="small"
                    className="border-radius-20 roboto addBtn"
                    style={{
                      boxShadow: 3,
                      borderRadius: '15px',
                      background: '#fff',
                      color: '#8B8D9D',
                      border: 'none',
                      fontWeight: '100 !important',
                      padding: '5px 16px',
                    }}
                  >
                    <AddIcon color="warning" className="mx-5" sx={{ color: '#9747FF' }} />
                    ADD PRODUCT
                  </Button>
                </Link>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <Grid container spacing={2} justifyContent="space-between" alignItems={'center'}>
          <Grid item xs={12} sm={12} md={5}></Grid>
          <Grid item xs={12} sm={12} md={2.3}>
            <div>
              <div className=" pt-10">
                <Box>
                  <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="horizontal tabs example"
                  >
                    <Tab label="Active" {...a11yProps(0)} className="roboto-700" />
                    <Tab label="InActive" {...a11yProps(1)} className="roboto-700" />
                  </Tabs>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2} justifyContent="space-between">
        {currentUserRole == 2 ? (
          ''
        ) : (
          <Grid item xs={12} sm={12} md={currentUserRole == 2 ? 3 : 2.3}>
            <div>
              <TextField
                id="outlined-select-currency"
                select={currentUserRole == 2 ? false : true}
                disabled={currentUserRole == 2 ? true : false}
                size="small"
                label="Account"
                name="accountWithId"
                onChange={getAccountId}
                value={accountValue}
                className={classes.filterField}
              >
                {acoountData
                  .sort((a, b) => a.AccountName.localeCompare(b.AccountName))
                  .map((option) => (
                    <MenuItem key={option.AccountName} value={option.Id}>
                      {option.AccountName}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={currentUserRole == 2 ? 3 : 2.3}>
          <TextField
            id="outlined-select-currency"
            select
            disabled={currentUserRole == 1 && accountValue == '' ? true : false}
            size="small"
            label="Category"
            name="Category"
            value={inputField.categoryName}
            onChange={getAllCategoryId}
            className={classes.filterField}
          >
            {categoryData?.map((option) => (
              <MenuItem key={option.Name} value={option.Id}>
                {option.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={currentUserRole == 2 ? 3 : 2.2}>
          <TextField
            id="outlined-select-currency"
            select
            disabled={inputField.categoryName == '' ? true : false}
            size="small"
            name="subCatergory"
            label="Sub Category"
            value={inputField.subCatergoryName}
            onChange={getAllSubCat}
            className={classes.filterField}
          >
            {subcategoryData.map((option) => (
              <MenuItem key={option.Name} value={option.Id}>
                {option.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={currentUserRole == 2 ? 3 : 2.3}>
          <TextField
            id="outlined-select-currency"
            select
            disabled={inputField.subCatergoryName == '' ? true : false}
            size="small"
            name="productName"
            label="Product Name"
            value={inputField.productName}
            onChange={getAllProduct}
            className={classes.filterField}
          >
            {getDataById.map((option) => (
              <MenuItem key={option.Name} value={option.Id}>
                {option.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={currentUserRole == 2 ? 3 : 2.3}>
          <div className="gridButtonSection">
            <div className="mx-3">
              <Button
                variant="contained"
                onClick={productFilter}
                size="small"
                className="border-radius-20 btn-orange w-100"
              >
                Apply
              </Button>
            </div>
            <div className="mx-3">
              <div>
                <Button
                  variant="contained"
                  onClick={resetData}
                  size="small"
                  className="border-radius-20 btn-orange w-100"
                >
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div>
        {/* ---------Table panel for 0----------- */}
        <TabPanel value={value} index={0}>
          <TableContainer component={Paper} className="mt-10 border-radius-20">
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow className="tableHeadCss">
                  <TableCell className={classes.tableCell}>Sr No</TableCell>
                  {currentUserRole == 2 ? '' : <TableCell className={classes.tableCell}>Account Name</TableCell>}
                  <TableCell className={classes.tableCell}>Product Name</TableCell>
                  <TableCell className={classes.tableCell}>GAI Item Code</TableCell>
                  <TableCell className={classes.tableCell}>Category</TableCell>
                  <TableCell className={classes.tableCell}>Sub Category</TableCell>
                  <TableCell className={classes.tableCell}>Item Code</TableCell>
                  <TableCell className={classes.tableCell}>UOM</TableCell>
                  {getPermission.AddEditProducts == 'AddEditProducts' ? (
                    <TableCell className={classes.tableCell} align="center">
                      Edit
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableCell}></TableCell>
                  )}
                  <TableCell className={classes.tableCell}>Status</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loader open={loading} />
              ) : (
                <>
                  {noFoundData ? (
                    <TableBody>
                      {getData?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCell}>{index + 1 + pageState.skip}</TableCell>
                          {currentUserRole == 2 ? (
                            ''
                          ) : (
                            <TableCell className={classes.tableCell}>{row?.Account?.AccountName}</TableCell>
                          )}
                          <TableCell className={classes.tableCell}>{row.Name}</TableCell>
                          <TableCell className={classes.tableCell}>{row.ProductKey}</TableCell>
                          <TableCell className={classes.tableCell}>{row.AccountCategory?.Name}</TableCell>
                          <TableCell className={classes.tableCell}>{row.AccountSubCategory?.Name}</TableCell>
                          <TableCell className={classes.tableCell}>{row.PluCode}</TableCell>
                          {/* <TableCell>{row.SkuCode}</TableCell> */}
                          <TableCell className={classes.tableCell}>{row.UOM}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {getPermission.AddEditProducts == 'AddEditProducts' ? (
                              <div className="buttonSecton" style={{ marginTop: '0px' }}>
                                <div className="submitButton">
                                  <Link
                                    to={{
                                      pathname: `/app/updateAddItem/${row.Id}`,
                                    }}
                                  >
                                    <IconButton aria-label="delete" color="warning">
                                      <img src={editImg} />
                                    </IconButton>
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <DeactiveDialog
                              id={row.Id.toString()}
                              handleClickOpen={handleOpenModal}
                              open={open}
                              text="Product"
                              subtext="product"
                              handleClickClose={handleClose}
                              handleClickSave={deleteDataId}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <div className="text-align-center font-18 font-bold py-15">No data found...</div>
                  )}
                </>
              )}
            </Table>
            <TablePagination
              component="div"
              count={totalItemData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </TabPanel>
        {/* ---------Table panel for 1----------- */}
        <TabPanel value={value} index={1}>
          <TableContainer component={Paper} className="mt-10 border-radius-20">
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow className="tableHeadCss">
                  <TableCell className={classes.tableCell}>sr no</TableCell>
                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell className={classes.tableCell}>GAI Item Code</TableCell>
                  <TableCell className={classes.tableCell}>Category</TableCell>
                  <TableCell className={classes.tableCell}>Sub Category</TableCell>
                  <TableCell className={classes.tableCell}>Item Code</TableCell>
                  {/* <TableCell>Sku Code</TableCell> */}
                  <TableCell className={classes.tableCell}>UOM</TableCell>
                  <TableCell className={classes.tableCell}>Status</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loader open={loading} />
              ) : (
                <>
                  {noFoundData ? (
                    <TableBody>
                      {InactiveData?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCell}>{index + 1 + pageState.skip}</TableCell>
                          <TableCell className={classes.tableCell}>{row.Name}</TableCell>
                          <TableCell className={classes.tableCell}>{row.ProductKey}</TableCell>
                          <TableCell className={classes.tableCell}>{row.AccountCategory?.Name}</TableCell>
                          <TableCell className={classes.tableCell}>{row.AccountSubCategory?.Name}</TableCell>
                          <TableCell className={classes.tableCell}>{row.PluCode}</TableCell>
                          {/* <TableCell>{row.SkuCode}</TableCell> */}
                          <TableCell className={classes.tableCell}>{row.UOM}</TableCell>
                          <TableCell className={classes.tableCell}>
                            <ActivateDialog
                              id={row.Id.toString()}
                              handleClickOpen={handleOpenModal}
                              open={open}
                              text="Product"
                              subtext="product"
                              handleClickClose={handleClose}
                              handleClickSave={getActiveDataById}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <div className="text-align-center font-18 font-bold py-15">No data found...</div>
                  )}
                </>
              )}
            </Table>
            <TablePagination
              component="div"
              count={totalItemData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </TabPanel>
      </div>
    <ApplyDialog handleCloseApply={handleCloseApply} openApply={openApply}  />
   
    </div>
  );
}
