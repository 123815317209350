import apiService from "../../Services/ApiServices"
import {  
 GET_COUNTRY_REQUEST,GET_COUNTRY_SUCCESS,GET_COUNTRY_FAILURE,
 GET_STATE_REQUEST,GET_STATE_SUCCESS,GET_STATE_FAILURE,
 GET_CITY_REQUEST,GET_CITY_SUCCESS,GET_CITY_FAILURE
} from '../Types'  


export const getCountryList = () =>  {
    try {
      return (dispatch) => {  
        dispatch(getCountryRequest())  
        let url = `/Country/all`;
        apiService.getData(url)
            .then(res => {
              const countries = res.data  
              if (res.data) {
                dispatch(getCountrySuccess(countries))  
            }
            else {
                dispatch(getCountryFailure('err'))
            }
              
            }).catch(err =>{ dispatch(getCountryFailure(err)) });
      }  
    } catch (err) {
      
    }
  };

 

  export const getCountryRequest = () => {  
    return {  
      type: GET_COUNTRY_REQUEST  
    }  
  }  
    
  export const getCountrySuccess = countries => {  
    return {  
      type: GET_COUNTRY_SUCCESS,  
      payload: countries  
    }  
  } 
    
  export const getCountryFailure = error => {  
    return {  
      type: GET_COUNTRY_FAILURE,  
      payload: error  
    }  
  }  

 
