import apiService from "../../Services/ApiServices";
import {
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_FAILURE,
  GET_ACTIVE_PRODUCT_SUCCESS,
  GET_INACTIVE_PRODUCT_SUCCESS,
  GET_PRODUCTDETAIL_REQUEST,
  GET_PRODUCTDETAIL_SUCCESS,
  GET_PRODUCTDETAIL_FAILURE,
  SAVE_PRODUCT_REQUEST,
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_FAILURE,
  GET_IMAGE_REQUEST,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_FAILURE,
} from "../Types";
export const getProductDetailRequest = () => {
  return {
    type: GET_PRODUCTDETAIL_REQUEST,
  };
};

export const getProductDetailSuccess = (product) => {
  return {
    type: GET_PRODUCTDETAIL_SUCCESS,
    payload: product,
  };
};

export const getProductDetailFailure = (error) => {
  return {
    type: GET_PRODUCTDETAIL_FAILURE,
    payload: error,
  };
};
export const getProductRequest = () => {
  return {
    type: GET_PRODUCT_REQUEST,
  };
};

export const getProductSuccess = (Product) => {
  return {
    type: GET_ACTIVE_PRODUCT_SUCCESS,
    payload: Product,
  };
};

export const getProductFailure = (error) => {
  return {
    type: GET_PRODUCT_FAILURE,
    payload: error,
  };
};
// save Data return type
export const saveProductRequest = () => {
  return {
    type: SAVE_PRODUCT_REQUEST,
  };
};

export const saveProductSuccess = (result) => {
  return {
    type: SAVE_PRODUCT_SUCCESS,
    payload: result,
  };
};

export const saveProductFailure = (error) => {
  return {
    type: SAVE_PRODUCT_FAILURE,
    payload: error,
  };
};
export const getInactiveProductSuccess = (product) => {
  return {
    type: GET_INACTIVE_PRODUCT_SUCCESS,
    payload: product,
  };
};

// get data
export const getDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getProductRequest());
      let url = `/AccountProduct/all?skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId != "" && pageState.accountId != undefined) {
        url += `&accountId=${pageState.accountId}`;
      }
      if (pageState.categoryId != "") {
        url += `&categoryId=${pageState.categoryId}`;
      }
      if (pageState.SubCategoryId != "") {
        url += `&subCategoryId=${pageState.SubCategoryId}`;
      }
      if (pageState.productId != "") {
        url += `&productId=${pageState.productId}`;
      }
      apiService
        .getData(url)
        .then((res) => {
          const Category = res.data;
          if (res.data.Data == "") {
            dispatch(getProductFailure("err"));
          } else {
            dispatch(getProductSuccess(Category));
          }
        })
        .catch((err) => {
          dispatch(getProductFailure(err));
        });
    };
  } catch (err) {}
};
export const getInactiveDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getProductRequest());
      let url = `/AccountProduct/all?deleted=${true}&skip=${
        pageState.skip
      }&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId != "") {
        url += `&accountId=${pageState.accountId}`;
      }
      if (pageState.categoryId != "") {
        url += `&categoryId=${pageState.query}`;
      }
      if (pageState.SubCategoryId != "") {
        url += `&subCategoryId=${pageState.SubCategoryId}`;
      }
      if (pageState.productId != "") {
        url += `&productId=${pageState.productId}`;
      }

      apiService
        .getData(url)
        .then((res) => {
          const product = res.data;
          if (res.data.Data == "") {
            dispatch(getProductFailure("err"));
          } else {
            dispatch(getInactiveProductSuccess(product));
          }
        })
        .catch((err) => {
          dispatch(getProductFailure(err));
        });
    };
  } catch (err) {}
};
//Get logo image
export const getImageRequest = () => {
  return {
    type: GET_IMAGE_REQUEST,
  };
};

export const getImageSuccess = (result) => {
  return {
    type: GET_IMAGE_SUCCESS,
    payload: result,
  };
};

export const getImageFailure = (error) => {
  return {
    type: GET_IMAGE_FAILURE,
    payload: error,
  };
};

// save data
export const saveProduct = (formData) => {
  try {
    return (dispatch) => {
      dispatch(saveProductRequest());
      let url = `/AccountProduct/create`;
      apiService
        .saveData(url, formData)
        .then((res) => {
          const result = res.status;
          if (result == 200) {
            dispatch(saveProductSuccess(result));
          } else {
            dispatch(saveProductFailure(res.response.status));
          }
        })
        .catch((err) => {
          dispatch(saveProductFailure(err));
        });
    };
  } catch (err) {}
};

export const getLogoImageData = (productId) => {
  try {
    return (dispatch) => {
      dispatch(getImageRequest());
      let url = `/AccountProduct/logo/${productId}`;
      apiService
        .getData(url)
        .then((res) => {
          const imageData = res.data;
          if (res.data) {
            dispatch(getImageSuccess(imageData));
          } else {
            dispatch(getImageFailure("err"));
          }
        })
        .catch((err) => {
          dispatch(getImageFailure(err));
        });
    };
  } catch (err) {}
};
export const updateProduct = (formData, productId) => {
  try {
    return (dispatch) => {
      dispatch(saveProductRequest());
      let url = `/AccountProduct/update/${productId}`;
      apiService
        .saveData(url, formData)
        .then((res) => {
          const result = res.status;
          if (result == 200) {
            dispatch(saveProductSuccess(result));
          } else {
            dispatch(saveProductFailure(res.response.status));
          }
        })
        .catch((err) => {
          dispatch(saveProductFailure(err));
        });
    };
  } catch (err) {}
};
export const getProductDetails = (productId) => {
  try {
    return (dispatch) => {
      dispatch(getProductDetailRequest());
      let url = `/AccountProduct/${productId}`;
      apiService
        .getData(url)
        .then((res) => {
          const productDetail = res.data;
          if (res.data) {
            dispatch(getProductDetailSuccess(productDetail));
          } else {
            dispatch(getProductDetailFailure("err"));
          }
        })
        .catch((err) => {
          dispatch(getProductDetailFailure(err));
        });
    };
  } catch (err) {}
};
