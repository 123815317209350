
import apiService from "../../Services/ApiServices"
import {
  GET_ITEM_WISE_REQUEST, GET_ITEM_WISE_SUCCESS, GET_ITEM_WISE_FAILURE
} from '../Types'
export const getItemWiseReports = (AccountId, pageState) => {
  try {
    return (dispatch) => {
      dispatch(getItemWiseRequest())
      let url = `/Reports/orders/itemwise?accountId=${AccountId}&skip=${pageState.skip}&top=${pageState.top}&FromDate=${pageState.fromDate}`;
      if (pageState.query) {
        url += `&query=${pageState.query}`;
      }
      if (pageState.StateId) {
        url += `&StateId=${pageState.StateId}`;
      }
      if (pageState.CityId) {
        url += `&CityId=${pageState.CityId}`;
      }
      if (pageState.StoreId) {
        url += `&StoreId=${pageState.StoreId}`;
      }
      if (pageState.TerminalId) {
        url += `&TerminalId=${pageState.TerminalId}`;
      }
      if(pageState.toDate){
        url += `&ToDate=${pageState.toDate}`
      }
      if (pageState.PaymentMode) {
        if (pageState.PaymentMode == "ALL") {
          url += `&PaymentMode`;
        }
        else {
          url += `&PaymentMode=${pageState.PaymentMode}`;
        }
      }


      apiService.getData(url)
        .then(res => {
          const itemWiseData = res.data
          if (res.data.Data == "") {
            dispatch(getItemWiseFailure('err'))
          }
          else {
            dispatch(getItemWiseSuccess(itemWiseData))
          }
        }).catch(err => { dispatch(getItemWiseFailure(err)) });
    }
  } catch (err) {

  }
};
export const getItemWiseRequest = () => {
  return {
    type: GET_ITEM_WISE_REQUEST
  }
}

export const getItemWiseSuccess = itemWiseData => {
  return {
    type: GET_ITEM_WISE_SUCCESS,
    payload: itemWiseData
  }
}

export const getItemWiseFailure = error => {
  return {
    type: GET_ITEM_WISE_FAILURE,
    payload: error
  }
}  
