import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import DefaultInput from '../../Component/TextField';
import { Grid } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from "react"
import apiService from "../../Services/ApiServices";
import { useLocation, Link as RouterLink } from 'react-router-dom'
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveLicense, getLicenseDetails, updateLicense } from '../../Redux/License/LicenseAction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function BasicTable() {
    const licenseDetail = useSelector((s) => s.licenseReducer);
    const dispatch = useDispatch();
    const [currentUserId, setCurrentUserId] = useState({});
    const [getAccountId, setAccountIdLicense] = useState()
    const [getUserRoles, setUserRoles] = useState("")

    useEffect(() => {
        const userDetail = JSON.parse(localStorage.getItem("userDetails"));
        setUserRoles(userDetail.userRole)
        setAccountIdLicense(userDetail.accountId)
        getAllAccount()
    }, [])
    const [inputField, setInputField] = useState({
        LicenseKey: "",
        MacAddress: "",
        GeneratedOn: "",
        ExpiryDate: "",
        ApkVersion: "",
        ActivationDate: ""
    });
    const inputsHandler = (e) => {
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));

    };
    const handleChangeDate = (e) => {
        setInputField((prevState) => ({
            ...prevState,
        }));
    }
    useEffect(() => {
        if (params.updateLicenseId) {
            dispatch(getLicenseDetails(params.updateLicenseId));
        }
    }, []);
    const params = useParams();
    const navigate = useNavigate();
    const resetData = () => {
        setInputField({
            LicenseKey: "",
            MacAddress: "",
            GeneratedOn: "",
            ExpiryDate: "",
            ApkVersion: "",
            ActivationDate: ""
        })
        if (params.updateLicenseId && accontId) {
            navigate(`/app/License/${accontId}`);
        }
        else {
            navigate(`/app/License/${params.createLicenseId}`);

        }
    }
    //validation Type
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
        }
    }, [formErrors]);
    const validate = (values) => {
        const errors = {};
        let formIsValid = true;
        if (!values.LicenseKey) {
            errors.LicenseKey = "License Key is required!";
            formIsValid = false;
        }
        if (!values.ExpiryDate) {
            errors.ExpiryDate = "Expiry Date is required!";
            formIsValid = false;
        }
        if (!values.GeneratedOn) {
            errors.GeneratedOn = "Generated Date is required!";
            formIsValid = false;
        }


        setFormErrors(errors)
        return formIsValid;
    };
    useEffect(() => {
        if (licenseDetail.LicenseDetails) {
            var accInfo = licenseDetail.LicenseDetails;
            setAccountId(accInfo.AccountId)
            if (Object.keys(accInfo).length > 0) {
                setInputField({
                    LicenseKey: accInfo.LicenseKey != null ? accInfo.LicenseKey : "",
                    GeneratedOn: moment(accInfo.GeneratedOn).format("YYYY-MM-DD") != null ? moment(accInfo.GeneratedOn).format("YYYY-MM-DD") : "",
                    ExpiryDate: moment(accInfo.ExpiryDate).format("YYYY-MM-DD") != null ? moment(accInfo.ExpiryDate).format("YYYY-MM-DD") : "",
                    ActivationDate: moment(accInfo.ActivationDate).format("YYYY-MM-DD") != null ? moment(accInfo.ActivationDate).format("YYYY-MM-DD") : "",
                    ApkVersion: accInfo.AppVersion != null ? accInfo.AppVersion : "",
                })
            }
        }
        else if (licenseDetail.result == 200) {
            if (params.updateLicenseId && accontId) {
                toast("License update successfully");
                setTimeout(() => {
                    navigate(`/app/License/${accontId}`);
                }, 1000)
            }
            else {

                toast("License save successfully");
                setTimeout(() => {
                    navigate(`/app/License/${params.createLicenseId}`);
                }, 1000)
            }
        }
        else if (licenseDetail.error.status == 404) {
            toast(licenseDetail.error.data);
        }
        else if (licenseDetail.error.status == 401) {
            toast(licenseDetail.error.data);
        }
        else if (licenseDetail.error.status == 403) {
            toast(licenseDetail.error.data);
        }
        else if (licenseDetail.error.status == 400) {
            toast(licenseDetail.error.data);
        }
        else if (licenseDetail.error.status == undefined) {
        }
        else {
            toast("Something went wrong. Please try again later. ");
        }
    }, [licenseDetail]);

    const [accontId, setAccountId] = useState("")
    const saveLicenseDetails = () => {
        dispatch(saveLicense(
            {
                licenseKey: inputField.LicenseKey,
                expiryDate: new Date(inputField.ExpiryDate).toISOString(),
                generatedOn: new Date(inputField.ExpiryDate).toISOString(),
                activationDate: moment.utc(inputField.ActivationDate).local().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                appVersion: inputField.ApkVersion,
                accountId: getAccountId
            }
        ));
    };
    // Update License
    const updateData = () => {
        dispatch(updateLicense({
            licenseKey: inputField.LicenseKey,
            expiryDate: new Date(inputField.ExpiryDate).toISOString(),
            generatedOn: new Date(inputField.ExpiryDate).toISOString(),
            activationDate: moment.utc(inputField.ActivationDate).local().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            appVersion: inputField.ApkVersion,
            accountId: accontId
        }, params.updateLicenseId));
    };
    const submitForm = (e) => {
        e.preventDefault();
        if (validate(inputField)) {
            setIsSubmit(true);
            if (params.updateLicenseId && accontId) {
                updateData();
            }
            else {
                saveLicenseDetails();
            }
        }

    }
    const [startMinDate, setStartDate] = useState(new Date())
    const [acoountData, setAccountData] = useState([])
    const getAllAccountId = (e) => {

        setInputField((prevState) => ({
            ...prevState,
            // Roles: event.target.value
            StoreTypeId: e.target.value,
            accountName: e.target.value
        }));
        setAccountIdLicense(e.target.value)
    };
    const getAllAccount = () => {
        apiService.getData(`/Account/all/dropdown`)
            .then(res => {
                setAccountData(res.data)

            }).catch(res => {

            })
    }
    return (
        <div>
            <ToastContainer autoClose={1000} />
            <div className='justify-content-space'>
                <div>
                    <div className='poppins-bold'>
                        New License
                    </div>
                </div>
            </div>
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb" className='text-black'>
                    {params.updateLicenseId && accontId ?
                        <Link color='inherit' component={RouterLink} to={{ pathname: `/app/License/${params.updateLicenseId && accontId}` }} underline="hover">
                            Manage License
                        </Link> : <Link color='inherit' component={RouterLink} to={{ pathname: `/app/License/${params.createLicenseId}` }} underline="hover">
                            Manage License
                        </Link>}
                    <Link color='inherit' className="textDecoration">
                        {params.updateLicenseId && accontId ? <span>Update License</span> :
                            <span>New License</span>}
                    </Link>
                </Breadcrumbs>
            </div>
            <form onSubmit={submitForm}>

                <Card className='mt-10 border-radius-20 width-100'>
                    <div className='text-align-center p-5 font-weight-bold bg-white'>Add New License</div>
                    <div className='p-4'>
                        <div>
                            <Grid container spacing={3}>
                                {
                                    getUserRoles == 1 ?
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        size='small'
                                                        label="Account *"
                                                        name='accountWithId'
                                                        value={inputField.accountName}
                                                        onChange={getAllAccountId}
                                                    >
                                                        {acoountData.map((option) => (
                                                            <MenuItem key={option.value} value={option.Id}>
                                                                {option.AccountName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </div>
                                        </Grid> : <div></div>

                                }
                                <Grid item xs={12} sm={12} md={6}>
                                    <DefaultInput
                                        id="LicenseKey"
                                        label="License Key *"
                                        variant="standard"
                                        name="LicenseKey"
                                        value={inputField.LicenseKey}
                                        onChange={inputsHandler}
                                        placeHolder="License Key"
                                    />
                                    <div className='errors'>{formErrors.LicenseKey}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        id="date"
                                        label="Generated On *"
                                        type="date"
                                        name='GeneratedOn'
                                        value={inputField.GeneratedOn}
                                        onChange={inputsHandler}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <div className='errors'>{formErrors.GeneratedOn}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    {/* <TextField
                                        id="ExpiryDate"
                                        defaultValue={new Date().toISOString().slice(0, 16)}
                                        inputProps={{
                                            // min: "2021-02-20T00:00",
                                            min: new Date().toISOString().slice(0, 16)
                                        }}
                                        label="Expiry Date"
                                        type="date"
                                        // type="datetime-local"
                                        name='ExpiryDate'
                                        value={inputField.ExpiryDate}
                                        onChange={inputsHandler}
                                        // className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                            min: "2022-08-29"
                                        }}
                                    /> */}
                                    <TextField
                                        // DatePicker
                                        id="ExpiryDate"
                                        // inputProps={{
                                        //     // min: "2021-02-20T00:00",
                                        //     min: new Date().toISOString().slice(0, 16)
                                        // }}
                                        // disablePast
                                        // minDate={startMinDate}
                                        label="Expiry Date *"
                                        // type="datetime-local"
                                        type="date"
                                        name='ExpiryDate'
                                        // minDate={today}
                                        value={inputField.ExpiryDate}
                                        onChange={inputsHandler}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    />

                                    {/* <DatePicker id="ExpiryDate"
                                        // disablePast
                                        minDate={startMinDate}
                                        label="Expiry Date *"
                                        type="date"
                                        name='ExpiryDate'
                                        // minDate={today}
                                        value={inputField.ExpiryDate}
                                        onChange={handleChangeDate}
                                    /> */}
                                    <div className='errors'>{formErrors.ExpiryDate}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        id="ActivationDate"
                                        label="Activation Date"
                                        type="date"
                                        name="ActivationDate"
                                        value={inputField.ActivationDate}
                                        onChange={inputsHandler}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <DefaultInput
                                        id="ApkVersion"
                                        label="App Version"
                                        variant="standard"
                                        name="ApkVersion"
                                        value={inputField.ApkVersion}
                                        onChange={inputsHandler}
                                        placeHolder="Apk Version"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='buttonSecton'>
                            <div className='mx-3'>
                                <Button variant="contained" type="submit" size="small" className='border-radius-20 btn-orange w-100'>
                                    Save
                                </Button>
                            </div>
                            <div>
                                <Button variant="contained" size="small" className='border-radius-20 btn-orange w-100' onClick={resetData}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </Card>
            </form>
        </div>
    );
}
