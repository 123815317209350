import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux'

import AccountReducer from "./Account/AccountReducer";
import TerminalReducer from "./Terminal/TerminalReducer"
import LicenseReducer from "./License/LicenseReducer";
import StoreReducer from "./Store/StoreReducer";
import UserReducer from "./User/UserReducer";
import CategoryReducer from "./Category/CategoryReducer"
import SubCategoryReducer from "./SubCategory/SubCategoryReducer"
import ProductReducer from "./Product/ProductReducer"
import CountryReducer from "./Country/CountryReducer";
import StateReducer from "./State/StateReducer";
import CityReducer from "./City/CityReducer";
import DeviceModelReducer from './DeviceModel/DeviceModelReducer';
import SaleWiseReducer from './Reports/SaleWiseReducer';
import ItemWiseReducer from './Reports/ItemWiseReducer';

import thunk from 'redux-thunk'


const MainReducer = combineReducers({
    accountReducer: AccountReducer,
    terminalReducer: TerminalReducer,
    licenseReducer: LicenseReducer,
    storeReducer: StoreReducer,
    countryReducer: CountryReducer,
    stateReducer: StateReducer,
    userReducer: UserReducer,
    cityReducer: CityReducer,
    categoryReducer: CategoryReducer,
    subCategoryReducer: SubCategoryReducer,
    productReducer: ProductReducer,
    deviceModelReducer: DeviceModelReducer,
    saleWiseReducer:SaleWiseReducer,
    itemWiseReducer:ItemWiseReducer
})
const store = createStore(MainReducer, applyMiddleware(thunk))

export default store  