import React,{useState,useEffect} from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  Box,
  Button,
  Menu,
  Card,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import imgOne from "../../assets/Images/Apples.webp";
import imgTwo from "../../assets/Images/healthy-vegetables.avif";


const useStyles = makeStyles((theme) => ({
  table: {
    "& tbody>.MuiTableRow-root:hover": {
      background: "#9747FF",
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      fontWeight: "700",
    },
    "&:hover .MuiTableCell-root, &:hover .material-icons-outlined": {
      color: "#fff !important",
    },
    "&:hover .bg-ligtGrey": {
      background: "rgba(192,192,192,0.3); !important",
    },
  },
}));

export default function BasicTable(props) {
  const classes = useStyles();
  const [getData, setData] = useState(props.mappingData);
  // const [mergedData, setMergedData] = useState([]);
  // console.log("mergedData",mergedData);
  // useEffect(() => { 
    // const apiData1 = [
    //   { id: 1, name: 'Item 1 from API 1' },
    //   { id: 2, name: 'Item 2 from API 1' },
    //   // ...
    // ];
    
    // API response format from Endpoint 2
    // const apiData2 = [
    //   { id: 1, description: 'Description 1 from API 2' },
    //   { id: 2, description: 'Description 2 from API 2' },
      // ...
    // ];
  //   const mergedData = apiData1.map(item1 => {
  //     const matchingItem = apiData2.find(item2 => item2.id === item1.id);
  //     console.log("matchingItem",matchingItem)
  //     if (matchingItem) {
  //       return { ...item1, ...matchingItem };
  //     }
  //     return item1;
  //   });

  //   setMergedData(mergedData);
  // }, []);
  return (
    <TableContainer
      component={Paper}
      className="border-radius-20"
      sx={{ marginTop: "-10px" }}
    >
      <Table aria-label="simple table" size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="text-offGrey  font-14">Item Image</TableCell>
            <TableCell className="text-offGrey text-center-imp font-14">
              Item Name
            </TableCell>
            <TableCell className="text-offGrey text-center-imp font-14">
              GAI Item Id
            </TableCell>
            <TableCell className="text-offGrey text-center-imp font-14">
              Category
            </TableCell>
            <TableCell className="text-offGrey text-center-imp font-14">
              UOM
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getData?.map((row,index) => (
            <TableRow key={index} className={classes.tableRow}>
              <TableCell>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} className="zoom ">
                    <Box>
                      <img
                        src={row.ItemImage}
                        className="w-100 border-radius-8"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className="hide">
                    <Box>
                      <img
                        src={row.ItemImage}
                        className="w-100 border-radius-8"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className="text-center-imp text-offPurple">
                <Box className="bg-ligtGrey p-8 border-radius-8  font-weight-bold">
                  {row.label}
                </Box>
              </TableCell>
              <TableCell className="text-center-imp">{row.ItemId}</TableCell>
              <TableCell className="text-center-imp">{row.Category}</TableCell>
              <TableCell className="text-center-imp">{row.UOM}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <TablePagination
                        component="Box"
                        count={totalItemData}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
    </TableContainer>
  );
}
