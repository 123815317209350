import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Grid } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import DatePicker, { DateObject } from "react-multi-date-picker"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InputAdornment from '@mui/material/InputAdornment';
import InputIcon from "react-multi-date-picker/components/input_icon"

export default function BasicTable(props) {
    return (
        <div>
            <div>
                <div>
                    <Grid container spacing={3} className="pt-10">
                        <Grid item xs={12} sm={12} md={3}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size='small'
                                label="Select Country"
                                value={props.getCountry}
                                onChange={props.getAllStateInput}
                            >
                                {props.country.map((option) => (
                                    <MenuItem key={option.value} value={option.Id}>
                                        {option.Name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size='small'
                                label="State"
                                value={props.getState}
                                onChange={props.getCityByState}
                            >
                                {props.State.map((option) => (
                                    <MenuItem key={option.Id} value={option.Id}>
                                        {option.Name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size='small'
                                label="City"
                                value={props.getCity}
                                onChange={props.getAllCityInput}
                            >
                                {props.City.map((option) => (
                                    <MenuItem key={option.Id} value={option.Id}>
                                        {option.Name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size='small'
                                label="Account"
                                value={props.getAccount}
                                onChange={props.getAllAccountId}
                            >
                                {props.accounts.map((option) => (
                                    <MenuItem key={option.Id} value={option.Id}>
                                        {option.AccountName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size='small'
                                label="Store"
                                value={props.getStore}
                                onChange={props.getAllStoresData}
                            >
                                {props.stores.map((option) => (
                                    <MenuItem key={option.Id} value={option.Id}>
                                        {option.StoreName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size='small'
                                label="Terminal"
                                value={props.getTerminal}
                                onChange={props.getAllTerminalData}
                            >
                                {props.terminal.map((option) => (
                                    <MenuItem key={option.Id} value={option.Id}>
                                        {option.TerminalName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <DatePicker
                                value={props.getValue}
                                onChange={props.setValue}
                                range
                                className='w-100'
                                render={<InputIcon />}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="p-0 pt-15">
                            <div className='buttonSecton p-0'>
                                <div className='mx-3'>
                                    <Button variant="contained" size="small" onClick={props.onChildClick} className='border-radius-20 btn-orange w-100'>
                                        Apply
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="contained" size="small" onClick={props.onCancelClick} className='border-radius-20 btn-orange w-100'>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
