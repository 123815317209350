import {  
    GET_ACCOUNT_REQUEST,  
    GET_ACTIVE_ACCOUNT_SUCCESS,
    GET_INACTIVE_ACCOUNT_SUCCESS, 
    GET_ACCOUNT_FAILURE ,
    GET_ACCOUNTDETAIL_REQUEST,
    GET_ACCOUNTDETAIL_SUCCESS,
    GET_ACCOUNTDETAIL_FAILURE,
    SAVE_ACCOUNT_REQUEST,
    SAVE_ACCOUNT_SUCCESS,
    SAVE_ACCOUNT_FAILURE,
    GET_IMAGE_REQUEST,
    GET_IMAGE_SUCCESS,
    GET_IMAGE_FAILURE

  } from '../Types'
  
    
  const initialState = {  
    loading: false, 
    noFoundData:false, 
    accounts:{},  
    error: ''  ,
    isAccountActive:true,
    accountDetails:{},
    result:'',
    imageData:{}
  }  
    
  const reducer = (state = initialState, action) => {  
    
    switch (action.type) {  
      case GET_ACCOUNT_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false
        }  
      case GET_ACTIVE_ACCOUNT_SUCCESS:
             
        return {  
          loading: false,  
          accounts: action.payload,  
          noFoundData:false,
          error: ''  ,
          isAccountActive:true,
        }  
        case GET_INACTIVE_ACCOUNT_SUCCESS:
        return {  
          loading: false,  
          accounts: action.payload,  
          noFoundData:false,
          error: ''  ,
          isAccountActive:false
        }  
      case GET_ACCOUNT_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          accounts: {},  
          error: action.payload  
        }  
        case GET_ACCOUNTDETAIL_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false
        }  
        case GET_ACCOUNTDETAIL_SUCCESS:
        return {  
          loading: false,  
          accountDetails: action.payload,  
          noFoundData:false,
          error: ''  ,
          isAccountActive:false
        }  
      case GET_ACCOUNTDETAIL_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          accountDetails: {},  
          error: action.payload  
        }  
        case SAVE_ACCOUNT_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false,
          error:''
        }  
        case SAVE_ACCOUNT_SUCCESS:
        return {  
          loading: false,  
          result: action.payload,  
          noFoundData:false,
          error: ''  ,
          isAccountActive:false
        }  
      case SAVE_ACCOUNT_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          result:action.payload,  
          error: action.payload.response  
        } 
        case GET_IMAGE_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false,
          accountDetails:{},
          imageData:{}
        }  
        case GET_IMAGE_SUCCESS:
        return {  
          loading: false,  
          imageData: action.payload,  
          error: ''  
           }  
      case GET_IMAGE_FAILURE:  
        return {  
          loading: false, 
          error: action.payload  
        }  
        
      default: return state  
    }  
  }  
    
  export default reducer  