import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

export default function SampleHook(props) {
    return (
        <div>
            <Table aria-label="simple table" size="small" ref={props.tableRef}>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Account Name :</TableCell>
                    {
                        (props.getExportData?.OrderResponseHead?.AccountName) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{props.getExportData?.OrderResponseHead?.AccountName}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>State :</TableCell>
                    {
                        (props.getExportData?.OrderResponseHead?.State) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{props.getExportData?.OrderResponseHead?.State}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>City :</TableCell>
                    {
                        (props.getExportData?.OrderResponseHead?.City) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{props.getExportData?.OrderResponseHead?.City}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Store Name :</TableCell>
                    {
                        (props.getExportData?.OrderResponseHead?.StoreName) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{props.getExportData?.OrderResponseHead?.StoreName}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Terminal :</TableCell>
                    {
                        (props.getExportData?.OrderResponseHead?.TerminalName) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{props.getExportData?.OrderResponseHead?.TerminalName}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Sales report from :</TableCell>
                    {
                        (props.getExportData?.OrderResponseHead?.FromDate) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{moment(props.getExportData?.OrderResponseHead?.FromDate).format("DD-MM-YYYY")}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                     {
                        (props.getExportData?.OrderResponseHead?.ToDate) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>to {moment(props.getExportData?.OrderResponseHead?.ToDate).format("DD-MM-YYYY")}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Total No. of bills :</TableCell>
                    {
                        (props.getExportData?.TotalItems) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{props.getExportData?.TotalItems}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow></TableRow>
                <TableHead>
                    <TableRow style={{ fontSize: '16px', textAlign: 'center' }}
                    >
                        <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>Bill</TableCell>
                        <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>Bill Date</TableCell>
                        <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>Total Amount</TableCell>
                        <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>
                            Payment Mode
                        </TableCell>
                        <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>
                            Status
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.getExportData?.Data?.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{row.InvoiceNumberStr}</TableCell>
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{moment(row.OrderDate).format("DD-MM-YYYY")}</TableCell>
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{row.OrderAmount}</TableCell>
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{row.PaymentMode}</TableCell>
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>
                                {row.Status}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableRow></TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Total :</TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ fontSize: '16px' }}>{props.getExportData?.OrderResponseHead?.TotalSales}</TableCell>
                </TableRow>
                <TableRow></TableRow>
                <TableRow>
                    <TableCell style={{ fontSize: '16px' }}></TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>No of Bills</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Basic Amount</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontSize: '16px' }}>Cash Sales</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>{props.getExportData?.OrderResponseHead?.CashSalesCount}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>{props.getExportData?.OrderResponseHead?.CashSales}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontSize: '16px' }}>Card Sales</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>{props.getExportData?.OrderResponseHead?.CardSalesCount}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>{props.getExportData?.OrderResponseHead?.CardSales}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontSize: '16px' }}>UPI Sales</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>{props.getExportData?.OrderResponseHead?.UPISalesCount}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>{props.getExportData?.OrderResponseHead?.UPISales}</TableCell>
                </TableRow>
            </Table>

        </div>
    );
}
