import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css";
import DatePicker, { DateObject } from "react-multi-date-picker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InputAdornment from "@mui/material/InputAdornment";
import InputIcon from "react-multi-date-picker/components/input_icon";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ImportFile from "../assets/Images/icon-park-outline_export.png";
import Autocomplete from "@mui/material/Autocomplete";
import Clear from "@material-ui/icons/Clear";
import IosShareIcon from "@mui/icons-material/IosShare";
import exportIcon from "../assets/Images/export.png";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
    background: "#9747FF",
    borderRadius: "14px",
    paddingLeft: 0,
    paddingTop: 0,
    height: "85px",
    border: "none",
    width: "100%!important",
    overflow: "hidden!important",

    "& .MuiSelect-select": {
      textOverflow: "unset!important",
      whiteSpace: "break-spaces!important",
      overflow: "hidden!important",
    },
  },
}));
export default function BasicTable(props) {
  const classes = useStyles();
  return (
    <div>
      <div>
        <Card className="mt-10 border-radius-14 width-100" elevation={8}>
          <div>
            <Grid
              container
              spacing={1}
              className="buttonSecton"
              style={{ height: "85px" }}
            >
              <Grid item xs={12} sm={12} md={1.5} className="pt-0">
                <FormControl>
                  <Select
                    sx={{ color: "#fff", overflow: "hidden!important" }}
                    className={classes.root}
                    value={props.getReports}
                    onChange={props.getAllReports}
                    displayEmpty
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    {props.reportsArray.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.Id}
                        defaultValue={1}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={1.5}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  size="small"
                  className="lato-font"
                  disableClearable
                  value={props.getState}
                  onChange={(event, value) =>
                    props.getCityByState(event, value)
                  }
                  options={props.State}
                  getOptionLabel={(option) => (option ? option.Name : "")}
                  sx={{
                    boxShadow: 2,
                    borderRadius: "15px",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #eee",
                      },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      sx={{
                        "& .MuiFormLabel-root": {
                          fontFamily: "Lato !important",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        classes: { input: 'lato-font'},
                        endAdornment: props.getState ? (
                          <Clear
                             style={{cursor:"pointer"}}
                            fontSize="inherit"
                            onClick={props.handleStateClear}
                          />
                        ) : (
                          ""
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1.5}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  size="small"
                  disableClearable
                  className="lato-font"
                  value={props.getCity}
                  onChange={(event, value) => props.getAllCityInput(value)}
                  options={props.City}
                  getOptionLabel={(option) => (option ? option.Name : "")}
                  sx={{
                    boxShadow: 2,
                    borderRadius: "15px",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #eee",
                      },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      sx={{
                        "& .MuiFormLabel-root": {
                          fontFamily: "Lato !important",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        classes: { input: 'lato-font'},
                        endAdornment: props.getCity ? (
                          <Clear
                          style={{cursor:"pointer"}}
                            fontSize="inherit"
                            onClick={props.handleCityClear}
                          />
                        ) : (
                          ""
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={1.5}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  size="small"
                  disableClearable
                  className="lato-font"
                  value={props.getStore}
                  onChange={(event, value) => props.getAllStoresData(value)}
                  options={props.stores}
                  getOptionLabel={(option) => (option ? option.StoreName : "")}
                  sx={{
                    boxShadow: 2,
                    borderRadius: "15px",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #eee",
                      },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store"
                      sx={{

                        "& .MuiFormLabel-root": {
                          fontFamily: "Lato !important",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        classes: { input: 'lato-font'},
                        endAdornment: props.getStore ? (

                          <Clear
                          style={{cursor:"pointer"}}
                            fontSize="inherit"
                            onClick={props.handleStoreClear}
                          />
                        ) : (
                          ""
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1.5}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  size="small"
                  value={props.getTerminal}
                  disableClearable
                  className="lato-font"
                  onChange={(event, value) => props.getAllTerminalData(value)}
                  options={props.terminal}
                  getOptionLabel={(option) =>
                    option ? option.TerminalName : ""
                  }
                  sx={{
                    boxShadow: 2,
                    borderRadius: "15px",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #eee",
                      },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Terminal"
                      
                      sx={{
                        "& .MuiFormLabel-root": {
                          fontFamily: "Lato !important",
                        },
                       
                       
                      }}
                      InputProps={{
                        ...params.InputProps,
                        classes: { input: 'lato-font'},
                        endAdornment: props.getTerminal ? (
                          <Clear
                          style={{cursor:"pointer"}}
                            fontSize="inherit"
                            onClick={props.handleTerminalClear}
                          />
                        ) : (
                          ""
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2.5}>
                <DatePicker
                  value={props.getValue}
                  onChange={props.setDateValue}
                  format="DD/MM/YYYY"
                  range
                  className="datePicker lato-font"
                  render={<InputIcon />}
                  
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <div className="buttonSecton p-0" style={{ gap: 10 }}>
                  {props.getApply === true ? (
                    <div>
                      <Button
                        variant="contained"
                        size="large"
                        className="border-radius-10 bg-offPurple"
                        onClick={props.FilerSaleWiseData}
                      >
                        Apply
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        disabled
                        variant="contained"
                        size="large"
                        className="border-radius-10 "
                      >
                        Apply
                      </Button>
                    </div>
                  )}
                  <div>
                    <Button
                      size="small"
                      onClick={props.handleDownloadExcel}
                    >
                      <span className="font-weight-bold">
                        <img
                          src={exportIcon}
                          width={50}
                          height={40}
                          style={{ marginTop: 10 }}
                        />
                      </span>
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </div>
    </div>
  );
}
