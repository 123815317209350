import React from 'react'
import { TextField, FormControl, InputAdornment } from '@mui/material';
import '../assets/scss/InputField.css';


function DefaultInput(props) {

    const { id, label, variant, autoFocus, color, defaultValue, disabled, error, name, onChange, rows,
        placeholder, type, value, multiline, minRow, maxRow, inputProps,InputProps, formControlProps } = props
    return (
        <div>
            <FormControl
                {...formControlProps}
            >
                <TextField
                    size="small"
                    id={id}
                    label={label}
                    autoFocus={autoFocus}
                    className="TextField"
                    color={color}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    error={error}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    multiline={multiline || false}
                    type={type}
                    value={value}
                    rows={rows}
                    inputProps={inputProps}
                    InputProps={InputProps}
                    
                    {...inputProps}
                />
            </FormControl>
        </div>
    )
}

export default DefaultInput