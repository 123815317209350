import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  TextField,
  Button,
  Card,
  Box,
  Grid,
  MenuItem,
} from "@mui/material";
import DefaultInput from "../../Component/TextField";
import {
  saveProduct,
  getProductDetails,
  updateProduct,
  getLogoImageData,
} from "../../Redux/Product/ProductAction";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../Services/ApiServices";
import DefaultButton from "../../Component/Button";
import { styles } from "../../assets/scss/AddItem";
const UomArray = [
  {
    id: 1,
    value: "KGS",
    label: "KGS",
  },
  {
    id: 2,
    value: "PCS",
    label: "PCS",
  },
];

export default function BasicTable() {
  const ProductDetail = useSelector((s) => s.productReducer);
  const navigate = useNavigate();
  const [logoImage, setLogoImage] = useState("");
  const [logoImageURL, setLogoUrl] = useState(null);
  const [previewURL, setPreviewUrl] = useState(null);
  const [selectedFileName, setFileName] = useState("");
  const [subCatId, setSubCatId] = useState("");
  const [catId, setAccountCatId] = useState("");
  const [currentUserRole, setRole] = useState("");
  const [getUserRoles, setUserRoles] = useState("");
  const [getAccountId, setAccountId] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const [inputField, setInputField] = useState({
    ItemName: "",
    ProductKey: "",
    categoryName: "",
    accountName: "",
    subCatergoryName: "",
    ItemDescription: "",
    SkuCode: "",
    PluCode: "",
    UOMName: "",
    ProductQuote: "",
    ProductMetadata: "",
    BarCode: "",
  });
  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [Roles, setRoles] = React.useState("");

  const handleChange = (event) => {
    setRoles(event.target.value);
  };
  const Input = styled("input")({
    display: "none",
  });
  const params = useParams();

  useEffect(() => {
    if (params.productId) {
      dispatch(getProductDetails(params.productId));
    }

    getAllAccount();
  }, []);
  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    setAccountId(userDetail.accountId);
    setUserRoles(userDetail.userRole);
    getAllCategory(userDetail.accountId);
    getAllAccount();
  }, []);
  // Update account
  const updateData = () => {
    if (logoImage != "") {
      formData.append("file", logoImage);
    }
    dispatch(updateProduct(formData, params.productId));
  };
  useEffect(() => {
    if (ProductDetail.productDetails) {
      var accInfo = ProductDetail.productDetails;
      if (Object.keys(accInfo).length > 0) {
        getAllCategory(accInfo.AccountId);
        getAllSubCategory(accInfo.AccountCategoryId);
        setInputField({
          ItemName: accInfo.Name != null ? accInfo.Name : "",
          ItemDescription:
            accInfo.Description != null ? accInfo.Description : "",
          ProductKey: accInfo.ProductKey != null ? accInfo.ProductKey : "",
          accountId: accInfo.AccountId != null ? accInfo.AccountId : "",
          // getAllCategory(accontId),
          categoryName:
            accInfo.AccountCategory.Id != null
              ? accInfo.AccountCategory.Id
              : "",
          subCatergoryName:
            accInfo.AccountSubCategory.Id != null
              ? accInfo.AccountSubCategory.Id
              : "",
          categoryId:
            accInfo.AccountCategory.Id != null
              ? accInfo.AccountCategory.Id
              : "",
          subCategoryId:
            accInfo.AccountSubCategory.Id != null
              ? accInfo.AccountSubCategory.Id
              : "",
          accountName: accInfo.AccountId != null ? accInfo.AccountId : "",

          SkuCode: accInfo.SkuCode != null ? accInfo.SkuCode : "",
          PluCode: accInfo.PluCode != null ? accInfo.PluCode : "",
          UOMName: accInfo.UOM != null ? accInfo.UOM : "",
          UOM: accInfo.UOM != null ? accInfo.UOM : "",
          BarCode: accInfo.BarCode != null ? accInfo.BarCode : "",
          ProductQuote:
            accInfo.ProductQuote != null ? accInfo.ProductQuote : "",
          ProductMetadata:
            accInfo.ProductMetadata != null ? accInfo.ProductMetadata : "",
        });
        if (accInfo.FilePath) {
          getLogoImage(accInfo.Id);
        }
      }
    } else if (ProductDetail.result == 200) {
      if (params.productId) {
        toast("Product update successfully");
        setTimeout(() => {
          navigate("/app/Product");
        }, 1000);
      } else {
        toast("Product save successfully");
        setTimeout(() => {
          navigate("/app/Product");
        }, 1000);
      }
    } else if (ProductDetail.imageData) {
      const imageUrl = ProductDetail.imageData.FileData;
      setPreviewUrl("data:image/png;base64," + imageUrl);
    } else if (ProductDetail.error.status == 404) {
      toast(ProductDetail.error.data);
    } else if (ProductDetail.error.status == 401) {
      toast(ProductDetail.error.data);
    } else if (ProductDetail.error.status == 403) {
      toast(ProductDetail.error.data);
    } else if (ProductDetail.error.status == undefined) {
    } else {
      if (ProductDetail.error != "") {
        toast(ProductDetail.error);
      }
    }
  }, [ProductDetail]);

  let formData = new FormData();
  formData.append("Name", inputField.ItemName);
  formData.append("ProductKey", inputField.ProductKey);
  formData.append("CategoryId", inputField.categoryId);
  formData.append("SubCategoryId", inputField.subCategoryId);
  formData.append("SkuCode", inputField.SkuCode);
  formData.append("PluCode", inputField.PluCode);
  formData.append("PluCode", inputField.PluCode);
  formData.append("UOM", inputField.UOMName);
  formData.append("ProductQuote", inputField.ProductQuote);
  formData.append("Description", inputField.ItemDescription);
  formData.append("ProductMetadata", inputField.ProductMetadata);
  formData.append("BarCode", inputField.BarCode);
  if (logoImage != null) {
    formData.append("file", logoImage);
  }
  if (params.productId) {
    formData.append("productId", params.productId);
  }
  if (getAccountId !== "") {
    formData.append("AccountId", getAccountId);
  }
  if (getAccountId == "" && getUserRoles == 1) {
    formData.append("AccountId", inputField.accountId);
  }

  const getLogoImage = (productId) => {
    if (productId) {
      dispatch(getLogoImageData(productId));
    }
  };
  const saveProductDetails = () => {
    dispatch(saveProduct(formData));
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    let formIsValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.PluCode) {
      errors.PluCode = "Item Code is required!";
      formIsValid = false;
    }
    if (!values.categoryName) {
      errors.categoryName = "Category Name is required!";
      formIsValid = false;
    }
    if (!values.subCatergoryName) {
      errors.subCatergoryName = "Sub Category Name is required!";
      formIsValid = false;
    }
    if (!values.ItemName) {
      errors.ItemName = "Product Name is required!";
      formIsValid = false;
    }

    if (!values.UOMName) {
      errors.UOMName = "Unit Of Measurement is required!";
      formIsValid = false;
    }

    setFormErrors(errors);
    return formIsValid;
  };
  const submitForm = (e) => {
    e.preventDefault();
    if (validate(inputField)) {
      setIsSubmit(true);
      if (params.productId) {
        updateData();
      } else {
        saveProductDetails();
      }
    }
  };
  //Upload logo
  const [fileError, setFileError] = useState("");
  const uploadImage = (e) => {
    setFileError("");
    let file = e.target.files[0];
    var idxDot = file.name.lastIndexOf(".") + 1;
    var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
    if (extFile == "jpg" || extFile == "png") {
      if (file.size > 1000000) {
        setFileError("Please upload a file smaller than 1 MB");
        return false;
      } else {
        setLogoImage(file);
        setFileName(file.name);
        setPreviewUrl(URL.createObjectURL(file));
      }
    } else {
      setFileError("Only jpg/jpeg, png files are allowed!");
    }
  };
  const [categoryData, setCategoryData] = useState([]);
  const [getCatId, setCatId] = useState([]);
  const getAllCategoryId = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      categoryId: e.target.value,
      categoryName: e.target.value,
    }));
    getAllSubCategory(e.target.value);
  };
  const getAllCategory = (Obj) => {
    ApiServices.getData(`/AccountCategory/all?accountId=${Obj}`)
      .then((res) => {
        setCategoryData(res.data.Data);
      })
      .catch((res) => {});
  };
  const getAllSubCat = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      subCategoryId: e.target.value,
      subCatergoryName: e.target.value,
    }));
  };
  const [subcategoryData, setSubCategoryData] = useState([]);
  const getAllSubCategory = (Obj) => {
    ApiServices.getData(`/AccountSubCategory/all?categoryId=${Obj}`)
      .then((res) => {
        setSubCategoryData(res.data.Data);
      })
      .catch((res) => {});
  };
  const [acoountData, setAccountData] = useState([]);
  const getAllAccountId = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      accountId: e.target.value,
      accountName: e.target.value,
    }));
    getAllCategory(e.target.value);
  };
  const getAllAccount = () => {
    ApiServices.getData(`/Account/all/dropdown`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((res) => {});
  };
  const getAllUOM = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      UOM: e.target.value,
      UOMName: e.target.value,
    }));
  };
  const resetData = () => {
    setInputField({
      ItemName: "",
      ProductKey: "",
      categoryName: "",
      accountName: "",
      subCatergoryName: "",
      ItemDescription: "",
      SkuCode: "",
      PluCode: "",
      UOMName: "",
      ProductQuote: "",
      ProductMetadata: "",
    });
    navigate("/app/Product");
  };
  return (
    <div style={{ marginBottom: "30px" }}>
      <ToastContainer autoClose={1000} />
      {/* --------------btn section----------- */}
      <Grid container sx={{ marginLeft: "-5px " }}>
        <Grid item xs={12} sm={12} md={11}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Box {...styles.tab}>
                <Box {...styles.tabInside}>
                  {params.productId ? "Update Product" : "New Product"}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
          <DefaultButton
            color="warning"
            variant="contained"
            size="small"
            Text="Back"
          />
        </Grid>
      </Grid>

      <form onSubmit={submitForm}>
        <Card className="border-radius-8 width-100" sx={{ marginTop: "-25px" }}>
          <div className="p-4">
            <div>
              <Grid container spacing={6}>
                {getUserRoles == 1 && getAccountId == "" ? (
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      size="small"
                      label="Account *"
                      name="accountWithId"
                      value={inputField.accountName}
                      onChange={getAllAccountId}
                    >
                      {acoountData.map((option) => (
                        <MenuItem key={option.value} value={option.Id}>
                          {option.AccountName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                ) : (
                  <div></div>
                )}
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="ItemName"
                    label="Product Name"
                    variant="standard"
                    name="ItemName"
                    value={inputField.ItemName}
                    onChange={inputsHandler}
                    placeHolder="Item Name"
                  />
                  <div className="errors">{formErrors.ItemName}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="ProductKey"
                    label="GAI Item Code"
                    variant="standard"
                    name="ProductKey"
                    value={inputField.ProductKey}
                    onChange={inputsHandler}
                    placeHolder="GAI Item Code"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    label="Category *"
                    name="Category"
                    value={inputField.categoryName}
                    onChange={getAllCategoryId}
                  >
                    {categoryData?.map((option) => (
                      <MenuItem key={option.value} value={option.Id}>
                        {option.Name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className="errors">{formErrors.categoryName}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    name="subCatergoryName"
                    label="Sub Category*"
                    value={inputField.subCatergoryName}
                    onChange={getAllSubCat}
                  >
                    {subcategoryData.map((option) => (
                      <MenuItem key={option.value} value={option.Id}>
                        {option.Name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className="errors">{formErrors.subCatergoryName}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6}>
                      <DefaultInput
                        id="PluCode"
                        label="Item Code*"
                        variant="standard"
                        name="PluCode"
                        value={inputField.PluCode}
                        onChange={inputsHandler}
                        placeHolder="Item Code"
                      />
                      <div className="errors">{formErrors.PluCode}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="UOM"
                        select
                        size="small"
                        name="UOM"
                        label="Unit Of Measurement*"
                        value={inputField.UOMName}
                        onChange={getAllUOM}
                      >
                        {UomArray.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <div className="errors">{formErrors.UOMName}</div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="ItemDescription"
                    label="Item Description"
                    multiline
                    rows={3}
                    variant="standard"
                    name="ItemDescription"
                    value={inputField.ItemDescription}
                    onChange={inputsHandler}
                    placeHolder="Item Description"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div class="drop_box">
                        <Grid item xs={12} sm={12} md={12} className="mb-12">
                          <label htmlFor="contained-button-file">
                            <input
                              type="file"
                              hidden
                              accept="image/png, image/jpeg"
                              id="logoImage"
                              onChange={uploadImage}
                            />
                            <label
                              for="logoImage"
                              class="btn btn-warning"
                              tabindex="0"
                            >
                              Upload Image
                            </label>
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}></Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          {previewURL != null ? (
                            <div className="img-section" for="logoImage">
                              <img src={previewURL} width="200" height="100" />{" "}
                            </div>
                          ) : null}
                        </Grid>
                        <div className="text-align-center">
                          <p>Files Supported: JPG, PNG</p>
                        </div>
                      </div>
                      <div className="errors">{fileError}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="BarCode"
                    label="Enter BarCode"
                    variant="standard"
                    name="BarCode"
                    value={inputField.BarCode}
                    onChange={inputsHandler}
                    placeHolder="Enter BarCode"
                  />
                </Grid>
              </Grid>
            </div>
            <div className="buttonSecton">
              <div className="mx-3 " style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  {...styles.SaveBtn}
                  className="btn-orange "
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </form>
    </div>
  );
}
