export  const styles = {
  flex: {
    sx: {
      display: "flex",
    
      flexDirection: "column",
      width: "100%",
    }
  },
  mainBox: {
    sx: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "start",
      flexDirection: "row",
      // flexWrap:"wrap",
      width: "100%",
      gap: "2%",
    },
  },
  gridContainer: {
    sx: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  tabBox: {
    sx: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: " #838181",
      fontFamily: "Lato",
      fontWeight: "bold",
      borderRadius: "8px",
      background: " #fff",
      padding: " 1.3rem 0",
      border: "none",
    },
  },
  search: {
    sx: {
      boxShadow: 0,
      borderRadius: "8px",
      background: "#fff",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiFilledInput-root": {
        background: "#fff",
        borderBottom: 0,
        borderRadius: "10px",
      },
    },
  },
  searchInventoty:{
    sx:{
      boxShadow: 2,
      borderRadius: "25px",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
        {
          border: "1px solid #eee",
        },
    }
  }
};

