import {
    GET_CITY_REQUEST,GET_CITY_SUCCESS,GET_CITY_FAILURE,SAVE_CITY_REQUEST,SAVE_CITY_SUCCESS,SAVE_CITY_FAILURE
   } from '../Types'  

    
    
  const initialState = {  
    loading: false, 
    noFoundData:false, 
    cities:[],
    result:[],
    error: ''  
   
  }  
    
  const reducer = (state = initialState, action) => {  
    
    switch (action.type) {  
        case GET_CITY_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false
        }  
      case GET_CITY_SUCCESS:
        return {  
          loading: false,  
          cities: action.payload,  
          noFoundData:false,
          error: ''           
        }  
       
      case GET_CITY_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          cities: [],  
          error: action.payload  
        }  
        case SAVE_CITY_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          error:''
        }  
        case SAVE_CITY_SUCCESS:
        return {  
          loading: false,  
          result: action.payload,  
          noFoundData:false,
          error: ''  ,
        }  
      case SAVE_CITY_FAILURE:  
        return {  
          loading: false, 
          result:action.payload,  
          error: action.payload.response  
        } 
      default: return state  
    }  
  }  
    
  export default reducer  