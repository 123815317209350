import { config } from "../config";
import axios from "axios";

export default {
  async saveData(url, data) {
    try {
      const a = await axios.post(config.BASE_URL + url, data, {
        headers: {
          Authorization: "Bearer ".concat(localStorage.getItem("accessToken")),
        },
      });
      return Promise.resolve(a);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateData(url, data) {
    try {
      const a = await axios.put(config.BASE_URL + url, data, {
        headers: {
          Authorization: "Bearer ".concat(localStorage.getItem("accessToken")),
        },
      });

      return Promise.resolve(a);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getData(url, data) {
    try {
      const a = await axios.get(config.BASE_URL + url, {
        headers: {
          Authorization: "Bearer ".concat(localStorage.getItem("accessToken")),
        },
      });

      return Promise.resolve(a);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async deleteData(url) {
    try {
      const a = await axios.delete(config.BASE_URL + url, {
        headers: {
          Authorization: "Bearer ".concat(localStorage.getItem("accessToken")),
        },
      });
      return Promise.resolve(a);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
