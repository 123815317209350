import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  Box,
  Button,
  Menu,
  Card,
  IconButton,
} from "@mui/material";
import MappingDetails from "./MappingDetails";
import GAIMapping from "./GAIMapping";
import ApiServices from "../../Services/ApiServices";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import TuneIcon from "@material-ui/icons/Tune";
import { makeStyles } from "@material-ui/core/styles";
import ImportFile from "../../assets/Images/import.png";
import imgOne from "../../assets/Images/Apples.webp";
import imgTwo from "../../assets/Images/healthy-vegetables.avif";
import { styles } from "./Style/MapItems";

const getData = [
  {
    id: 1,
    ItemImage: imgOne,
    label: "Apple",
    ItemId: 5677575,
    Category: "Fruits",
    UOM: "Piece",
    mapItems: null,
  },
  {
    id: 2,
    ItemImage: imgTwo,
    label: "Okra",
    ItemId: 5677576,
    Category: "Vegetables",
    UOM: "Piece",
    mapItems: null,
  },
  {
    id: 3,
    ItemImage: imgOne,
    label: "Apple",
    ItemId: 5677575,
    Category: "Fruits",
    UOM: "Piece",
    mapItems: null,
  },
  {
    id: 4,
    ItemImage: imgTwo,
    label: "Okra",
    ItemId: 5677576,
    Category: "Vegetables",
    UOM: "Piece",
    mapItems: null,
  },
  {
    id: 5,
    ItemImage: imgOne,
    label: "Apple",
    ItemId: 5677575,
    Category: "Fruits",
    UOM: "Piece",
    mapItems: null,
  },
  {
    id: 6,
    ItemImage: imgTwo,
    label: "Okra",
    ItemId: 5677576,
    Category: "Vegetables",
    UOM: "Piece",
    mapItems: null,
  },
  {
    id: 7,
    ItemImage: imgOne,
    label: "Apple",
    ItemId: 5677575,
    Category: "Fruits",
    UOM: "Piece",
    mapItems: null,
  },
  {
    id: 8,
    ItemImage: imgTwo,
    label: "Okra",
    ItemId: 5677576,
    Category: "Vegetables",
    UOM: "Piece",
    mapItems: null,
  },
];
const useStyles = makeStyles((theme) => ({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function BasicTable() {
  const classes = useStyles();

  const [activeIndex, setActiveIndex] = useState(1);
  const [getAccountValueId, setAccountValue] = useState(1);
  const [acoountData, setAccountData] = useState([]);
  const [getDraggableObject, setDraggableObject] = useState([]);
  const [getInvetoryList, setInventoryList] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // console.log("getDraggableObject", getDraggableObject);
  // --------------for getting data--------------
  useEffect(() => {
    getAllAccount();
  }, []);

  const getAllAccount = () => {
    ApiServices.getData(`/Account/all/dropdown`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((res) => {});
  };

  const onDrag = (event, Obj) => {
    console.log("event in ondrag", Obj);
    event.preventDefault();
    setDraggableObject(Obj);

    // const draggedItemId = event.dataTransfer.getData("text/plain");
    // const draggedItem = getDraggableObject?.find((item) => item.id == Obj.id);
    // console.log("draggedItem", draggedItem);
    // if (draggedItem && draggedItem.draggable) {
    //   const updatedItems = getDraggableObject?.filter(
    //     (item) => item.id !== Obj.id
    //   );
    //   setDraggableObject(updatedItems);
    // }
  };

  const getAllAccountId = (e) => {
    setAccountValue(e.target.value);
    // console.log("acccount id",e.target.value)
    // need to call api
  };

  const handleClick = (index) => {
    setActiveIndex(index);
  };
  const checkActive = (index, className) =>
    activeIndex === index ? className : "";

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const showList = () => {
    setInventoryList(!getInvetoryList);
  };

  // --------Search events-----------------
  // search field
  const [inputValue, setInputValue] = useState("");
  const [search, setSearch] = useState("");
  const [handleIcon, setIcon] = useState(true);
  const resetInputField = (e) => {
    // setSearch("");
    // pageState.query = "";
    // pageState.top = 10;
    // pageState.skip = 0;
    setInputValue("");
    // setRowsPerPage(parseInt(pageState.top));
    // setInputValue("");
    // if (pageState.isDeleted) {
    //   dispatch(getInactiveDataByPagination(pageState));
    // } else {
    //   setIsLoading(true);
    //   dispatch(getDataByCategoryDetails(pageState));
    // }
    // setnoFoundData(true);
    setIcon(true);
  };
  const handleUserInput = (e) => {
    if (e.target.value) {
      setIcon(false);
    }
    if (e.target.value === "") {
      setIcon(true);
    }
    setInputValue(e.target.value);
  };
  const SearchData = () => {
    console.log("search call", inputValue);
    // pageState.top = 10;
    // pageState.skip = 0;
    // setRowsPerPage(parseInt(pageState.top))
    // setPage(0);
    // pageState.query = inputValue;
    // console.log("pageState.query",pageState.query)
    // if (
    //   pageState.query == "" ||
    //   !regexConstant.searchSpace.test(pageState.query)
    // ) {
    //   toast("Please Enter the search key");
    // }

    // if (value == 0) {
    //   setIsLoading(true);
    //   dispatch(getDataByCategoryDetails(pageState));
    // } else {
    //   dispatch(getInactiveDataByPagination(pageState));
    // }
  };

  // -----------Sraech inventry list-----------------
  const [inputInventoryValue, setInputInventoryValue] = useState("");
  const [searchInventory, setSearchInventory] = useState("");
  const [handleInventorySearchIcon, setInventorySearhIcon] = useState(true);
  const resetInventoryInputField = (e) => {
    // setSearch("");
    // pageState.query = "";
    // pageState.top = 10;
    // pageState.skip = 0;
    setInputInventoryValue("");
    // setRowsPerPage(parseInt(pageState.top));
    // setInputValue("");
    // if (pageState.isDeleted) {
    //   dispatch(getInactiveDataByPagination(pageState));
    // } else {
    //   setIsLoading(true);
    //   dispatch(getDataByCategoryDetails(pageState));
    // }
    // setnoFoundData(true);
    setInventorySearhIcon(true);
  };
  const handleInventorySearchInput = (e) => {
    if (e.target.value) {
      setInventorySearhIcon(false);
    }
    if (e.target.value === "") {
      setInventorySearhIcon(true);
    }
    setInputInventoryValue(e.target.value);
  };
  const SearchInventoryData = () => {
    console.log("search call inventory", inputInventoryValue);
    // pageState.top = 10;
    // pageState.skip = 0;
    // setRowsPerPage(parseInt(pageState.top))
    // setPage(0);
    // pageState.query = inputValue;
    // console.log("pageState.query",pageState.query)
    // if (
    //   pageState.query == "" ||
    //   !regexConstant.searchSpace.test(pageState.query)
    // ) {
    //   toast("Please Enter the search key");
    // }

    // if (value == 0) {
    //   setIsLoading(true);
    //   dispatch(getDataByCategoryDetails(pageState));
    // } else {
    //   dispatch(getInactiveDataByPagination(pageState));
    // }
  };

  const [getModelId, setModelId] = useState("");
  const getModelDataId = (e) => {
    setModelId(e.target.value);
    // console.log("e.target.value",e.target.value)
    // need to call api
  };

  useEffect(() => {
    getAllAccount();
  }, []);

  const modelData = [
    {
      id: 1,
      name: "Model-1",
    },
    {
      id: 2,
      name: "Model-2",
    },
  ];
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          width: "100%",
          justifyContent: "end",
          alignItems: "end",
          marginBottom: "30px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={8}></Grid>
          <Grid item lg={2}>
            <TextField
              id="outlined-select-currency"
              select
              size="small"
              label="Select Modal"
              name="SelectModal"
              value={getModelId}
              onChange={getModelDataId}
              // style={{ width: "10%!important" }}
              sx={{
                boxShadow: 3,
                borderRadius: "15px",
                background: "#fff",
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #eee",
                },
              }}
            >
              {modelData?.map((option) => (
                <MenuItem key={option.value} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={2}>
            <TextField
              id="outlined-select-currency"
              select
              size="small"
              label="Account"
              name="accountWithId"
              value={getAccountValueId}
              onChange={getAllAccountId}
              // style={{ width: "10%!important" }}
              sx={{
                boxShadow: 3,
                borderRadius: "15px",
                background: "#fff",
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #eee",
                },
              }}
            >
              {acoountData?.map((option) => (
                <MenuItem key={option.value} value={option.Id}>
                  {option.AccountName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </div>

      <Box {...styles.mainBox}>
        {/* right side tab and table */}
        <Box {...styles.flex}>
          <Grid container spacing={1} {...styles.gridContainer}>
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              sx={{ marginLeft: "-4px", marginBottom: "-5px" }}
            >
              <Box {...styles.tabBox}>
                <Box
                  className={`tab ${checkActive(1, "active")} `}
                  onClick={() => handleClick(1)}
                >
                  Mapping
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              sx={{ marginBottom: "-5px" }}
            >
              <Box {...styles.tabBox}>
                <Box
                  className={` tab ${checkActive(2, "active")}`}
                  onClick={() => handleClick(2)}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <span> GAI</span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} sx={{ marginBottom: "10px" }}>
              <TextField
                fullWidth
                placeholder="Search Item ID,Item Name & Category"
                size="small"
                type="text"
                onBlur={(e) => setSearch(e.target.value)}
                value={inputValue}
                onChange={handleUserInput}
                {...styles.search}
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="set-icon-right" position="start">
                      <IconButton
                        aria-label="delete"
                        onClick={resetInputField}
                        color="warning"
                      >
                        {handleIcon ? <SearchIcon /> : <CancelIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(e) => {
                  console.log(e.key);
                  if (e.key === "Enter") {
                    SearchData();
                  }
                }}
              />
            </Grid>
            {/* -------------filter btn-------------- */}
            <Grid item xs={12} sm={12} md={4}>
              {activeIndex == 2 ? (
                <Box className="text-align-end">
                  <Button
                    id="basic-button"
                    variant="contained"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClickMenu}
                    className="bg-fff border-radius-20 text-color-grey font-bold"
                    startIcon={<TuneIcon />}
                  >
                    Filter
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{ width: 320 }}
                  >
                    <MenuItem onClick={handleClose}>Kg</MenuItem>
                    <MenuItem onClick={handleClose}>Pieces</MenuItem>
                    <MenuItem onClick={handleClose}>Fruits</MenuItem>
                    <MenuItem onClick={handleClose}>Vegetables</MenuItem>
                  </Menu>
                </Box>
              ) : null}
            </Grid>
          </Grid>
          <Box>
            <Box className={`panel ${checkActive(1, "active")}`}>
              <GAIMapping
                getAccountValueId={getAccountValueId}
                mappingData={getData}
                getDraggableObject={getDraggableObject}
                setDraggableObject={setDraggableObject}
              />
            </Box>
            <Box className={`panel ${checkActive(2, "active")}`}>
              <MappingDetails mappingData={getData} />
            </Box>
          </Box>
        </Box>

        {/* ------------Inventry List-------------------- */}
        {activeIndex == 1 ? (
          <Box width={"30%"}>
            <Card
              height={"80vh"}
              className="width-100 border-radius-8 text-align-center"
            >
              <Box
                style={{ color: "#8B8D9D", fontWeight: "bold" }}
                className="text-align-center p-5 font-16"
              >
                Inventory List
              </Box>
              {getInvetoryList == true ? null : (
                <Box className="p-14 margin-35">
                  <Box className="text-align-center font-14 font-weight-bold font-14 my-4">
                    Import your inventory list here!
                  </Box>
                  <Box className="pt-4">
                    <Button
                      variant="contained"
                      className="border-radius-10 bg-offPurple p-4"
                      onClick={showList}
                    >
                      <span className="font-weight-bold">
                        <img src={ImportFile} className="w-24" />
                      </span>
                      Import
                    </Button>
                  </Box>
                </Box>
              )}
              {getInvetoryList == true ? (
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} className="mx-10 my-10">
                      <TextField
                        fullWidth
                        placeholder="Search Item ID & Item Name"
                        id="userName"
                        size="small"
                        type="text"
                        onBlur={(e) => setSearchInventory(e.target.value)}
                        value={inputInventoryValue}
                        onChange={handleInventorySearchInput}
                        {...styles.searchInventoty}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              className="set-icon-right"
                              position="start"
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={resetInventoryInputField}
                                color="warning"
                              >
                                {handleInventorySearchIcon ? (
                                  <SearchIcon />
                                ) : (
                                  <CancelIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyPress={(e) => {
                          console.log(e.key);
                          if (e.key === "Enter") {
                            SearchInventoryData();
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box className="text-align-center p-4">
                    <Grid
                      container
                      spacing={1}
                      className="font-weight-bold text-offGrey text-align-center font-14"
                    >
                      <Grid item xs={12} sm={12} md={6}>
                        <Box>Item Id</Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Box>Item Name</Box>
                      </Grid>
                    </Grid>
                    {getData?.map((row, index) => (
                      <Grid
                        container
                        spacing={1}
                        className="pt-10 text-offOrange text-align-center font-14"
                        key={row.id}
                        draggable
                        onDrag={(event) => onDrag(event, row)}
                      >
                        <Grid item xs={12} sm={12} md={6}>
                          <Box>{row.ItemId}</Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Box className="bg-lightOrange p-8 border-radius-8  font-weight-bold">
                            {row.label}
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Box>
              ) : null}
            </Card>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
