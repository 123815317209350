import {
    GET_CATEGORY_REQUEST,
    GET_ACTIVE_CATEGORY_SUCCESS,
    GET_INACTIVE_CATEGORY_SUCCESS,
    GET_CATEGORY_FAILURE,
    GET_CATEGORYDETAIL_REQUEST,
    GET_CATEGORYDETAIL_SUCCESS,
    GET_CATEGORYDETAIL_FAILURE,
    SAVE_CATEGORY_REQUEST,
    SAVE_CATEGORY_SUCCESS,
    SAVE_CATEGORY_FAILURE,
    GET_IMAGE_REQUEST,
    GET_IMAGE_SUCCESS,
    GET_IMAGE_FAILURE

} from '../Types'
const initialState = {
    loading: false,
    noFoundData: false,
    category: {},
    error: '',
    isAccountActive: true,
    categoryDetails: {},
    result: '',
    imageData: {}
}
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                noFoundData: false
            }
        case GET_ACTIVE_CATEGORY_SUCCESS:

            return {
                loading: false,
                category: action.payload,
                noFoundData: false,
                error: '',
                isAccountActive: true,
            }
        case GET_INACTIVE_CATEGORY_SUCCESS:
            return {
                loading: false,
                category: action.payload,
                noFoundData: false,
                error: '',
                isAccountActive: false
            }
        case GET_CATEGORY_FAILURE:
            return {
                loading: false,
                noFoundData: true,
                category: {},
                error: action.payload
            }
        case GET_CATEGORYDETAIL_REQUEST:
            return {
                ...state,
                loading: true,
                noFoundData: false
            }
        case GET_CATEGORYDETAIL_SUCCESS:
            return {
                loading: false,
                categoryDetails: action.payload,
                noFoundData: false,
                error: '',
                // isAccountActive: false
            }
        case GET_CATEGORYDETAIL_FAILURE:
            return {
                loading: false,
                noFoundData: true,
                categoryDetails: {},
                error: action.payload
            }
        case SAVE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                noFoundData: false,
                error: ''
            }
        case SAVE_CATEGORY_SUCCESS:
            return {
                loading: false,
                result: action.payload,
                noFoundData: false,
                error: '',
                // isAccountActive: false
            }
        case SAVE_CATEGORY_FAILURE:
            return {
                loading: false,
                noFoundData: true,
                result: action.payload,
                error: action.payload.response
            }
        case GET_IMAGE_REQUEST:
            return {
                ...state,
                loading: true,
                noFoundData: false,
                categoryDetails: {},
                imageData: {}
            }
        case GET_IMAGE_SUCCESS:
            return {
                loading: false,
                imageData: action.payload,
                error: ''
            }
        case GET_IMAGE_FAILURE:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}

export default reducer  