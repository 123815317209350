import {
    GET_PRODUCT_REQUEST,
    GET_ACTIVE_PRODUCT_SUCCESS,
    GET_INACTIVE_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILURE,
    GET_PRODUCTDETAIL_REQUEST,
    GET_PRODUCTDETAIL_SUCCESS,
    GET_PRODUCTDETAIL_FAILURE,
    SAVE_PRODUCT_REQUEST,
    SAVE_PRODUCT_SUCCESS,
    SAVE_PRODUCT_FAILURE,
    GET_IMAGE_REQUEST,
    GET_IMAGE_SUCCESS,
    GET_IMAGE_FAILURE

} from '../Types'
const initialState = {
    loading: false,
    noFoundData: false,
    product: {},
    error: '',
    isAccountActive: true,
    productDetails: {},
    result: '',
    imageData: {}
}
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
                noFoundData: false
            }
        case GET_ACTIVE_PRODUCT_SUCCESS:

            return {
                loading: false,
                product: action.payload,
                noFoundData: false,
                error: '',
                isAccountActive: true,
            }
            case GET_INACTIVE_PRODUCT_SUCCESS:
            return {
                loading: false,
                product: action.payload,
                noFoundData: false,
                error: '',
                isAccountActive: false
            }
        case GET_PRODUCT_FAILURE:
            return {
                loading: false,
                noFoundData: true,
                product: {},
                error: action.payload
            }
        case GET_PRODUCTDETAIL_REQUEST:
            return {
                ...state,
                loading: true,
                noFoundData: false
            }
        case GET_PRODUCTDETAIL_SUCCESS:
            return {
                loading: false,
                productDetails: action.payload,
                noFoundData: false,
                error: '',
                // isAccountActive: false
            }
        case GET_PRODUCTDETAIL_FAILURE:
            return {
                loading: false,
                noFoundData: true,
                productDetails: {},
                error: action.payload
            }
        case SAVE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
                noFoundData: false,
                error: ''
            }
        case SAVE_PRODUCT_SUCCESS:
            return {
                loading: false,
                result: action.payload,
                noFoundData: false,
                error: '',
                // isAccountActive: false
            }
        case SAVE_PRODUCT_FAILURE:
            return {
                loading: false,
                noFoundData: true,
                result: action.payload,
                error: action.payload.response
            }
        case GET_IMAGE_REQUEST:
            return {
                ...state,
                loading: true,
                noFoundData: false,
                productDetails: {},
                imageData: {}
            }
        case GET_IMAGE_SUCCESS:
            return {
                loading: false,
                imageData: action.payload,
                error: ''
            }
        case GET_IMAGE_FAILURE:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}

export default reducer  