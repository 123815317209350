import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Divider, Grid, TextField, MenuItem } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {

    return (
        <div>
            <Button onClick={props.handleClickOpen} className="text-orange" fullWidth="true"  sx={{ textTransform:'capitalize',fontWeight: "400",fontSize:'14px' }}>
                Assign License
            </Button>
            <Dialog
                onClose={props.handleClose}
                // aria-labelledby="customized-dialog-title"
                open={props.open}
                sx={{
                    "& .MuiPaper-elevation24": {
                      boxShadow: "none!important",
                    },
                    " .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop":{
                      background:"rgba(0,0,0,0.1)!important"
                    },
                    "& .MuiBackdrop-root":{
                      opacity:"0.3!important"
                    }
                  }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" className="p-2" onClose={props.handleCloseDialog}>
                    Assign License to Store & POS
                </BootstrapDialogTitle>
                <DialogContent dividers>

                    <div>
                        <form onSubmit={props.submitForm}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        size='small'
                                        label="Select Store"
                                        name='storeName'
                                        value={props.storeName}
                                        onChange={props.getAllAccountId}
                                    >
                                        {props.storeData?.map((option) => (
                                            <MenuItem key={option.value} value={option.Id}>
                                                {option.StoreName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='errors'>{props.storeError}</div>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        size='small'
                                        label="Select Terminal"
                                        name='terminalName'
                                        value={props.terminalName}
                                        onChange={props.getAllTerminalId}
                                    >
                                        {props.terminalData?.map((option) => (
                                            option.License == null ?
                                                <MenuItem key={option.value} value={option.Id}>
                                                    <div>{option.TerminalName}</div>
                                                </MenuItem>
                                                :
                                                null
                                        ))}
                                    </TextField>
                                    <div className='errors'>{props.terminalNameError}</div>
                                </Grid>

                            </Grid>
                            <div className='text-align-center my-15'>
                                <Button variant="contained" size="small" className='border-radius-20 btn-orange w-100' type='submit' style={{ width: '40%' }}>
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}