export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST'
export const GET_ACTIVE_ACCOUNT_SUCCESS = 'GET_ACTIVE_ACCOUNT_SUCCESS'
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE'
export const GET_INACTIVE_ACCOUNT_SUCCESS = 'GET_INACTIVE_ACCOUNT_SUCCESS'

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE'

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST'
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS'
export const GET_STATE_FAILURE = 'GET_STATE_FAILURE'

export const GET_CITY_REQUEST = 'GET_CITY_REQUEST'
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS'
export const GET_CITY_FAILURE = 'GET_CITY_FAILURE'

export const GET_ACCOUNTDETAIL_REQUEST = 'GET_ACCOUNTDETAIL_REQUEST'
export const GET_ACCOUNTDETAIL_SUCCESS = 'GET_ACCOUNTDETAIL_SUCCESS'
export const GET_ACCOUNTDETAIL_FAILURE = 'GET_ACCOUNTDETAIL_FAILURE'

export const SAVE_ACCOUNT_REQUEST = 'SAVE_ACCOUNT_REQUEST'
export const SAVE_ACCOUNT_SUCCESS = 'SAVE_ACCOUNT_SUCCESS'
export const SAVE_ACCOUNT_FAILURE = 'SAVE_ACCOUNT_FAILURE'

export const GET_IMAGE_REQUEST = 'GET_IMAGE_REQUEST'
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS'
export const GET_IMAGE_FAILURE = 'GET_IMAGE_FAILURE'

export const GET_TERMINALDETAILS_REQUEST = 'GET_TERMINALDETAILS_REQUEST'
export const GET_TERMINALDETAILS_SUCCESS = 'GET_TERMINALDETAILS_SUCCESS'
export const GET_TERMINALDETAILS_FAILURE = 'GET_TERMINALDETAILS_FAILURE'
//  for save terminal
export const SAVE_TERMINAL_REQUEST = 'SAVE_TERMINAL_REQUEST'
export const SAVE_TERMINAL_SUCCESS = 'SAVE_TERMINAL_SUCCESS'
export const SAVE_TERMINAL_FAILURE = 'SAVE_TERMINAL_FAILURE'

// for get 
export const GET_TERMINAL_REQUEST = 'GET_TERMINAL_REQUEST'
export const GET_TERMINAL_LICENSE_REQUEST = 'GET_TERMINAL_LICENSE_REQUEST'
export const GET_ACTIVE_TERMINAL_SUCCESS = 'GET_ACTIVE_TERMINAL_SUCCESS'
export const GET_TERMINAL_FAILURE = 'GET_TERMINAL_FAILURE'
export const GET_INACTIVE_TERMINAL_SUCCESS = 'GET_INACTIVE_TERMINAL_SUCCESS'

// For license
export const GET_LICENSEDETAILS_REQUEST = 'GET_LICENSEDETAILS_REQUEST'
export const GET_LICENSEDETAILS_SUCCESS = 'GET_LICENSEDETAILS_SUCCESS'
export const GET_LICENSEDETAILS_FAILURE = 'GET_LICENSEDETAILS_FAILURE'

//  for save license
export const SAVE_LICENSE_REQUEST = 'SAVE_LICENSE_REQUEST'
export const SAVE_LICENSE_SUCCESS = 'SAVE_LICENSE_SUCCESS'
export const SAVE_LICENSE_FAILURE = 'SAVE_LICENSE_FAILURE'

// for get license
export const GET_LICENSE_REQUEST = 'GET_LICENSE_REQUEST'
export const GET_ACTIVE_LICENSE_SUCCESS = 'GET_ACTIVE_LICENSE_SUCCESS'
export const GET_LICENSE_FAILURE = 'GET_LICENSE_FAILURE'
export const GET_INACTIVE_LICENSE_SUCCESS = 'GET_INACTIVE_LICENSE_SUCCESS'

// Using for store
export const GET_STORE_REQUEST = 'GET_STORE_REQUEST'
export const GET_STORE_USER_REQUEST = 'GET_STORE_USER_REQUEST'
export const GET_STORE_FAILURE = 'GET_STORE_FAILURE'
export const GET_INACTIVE_STORE_SUCCESS = 'GET_INACTIVE_STORE_SUCCESS'

export const GET_STOREDETAIL_REQUEST = 'GET_STOREDETAIL_REQUEST'
export const GET_STOREDETAIL_SUCCESS = 'GET_STOREDETAIL_SUCCESS'
export const GET_STOREDETAIL_FAILURE = 'GET_STOREDETAIL_FAILURE'

export const SAVE_STORE_REQUEST = 'SAVE_STORE_REQUEST'
export const SAVE_STORE_SUCCESS = 'SAVE_STORE_SUCCESS'
export const SAVE_STORE_FAILURE = 'SAVE_STORE_FAILURE'

// Using for user
export const GET_USERDETAILS_REQUEST = 'GET_USERDETAILS_REQUEST'
export const GET_USERDETAILS_SUCCESS = 'GET_USERDETAILS_SUCCESS'
export const GET_USERDETAILS_FAILURE = 'GET_USERDETAILS_FAILURE'

export const GET_USERDETAILSPROFILE_REQUEST = 'GET_USERDETAILSPROFILE_REQUEST'
export const GET_USERDETAILSPROFILE_SUCCESS = 'GET_USERDETAILSPROFILE_SUCCESS'
export const GET_USERDETAILSPROFILE_FAILURE = 'GET_USERDETAILSPROFILE_FAILURE'

export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST'
export const GET_ACTIVE_STORE_SUCCESS = 'GET_ACTIVE_STORE_SUCCESS'
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS'
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE'

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_ACTIVE_USER_SUCCESS = 'GET_ACTIVE_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'
export const GET_INACTIVE_USER_SUCCESS = 'GET_INACTIVE_USER_SUCCESS'
// For get category
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST'
export const GET_ACTIVE_CATEGORY_SUCCESS = 'GET_ACTIVE_CATEGORY_SUCCESS'
export const GET_INACTIVE_CATEGORY_SUCCESS = 'GET_INACTIVE_CATEGORY_SUCCESS'
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE'

export const GET_CATEGORYDETAIL_REQUEST = 'GET_CATEGORYDETAIL_REQUEST'
export const GET_CATEGORYDETAIL_SUCCESS = 'GET_CATEGORYDETAIL_SUCCESS'
export const GET_CATEGORYDETAIL_FAILURE = 'GET_CATEGORYDETAIL_FAILURE'

export const SAVE_CATEGORY_REQUEST = 'SAVE_CATEGORY_REQUEST'
export const SAVE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS'
export const SAVE_CATEGORY_FAILURE = 'SAVE_CATEGORY_FAILURE'
// For get subcategory
export const GET_SUBCATEGORY_REQUEST = 'GET_SUBCATEGORY_REQUEST'
export const GET_ACTIVE_SUBCATEGORY_SUCCESS = 'GET_ACTIVE_SUBCATEGORY_SUCCESS'
export const GET_INACTIVE_SUBCATEGORY_SUCCESS = 'GET_INACTIVE_SUBCATEGORY_SUCCESS'
export const GET_SUBCATEGORY_FAILURE = 'GET_SUBCATEGORY_FAILURE'

export const GET_SUBCATEGORYDETAIL_REQUEST = 'GET_SUBCATEGORYDETAIL_REQUEST'
export const GET_SUBCATEGORYDETAIL_SUCCESS = 'GET_SUBCATEGORYDETAIL_SUCCESS'
export const GET_SUBCATEGORYDETAIL_FAILURE = 'GET_SUBCATEGORYDETAIL_FAILURE'

export const SAVE_SUBCATEGORY_REQUEST = 'SAVE_SUBCATEGORY_REQUEST'
export const SAVE_SUBCATEGORY_SUCCESS = 'SAVE_SUBCATEGORY_SUCCESS'
export const SAVE_SUBCATEGORY_FAILURE = 'SAVE_SUBCATEGORY_FAILURE'

// For get subcategory
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST'
export const GET_ACTIVE_PRODUCT_SUCCESS = 'GET_ACTIVE_PRODUCT_SUCCESS'
export const GET_INACTIVE_PRODUCT_SUCCESS = 'GET_INACTIVE_PRODUCT_SUCCESS'
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE'

export const GET_PRODUCTDETAIL_REQUEST = 'GET_PRODUCTDETAIL_REQUEST'
export const GET_PRODUCTDETAIL_SUCCESS = 'GET_PRODUCTDETAIL_SUCCESS'
export const GET_PRODUCTDETAIL_FAILURE = 'GET_PRODUCTDETAIL_FAILURE'

export const SAVE_PRODUCT_REQUEST = 'SAVE_PRODUCT_REQUEST'
export const SAVE_PRODUCT_SUCCESS = 'SAVE_PRODUCT_SUCCESS'
export const SAVE_PRODUCT_FAILURE = 'SAVE_PRODUCT_FAILURE'

export const GET_DEVICEMODEL_REQUEST = "GET_DEVICEMODEL_REQUEST";
export const GET_DEVICEMODEL_SUCCESS = "GET_DEVICEMODEL_SUCCESS";
export const GET_DEVICEMODEL_FAILURE = "GET_DEVICEMODEL_FAILURE"

export const GET_MAP_REQUEST = 'GET_MAP_REQUEST'
export const GET_ACTIVE_MAP_SUCCESS = 'GET_ACTIVE_MAP_SUCCESS'
export const GET_MAP_FAILURE = 'GET_MAP_FAILURE'

export const GET_MAPDETAILS_REQUEST = 'GET_MAPDETAILS_REQUEST'
export const GET_MAPDETAILS_SUCCESS = 'GET_MAPDETAILS_SUCCESS'
export const GET_MAPDETAILS_FAILURE = 'GET_MAPDETAILS_FAILURE'

export const SAVE_MAP_REQUEST = 'SAVE_MAP_REQUEST'
export const SAVE_MAP_SUCCESS = 'SAVE_MAP_SUCCESS'
export const SAVE_MAP_FAILURE = 'SAVE_MAP_FAILURE'

export const  GET_UNMAP_REQUEST = 'GET_UNMAP_REQUEST'
export const  GET_UN_MAP_SUCCESS = 'GET_UN_MAP_SUCCESS'
export const  GET_UNMAP_FAILURE = 'GET_UNMAP_FAILURE'

export const GET_SALE_WISE_REQUEST = "GET_SALE_WISE_REQUEST"
export const GET_SALE_WISE_SUCCESS = "GET_SALE_WISE_SUCCESS"
export const GET_SALE_WISE_FAILURE = "GET_SALE_WISE_FAILURE"

export const GET_ITEM_WISE_REQUEST = "GET_ITEM_WISE_REQUEST"
export const GET_ITEM_WISE_SUCCESS = "GET_ITEM_WISE_SUCCESS"
export const GET_ITEM_WISE_FAILURE = "GET_ITEM_WISE_FAILURE"

export const SAVE_CITY_REQUEST = 'SAVE_CITY_REQUEST'
export const SAVE_CITY_SUCCESS = 'SAVE_CITY_SUCCESS'
export const SAVE_CITY_FAILURE = 'SAVE_CITY_FAILURE'

// ---------Gai mapping--------------

export const GET_GAI_DATA_REQUEST = 'GET_GAI_DATA_REQUEST '
export const GET_GAI_DATA_REQUEST_SUCCESS = 'GET_GAI_DATA_REQUEST_SUCCESS'
export const GET_GAI_DATA_REQUEST_FAILURE = 'GET_GAI_DATA_REQUEST_FAILURE'
