import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import imgTwo from "../../assets/Images/Animation 1.gif";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import "../../assets/scss/login.css";
import { config } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function BasicCard() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loginDisable, setLoginDisable] = useState();
  const loginValidationSchema = Yup.object().shape({
    userName: Yup.string().required("User Name is required"),
    OTPNumber: Yup.string().required("OTP Number is required"),
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
      OTPNumber: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      axios
        .post(config.OTP_API_URL, {
          userName: values.userName,
          pin: values.OTPNumber,
        })
        .then((response) => {
          navigate(`/ResetPassword`);
          const user = response.data;
          localStorage.setItem("userLoginDetails", JSON.stringify(user));
        })
        .catch((error) => {
          if (error.response.status == 404) {
            toast(error.response.data);
          } else if (error.response.status == 401) {
            toast(error.response.data);
          } else if (error.response.status == 403) {
            toast(error.response.data);
          } else if (error.response.status == 400) {
            toast(error.response.data);
          } else {
            toast("Something went wrong. Please try again later. ");
          }
        });
    },
  });
  const { handleSubmit, handleChange, handleBlur, errors, touched, values } =
    formik;
  return (
    <>
      <ToastContainer autoClose={1000} />
      <Box sx={{ width: "100%", height: "100vh", background: "white" }}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          height={"90vh"}
        >
          <Grid item>
            <img src={imgTwo} alt="login gif" />
          </Grid>
          <Grid item>
            <Card className="card" sx={{ width: 300 }}>
              <CardContent sx={{ padding: "0" }}>
                <Typography
                  component={"h2"}
                  variant="body1"
                  className="subTagtitle"
                >
                  Set verification Code
                </Typography>
              </CardContent>
              <form onSubmit={handleSubmit}>
                <Box mt={4}>
                  <TextField
                    fullWidth
                    variant="standard"
                    label="Enter User Name"
                    style={{ marginTop: "12px" }}
                    id="username"
                    name="userName"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userName}
                  />
                  {errors.userName && touched.userName ? (
                    <Box className="errors">{errors.userName}</Box>
                  ) : null}
                </Box>
                <Box mt={3}>
                  <TextField
                    fullWidth
                    variant="standard"
                    style={{ marginTop: "12px" }}
                    label="verification code"
                    id="OTPNumber"
                    name="OTPNumber"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.OTPNumber}
                  />
                  {errors.OTPNumber && touched.OTPNumber ? (
                    <Box className="errors">{errors.OTPNumber}</Box>
                  ) : null}
                </Box>

                <CardActions
                  sx={{ marginTop: "36px", padding: "0", marginBottom: "40px" }}
                >
                  <Grid container justifyContent={"space-between"}>
                    <Grid item md={12} mt={5}>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        disabled={loginDisable}
                        className="btn-orange px-10"
                        style={{ width: "100%" }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </form>
            </Card>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            className="copyright"
            px={5}
          >
            <Grid item pl={2}>
              <p>
                2023 <span>Quinta</span> Systems Pvt. Ltd.
              </p>
            </Grid>
            <Grid item pr={3}>
              <p>
                <Link underline="none">Terms & Conditions</Link>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
