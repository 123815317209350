import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Card,
  Box,
  Grid,
  MenuItem,
  Breadcrumbs,
  Link,
} from "@mui/material";
import DefaultInput from "../../Component/TextField";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../Component/Button";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiServices from "../../Services/ApiServices";
import { styles } from "../../assets/scss/AddItem";


export default function BasicTable() {
  const terminalDetail = useSelector((s) => s.terminalReducer);
  const dispatch = useDispatch();
  const params = useParams();
  const [Roles, setRoles] = React.useState("Admin");

  const navigate = useNavigate();
  const [inputField, setInputField] = useState({
    accountName: "",
    NoOfLicense: "",
  });
  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    getAllAccount();
  }, []);
  const [getStoreId, setStoreId] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // Save new account
  const saveLicenseDetails = () => {
    ApiServices.saveData(`/License/createMultiple`, {
      accountId: inputField.accountId,
      numberOfLicense: inputField.NoOfLicense,
    })
      .then((res) => {
        if (res.status == 200) {
          toast("License create successfully");
          setTimeout(() => {
            navigate(`/app/License`);
          }, 1000);
        }
      })
      .catch((res) => {});
  };

  //validation Type

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    let formIsValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexNumber = /^[0-9\b]+$/;
    const letter = /^[a-zA-Z ]*$/;
    const onlyTennumber = /^\d{10}$/;
    if (!values.accountName) {
      errors.accountName = "Account Name is required!";
      formIsValid = false;
    }
    if (!regexNumber.test(values.NoOfLicense)) {
      errors.NoOfLicense = "Please enter numbers only";
      formIsValid = false;
    }
    if (!values.NoOfLicense || values.NoOfLicense == 0) {
      errors.NoOfLicense = "No. of License is required!";
      formIsValid = false;
    }
    if (values.NoOfLicense.length > 4) {
      errors.NoOfLicense = "Please enter less than 4 digit";
      formIsValid = false;
    }
    setFormErrors(errors);
    return formIsValid;
  };

  // mention save method
  const submitForm = (e) => {
    e.preventDefault();
    if (validate(inputField)) {
      setIsSubmit(true);

      saveLicenseDetails();
    }
  };
  const handleChange = (event) => {
    setRoles(event.target.value);
  };
  const [acoountData, setAccountData] = useState([]);

  const getAllAccountId = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      accountId: e.target.value,
      accountName: e.target.value,
    }));
  };
  const getAllAccount = () => {
    ApiServices.getData(`/Account/all/dropdown`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((res) => {});
  };
  return (
    <div style={{ marginBottom: "30px" }}>
      <ToastContainer autoClose={1000} />
      {/* --------------btn section----------- */}
      <Grid container sx={{ marginLeft: "-5px " }}>
        <Grid item xs={12} sm={12} md={11}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Box {...styles.tab}>
                <Box {...styles.tabInside}>New License</Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
          <DefaultButton
            color="warning"
            variant="contained"
            size="small"
            Text="Back"
          />
        </Grid>
      </Grid>

      <form onSubmit={submitForm}>
        <Card className="border-radius-8 width-100" sx={{ marginTop: "-5px" }}>
          <div className="p-4">
            <div>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    label="Account *"
                    name="accountName"
                    value={inputField.accountName}
                    onChange={getAllAccountId}
                  >
                    {acoountData.map((option) => (
                      <MenuItem key={option.value} value={option.Id}>
                        {option.AccountName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className="errors">{formErrors.accountName}</div>
                </Grid>

                <Grid item xs={12}>
                  <DefaultInput
                    id="NoOfLicense"
                    label="No Of License"
                    variant="standard"
                    name="NoOfLicense"
                    value={inputField.NoOfLicense}
                    onChange={inputsHandler}
                    placeHolder="No Of License"
                  />
                  <div className="errors">{formErrors.NoOfLicense}</div>
                </Grid>
              </Grid>
            </div>
            <div className="buttonSecton">
              <div className="mx-3" style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  {...styles.SaveBtn}
                  className="btn-orange "
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </form>
    </div>
  );
}
