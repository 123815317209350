import React, { useState, useEffect } from "react";
import {
  Link,
  Box,
  Card,
  CardActions,
  CardContent,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Button,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import imgTwo from "../../assets/Images/Animation 1.gif";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "../../assets/scss/login.css";
import { config } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import { userRoles } from "../../shared/constants";
import "react-toastify/dist/ReactToastify.css";
import jwt from "jwt-decode";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function BasicCard() {
  const navigate = useNavigate();
  const location = useLocation();
  const initialValues = { userName: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loginDisable, setLoginDisable] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [getDisbleValue, setDisableValue] = useState(true);


  const loginValidationSchema = Yup.object().shape({
    userName: Yup.string().required("User Name is required"),
    password: Yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      setDisableValue(false);
      axios
        .post(config.AUTH_API_URL, {
          userName: values.userName,
          password: values.password,
        })
        .then((response) => {
          const user = jwt(response.data.AccessToken); // decode your token here
          localStorage.setItem("userDetails", JSON.stringify(user));
          localStorage.setItem("accessToken", response.data.AccessToken);
          localStorage.setItem("refreshToken", response.data.RefreshToken);
          if (response.status == 200) {
            toast("Login Successful");
            setTimeout(() => {
              const userDetail = JSON.parse(
                localStorage.getItem("userDetails")
              );
              if (
                userDetail.userRole == userRoles.SuperAdmin ||
                userDetail.userRole == userRoles.AccountAdmin
              ) {
                navigate("/app/ManageAccount");
              } else if (userDetail.userRole == userRoles.StoreManager) {
                navigate(`/app/ManageStore/${userDetail.accountId}`);
              } else if (userDetail.userRole == userRoles.StoreStaff) {
                navigate(`/app/ManageStore/${userDetail.accountId}`);
              }
            }, 1000);
            setTimeout(() => {
              setDisableValue(true);
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.response.status == 404) {
            toast(error.response.data);
          } else if (error.response.status == 401) {
            toast(error.response.data);
          } else if (error.response.status == 403) {
            toast(error.response.data);
          } else if (error.response.status == 400) {
            toast(error.response.data);
          } else {
            toast("Something went wrong. Please try again later. ");
          }
          setTimeout(() => {
            setDisableValue(true);
          }, 2500);
        });
    },
  });
  const { handleSubmit, handleChange, handleBlur, errors, touched, values } =
    formik;
  const onPasswordTypeChange = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <Box sx={{ width: "100%", height: "100vh", background: "white" }}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          height={"90vh"}
        >
          <Grid item>
            <img src={imgTwo} alt="login gif" style={{ width: "500px" }} />
          </Grid>
          <Grid item className="mt-2">
            <Card className="card">
              <CardContent sx={{ padding: "0" }}>
                <Typography component={"h1"} variant="h4" className="title">
                  Sign In
                </Typography>
                <Typography
                  component={"h2"}
                  variant="body1"
                  className="subtitle"
                >
                  Welcome to Self Checkout Central
                </Typography>
              </CardContent>
              {/* ------------------Form start-------------------- */}
              <form onSubmit={handleSubmit}>
                <Box mt={4}>
                  <TextField
                    fullWidth
                    label="Username*"
                    variant="standard"
                    id="username"
                    name="userName"
                    size="small"
                    InputLabelProps={{
                      sx: {
                        fontWeight: "600",
                      },
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userName}
                  />
                  {errors.userName && touched.userName ? (
                    <Box className="errors">{errors.userName}</Box>
                  ) : null}
                </Box>
                <Box mt={3}>
                  <FormControl variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-password"
                      sx={{ fontWeight: "600" }}
                    >
                      Password*
                    </InputLabel>
                    <Input
                      fullWidth
                      variant="standard"
                      type={passwordType}
                      id="password"
                      name="password"
                      InputLabelProps={{
                        sx: {
                          fontWeight: "600",
                        },
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={onPasswordTypeChange}
                            onMouseDown={onPasswordTypeChange}
                          >
                            {passwordType === "password" ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.password && touched.password ? (
                      <Box className="errors">{errors.password}</Box>
                    ) : null}
                  </FormControl>
                </Box>

                {/* ------------------Card actions----------------- */}
                <CardActions
                  sx={{ marginTop: "36px", padding: "0", marginBottom: "40px" }}
                >
                  <Grid container justifyContent={"space-between"}>
                    <Grid item>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox sx={{ padding: "0px 9px" }} />}
                          label="Remember me"
                          className={"classes.checkRoot"}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item>
                      <Link
                        href="/RestMobileNo"
                        underline="hover"
                        color="inherit"
                        className="font-16 font-weight-500"
                      >
                        {"Forgot Password"}
                      </Link>
                    </Grid>
                    <Grid item md={12} mt={5}>
                      {getDisbleValue == true ? (
                        <Button
                          variant="contained"
                          size="medium"
                          type="submit"
                          // className="btn-orange"
                          fullWidth
                          sx={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                            background: "#ea7626",
                          }}
                        >
                          Sign In
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="medium"
                          type="submit"
                          disabled
                          className=""
                          fullWidth
                          sx={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          Sign In
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </CardActions>
                <p className="poweredBy">
                  Powered By
                  <Link href="https://www.quinta.co.in/" className="text-orange" underline="none" ml={1} target="_blank" >
                    Quinta
                  </Link>
                </p>
              </form>
            </Card>
          </Grid>
          {/* ----------------Bottom text------------------------ */}
          <Grid
            container
            justifyContent={"space-between"}
            className="copyright"
            px={5}
            width="70%"
          >
            <Grid item md={6}>
              <p className="float-left">
              <Link underline="none" href="https://www.quinta.co.in/" target="_blank">
                2023 <span>Quinta</span> Systems Pvt. Ltd.
                </Link>
              </p>
            </Grid>

            <Grid item md={6} paddingRight={"138px"}>
              <p className="float-right">
                <Link underline="none" href="/Terms&Conditon" target="_blank">Terms & Conditions</Link>
             
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
