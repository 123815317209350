import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import img2 from "../../assets/Images/dashboard-menu/group.png";
import { useLocation, Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { CardActionArea } from "@mui/material";
import FilterReports from "../../Component/FilterReports";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ApiServices from "../../Services/ApiServices";
import moment from "moment";
// import ReportsOne from "../ReportsOne";
import SaleReports from "./SalesReports";
import ItemWiseReports from "./ItemWiseReports";
import { getCountryList } from "../../Redux/Country/CountryAction";
import { getStateListByAccount } from "../../Redux/State/StateAction";
import { getCityListByAccount } from "../../Redux/City/CityAction";
import { makeStyles } from "@material-ui/core/styles";
import { getSaleWiseReports } from "../../Redux/Reports/SaleWiseAction";
import { getItemWiseReports } from "../../Redux/Reports/ItemWiseAction";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { downloadExcel } from "react-export-table-to-excel";
import SalwWiseExcel from "../../Component/Excel/SaleWiseExcel";
import ItemWiseExcel from "../../Component/Excel/ItemWiseExcel";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
    background: "#9747FF",
    borderRadius: "14px",
    paddingLeft: 0,
    paddingTop: 0,
  },
}));

const reportsArray = [
  {
    Id: 1,
    name: "Sales Reports",
  },
  {
    Id: 2,
    name: "Item Wise Sales Reports",
  },
];
const pageState = {
  skip: 0,
  top: 10,
  isDeleted: false,
  query: "",
  StateId: "",
  CityId: "",
  StoreId: "",
  TerminalId: "",
  fromDate: "",
  toDate: "",
  PaymentMode: "",
  orderId: "",
};

export default function BasicTable() {
  const {
    loading,
    saleWiseData,
    noFoundData,
    TotalItems,
    saleWiseDetailsData,
    noFoundDataInItem,
    ItemWiseData,
    TotalItemsWise,
  } = useSelector((state) => ({
    loading: state.saleWiseReducer.loading,
    noFoundData: state.saleWiseReducer.noFoundData,
    saleWiseData: state.saleWiseReducer.saleWiseData,
    TotalItems: state.saleWiseReducer.saleWiseData.TotalItems,
    ItemWiseData: state.itemWiseReducer.itemWiseData,
    TotalItemsWise: state.itemWiseReducer.itemWiseData.TotalItems,
    noFoundDataInItem: state.itemWiseReducer.noFoundData,
  }));
  const classes = useStyles();
  const [totalItemData, setTotalItemData] = useState(1);
  const [getApply, setApply] = useState(false);
  const [country, setCountry] = useState([]);
  const [City, setCity] = useState([]);
  const [State, setState] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [SearchInput, setSearchInput] = useState();
  const [handleIcon, setHandleIcon] = useState(true);
  const [values, setValues] = useState(
    new DateObject().subtract(0, "days"),
    new DateObject().add(1, "days")
  );
  const [getDataChange, setDataChange] = useState(false);
  const setDateValue = (e) => {
    setApply(true);
    setValues(e);
    if (e) {
      setDataChange(true);
    }
  };
  const dispatch = useDispatch();
  const countryData = useSelector((s) => s.countryReducer);
  const stateData = useSelector((s) => s.stateReducer);
  const cityData = useSelector((s) => s.cityReducer);
  const [getReports, setReprots] = useState(1);
  const [getStateData, setStateData] = useState("");
  const [getCityData, setCityData] = useState("");
  const [getStoreData, setStoreData] = useState("");

  const [getTerminalData, setTerminalData] = useState("");
  const [inputField, setInputField] = useState({
    country: "",
    State: "",
    City: "",
    accountName: "",
    storeName: "",
    terminalName: "",
    reportsValue: 1,
    pageNumber: "",
  });

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [getPageNumber, setPageNumber] = useState("");
  const [getAccountId, setAccountId] = useState();

  // Calling  api
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    dispatch(getSaleWiseReports(getAccountId, pageState));
  };
  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    dispatch(getSaleWiseReports(getAccountId, pageState));
  };

  const handleItemChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    dispatch(getItemWiseReports(getAccountId, pageState));
  };
  const handleChangeItemWisePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    dispatch(getItemWiseReports(getAccountId, pageState));
  };
  // intial List Call
  const intialListCall = (obj) => {
    pageState.top = 10;
    pageState.skip = 0;
    pageState.StateId = "";
    pageState.CityId = "";
    pageState.StoreId = "";
    pageState.TerminalId = "";
    pageState.toDate = "";
    pageState.PaymentMode = "";
    pageState.orderId = "";
    const today = new Date();
    pageState.fromDate = moment(today).format("YYYY-MM-DD");
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    dispatch(getSaleWiseReports(obj, pageState));
    dispatch(getItemWiseReports(obj, pageState));
  };

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    const today = new Date();
    pageState.fromDate = moment(today).format("YYYY-MM-DD");
    dispatch(getSaleWiseReports(userDetail.accountId, pageState));
    setAccountId(userDetail.accountId);
    dispatch(getStateListByAccount(userDetail.accountId));
    pageState.skip = 0;
    pageState.top = 10;
    dispatch(getItemWiseReports(userDetail.accountId, pageState));
    intialListCall(userDetail.accountId);
  }, []);

  // reports array onChange method
  const getAllReports = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      reportsValueId: e.target.value,
      reportsValue: e.target.value,
    }));

    setReprots(e.target.value);
    setStateData("");
    setCityData("");
    setStoreData("");
    setTerminalData("");
    const date = new DateObject();
    setValues(date);
    intialListCall(getAccountId);
  };
  // get all States for selected country
  useEffect(() => {
    if (stateData) {
      setState(stateData.states);
    }
  }, [stateData]);
  // get all city for selected state
  useEffect(() => {
    if (cityData) {
      setCity(cityData.cities);
    }
  }, [cityData]);

  const getCityByState = (event, value) => {
    setStateData(value);
    setApply(true);
    if (value.Id) {
      setCityData("");
      setStoreData("");
      setTerminalData("");
    }
    dispatch(getCityListByAccount(getAccountId, value.Id));
  };
  // using for city
  const getAllCityInput = (e) => {
    setCityData(e);
    if (e.Id) {
      setStoreData("");
      setTerminalData("");
    }
    getAllStores(e.Id);
  };

  const [stores, setStores] = useState([]);
  const getAllStoresData = (e) => {
    setStoreData(e);
    if (e.Id) {
      setTerminalData("");
      getAllTerminal(e.Id);
    }
  };
  const getAllStores = (id) => {
    ApiServices.getData(
      `/Store/getByAccount?accountId=${getAccountId}&CityId=${id}`
    )
      .then((res) => {
        setStores(res.data);
      })
      .catch((res) => {});
  };
  const [terminal, setTerminal] = useState([]);
  const getAllTerminalData = (e) => {
    setTerminalData(e);
  };
  const getAllTerminal = (id) => {
    ApiServices.getData(`/Terminal/getByStore/${id}`)
      .then((res) => {
        setTerminal(res.data);
      })
      .catch((res) => {});
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [getExportData, setExportData] = useState(null);
  const [getItemWiseExportData, setItemWiseExportData] = useState(null);
  const tableRef = useRef(null);

  const handleDownloadExcel = () => {
    pageState.StateId = getStateData.Id;
    pageState.CityId = getCityData.Id;
    pageState.StoreId = getStoreData.Id;
    pageState.TerminalId = getTerminalData.Id;
    pageState.fromDate = new DateObject(new Date(values[0]));
    pageState.toDate = new DateObject(new Date(values[1]));
    let url = `/Reports/orders/export?accountId=${getAccountId}`;
    if (pageState.StateId) {
      url += `&StateId=${pageState.StateId}`;
    }
    if (pageState.CityId) {
      url += `&CityId=${pageState.CityId}`;
    }
    if (pageState.StoreId) {
      url += `&StoreId=${pageState.StoreId}`;
    }
    if (pageState.TerminalId) {
      url += `&TerminalId=${pageState.TerminalId}`;
    }
    if (getDataChange == true) {
      url += `&FromDate=${pageState.fromDate}`;
    } else {
      const today = new Date();
      pageState.fromDate = moment(today).format("YYYY-MM-DD");
      url += `&FromDate=${pageState.fromDate}`;
    }
    if (pageState.toDate) {
      url += `&ToDate=${pageState.toDate}`;
    }

    ApiServices.getData(url)
      .then((res) => {
        setExportData(res.data);
      })
      .catch((res) => {});
  };
  const handleItemWiseDownloadExcel = () => {
    pageState.StateId = getStateData.Id;
    pageState.CityId = getCityData.Id;
    pageState.StoreId = getStoreData.Id;
    pageState.TerminalId = getTerminalData.Id;
    pageState.fromDate = new DateObject(new Date(values[0]));
    pageState.toDate = new DateObject(new Date(values[1]));
    let url = `/Reports/orders/itemwiseExport?accountId=${getAccountId}`;
    if (pageState.StateId) {
      url += `&StateId=${pageState.StateId}`;
    }
    if (pageState.CityId) {
      url += `&CityId=${pageState.CityId}`;
    }
    if (pageState.StoreId) {
      url += `&StoreId=${pageState.StoreId}`;
    }
    if (pageState.TerminalId) {
      url += `&TerminalId=${pageState.TerminalId}`;
    }
    if (getDataChange == true) {
      url += `&FromDate=${pageState.fromDate}`;
    } else {
      const today = new Date();
      pageState.fromDate = moment(today).format("YYYY-MM-DD");
      url += `&FromDate=${pageState.fromDate}`;
    }
    if (pageState.toDate) {
      url += `&ToDate=${pageState.toDate}`;
    }
    ApiServices.getData(url)
      .then((res) => {
        setItemWiseExportData(res.data);
      })
      .catch((res) => {});
  };

  useEffect(() => {
    if (getExportData) {
      onDownload();
    }
  }, [getExportData]);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Sale Reports",
    sheet: "Sale Reports",
  });

  const FilerSaleWiseData = () => {
    pageState.top = 10;
    pageState.skip = 0;
    pageState.StateId = getStateData.Id;
    pageState.CityId = getCityData.Id;
    pageState.StoreId = getStoreData.Id;
    pageState.TerminalId = getTerminalData.Id;
    if (getDataChange == true) {
      pageState.fromDate = new DateObject(new Date(values[0]));
    } else {
      const today = new Date();
      pageState.fromDate = moment(today).format("YYYY-MM-DD");
    }

    pageState.toDate = new DateObject(new Date(values[1]));
    dispatch(getSaleWiseReports(getAccountId, pageState));
  };
  const FilerItemWiseData = () => {
    pageState.StateId = getStateData.Id;
    pageState.CityId = getCityData.Id;
    pageState.StoreId = getStoreData.Id;
    pageState.TerminalId = getTerminalData.Id;
    if (getDataChange == true) {
      pageState.fromDate = new DateObject(new Date(values[0]));
    } else {
      const today = new Date();
      pageState.fromDate = moment(today).format("YYYY-MM-DD");
    }
    pageState.toDate = new DateObject(new Date(values[1]));
    dispatch(getItemWiseReports(getAccountId, pageState));
  };
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (event) => {
    paymentFilter(event.target.id);
    setAnchorEl(null);
  };
  const paymentFilter = (getMenuItemId) => {
    pageState.PaymentMode = getMenuItemId;
    dispatch(getSaleWiseReports(getAccountId, pageState));
    setAnchorEl(null);
  };
  const handleClickItemMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseItemMenu = (event) => {
    ItemWisePaymentFilter(event.target.id);
    setAnchorEl(null);
  };
  const ItemWisePaymentFilter = (getMenuItemId) => {
    pageState.PaymentMode = getMenuItemId;
    dispatch(getItemWiseReports(getAccountId, pageState));
    setAnchorEl(null);
  };
  // For Modal
  const [open, setOpen] = React.useState(false);
  const [getDetailsData, setDetailsData] = useState([]);
  const [getDetailsByID, setDetailsByID] = useState([]);
  const handleOpen = (e) => {
    setDetailsByID(e);
    ApiServices.getData(`/Reports/orderdetail?OrderId=${e.OrderId}`)
      .then((res) => {
        setDetailsData(res.data.Data);
        setOpen(true);
      })
      .catch((res) => {});
  };

  const handleClose = () => setOpen(false);
  const handleSearchBillNumber = () => {
    setShowSearchInput(true);
  };
  const handleSearchClose = () => {
    setShowSearchInput(!showSearchInput);
    setSearchInput("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    dispatch(getItemWiseReports(getAccountId, pageState));
  };
  const handleSearchUserInput = (e) => {
    pageState.query = e.target.value;
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    dispatch(getItemWiseReports(getAccountId, pageState));
    if (e.target.value) {
      setHandleIcon(false);
    }
    setSearchInput(e.target.value);
  };

  const handleStateClear = (e) => {
    setApply(false);
    setStateData("");
    setCityData("");
    setStoreData("");
    setTerminalData("");

    dispatch(getCityListByAccount(getAccountId));
    dispatch(getAllStores(getAccountId, getCityData));

    dispatch(getAllTerminal(getTerminalData));
  };
  const handleCityClear = (e) => {
    setCityData("");
    setStoreData("");
    setTerminalData("");
    dispatch(getAllStores(getAccountId));
  };
  const handleStoreClear = () => {
    setStoreData("");
    setTerminalData("");
  };
  const handleTerminalClear = () => {
    setTerminalData("");
  };
  return (
    <div>
      <div>
        {(() => {
          if (getReports == 1) {
            return (
              <div>
                <FilterReports
                  country={country}
                  City={City}
                  State={State}
                  getCityByState={getCityByState}
                  getAllCityInput={getAllCityInput}
                  getState={getStateData}
                  getCity={getCityData}
                  getAccount={inputField.accountName}
                  stores={stores}
                  getAllStoresData={getAllStoresData}
                  getStore={getStoreData}
                  terminal={terminal}
                  getAllTerminalData={getAllTerminalData}
                  getTerminal={getTerminalData}
                  getValue={values}
                  setValue={setValues}
                  reportsArray={reportsArray}
                  getReports={inputField.reportsValue}
                  getAllReports={getAllReports}
                  handleDownloadExcel={handleDownloadExcel}
                  FilerSaleWiseData={FilerSaleWiseData}
                  getApply={getApply}
                  setDateValue={setDateValue}
                  handleStateClear={handleStateClear}
                  handleCityClear={handleCityClear}
                  handleStoreClear={handleStoreClear}
                  handleTerminalClear={handleTerminalClear}
                />
                <div className="hide">
                  <SalwWiseExcel
                    tableRef={tableRef}
                    getExportData={getExportData}
                  />
                </div>
              </div>
            );
          } else if (getReports == 2) {
            return (
              <div>
                <FilterReports
                  country={country}
                  City={City}
                  State={State}
                  getCityByState={getCityByState}
                  getAllCityInput={getAllCityInput}
                  getState={getStateData}
                  getCity={getCityData}
                  getAccount={inputField.accountName}
                  stores={stores}
                  getAllStoresData={getAllStoresData}
                  getStore={getStoreData}
                  terminal={terminal}
                  getAllTerminalData={getAllTerminalData}
                  getTerminal={getTerminalData}
                  getValue={values}
                  setValue={setValues}
                  reportsArray={reportsArray}
                  getReports={inputField.reportsValue}
                  getAllReports={getAllReports}
                  handleDownloadExcel={handleItemWiseDownloadExcel}
                  FilerSaleWiseData={FilerItemWiseData}
                  getApply={getApply}
                  setDateValue={setDateValue}
                  handleStateClear={handleStateClear}
                  handleCityClear={handleCityClear}
                  handleStoreClear={handleStoreClear}
                  handleTerminalClear={handleTerminalClear}
                />
                <div className="hide">
                  <ItemWiseExcel
                    tableRef={tableRef}
                    getItemWiseExportData={getItemWiseExportData}
                  />
                </div>
              </div>
            );
          } else {
            return null;
          }
        })()}
      </div>
      <div>
        {(() => {
          if (getReports == 1) {
            return (
              <SaleReports
                saleWiseData={saleWiseData}
                noFoundData={noFoundData}
                handleChangePage={handleChangePage}
                TotalItems={TotalItems}
                page={page}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                anchorEl={anchorEl}
                openMenu={openMenu}
                handleClickMenu={handleClickMenu}
                handleCloseMenu={handleCloseMenu}
                pageNumber={inputField.pageNumber}
                inputsHandler={inputsHandler}
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                saleWiseDetailsData={saleWiseDetailsData}
                getDetailsData={getDetailsData}
                getDetailsByID={getDetailsByID}
                getPageNumber={getPageNumber}
              />
            );
          } else if (getReports == 2) {
            return (
              <ItemWiseReports
                ItemWiseData={ItemWiseData}
                noFoundData={noFoundDataInItem}
                TotalItemsWise={TotalItemsWise}
                handleChangeRowsPerPage={handleItemChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                handleChangeItemWisePage={handleChangeItemWisePage}
                pageNumber={inputField.pageNumber}
                inputsHandler={inputsHandler}
                page={page}
                open={open}
                anchorEl={anchorEl}
                openMenu={openMenu}
                handleClickMenu={handleClickItemMenu}
                handleCloseMenu={handleCloseItemMenu}
                showSearchInput={showSearchInput}
                handleSearchBillNumber={handleSearchBillNumber}
                handleSearchClose={handleSearchClose}
                SearchInput={SearchInput}
                setSearchInput={setSearchInput}
                handleSearchUserInput={handleSearchUserInput}
                handleIcon={handleIcon}
                getPageNumber={getPageNumber}
                TotalItems={TotalItems}
              />
            );
          } else {
            return null;
          }
        })()}
      </div>
    </div>
  );
}
