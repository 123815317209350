
import apiService from "../../Services/ApiServices"
import {
  GET_SALE_WISE_REQUEST, GET_SALE_WISE_SUCCESS, GET_SALE_WISE_FAILURE
} from '../Types'
export const getSaleWiseReports = (AccountId, pageState) => {
  try {
    return (dispatch) => {
      dispatch(getSaleWiseRequest())
      let url = `/Reports/orders?accountId=${AccountId}&skip=${pageState.skip}&top=${pageState.top}&FromDate=${pageState.fromDate}`;
      if (pageState.StateId) {
        url += `&StateId=${pageState.StateId}`;
      }
      if (pageState.CityId) {
        url += `&CityId=${pageState.CityId}`;
      }
      if (pageState.StoreId) {
        url += `&StoreId=${pageState.StoreId}`;
      }
      if (pageState.TerminalId) {
        url += `&TerminalId=${pageState.TerminalId}`;
      }
      if(pageState.toDate){
        url += `&ToDate=${pageState.toDate}`
      }
      if (pageState.PaymentMode) {
        if (pageState.PaymentMode == "ALL") {
          url += `&PaymentMode`;
        }
        else {
          url += `&PaymentMode=${pageState.PaymentMode}`;
        }
      }

      apiService.getData(url)
        .then(res => {
          const saleWiseData = res.data
          if (res.data.Data == "") {
            dispatch(getSaleWiseFailure('err'))
          }
          else {
            dispatch(getSaleWiseSuccess(saleWiseData))
          }
        }).catch(err => { dispatch(getSaleWiseFailure(err)) });
    }
  } catch (err) {

  }
};
export const getSaleWiseRequest = () => {
  return {
    type: GET_SALE_WISE_REQUEST
  }
}

export const getSaleWiseSuccess = saleWiseData => {
  return {
    type: GET_SALE_WISE_SUCCESS,
    payload: saleWiseData
  }
}

export const getSaleWiseFailure = error => {
  return {
    type: GET_SALE_WISE_FAILURE,
    payload: error
  }
}  
