import apiService from "../../Services/ApiServices";
import {
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_FAILURE,
  GET_ACTIVE_ACCOUNT_SUCCESS,
  GET_INACTIVE_ACCOUNT_SUCCESS,
  GET_ACCOUNTDETAIL_REQUEST,
  GET_ACCOUNTDETAIL_SUCCESS,
  GET_ACCOUNTDETAIL_FAILURE,
  SAVE_ACCOUNT_REQUEST,
  SAVE_ACCOUNT_SUCCESS,
  SAVE_ACCOUNT_FAILURE,
  GET_IMAGE_REQUEST,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_FAILURE,
} from "../Types";

export const saveAccounts = (formData) => {
  try {
    return (dispatch) => {
      dispatch(saveAccountRequest());
      let url = `/Account/create`;
      apiService
        .saveData(url, formData)
        .then((res) => {
          const result = res.status;
          if (result == 200) {
            dispatch(saveAccountSuccess(result));
          } else {
            dispatch(saveAccountFailure(res.response.status));
          }
        })
        .catch((err) => {
          dispatch(saveAccountFailure(err));
        });
    };
  } catch (err) {}
};

export const updateAccounts = (formData, accountId) => {
  try {
    return (dispatch) => {
      dispatch(saveAccountRequest());
      let url = `/Account/update/${accountId}`;
      apiService
        .saveData(url, formData)
        .then((res) => {
          const result = res.status;
          if (result == 200) {
            dispatch(saveAccountSuccess(result));
          } else {
            dispatch(saveAccountFailure(res.response.status));
          }
        })
        .catch((err) => {
          dispatch(saveAccountFailure(err));
        });
    };
  } catch (err) {}
};
export const getAccountDetails = (accountId) => {
  try {
    return (dispatch) => {
      dispatch(getAccountDetailRequest());
      let url = `/Account/${accountId}`;
      apiService
        .getData(url)
        .then((res) => {
          const accountsDetail = res.data;
          if (res.data) {
            dispatch(getAccountDetailSuccess(accountsDetail));
          } else {
            dispatch(getAccountDetailFailure("err"));
          }
        })
        .catch((err) => {
          dispatch(getAccountDetailFailure(err));
        });
    };
  } catch (err) {}
};

export const getDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getAccountRequest());
      let url = `/Account/all?skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId != "") {
        url += `&accountId=${pageState.accountId}`;
      }
      apiService
        .getData(url)
        .then((res) => {
          const accounts = res.data;
          if (res.data.Data == "") {
            dispatch(getAccountFailure("err"));
          } else {
            dispatch(getAccountSuccess(accounts));
          }
        })
        .catch((err) => {
          dispatch(getAccountFailure(err));
        });
    };
  } catch (err) {}
};

export const getInactiveDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getAccountRequest());
      let url = `/Account/all?deleted=${true}&skip=${pageState.skip}&top=${
        pageState.top
      }`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      apiService
        .getData(url)
        .then((res) => {
          const accounts = res.data;
          if (res.data.Data == "") {
            dispatch(getAccountFailure("err"));
          } else {
            dispatch(getInactiveAccountSuccess(accounts));
          }
        })
        .catch((err) => {
          dispatch(getAccountFailure(err));
        });
    };
  } catch (err) {}
};

export const getLogoImageData = (accountId) => {
  try {
    return (dispatch) => {
      dispatch(getImageRequest());
      let url = `/Account/logo/${accountId}`;
      apiService
        .getData(url)
        .then((res) => {
          const imageData = res.data;
          if (res.data) {
            dispatch(getImageSuccess(imageData));
          } else {
            dispatch(getImageFailure("err"));
          }
        })
        .catch((err) => {
          dispatch(getImageFailure(err));
        });
    };
  } catch (err) {}
};

export const getAccountRequest = () => {
  return {
    type: GET_ACCOUNT_REQUEST,
  };
};

export const getAccountSuccess = (accounts) => {
  return {
    type: GET_ACTIVE_ACCOUNT_SUCCESS,
    payload: accounts,
  };
};

export const getInactiveAccountSuccess = (accounts) => {
  return {
    type: GET_INACTIVE_ACCOUNT_SUCCESS,
    payload: accounts,
  };
};

export const getAccountFailure = (error) => {
  return {
    type: GET_ACCOUNT_FAILURE,
    payload: error,
  };
};

//Get account Details
export const getAccountDetailRequest = () => {
  return {
    type: GET_ACCOUNTDETAIL_REQUEST,
  };
};

export const getAccountDetailSuccess = (accounts) => {
  return {
    type: GET_ACCOUNTDETAIL_SUCCESS,
    payload: accounts,
  };
};

export const getAccountDetailFailure = (error) => {
  return {
    type: GET_ACCOUNTDETAIL_FAILURE,
    payload: error,
  };
};

//Save account Details
export const saveAccountRequest = () => {
  return {
    type: SAVE_ACCOUNT_REQUEST,
  };
};

export const saveAccountSuccess = (result) => {
  return {
    type: SAVE_ACCOUNT_SUCCESS,
    payload: result,
  };
};

export const saveAccountFailure = (error) => {
  return {
    type: SAVE_ACCOUNT_FAILURE,
    payload: error,
  };
};
//Get logo image
export const getImageRequest = () => {
  return {
    type: GET_IMAGE_REQUEST,
  };
};

export const getImageSuccess = (result) => {
  return {
    type: GET_IMAGE_SUCCESS,
    payload: result,
  };
};

export const getImageFailure = (error) => {
  return {
    type: GET_IMAGE_FAILURE,
    payload: error,
  };
};
