import React, { useState, useEffect } from "react";
import ApiServices from "../../Services/ApiServices";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import {
  FormControl,
  Input,
  InputAdornment,
  Typography,
  InputLabel,
  IconButton,
  Link,
  Card,
  Button,
  Grid,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import imgTwo from "../../assets/Images/Animation 1.gif";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import "../../assets/scss/login.css";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function ConfirmPassword() {
  const navigate = useNavigate();

  const ValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is mendatory")
      .min(3, "Password must be at 3 char long"),
    conformPassword: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      conformPassword: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      ApiServices.saveData(`/Auth/createNewPassword`, {
        userId: getUserId,
        currentPassword: values.CurrentPassword,
        newPassword: values.password,
      })
        .then((res) => {
          if (res.status == 200) {
            toast("Reset password successfully");
            setTimeout(() => {
              navigate(`/`);
            }, 1000);
          }
        })
        .catch((res) => {
          if (res.error.status == 404) {
            toast(res.error.data);
          } else if (res.error.status == 401) {
            toast(res.error.data);
          } else if (res.error.status == 403) {
            toast(res.error.data);
          }
        });
    },
  });
  const { handleSubmit, handleChange, handleBlur, errors, touched, values } =
    formik;

  const [hidden, setHidden] = useState(true);
  const [confrimPass, setConfirmPass] = useState(true);

  const toggleShow = (e) => {
    setHidden(!hidden);
  };
  const confirmToggleShow = (e) => {
    setConfirmPass(!confrimPass);
  };
  const [getUserId, setUserId] = useState();

  useEffect(() => {
    const userLoginDetails = JSON.parse(
      localStorage.getItem("userLoginDetails")
    );
    setUserId(userLoginDetails.Id);
  }, []);

  function onSubmit(data) {}
  return (
    <>
      <ToastContainer autoClose={1000} />
      <Box sx={{ width: "100%", height: "100vh", background: "white" }}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          height={"90vh"}
        >
          <Grid item>
            <img src={imgTwo} alt="login gif" />
          </Grid>
          <Grid item>
            <Card className="card" sx={{ width: 300 }}>
              <CardContent sx={{ padding: "0" }}>
                <Typography
                  component={"h2"}
                  variant="body1"
                  className="subTagtitle"
                >
                  Reset Password
                </Typography>
              </CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box mt={4}>
                  <FormControl variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-password"
                      sx={{ fontWeight: "600" }}
                    >
                      New Password
                    </InputLabel>
                    <Input
                      fullWidth
                      id="password"
                      name="password"
                      variant="standard"
                      type={hidden ? "password" : "text"}
                      placeholder="Enter New password"
                      //   {...register("password")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      InputLabelProps={{
                        sx: {
                          fontWeight: "600",
                        },
                      }}
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleShow}
                            onMouseDown={toggleShow}
                          >
                            {hidden ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {errors.password && touched.password ? (
                    <Box className="errors">{errors.password}</Box>
                  ) : null}
                </Box>
                <Box mt={3}>
                  <FormControl variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-password"
                      sx={{ fontWeight: "600" }}
                    >
                      Confirm Password
                    </InputLabel>
                    <Input
                      fullWidth
                      id="confirmPwd"
                      name="conformPassword"
                      variant="standard"
                      type={confrimPass ? "password" : "text"}
                      placeholder="Enter confirm password"
                      //   {...register("confirmPwd")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.conformPassword}
                      InputLabelProps={{
                        sx: {
                          fontWeight: "600",
                        },
                      }}
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={confirmToggleShow}
                            onMouseDown={confirmToggleShow}
                          >
                            {confrimPass ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {errors.conformPassword && touched.conformPassword ? (
                    <Box className="errors">{errors.conformPassword}</Box>
                  ) : null}{" "}
                </Box>

                <CardActions
                  sx={{ marginTop: "36px", padding: "0", marginBottom: "40px" }}
                >
                  <Grid container justifyContent={"space-between"}>
                    <Grid item md={12} mt={5}>
                      <Button
                        variant="contained"
                        size="small"
                        className="btn-orange"
                        style={{ width: "100%" }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </form>
            </Card>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            className="copyright"
            px={5}
          >
            <Grid item pl={2}>
              <p>
                2023 <span>Quinta</span> Systems Pvt. Ltd.
              </p>
            </Grid>
            <Grid item pr={3}>
              <p>
                <Link underline="none">Terms & Conditions</Link>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
