import React, { useState, useEffect } from "react";
import {
  Menu,
  Backdrop,
  CircularProgress,
  TablePagination,
  Modal,
  MenuItem,
  useTheme,
  useMediaQuery,
  styled,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Avatar,
  Grid,
  Collapse,
  Tab,
  Tabs,
  Box,
  Card,
  Button,
  InputAdornment,
  TextField,
  IconButton,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddAltRounded from "@mui/icons-material/PersonAddAltRounded";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import ApiServices from "../../Services/ApiServices";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import img from "../../assets/Images/arrow-up.png";
import img1 from "../../assets/Images/down-arrow.png";
import { userRoles, regexConstant } from "../../shared/constants";
import AssignLicense from "../../Component/LicenseDialog/AssignLicense";
import UnassignLicense from "../../Component/LicenseDialog/UnassignLicense";
import DetachLicense from "../../Component/LicenseDialog/DetachMac";

// Using for Dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  customTable: {
    "& .MuiTableCell-root": {
      padding: "9px", // <-- arbitrary value
    },
  },
  dialogPaper: {
    maxWidth: "900px !important",
    "& .MuiBackdrop-root": {
      opacity: "0.2",
    },
    "& .MuiDialog-backdrop": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },

  tableCell: {
    "&.MuiTableCell-root": {
      border: "none!important",
      padding: "10px",
    },
  },
});
const pageState = {
  skip: 0,
  top: 10,
  skipRow: 0,
  topRow: 10,
  isDeleted: false,
  query: "",
  accountId: "",
};
// ------------row start--------------------

function Row(props) {
  const classes = useStyles();
  const { row, index } = props;
  const [open, setOpen] = React.useState(false);
  const [noFoundData, setnoFoundData] = useState(true);

  const [inputField, setInputField] = useState({
    accountName: "",
    terminalName: "",
    ActivationDate: "",
    ExpiryDate: "",
  });
  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [getAccountId, setAccountId] = useState();
  const [getListLicense, setListLicense] = useState([]);
  const getLicenseList = (e) => {
    setOpen(!open);
    getLicenseByAccountId(e.target.id);
    setAccountId(e.target.id);
  };
  const closeLicenseList = (e) => {
    setOpen(!open);
    setAccountId(e.target.id);
  };
  const [page, setPage] = React.useState(0);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalItemData, setTotalItemData] = useState(1);

  const handleChangePageChild = (event, newPage) => {
    if (newPage > page) {
      pageState.skipRow = parseInt(pageState.skipRow + pageState.topRow);
      setCurrentPageState({
        skipRow: pageState.skipRow + pageState.topRow,
        topRow: pageState.topRow,
      });
    } else {
      pageState.skipRow = parseInt(pageState.skipRow - pageState.topRow);
      setCurrentPageState({
        skipRow: pageState.skipRow - pageState.topRow,
        topRow: pageState.topRow,
      });
    }
    setPage(newPage);
    getLicenseByAccountId(getIdForRowPerPage, pageState);
  };
  const [getIdForRowPerPage, setIdForRowPerPage] = useState();
  const handleChangeRowsPerPageChild = (event) => {
    pageState.topRow = event.target.value;
    pageState.skipRow = 0;
    setRowsPerPage(parseInt(pageState.topRow));
    setPage(0);
    getLicenseByAccountId(getIdForRowPerPage, pageState);
  };
  const getLicenseByAccountId = (id) => {
    setIdForRowPerPage(id);
    ApiServices.getData(
      `/License/ByAccountId/${id}?skip=${pageState.skipRow}&top=${pageState.topRow}`
    )
      .then((res) => {
        if (res.data.Data == "") {
          setnoFoundData(false);
        } else {
          setnoFoundData(true);
          setTotalItemData(res.data.TotalItems ? res.data.TotalItems : 1);
          setListLicense(res.data.Data);
        }
      })
      .catch((res) => {});
  };
  // Menu bar
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [MenuDataId, setMenuDataId] = useState();
  const OpenMenu = Boolean(anchorEl);
  const handleClick = (event, historyRow) => {
    setAnchorEl(event.currentTarget);
    setMenuDataId(historyRow);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  const [getDialogId, setDialogId] = React.useState(false);
  const [getLicenseId, setLicenseId] = useState("");
  const [getLicenseObj, setLicenseObj] = useState({});
  const handleClickOpenDialog = (e) => {
    setLicenseObj(e);
    setDialogId(e.AccountId);
    setLicenseId(e.Id);
    getAllAccount(e.Id);
    inputField.storeName = "";
    inputField.terminalName = "";
    setOpenDialog(true);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const [storeData, setStoreData] = useState([]);
  const getAllAccountId = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      storeId: e.target.value,
      storeName: e.target.value,
    }));
    getAllTerminal(e.target.value);
  };
  const getAllAccount = () => {
    ApiServices.getData(`/Store/all?accountId=${getAccountId}`)
      .then((res) => {
        setStoreData(res.data.Data);
      })
      .catch((res) => {});
  };
  const getAllTerminalId = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      terminalId: e.target.value,
      terminalName: e.target.value,
    }));
  };
  const [terminalData, setTerminalData] = useState([]);

  const getAllTerminal = (getStoreId) => {
    ApiServices.getData(`/Terminal/all?storeId=${getStoreId}`)
      .then((res) => {
        setTerminalData(res.data.Data);
      })
      .catch((res) => {});
  };
  const [values, setValues] = useState(new Date());
  const [toValues, toSetValues] = useState(new Date());
  const saveLicenseDetails = () => {
    // var FromDate = new Date(values).toISOString();
    // var ToDate = new Date(toValues).toISOString();
    if (
      getLicenseObj.ActivationDate == null &&
      getLicenseObj.ExpiryDate == null
    ) {
      var currentDate = new Date();
      var expiryDate = new Date();
      expiryDate.setFullYear(currentDate.getFullYear() + 1);
      ApiServices.saveData(`/License/assign/terminal`, {
        terminalId: inputField.terminalId,
        licenseId: getLicenseId,
        activationDate: currentDate,
        expiryDate: expiryDate,
      })
        .then((res) => {
          if (res.status == 200) {
            setTimeout(() => {
              toast("License assign successfully");
              setOpenDialog(false);
              getLicenseByAccountId(getAccountId);
            }, 1000);
          }
        })
        .catch((res) => {
          if (res.response.status == 404) {
            toast(res.response.data);
          } else if (res.response.status == 401) {
            toast(res.response.data);
          } else if (res.response.status == 403) {
            toast(res.response.data);
          } else if (res.response.status == 400) {
            toast(res.response.data);
          } else if (res.response.status == undefined) {
          } else {
            toast("Something went wrong. Please try again later. ");
          }
        });
    } else {
      ApiServices.saveData(`/License/assign/terminal`, {
        terminalId: inputField.terminalId,
        licenseId: getLicenseId,
        activationDate: getLicenseObj.ActivationDate,
        expiryDate: getLicenseObj.ExpiryDate,
      })
        .then((res) => {
          if (res.status == 200) {
            setTimeout(() => {
              toast("License assign successfully");
              setOpenDialog(false);
              getLicenseByAccountId(getAccountId);
            }, 1000);
          }
        })
        .catch((res) => {
          if (res.response.status == 404) {
            toast(res.response.data);
          } else if (res.response.status == 401) {
            toast(res.response.data);
          } else if (res.response.status == 403) {
            toast(res.response.data);
          } else if (res.response.status == 400) {
            toast(res.response.data);
          } else if (res.response.status == undefined) {
          } else {
            toast("Something went wrong. Please try again later. ");
          }
        });
    }
  };
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    let formIsValid = true;
    if (!values.storeName) {
      errors.storeName = "Store Name is required!";
      formIsValid = false;
    }
    if (!values.terminalName) {
      errors.terminalName = "Terminal Name is required!";
      formIsValid = false;
    }
    setFormErrors(errors);
    return formIsValid;
  };
  const submitForm = (e) => {
    e.preventDefault();
    if (validate(inputField)) {
      setIsSubmit(true);
      saveLicenseDetails();
      setAnchorEl(null);
    }
  };
  const [openRemoveLicense, setOpenRemoveLicense] = React.useState(false);

  const handleOpenModalLicense = (e) => {
    setOpenRemoveLicense(true);
    setLicenseId(e.target.value);
    setModalDataId(e.target.id);
  };
  const handleClose = () => {
    setOpenRemoveLicense(false);
  };
  const [openDetachLicense, setOpenDetachLicense] = React.useState(false);

  const handleOpenDetachLicense = (e) => {
    setOpenDetachLicense(true);
    setLicenseId(e.Id);
    setModalDataId(e.Id);
  };
  const handleDetachLicenseClose = () => {
    setOpenDetachLicense(false);
    setAnchorEl(null);
  };
  const [ModalDataId, setModalDataId] = useState();
  const removeLicense = (obj) => {
    ApiServices.saveData(`/License/remove/terminal`, {
      terminalId: obj.Terminal?.Id,
      licenseId: obj.Id,
    })
      .then((res) => {
        setOpenRemoveLicense(false);
        getLicenseByAccountId(getAccountId);
        setAnchorEl(null);
      })
      .catch((e) => {
        return e;
      });
  };
  const removeMac = (obj) => {
    ApiServices.saveData(`/License/remove/mac`, {
      terminalId: obj.Terminal?.Id,
      licenseId: obj.Id,
    })
      .then((res) => {
        setOpenRemoveLicense(false);
        getLicenseByAccountId(getAccountId);
        setAnchorEl(null);
      })
      .catch((e) => {
        return e;
      });
  };
  const handleDobChange = (event) => {
    toSetValues(event.target.value);
  };
  const handleActivationChange = (event) => {
    setValues(event.target.value);
  };

  const todayDate = useState(new Date().toISOString().slice(0, 16));
  return (
    <React.Fragment>
      <ToastContainer autoClose={1000} />
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell className={classes.tableCell} component="th" scope="row">
          {index + 1 + pageState.skip}
        </TableCell>
        <TableCell className={classes.tableCell} align="center">
          {row.AccountName}
        </TableCell>
        <TableCell className={classes.tableCell} align="center">
          {row.LicenseCount}
        </TableCell>
        <TableCell className={classes.tableCell} align="right">
          <div>
            <div className="arrow-button">
              <Button
                id={row.AccountId}
                size="small"
                onClick={open ? closeLicenseList : getLicenseList}
                style={{ width: "22%", height: "20px", zIndex: "1111" }}
              ></Button>
              <div className="arrow-img">
                <img src={open ? img1 : img} />
              </div>
            </div>
          </div>
        </TableCell>
      </TableRow>
      <TableRow style={{ paddingBottom: 0, paddingTop: 0 }}>
        <TableCell
          className={classes.tableCell}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table aria-label="purchases">
                <TableHead>
                  <TableRow size="small" className="font-16">
                    <TableCell className={classes.tableCell}>S No</TableCell>
                    <TableCell className={classes.tableCell}>
                      License Key
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      MAC Address
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Store Name
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Terminal Name
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Generated On
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Activation Date
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Expiry Date
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      APK Version
                    </TableCell>
                    <TableCell className={classes.tableCell}>Status</TableCell>
                    <TableCell className={classes.tableCell}></TableCell>
                  </TableRow>
                </TableHead>
                {noFoundData ? (
                  <TableBody>
                    {getListLicense?.map((historyRow, index) => (
                      <TableRow
                        key={historyRow.date}
                        classes={{ paper: classes.MuiTableCellBody }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          component="th"
                          scope="row"
                        >
                          {index + 1 + pageState.skipRow}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {historyRow.LicenseKey ? (
                            historyRow.LicenseKey
                          ) : (
                            <div>-</div>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {historyRow.MacAddress ? (
                            historyRow.MacAddress
                          ) : (
                            <div>-</div>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {historyRow.Terminal?.Store.StoreName ? (
                            historyRow.Terminal?.Store.StoreName
                          ) : (
                            <div>-</div>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {historyRow.Terminal?.TerminalName ? (
                            historyRow.Terminal?.TerminalName
                          ) : (
                            <div>-</div>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {historyRow.GeneratedOn ? (
                            moment(historyRow.GeneratedOn).format("DD-MM-YYYY")
                          ) : (
                            <div>-</div>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {historyRow.ActivationDate ? (
                            moment(historyRow.ActivationDate).format(
                              "DD-MM-YYYY"
                            )
                          ) : (
                            <div>-</div>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {historyRow.ExpiryDate ? (
                            moment(historyRow.ExpiryDate).format("DD-MM-YYYY")
                          ) : (
                            <div>-</div>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {historyRow.ApkVersion ? (
                            historyRow.ApkVersion
                          ) : (
                            <div>-</div>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {historyRow.Status == "In Use" ? (
                            <div className="text-green">
                              {historyRow.Status ? (
                                historyRow.Status
                              ) : (
                                <div>-</div>
                              )}
                            </div>
                          ) : (
                            <div>
                              {historyRow.Status ? (
                                historyRow.Status
                              ) : (
                                <div>-</div>
                              )}
                            </div>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <div>
                            <Button
                              // id={historyRow.Id}
                              onClick={(e) => handleClick(e, historyRow)}
                              aria-controls={
                                OpenMenu ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={OpenMenu ? "true" : undefined}
                            >
                              <MoreVertIcon sx={{ color: "#9747FF" }} />
                            </Button>

                            <Menu
                              // id={ModalDataId}
                              anchorEl={anchorEl}
                              open={OpenMenu}
                              elevation={1}
                              borderRadius="50%"
                              style={{ borderRadius: 20 }}
                              sx={{
                                "&:hover": { backgroundColor: "transparent" },
                              }}
                              onClose={handleCloseMenu}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                className={classes.menuItem}
                                style={{ color: "#000" }}
                              >
                                {MenuDataId?.Status == "Available" ? (
                                  <AssignLicense
                                    handleClickOpen={(e) =>
                                      handleClickOpenDialog(MenuDataId)
                                    }
                                    handleCloseDialog={handleCloseDialog}
                                    open={openDialog}
                                    submitForm={submitForm}
                                    storeName={inputField.storeName}
                                    getAllAccountId={getAllAccountId}
                                    storeData={storeData}
                                    storeError={formErrors.storeName}
                                    terminalName={inputField.terminalName}
                                    getAllTerminalId={getAllTerminalId}
                                    terminalData={terminalData}
                                    terminalNameError={formErrors.terminalName}
                                  />
                                ) : (
                                  <UnassignLicense
                                    handleOpenModalLicense={(e) =>
                                      handleOpenModalLicense(MenuDataId)
                                    }
                                    handleClose={handleClose}
                                    openRemoveLicense={openRemoveLicense}
                                    removeLicense={(e) =>
                                      removeLicense(MenuDataId)
                                    }
                                  />
                                )}
                              </MenuItem>

                              {MenuDataId?.MacAddress ? (
                                <MenuItem
                                  style={{ color: "#000" }}
                                  className={classes.menuItem}
                                >
                                  <DetachLicense
                                    handleOpenDetachLicense={(e) =>
                                      handleOpenDetachLicense(MenuDataId)
                                    }
                                    handleDetachLicenseClose={
                                      handleDetachLicenseClose
                                    }
                                    openDetachLicense={openDetachLicense}
                                    removeLicense={(e) => removeMac(MenuDataId)}
                                  />
                                </MenuItem>
                              ) : null}
                            </Menu>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <div className="text-align-center font-18 font-bold py-15">
                    No data found...
                  </div>
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalItemData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePageChild}
                onRowsPerPageChange={handleChangeRowsPerPageChild}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function BasicTable() {
  // ----------constants-------------------
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [getUserAccount, setUserAccount] = useState();
  const [getUserRole, setUserRole] = useState();
  // for data in main table---------------
  const [getData, setData] = useState([]);
  const [totalItemData, setTotalItemData] = useState(1);
  const [noFoundData, setnoFoundData] = useState(true);
  const [page, setPage] = useState(0);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [inputValue, setInputValue] = useState("");
  // Input Field handler
  const [search, setSearch] = useState("");
  const [handleIcon, setIcon] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    pageState.accountId = userDetail.accountId;
    setUserRole(userDetail.userRole);
  });

  useEffect(() => {
    pageState.query = "";
    getDataByAccount(pageState);
  }, []);

  // --------pegination----------
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    getDataByAccount(pageState);
  };
  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    getDataByAccount(pageState);
  };

  // ------search evnt-----------
  const SearchData = () => {
    setOpen(!open);
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    pageState.query = inputValue;
    if (
      pageState.query == "" ||
      !regexConstant.searchSpace.test(pageState.query)
    ) {
      toast("Please Enter the search key");
    }
    if (pageState.query) {
      getDataByAccount(pageState);
    }
  };

  const resetInputField = (e) => {
    setSearch("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setInputValue("");
    getDataByAccount(pageState);
  };
  const handleUserInput = (e) => {
    if (e.target.value) {
      setIcon(false);
    }
    if (e.target.value === "") {
      setIcon(true);
    }

    setInputValue(e.target.value);
  };
  // -----------------get license data-----------------
  const getDataByAccount = (pageState) => {
    setIsLoading(true);
    let url = `/License/LicensecountByAccount?skip=${pageState.skip}&top=${pageState.top}`;
    if (pageState.accountId != "") {
      url += `&AccountId=${pageState.accountId}`;
    }
    if (pageState.query != "") {
      url += `&query=${pageState.query}`;
    }
    ApiServices.getData(url)
      .then((res) => {
        if (res.data.Data == "") {
          // setIsLoading(true);
          setnoFoundData(false);
        } else {
          setData(res.data.Data);
          setnoFoundData(true);
          setTotalItemData(res.data.TotalItems ? res.data.TotalItems : 1);
        }
        setIsLoading(false);
      })
      .catch((res) => {});
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {/* -------------Header section------------------ */}
      <div>
        <div className="justify-content-space">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer }}
            open={isLoading}
            className="backdrop-circle"
          >
            <CircularProgress style={{ color: "#ea7626" }} />
          </Backdrop>
          <div className="poppins-bold">Manage License</div>
          <div>
            <div className="text-align-end showMobile my-8">
              <Link to="/app/NewLicense" className="textDecoration">
                <Button>
                  <Avatar
                    className="border-orange"
                    style={{ backgroundColor: "white" }}
                  >
                    <PersonAddAltRounded className="text-orange" />
                  </Avatar>
                </Button>
              </Link>
            </div>
            <div className="showINWindow">
              {getUserRole == 1 ? (
                <div>
                  <Link to="/app/NewLicense" className="textDecoration">
                    <Button
                      variant="outlined"
                      size="small"
                      className="border-radius-20 roboto"
                      style={{
                        boxShadow: 3,
                        borderRadius: "15px",
                        background: "#fff",
                        color: "#8B8D9D",
                        border: "none",
                        fontWeight: "100 !important",
                        padding: "5px 16px",
                      }}
                    >
                      <AddIcon className="mx-5" sx={{ color: "#9747FF" }} />
                      CREATE LICENSE
                    </Button>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={5}>
            <div>
              <div className="pt-10 search-section">
                <div>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={11}>
                      <TextField
                        fullWidth
                        id="userName"
                        size="small"
                        type="Text"
                        // onBlur={e => setSearch(e.target.value)}
                        value={inputValue}
                        onChange={handleUserInput}
                        classes={{ root: classes.customTextField }}
                        placeholder="Search Here"
                        sx={{
                          boxShadow: 5,
                          borderRadius: "10px",
                          background: "white",
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none !important",
                            },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="set-icon-right"
                              position="start"
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={resetInputField}
                                color="warning"
                              >
                                {handleIcon ? <SearchIcon /> : <CancelIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            SearchData();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                      <div className="mt-5">
                        <Button
                          variant="contained"
                          size="small"
                          className="border-radius-20 btn-orange w-100"
                          onClick={SearchData}
                        >
                          Search
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* -------------Table section------------------ */}

      <TableContainer component={Paper} className="mt-10 border-radius-20">
        <Table
          aria-label="collapsible table"
          classes={{ root: classes.customTable }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Sr No</TableCell>
              <TableCell className={classes.tableCell} align="center">
                Account Name
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Total Licenses
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                View More
              </TableCell>
            </TableRow>
          </TableHead>
          {noFoundData ? (
            <TableBody>
              {getData?.map((row, index) => (
                <Row
                  key={row.name}
                  row={row}
                  index={index}
                  setOpen={setOpen}
                  open={open}
                />
              ))}
            </TableBody>
          ) : (
            <div className="text-align-center font-18 font-bold py-15">
              No data found...
            </div>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalItemData}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* ------------bottom card----------- */}
      <Card
        style={{ width: "100%", padding: "10px" }}
        className="mt-10 border-radius-20"
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className="text-align-center"
        >
          <Grid item xs={12} sm={12} md={2}>
            <div>
              Total Licenses: <span className="text-orange">4</span>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <div>
              Total Active Licenses: <span className="text-orange">2</span>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div>
              Total Inactive Licenses: <span className="text-orange">5</span>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
