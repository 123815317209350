import {
  GET_TERMINAL_REQUEST,
  GET_ACTIVE_TERMINAL_SUCCESS,
  GET_INACTIVE_TERMINAL_SUCCESS,
  GET_TERMINAL_FAILURE,
  GET_TERMINALDETAILS_REQUEST,
  GET_TERMINALDETAILS_SUCCESS,
  GET_TERMINALDETAILS_FAILURE,
  SAVE_TERMINAL_REQUEST,
  SAVE_TERMINAL_SUCCESS,
  SAVE_TERMINAL_FAILURE,
  GET_TERMINAL_LICENSE_REQUEST,
} from "../Types";

const initialState = {
  loading: false,
  noFoundData: false,
  terminal: {},
  error: "",
  isAccountActive: true,
  TerminalDetails: {},
  result: "",
  imageData: {},
  getLicensValue: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TERMINAL_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
      };
    case GET_ACTIVE_TERMINAL_SUCCESS:
      return {
        loading: false,
        terminal: action.payload,
        noFoundData: false,
        error: "",
        isAccountActive: true,
      };
    case GET_INACTIVE_TERMINAL_SUCCESS:
      return {
        loading: false,
        terminal: action.payload,
        noFoundData: false,
        error: "",
        isAccountActive: false,
      };
    case GET_TERMINAL_LICENSE_REQUEST:
      return {
        loading: false,
        terminal: action.payload,
        noFoundData: false,
        error: "",
        isAccountActive: false,
        getLicensValue: true,
      };
    case GET_TERMINAL_FAILURE:
      return {
        loading: false,
        noFoundData: true,
        terminal: {},
        error: action.payload,
      };
    case GET_TERMINALDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
      };
    case GET_TERMINALDETAILS_SUCCESS:
      return {
        loading: false,
        TerminalDetails: action.payload,
        noFoundData: false,
        error: "",
      };
    case GET_TERMINALDETAILS_FAILURE:
      return {
        loading: false,
        noFoundData: true,
        TerminalDetails: {},
        error: action.payload,
      };
    case SAVE_TERMINAL_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
        error: "",
      };
    case SAVE_TERMINAL_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        noFoundData: false,
        error: "",
      };
    case SAVE_TERMINAL_FAILURE:
      return {
        loading: false,
        noFoundData: true,
        result: action.payload,
        error: action.payload.response,
      };

    default:
      return state;
  }
};

export default reducer;
