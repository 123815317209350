import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Autocomplete,
  IconButton,
  Card,
  TextField,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box
} from "@mui/material";
import imgOne from "../../assets/Images/Apples.webp";
import imgTwo from "../../assets/Images/healthy-vegetables.avif";
import TuneIcon from "@mui/icons-material/Tune";
import ApiServices from "../../Services/ApiServices";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";


const useStyles = makeStyles((theme) => ({
  table: {
    "& tbody>.MuiTableRow-root:hover": {
      background: "#9747FF",
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      fontWeight: "700",
    },
    "&:hover .MuiTableCell-root, &:hover .material-icons-outlined": {
      color: "#fff !important",
    },
    "&:hover .bg-ligtGrey": {
      background: "rgba(192,192,192,0.3); !important",
    },
  },
  root: {
    "& .MuiFilledInput-root": {
      color: "#fff",
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
}));


export default function BasicTable(props) {
  const classes = useStyles();
  const [getMapData, setMapData] = useState([]);
  const [getCompletedTask, setCompletedTask] = useState("");
  const [getDataDetails, setDataDetails] = useState(props.mappingData);
  // const [getDraggableObject, setDraggableObject] = useState(props.getDraggableObject);
  const [addedBy, setAddedBy] = React.useState(null);
  const [getMapList, setMapList] = useState("");
  // console.log("getMapData",getMapData);

  useEffect(() => {
    getAllMappedList();
  }, []);

  const getAllMappedList = () => {
    ApiServices.getData(`/AccountProduct/allMapped`)
      .then((res) => {
        console.log("res",res.data.Data)
        const arr = res.data.Data;
        // arr.map((object) => {
        //   object.mapItems = "";
        // });

        setMapData(arr);
      })
      .catch((res) => {});
  };
  const onDragOver = (e) => {
    e.preventDefault();
  };
  const onDrop = (event, index) => {
    const updateGetDataDetails = getDataDetails;
    const obj = updateGetDataDetails[index];
    obj.mapItems = props.getDraggableObject.ItemId + " " + props.getDraggableObject.label;
    setDataDetails(updateGetDataDetails);
    setMapData(updateGetDataDetails);
    props.setDraggableObject(updateGetDataDetails);
    handleAddedByChange(obj);
  };

  const handleAddedByChange = (event, newValue, updateGetDataDetails) => {
    if (newValue) {
      setAddedBy(newValue);
    } else {
      setAddedBy(props.getDraggableObject);
    }
    postDataMap();
  };
  const postDataMap = () => {
    ApiServices.saveData(`/AccountProduct/map`, {
      accountId: props.getAccountValueId,
      masterProductId: 0,
      accountProductId: addedBy.id,
      aiProductId: addedBy.label,
    })
      .then((res) => {})
      .catch((res) => {});
  };

  

  return (
    <Grid container spacing={1} sx={{ marginTop: "-10px" }}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className="border-radius-8 width-100">
          <Table
            aria-label="simple table"
            size="small"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell className="text-offGrey text-center-imp font-14">
                  <Box className="flexClass">
                    <Box>Categories</Box>
                    <Box className="mx-5">
                      <IconButton aria-label="TuneIcon" className="p-0">
                        <TuneIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell className="text-offGrey  font-14">
                  Item Image
                </TableCell>
                <TableCell className="text-offGrey text-center-imp font-14">
                  <Box className="flexClass">
                    <Box>Item Name</Box>
                    <Box className="mx-5">
                      <IconButton aria-label="TuneIcon" className="p-0">
                        <TuneIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell className="text-offGrey text-center-imp font-14">
                  GAI Item Id
                </TableCell>
                <TableCell className="text-offOrange text-center-imp font-14">
                  Mapped Item
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getDataDetails?.map((row, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell className="text-center-imp">
                    {row.Category}
                  </TableCell>
                  <TableCell>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={6} className="zoom">
                        <Box>
                          <img
                            src={row.ItemImage}
                            className="w-100 border-radius-8"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} className="hide">
                        <Box>
                          <img
                            src={row.ItemImage}
                            className="w-100 border-radius-8"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className="text-center-imp text-offPurple">
                    <Box className="bg-ligtGrey p-8 border-radius-8  font-weight-bold">
                      {row.label}
                    </Box>
                  </TableCell>
                  <TableCell className="text-center-imp">
                    {row.ItemId}
                  </TableCell>
                  <TableCell
                    className="text-center-imp"
                    style={{ width: "30%" }}
                  >
                    <Autocomplete
                      freeSolo
                      size="small"
                      id="free-solo-2-demo"
                      disableClearable
                      sx={{
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            border: "1px solid #eee",
                          },
                      }}
                      style={{
                        width: "100%",
                        background: "#fff",
                        borderRadius: "25px",
                      }}
                      className={classes.root}
                      onDrop={(event) => onDrop(event, index)}
                      onDragOver={(event) => onDragOver(event)}
                      value={row.mapItems}
                      options={getDataDetails.map(
                        (option) => `${option.ItemId}  ${option.label}`
                      )}
                      onChange={(event, newValue) => {
                        handleAddedByChange(event, newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          classes={{ root: classes.customTextField }}
                          {...params}
                          placeholder="Search Item ID & Item Name"
                          sx={{
                            boxShadow: 5,
                            borderRadius: "25px",
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                border: "1px solid #eee",
                              },
                          }}
                          // sx={{ width: 100 }}
                          InputProps={{
                            style: {
                              padding: "5px",
                            },
                            ...params.InputProps,
                            type: "search",
                            startAdornment: (
                              <InputAdornment
                                className="set-icon-right"
                                position="start"
                              >
                                <IconButton aria-label="delete" color="warning">
                                  {row.mapItems ? null : <SearchIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    ></Autocomplete>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <TablePagination
                        component="Box"
                        count={totalItemData}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
        </Card>
      </Grid>
    </Grid>
  );
}
