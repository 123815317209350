import React, { createRef, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@mui/material/TablePagination";
import { Button, TextField, Menu, MenuItem } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import CancelIcon from "@mui/icons-material/Cancel";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
  ul: {
    "& .Mui-selected": {
      backgroundColor: "transparent !important",
      color: "#9747FF !important",
    },
  },
  table: {
    "& tbody>.MuiTableRow-root:hover": {},
  },
  tableRow: {
    borderRadius: "20px",
    "& .MuiTableCell-root": {
      fontWeight: "700",
      border: "none",
    },
    "&:hover .MuiTableCell-root": {},
    "&:hover .MuiTableCell-root, &:hover .material-icons-outlined": {
      color: "#fff !important",
      background: "#9747FF",
      borderRadius: "20px",
    },

    "&:hover .text-offPurple": {
      color: "#fff !important",
    },
  },
  "&:hover .tableRow": {
    background: "#9747FF",
    borderRadius: "20px",
  },
  root: {
    "& .MuiFilledInput-root": {
      color: "#fff",
    },
  },
}));

const rows = [
  {
    billNumber: "ABC001",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",

    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    billNumber: "ABC002",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    billNumber: "ABC003",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    billNumber: "ABC004",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    billNumber: "ABC005",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    billNumber: "ABC006",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",

    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    billNumber: "ABC007",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    id: 8,
    billNumber: "ABC008",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    name: "8",
    billNumber: "ABC009",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    billNumber: "ABC0010",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    billNumber: "ABC0011",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    billNumber: "ABC0012",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
  {
    billNumber: "ABC0013",
    billDate: "13/07/23",
    totalAmount: "111.61",
    paymentMode: "Card",
    status: "Settled",
    history: [
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
      {
        itemName: "mango",
        quantity: "1.5kg",
        totalPrice: "150",
        cat: "fruits",
      },
    ],
  },
];
const pageState = {
  skip: 0,
  top: 10,
  isDeleted: false,
  query: "",
  accountId: "",
};
export default function CollapsibleTable(props) {
  const [page, setPage] = React.useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const scrollRefs = useRef([]);
  const scrollSmoothHandler = (index) => () => {
    let clickedIndex = scrollRefs.current[index];
    let tagName = clickedIndex.current;
    tagName.classList.add("stick");
    tagName.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const chipsArray = document.querySelectorAll(" div > .chip");

    if (!loaded) {
      scrollRefs.current = [...Array(chipsArray.length).keys()].map(
        (_, i) => scrollRefs.current[i] ?? createRef()
      );

      chipsArray.forEach((elem, index) => {
        elem.addEventListener("click", scrollSmoothHandler(index));
      });
      setLoaded(true);
    }
  }, [loaded]);
  const [getId, setId] = useState("");
  function scrolldiv(obj) {
    var elem = document.getElementById(obj);
    setId(obj);
    elem.scrollIntoView(0, {
      behavior: "smooth",
    });
  }
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  return (
    <div>
      <div className="mt-10">
        <Grid container spacing={1} justifyContent="space-between">
          <Grid md={10}>
            <Card className="border-radius-20">
              <div className="mt-10 mb-10" style={{ position: "sticky" }}>
                <Grid container spacing={1} justifyContent="center">
                  <Grid item xs={12} sm={12} md={2.5}>
                    <div className="text-center-imp">
                      <div className="text-center-imp text-color-grey font-14">
                        Total Number Of Bills
                      </div>
                      {props.ItemWiseData?.TotalItems ? (
                        <div className="text-offPurple font-16 font-weight-bold">
                          {props.ItemWiseData?.TotalItems}
                        </div>
                      ) : (
                        <div className="text-offPurple font-16 font-weight-bold">
                          -
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2.5}>
                    <div className="text-center-imp">
                      <div className="text-center-imp text-color-grey font-14">
                        Total Sales Amount
                      </div>
                      {props.ItemWiseData?.OrderResponseHead?.TotalSales ? (
                        <div className="text-offPurple font-16 font-weight-bold">
                          {numberFormat(
                            props.ItemWiseData?.OrderResponseHead?.TotalSales
                          )}
                        </div>
                      ) : (
                        <div className="text-offPurple font-16 font-weight-bold">
                          -
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <div className="text-center-imp">
                      <div>
                        <div className="text-color-grey font-14">
                          Cash Sales
                        </div>
                      </div>
                      {props.ItemWiseData?.OrderResponseHead?.CashSales ? (
                        <div className="text-offPurple font-16 font-weight-bold">
                          {numberFormat(
                            props.ItemWiseData?.OrderResponseHead?.CashSales
                          )}
                        </div>
                      ) : (
                        <div className="text-offPurple font-16 font-weight-bold">
                          -
                        </div>
                      )}
                      <div className="text-color-grey font-12">
                        (Bill Count :
                        {props.ItemWiseData?.OrderResponseHead ? (
                          <span className="text-offPurple font-12 font-weight-bold">
                            {
                              props.ItemWiseData?.OrderResponseHead
                                ?.CashSalesCount
                            }
                          </span>
                        ) : (
                          <span className="text-offPurple font-12 font-weight-bold">
                            -
                          </span>
                        )}
                        )
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <div className="text-center-imp">
                      <div>
                        <div className="text-color-grey font-14">
                          Card Sales
                        </div>
                      </div>
                      {props.ItemWiseData?.OrderResponseHead?.CardSales ? (
                        <div className="text-offPurple font-16 font-weight-bold">
                          {numberFormat(
                            props.ItemWiseData?.OrderResponseHead?.CardSales
                          )}
                        </div>
                      ) : (
                        <div className="text-offPurple font-16 font-weight-bold">
                          -
                        </div>
                      )}
                      <div className="text-color-grey font-12">
                        (Bill Count :
                        {props.ItemWiseData?.OrderResponseHead ? (
                          <span className="text-offPurple font-12 font-weight-bold">
                            {
                              props.ItemWiseData?.OrderResponseHead
                                ?.CardSalesCount
                            }
                          </span>
                        ) : (
                          <span className="text-offPurple font-12 font-weight-bold">
                            -
                          </span>
                        )}
                        )
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <div className="text-center-imp">
                      <div>
                        <div className="text-color-grey font-14">UPI Sales</div>
                      </div>
                      {props.ItemWiseData?.OrderResponseHead?.UPISales ? (
                        <div className="text-offPurple font-16 font-weight-bold">
                          {numberFormat(
                            props.ItemWiseData?.OrderResponseHead?.UPISales
                          )}
                        </div>
                      ) : (
                        <div className="text-offPurple font-16 font-weight-bold">
                          -
                        </div>
                      )}
                      <div className="text-color-grey font-12">
                        (Bill Count :
                        {props.ItemWiseData?.OrderResponseHead ? (
                          <span className="text-offPurple font-12 font-weight-bold">
                            {
                              props.ItemWiseData?.OrderResponseHead
                                ?.UPISalesCount
                            }
                          </span>
                        ) : (
                          <span className="text-offPurple font-12 font-weight-bold">
                            -
                          </span>
                        )}
                        )
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <TableContainer component={Paper} className="tableHeight">
                <Table aria-label="collapsible table" className={classes.table}>
                  <TableHead
                    style={{ position: "sticky", top: 0, background: "#fff" }}
                  >
                    <TableRow>
                      <TableCell className="headerContent">
                        {!props.showSearchInput ? (
                          <Box className="flexClass">
                            <Box>Bill Number</Box>
                            <Box className="mx-5">
                              <IconButton aria-label="TuneIcon" className="p-0">
                                <SearchIcon
                                  onClick={props.handleSearchBillNumber}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <TextField
                              fullWidth
                              label="Search Here"
                              id="billNumber"
                              size="small"
                              type="text"
                              onBlur={(e) =>
                                props.setSearchInput(e.target.value)
                              }
                              value={props.SearchInput}
                              onChange={props.handleSearchUserInput}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    className="set-icon-right"
                                    position="start"
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      color="warning"
                                    >
                                      {props.handleIcon ? (
                                        <SearchIcon />
                                      ) : (
                                        <CancelIcon
                                          onClick={props.handleSearchClose}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        )}
                      </TableCell>
                      <TableCell className="headerContent">Bill Date</TableCell>
                      <TableCell className="headerContent">
                        Total Amount
                      </TableCell>
                      <TableCell className="text-offGrey text-center-imp font-14">
                        <div className="flexClass">
                          <div>Payment Mode</div>
                          <div className="mx-5">
                            <IconButton
                              aria-label="TuneIcon"
                              className="p-0"
                              id="basic-button"
                              variant="contained"
                              aria-controls={
                                props.openMenu ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={
                                props.openMenu ? "true" : undefined
                              }
                              onClick={props.handleClickMenu}
                            >
                              <TuneIcon />
                            </IconButton>
                            <Menu
                              id="basic-menu"
                              anchorEl={props.anchorEl}
                              open={props.openMenu}
                              onClose={props.handleCloseMenu}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={{ width: 320 }}
                            >
                              <MenuItem
                                id="ALL"
                                onClick={props.handleCloseMenu}
                              >
                                ALL
                              </MenuItem>
                              <MenuItem
                                id="UPI"
                                onClick={props.handleCloseMenu}
                              >
                                UPI
                              </MenuItem>
                              <MenuItem
                                id="CASH"
                                onClick={props.handleCloseMenu}
                              >
                                CASH
                              </MenuItem>
                              <MenuItem
                                id="CARD"
                                onClick={props.handleCloseMenu}
                              >
                                CARD
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="headerContent">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  {props.noFoundData == false ? (
                    <>
                      {props.ItemWiseData?.Data?.map((row, i) => {
                        return (
                          <TableBody>
                            <TableRow
                              sx={{ "& > *": { borderBottom: "unset" } }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={
                                  getId === row.InvoiceNumberStr
                                    ? "headerChildContentOne"
                                    : "headerChildContent"
                                }
                                id={row.InvoiceNumberStr}
                              >
                                {row.InvoiceNumberStr}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className={
                                  getId === row.InvoiceNumberStr
                                    ? "headerChildContentOne"
                                    : "headerChildContent"
                                }
                                id={row.InvoiceNumberStr}
                              >
                                {moment(row.OrderDate).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className={
                                  getId === row.InvoiceNumberStr
                                    ? "headerChildContentOne"
                                    : "headerChildContent"
                                }
                                id={row.InvoiceNumberStr}
                              >
                                {numberFormat(row.OrderAmount)}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className={
                                  getId === row.InvoiceNumberStr
                                    ? "headerChildContentOne"
                                    : "headerChildContent"
                                }
                                id={row.InvoiceNumberStr}
                              >
                                {row.PaymentMode}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="text-center-imp font-weight-7 text-lightGreen"
                              >
                                {row.Status}
                              </TableCell>
                            </TableRow>
                            <TableRow className={classes.tableRow}>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={6}
                              >
                                {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
                                <Box sx={{ margin: 1 }}>
                                  <Table size="small" aria-label="purchases">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className="childTable">
                                          Item Name
                                        </TableCell>
                                        <TableCell className="childTable">
                                          Quantity
                                        </TableCell>
                                        <TableCell className="childTable">
                                          Total Price
                                        </TableCell>
                                        <TableCell className="childTable">
                                          Categories
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {row.OrderDetail.map((historyRow) => (
                                        <TableRow key={historyRow.ProductName}>
                                          <TableCell
                                            className="childContent"
                                            component="th"
                                            scope="row"
                                          >
                                            {historyRow.ProductName}
                                          </TableCell>
                                          <TableCell className="childContent">
                                            {historyRow.Quantity}{" "}
                                            {historyRow.UOM}
                                          </TableCell>
                                          <TableCell className="childContent">
                                            {historyRow.Amount}
                                          </TableCell>
                                          <TableCell className="childContent">
                                            {historyRow.Category}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </>
                  ) : (
                    <div className="text-align-center font-18 font-bold py-15">
                      No data found...
                    </div>
                  )}
                </Table>
                <TablePagination
                  sx={{
                    ".css-pdct74-MuiTablePagination-selectLabel": {
                      fontFamily: "Lato !important",
                    },
                    ".css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input":
                      {
                        fontFamily: "Lato !important",
                      },
                    ".css-levciy-MuiTablePagination-displayedRows": {
                      fontFamily: "Lato !important",
                    },
                  }}
                  component="div"
                  count={props.TotalItems ? props.TotalItems : 0}
                  rowsPerPage={props.rowsPerPage}
                  page={props.page}
                  onPageChange={props.handleChangePage}
                  onRowsPerPageChange={props.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Card>
          </Grid>
          <Grid md={2}>
            <div className="listItemSection" style={{ position: "fixed" }}>
              <div className="headerContent">Bill Number</div>
              {props.noFoundData == false ? (
                <>
                  {props.ItemWiseData?.Data?.map((row, index) => (
                    <div
                      className="_2iA8p44d0WZ"
                      style={{
                        margin: "5%",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className={
                          getId === row.InvoiceNumberStr
                            ? "ListContentOne"
                            : "ListContent"
                        }
                        onClick={(e) => {
                          scrolldiv(row.InvoiceNumberStr);
                        }}
                      >
                        {row.InvoiceNumberStr}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="text-align-center font-18 font-bold py-15">
                  No data found...
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
