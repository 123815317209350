import { boolean } from "yup/lib/locale"

export const userRoles = {
    SuperAdmin: 1,
    AccountAdmin: 2,
    StoreManager: 3,
    StoreStaff: 4
}
export const regexConstant = {
    regexForSpec: new RegExp("^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @#&,.$-]*)?$"),
    regexAddress: new RegExp('\d{1,5}\s\w.\s(\b\w*\b\s){1,2}\w*\.'),
    regex: new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.com+$'),
    regexNumber: /^[0-9\b]+$/,
    letter: /^[a-zA-Z ]*$/,
    onlyTennumber: /^\d{10}$/,
    numberOnly: /^\d+$/,
    pincodeValidRegex: new RegExp('^[1-9][0-9]{5}$'),
    LatLongRegex: new RegExp("^-?([0-9]?[0-9]|90)(\.[0-9]{1,15})$"),
    NumberRegex: /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/,
    searchSpace: new RegExp("^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$-]*)?$"),
    GstRegex: new RegExp('^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$')
}
export const FileSizes = {
    sizeTenMb: 1e6,
    sizeFiveMb: 5e+6
}


