export  const styles = {
    Appbar:{
        sx:{
        backgroundColor: "white",
        color: "black",
        marginTop: 1,
        marginBottom: 10,
        marginRight: "1%",
        borderRadius: "25px",
        width: "98%",
      }},
      Toolbar:{sx:{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }},
      imgBox:{
        sx:{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: "20px",
          }
      },
      parentBox:{
        sx:{
            marginTop: "100px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            width: "99%",
            gap: "15px",
          }
      },
      outlet:{
        sx:{ width: "88%", marginRight: "1%" }
      }
}