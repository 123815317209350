import React from 'react'
import { TextField, FormControl, InputAdornment, Button } from '@mui/material';
import Link from '@mui/material/Link';
import { useLocation, Link as RouterLink,useNavigate } from 'react-router-dom'
import { Margin } from '@mui/icons-material';


function DefaultButton(props) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const { color, variant, startIcon, Text, to, size } = props
    return (
        <div>
                <Button variant={variant} onClick={goBack} size={size} color={color} startIcon={startIcon} className='border-radius-20 btn-orange w-100 text-bold' style={{ fontWeight: "bold", fontSize: "14px", width: "50%", padding:"3px"}}>{Text}</Button>
        </div>
    )
}

export default DefaultButton