import {
  GET_STORE_REQUEST,
  GET_ACTIVE_STORE_SUCCESS,
  GET_INACTIVE_STORE_SUCCESS,
  GET_STORE_FAILURE,
  GET_STOREDETAIL_REQUEST,
  GET_STOREDETAIL_SUCCESS,
  GET_STOREDETAIL_FAILURE,
  SAVE_STORE_REQUEST,
  SAVE_STORE_SUCCESS,
  SAVE_STORE_FAILURE,
  GET_IMAGE_REQUEST,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_FAILURE,
} from "../Types";

const initialState = {
  loading: false,
  noFoundData: false,
  store: {},
  error: "",
  isAccountActive: true,
  storeDetails: {},
  result: "",
  imageData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
      };
    case GET_ACTIVE_STORE_SUCCESS:
      return {
        loading: false,
        store: action.payload,
        noFoundData: false,
        error: "",
        isAccountActive: true,
      };
    case GET_INACTIVE_STORE_SUCCESS:
      return {
        loading: false,
        store: action.payload,
        noFoundData: false,
        error: "",
        isAccountActive: false,
      };
    case GET_STORE_FAILURE:
      return {
        loading: false,
        noFoundData: true,
        store: {},
        error: action.payload,
      };
    case GET_STOREDETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
      };
    case GET_STOREDETAIL_SUCCESS:
      return {
        loading: false,
        storeDetails: action.payload,
        noFoundData: false,
        error: "",
      };
    case GET_STOREDETAIL_FAILURE:
      return {
        loading: false,
        noFoundData: true,
        storeDetails: {},
        error: action.payload,
      };
    case SAVE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
        error: "",
      };
    case SAVE_STORE_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        noFoundData: false,
        error: "",
      };
    case SAVE_STORE_FAILURE:
      return {
        loading: false,
        noFoundData: true,
        result: action.payload,
        error: action.payload.response,
      };
    case GET_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        noFoundData: false,
        storeDetails: {},
        imageData: {},
      };
    case GET_IMAGE_SUCCESS:
      return {
        loading: false,
        imageData: action.payload,
        error: "",
      };
    case GET_IMAGE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
