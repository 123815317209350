import { useEffect, useState } from "react";
import { IconButton,Button,Dialog,DialogActions,DialogContent,Typography,DialogTitle,Switch} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// Using for Dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  customTable: {
    "& .MuiTableCell-root": {
      padding: "9px", 
    },
  },
  dialogPaper: {
    width: "100%",
  },
  switchColor: {
 
"&.PrivateSwitchBase-input MuiSwitch-input css-1m9pwf3":{
  color:"green !important"
}}
});



export default function UserMoreMenu(props) {
  const classes = useStyles();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [getPermission, setPermission] = useState({});
  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    let newObj = {};
    userDetail.permissions.forEach((element) => {
      newObj[`${element}`] = element;
    });
    setPermission(newObj);
  }, []);
  return (
    <>
   
      {getPermission.ActiveInactiveAccount == "ActiveInactiveAccount" ? (
        <Switch
        {...label}
        name="switch"
        id={props.id}
        onClick={props.handleClickOpen}
        inputProps={{ "aria-label": "disabled" }}
        className={classes.switchColor} 
        checked={false}

        />
      ) : (
        <Switch
          {...label}
          name="switch"
          id={props.id}
          disabled
          onClick={props.handleClickOpen}
          inputProps={{ "aria-label": "disabled" }}
          color="warning"
          checked={false}
        />
      )}
      <Dialog
        open={props.open}
        fullWidth
        BackdropProps={{ style: { opacity: 0.2 } }}
        maxWidth="sm"
        sx={{
          "& .MuiPaper-elevation24": {
            boxShadow: "none!important",
          },
          " .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop":{
            background:"rgba(0,0,0,0.1)!important"
          },
          "& .MuiBackdrop-root":{
            opacity:"0.3!important"
          }
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="p-2"
          onClose={props.handleClickClose}
        >
          Active {props.text}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure you want to activate this {props.subtext}.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="border-radius-20 btn-orange w-100 margin-top-bottom-10"
            onClick={props.handleClickSave}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            className="border-radius-20 btn-orange w-100 margin-top-bottom-10"
            onClick={props.handleClickClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      
    </>
  );
}
