import apiService from "../../Services/ApiServices"
import {
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_FAILURE,
  GET_ACTIVE_CATEGORY_SUCCESS,
  GET_INACTIVE_CATEGORY_SUCCESS,
  GET_CATEGORYDETAIL_REQUEST,
  GET_CATEGORYDETAIL_SUCCESS,
  GET_CATEGORYDETAIL_FAILURE,
  SAVE_CATEGORY_REQUEST,
  SAVE_CATEGORY_SUCCESS,
  SAVE_CATEGORY_FAILURE,
  GET_IMAGE_REQUEST,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_FAILURE
} from '../Types'

export const getCategoryDetailRequest = () => {
  return {
    type: GET_CATEGORYDETAIL_REQUEST
  }
}

export const getCategoryDetailSuccess = Category => {

  return {
    type: GET_CATEGORYDETAIL_SUCCESS,
    payload: Category
  }
}

export const getCategoryDetailFailure = error => {
  return {
    type: GET_CATEGORYDETAIL_FAILURE,
    payload: error
  }
}
export const getCategoryRequest = () => {
  return {
    type: GET_CATEGORY_REQUEST
  }
}

export const getCategorySuccess = store => {

  return {
    type: GET_ACTIVE_CATEGORY_SUCCESS,
    payload: store
  }
}


export const getCategoryFailure = error => {
  return {
    type: GET_CATEGORY_FAILURE,
    payload: error
  }
}
// save Data return type
export const saveCategoryRequest = () => {
  return {
    type: SAVE_CATEGORY_REQUEST
  }
}

export const saveCategorySuccess = result => {

  return {
    type: SAVE_CATEGORY_SUCCESS,
    payload: result
  }
}

export const saveCategoryFailure = error => {
  return {
    type: SAVE_CATEGORY_FAILURE,
    payload: error
  }
}
export const getInactiveCategorySuccess = category => {

  return {
    type: GET_INACTIVE_CATEGORY_SUCCESS,
    payload: category
  }
}
// get data 
export const getDataByCategoryDetails = (pageState) => {
  try {

    return (dispatch) => {
      dispatch(getCategoryRequest())
      let url = `/AccountCategory/all?skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId != "" && pageState.accountId != undefined) {
        url += `&accountId=${pageState.accountId}`;
      }
      apiService.getData(url)
        .then(res => {
          const Category = res.data
          if (res.data.Data == "") {
            dispatch(getCategoryFailure('err'))
          }
          else {
            dispatch(getCategorySuccess(Category))
          }

        }).catch(err => { dispatch(getCategoryFailure(err)) });
    }
  } catch (err) {

  }
};

export const getInactiveDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getCategoryRequest())
      let url = `/AccountCategory/all?deleted=${true}&skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId != "" && pageState.accountId != undefined) {
        url += `&accountId=${pageState.accountId}`;
      }
      apiService.getData(url)
        .then(res => {
          const category = res.data
          if (res.data.Data == "") {
            dispatch(getCategoryFailure('err'))
          }
          else {
            dispatch(getInactiveCategorySuccess(category))
          }

        }).catch(err => { dispatch(getCategoryFailure(err)) });
    }
  } catch (err) {

  }
};
//Get logo image
export const getImageRequest = () => {
  return {
    type: GET_IMAGE_REQUEST
  }
}

export const getImageSuccess = result => {

  return {
    type: GET_IMAGE_SUCCESS,
    payload: result
  }
}

export const getImageFailure = error => {
  return {
    type: GET_IMAGE_FAILURE,
    payload: error
  }
}


// save data
export const saveCategory = (formData) => {
  try {

    return (dispatch) => {
      dispatch(saveCategoryRequest())
      let url = `/AccountCategory/create`;
      apiService.saveData(url, formData)
        .then(res => {

          const result = res.status;
          if (result == 200) {
            dispatch(saveCategorySuccess(result))
          }
          else {

            dispatch(saveCategoryFailure(res.response.status))
          }

        }).catch(err => { dispatch(saveCategoryFailure(err)) });
    }
  } catch (err) {

  }
};

export const getLogoImageData = (categoryId) => {
  try {
    return (dispatch) => {
      dispatch(getImageRequest())
      let url = `/AccountCategory/logo/${categoryId}`;
      apiService.getData(url)
        .then(res => {
          const imageData = res.data
          if (res.data) {
            dispatch(getImageSuccess(imageData))
          }
          else {
            dispatch(getImageFailure('err'))
          }

        }).catch(err => { dispatch(getImageFailure(err)) });
    }
  } catch (err) {

  }
}
export const updateCategory = (formData, accountId) => {
  try {

    return (dispatch) => {
      dispatch(saveCategoryRequest())
      let url = `/AccountCategory/update/${accountId}`;
      apiService.saveData(url, formData)
        .then(res => {

          const result = res.status;
          if (result == 200) {
            dispatch(saveCategorySuccess(result))
          }
          else {

            dispatch(saveCategoryFailure(res.response.status))
          }

        }).catch(err => { dispatch(saveCategoryFailure(err)) });
    }
  } catch (err) {

  }
};
export const getCategoryDetails = (accountId) => {
  try {

    return (dispatch) => {
      dispatch(getCategoryDetailRequest())
      let url = `/AccountCategory/${accountId}`;
      apiService.getData(url)
        .then(res => {

          const CategoryDetail = res.data
          if (res.data) {
            dispatch(getCategoryDetailSuccess(CategoryDetail))
          }
          else {
            dispatch(getCategoryDetailFailure('err'))
          }

        }).catch(err => { dispatch(getCategoryDetailFailure(err)) });
    }
  } catch (err) {

  }
};