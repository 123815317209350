import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Menu,
  MenuItem,
  Avatar,
  Grid,
  Tab,
  Tabs,
  Box,
  Button,
  InputAdornment,
  TextField,
  IconButton,
  Typography,
  TablePagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddAltRounded from "@mui/icons-material/PersonAddAltRounded";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import apiService from "../../Services/ApiServices";
import Loader from "../../Component/Loader";
import DeactiveDialog from "../../Component/DeactiveDialog";
import ActivateDialog from "../../Component/ActivateDialog";

import { useDispatch, useSelector } from "react-redux";
import { getDataByPagination } from "../../Redux/Account/AccountAction";
import { getInactiveDataByPagination } from "../../Redux/Account/AccountAction";
import { regexConstant, userRoles } from "../../shared/constants";
import { toast, ToastContainer } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import editImg from "../../assets/Images/edit.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`,
  };
}
const pageState = {
  skip: 0,
  top: 10,
  isDeleted: false,
  query: "",
  accountId: "",
};
const useStyles = makeStyles({
  tableCell: {
    "&.MuiTableCell-root": {
      border: "none!important",
      padding: "10px",
    },
  },
});

export default function BasicTable() {
  const dispatch = useDispatch();
  const accountData = useSelector((s) => s.accountReducer);
  console.log("accountData",accountData)
  const { loading } = useSelector((state) => ({
    loading: state.accountReducer.loading,
  }));
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [getData, setData] = useState([]);
  const [InactiveData, setInactiveData] = useState([]);
  const [search, setSearch] = useState("");
  const [totalItemData, setTotalItemData] = useState(1);
  const [noFoundData, setnoFoundData] = useState(true);
  const [currentUserDetails, setCurrentUser] = useState({});
  const [getPermission, setPermission] = useState({});
  const [value, setValue] = React.useState(0);
  const [inputValue, setInputValue] = useState("");
  const [handleIcon, setIcon] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const [open, setOpen] = React.useState(false);
  const [ModalDataId, setModalDataId] = useState();
  const [getDeactiveId, setDeactiveId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [MenuDataId, setMenuDataId] = useState();
  const OpenMenu = Boolean(anchorEl);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  // intial List Call
  const intialListCall = () => {
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    if (value == 0) {
      // setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      // setIsLoading(true);
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    let newObj = {};
    userDetail.permissions.forEach((element) => {
      newObj[`${element}`] = element;
    });
    setPermission(newObj);
    if (userDetail) {
      setCurrentUser(userDetail);
    }
    if (
      userDetail.userRole == userRoles.AccountAdmin &&
      userDetail.accountId != ""
    ) {
      pageState.accountId = userDetail.accountId;
      pageState.skip = 0;
      pageState.top = 10;
      dispatch(getDataByPagination(pageState));
    } else {
      pageState.accountId = "";
      pageState.skip = 0;
      pageState.top = 10;
      dispatch(getDataByPagination(pageState));
    }
    intialListCall();
  }, []);

  useEffect(() => {
    if (accountData.accounts) {
      if (!accountData.noFoundData) {
        if (accountData.isAccountActive == true) {
          setData(accountData.accounts.Data);
          setTotalItemData(
            accountData.accounts
              ? accountData.accounts.TotalItems
              : 1
          );
        } else if (accountData.isAccountActive == false) {
          setInactiveData(accountData.accounts.Data);
          setTotalItemData(
            accountData.accounts
            ? accountData.accounts.TotalItems
            : 1
        );
          
        } else {
          // setIsLoading(false);
          
        }
        // setIsLoading(false);
      } else {
        setnoFoundData(false);
      }
    }
    if(accountData.noFoundData === true){
      setTotalItemData(
        0
    );
    }
  }, [accountData]);

  const handleChange = (event, newValue) => {
    const indexValue = event.target.id.split("-")[2];
    setValue(newValue);
    if (indexValue > 0) {
      pageState.isDeleted = true;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      setRowsPerPage(parseInt(pageState.top));
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      // setIsLoading(true);
      pageState.isDeleted = false;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      dispatch(getDataByPagination(pageState));
    }
  };
  const handleUserInput = (e) => {
    setIcon({ icon: "CancelIcon" });
    if (e.target.value) {
      setIcon(false);
    }
    setInputValue(e.target.value);
  };

  const resetInputField = (e) => {
    setSearch("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setInputValue("");
    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);

    if (pageState.isDeleted) {
      setIsLoading(true);
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    }
  };

  const SearchData = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    pageState.query = inputValue;
    // pageState.query = search;
    if (
      pageState.query == "" ||
      !regexConstant.searchSpace.test(pageState.query)
    ) {
      toast("Please Enter the search key");
    }
    if (value == 0) {
      setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  const getActiveDataById = (obj) => {
    apiService
      .saveData(`/Account/activate`, { accountId: ModalDataId })
      .then((res) => {
        setOpen(false);
        dispatch(getInactiveDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };

  const handleOpenModal = (e) => {
    setDeactiveId(e.target.id);
    setOpen(true);
    setModalDataId(e.target.id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteDataId = () => {
    apiService
      .deleteData(`/Account/${ModalDataId}`)
      .then((res) => {
        setOpen(false);
        setIsLoading(true);
        dispatch(getDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuDataId(event.currentTarget.id);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <ToastContainer autoClose={2000} />
      {/* ---------Header------------- */}
      <Box>
        <Box className="justify-content-space">
          <Box className="poppins-bold">Manage Account</Box>
          <Box>
            <Box className="text-align-end showMobile my-8">
              <Link to="/app/NewAccounts" className="textDecoration">
                <Button>
                  <Avatar
                    sx={{ border: "1px solid #9747FF" }}
                    style={{ backgroundColor: "white" }}
                  >
                    <PersonAddAltRounded sx={{ color: "#9747FF" }} />
                  </Avatar>
                </Button>
              </Link>
            </Box>
            <Box className="showINWindow">
              {currentUserDetails.userRole == 1 ? (
                <Box>
                  <Link to="/app/NewAccounts" className="textDecoration">
                    <Button
                      variant="outlined"
                      size="small"
                      className="border-radius-20 roboto"
                      style={{
                        boxShadow: 3,
                        borderRadius: "15px",
                        background: "#fff",
                        color: "#8B8D9D",
                        border: "none",
                        fontWeight: "100 !important",
                        padding: "3px 16px",
                      }}
                    >
                      <AddIcon className="mx-5" sx={{ color: "#9747FF" }} />
                      ADD NEW ACCOUNT
                    </Button>
                  </Link>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>

        <Grid container spacing={2} justifyContent="space-between">
          {currentUserDetails.userRole != 2 ? (
            <Grid item xs={12} sm={12} md={5}>
              <Box>
                <Box className="pt-10 search-section">
                  <Box>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item xs={12} sm={12} md={11}>
                        <TextField
                          fullWidth
                          id="userName"
                          size="small"
                          type="Text"
                          // onBlur={e => setSearch(e.target.value)}
                          value={inputValue}
                          onChange={handleUserInput}
                          classes={{ root: classes.customTextField }}
                          placeholder="Search Here"
                          sx={{
                            boxShadow: 5,
                            borderRadius: "10px",
                            background: "white",
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                border: "none !important",
                              },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                className="set-icon-right"
                                position="start"
                              >
                                <IconButton
                                  aria-label="delete"
                                  onClick={resetInputField}
                                  color="warning"
                                >
                                  {handleIcon ? <SearchIcon /> : <CancelIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              SearchData();
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={1}>
                        <Box className="mt-5">
                          <Button
                            variant="contained"
                            size="small"
                            className="border-radius-20 btn-orange w-100"
                            onClick={SearchData}
                          >
                            Search
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Box></Box>
          )}
          <Grid item xs={12} sm={12} md={2}>
            <Box>
              <Box className=" pt-10">
                <Box>
                  <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="horizontal tabs example"
                  >
                    <Tab
                      label="Active"
                      {...a11yProps(0)}
                      className="roboto-700"
                    />
                    <Tab
                      label="InActive"
                      {...a11yProps(1)}
                      className="roboto-700"
                    />
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box>
        {/* ----------Table pannel 0------------ */}
        <TabPanel value={value} index={0}>
          <TableContainer
            component={Paper}
            className="mt-10 border-radius-20 table-scroll"
          >
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Sr no</TableCell>
                  <TableCell className={classes.tableCell}>
                    Account Id
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Account Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Contact Person
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Contact Number
                  </TableCell>
                  <TableCell className={classes.tableCell}>Email ID</TableCell>
                  <TableCell className={classes.tableCell}>
                    City,State
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Status
                  </TableCell>
                  {getPermission.AddEditAccount == "AddEditAccount" ? (
                    <TableCell className={classes.tableCell} align="center">
                      Edit
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableCell}></TableCell>
                  )}
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                  ></TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loader open={loading} />
              ) : (
                <>
                  {noFoundData ? (
                    <TableBody>
                      {getData?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCell}>
                            {index + 1 + pageState.skip}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Id}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.AccountName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.ContactPerson}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.PrimaryPhoneNo}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Email}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.City["Name"]},{row.City.State["Name"]}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <DeactiveDialog
                              id={row.Id.toString()}
                              handleClickOpen={handleOpenModal}
                              open={open}
                              text="Account"
                              subtext="account"
                              handleClickClose={handleClose}
                              handleClickSave={deleteDataId}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {getPermission.AddEditAccount ==
                            "AddEditAccount" ? (
                              <Box
                                className="buttonSecton"
                                style={{ marginTop: "0px" }}
                              >
                                <Box className="submitButton">
                                  <Link
                                    to={{
                                      pathname: `/app/UpdateAccounts/${row.Id}`,
                                    }}
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      color="warning"
                                    >
                                      {/* <EditIcon sx={{color:"#9747FF"}} /> */}
                                      <img src={editImg} />
                                    </IconButton>
                                  </Link>
                                </Box>
                              </Box>
                            ) : (
                              <Box></Box>
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <Box className="Menu-section">
                              {currentUserDetails.userRole == 1 ? (
                                <Box>
                                  <Box>
                                    <Button
                                      id={row.Id}
                                      onClick={handleClick}
                                      aria-controls={
                                        OpenMenu ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        OpenMenu ? "true" : undefined
                                      }
                                    >
                                      <MoreVertIcon sx={{ color: "#9747FF" }} />
                                    </Button>

                                    <Menu
                                      id={ModalDataId}
                                      anchorEl={anchorEl}
                                      open={OpenMenu}
                                      elevation={1}
                                      borderRadius="50%"
                                      style={{ borderRadius: 20 }}
                                      onClose={handleCloseMenu}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <Link
                                        to={{
                                          pathname: `/app/ManageStore/${MenuDataId}`,
                                        }}
                                        className="textDecoration"
                                      >
                                        <MenuItem
                                          className="border-radius-20"
                                          style={{ color: "#000" }}
                                        >
                                          Manage Store
                                        </MenuItem>
                                      </Link>

                                      <Link
                                        to={{
                                          pathname: `/app/ManageUser/${MenuDataId}`,
                                        }}
                                        className="textDecoration"
                                      >
                                        <MenuItem
                                          className="border-radius-20"
                                          style={{ color: "#000" }}
                                        >
                                          Manage User
                                        </MenuItem>
                                      </Link>
                                    </Menu>
                                  </Box>
                                </Box>
                              ) : (
                                ""
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <Box className="text-align-center font-18 font-bold py-15">
                      No data found...
                    </Box>
                  )}
                </>
              )}
            </Table>
            <TablePagination
              component="div"
              count={totalItemData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </TabPanel>
        {/* ----------Table pannel 1------------ */}
        <TabPanel value={value} index={1}>
          <TableContainer
            component={Paper}
            className="mt-10 border-radius-20 table-scroll"
          >
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Sr no</TableCell>
                  <TableCell className={classes.tableCell}>
                    Account Id
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Account Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Contact Person
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Contact Number
                  </TableCell>
                  <TableCell className={classes.tableCell}>Email ID</TableCell>
                  <TableCell className={classes.tableCell}>
                    City,State
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loader open={loading} />
              ) : (
                <>
                  {noFoundData ? (
                    <TableBody>
                      {InactiveData?.map((row, index) => (
                        <TableRow key={row} className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            {index + 1 + pageState.skip}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Id}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.AccountName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.ContactPerson}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.PrimaryPhoneNo}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Email}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.City["Name"]},{row.City.State["Name"]}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <ActivateDialog
                              id={row.Id.toString()}
                              handleClickOpen={handleOpenModal}
                              open={open}
                              text="Account"
                              subtext="account"
                              handleClickClose={handleClose}
                              handleClickSave={getActiveDataById}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <Box className="text-align-center font-18 font-bold py-15">
                      No data found...
                    </Box>
                  )}
                </>
              )}
            </Table>
            <TablePagination
              component="Box"
              count={totalItemData?totalItemData:0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </TabPanel>
      </Box>
    </Box>
  );
}
