import apiService from "../../Services/ApiServices";
import {
  GET_TERMINAL_REQUEST,
  GET_TERMINAL_FAILURE,
  GET_ACTIVE_TERMINAL_SUCCESS,
  GET_INACTIVE_TERMINAL_SUCCESS,
  GET_TERMINALDETAILS_REQUEST,
  GET_TERMINALDETAILS_SUCCESS,
  GET_TERMINALDETAILS_FAILURE,
  SAVE_TERMINAL_REQUEST,
  SAVE_TERMINAL_SUCCESS,
  SAVE_TERMINAL_FAILURE,
  GET_TERMINAL_LICENSE_REQUEST,
} from "../Types";
// Using return type for get data
export const getTerminalRequest = () => {
  return {
    type: GET_TERMINAL_REQUEST,
  };
};
export const getTerminalLicenseRequest = (terminal) => {
  return {
    type: GET_TERMINAL_LICENSE_REQUEST,
    payload: terminal,
  };
};

export const getTerminalSuccess = (terminal) => {
  return {
    type: GET_ACTIVE_TERMINAL_SUCCESS,
    payload: terminal,
  };
};

export const getInactiveTerminalSuccess = (accounts) => {
  return {
    type: GET_INACTIVE_TERMINAL_SUCCESS,
    payload: accounts,
  };
};

export const getTerminalFailure = (error) => {
  return {
    type: GET_TERMINAL_FAILURE,
    payload: error,
  };
};
// here mention action for get data
export const getDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getTerminalRequest());
      let url = `/Terminal/all?skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.storeId != "") {
        url += `&storeId=${pageState.storeId}`;
      }
      apiService
        .getData(url)
        .then((res) => {
          const terminal = res.data;
          if (res.data.Data == "") {
            dispatch(getTerminalFailure("err"));
          } else {
            dispatch(getTerminalSuccess(terminal));
          }
        })
        .catch((err) => {
          dispatch(getTerminalFailure(err));
        });
    };
  } catch (err) {}
};
export const getLicenseData = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getTerminalRequest());
      let url = `/License/all?skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.storeId != "") {
        url += `&accountId=${pageState.accountId}`;
      }
      apiService
        .getData(url)
        .then((res) => {
          const terminal = res.data;
          if (res.data.Data == "") {
            dispatch(getTerminalFailure("err"));
          } else {
            dispatch(getTerminalLicenseRequest(terminal));
          }
        })
        .catch((err) => {
          dispatch(getTerminalFailure(err));
        });
    };
  } catch (err) {}
};

export const getInactiveDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getTerminalRequest());
      let url = `/Terminal/all?deleted=${true}&skip=${pageState.skip}&top=${
        pageState.top
      }`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.storeId != "") {
        url += `&storeId=${pageState.storeId}`;
      }
      apiService
        .getData(url)
        .then((res) => {
          const accounts = res.data;
          if (res.data.Data == "") {
            dispatch(getTerminalFailure("err"));
          } else {
            dispatch(getInactiveTerminalSuccess(accounts));
          }
        })
        .catch((err) => {
          dispatch(getTerminalFailure(err));
        });
    };
  } catch (err) {}
};
// save terminal return type
export const saveTerminalRequest = () => {
  return {
    type: SAVE_TERMINAL_REQUEST,
  };
};

export const saveTerminalSuccess = (result) => {
  return {
    type: SAVE_TERMINAL_SUCCESS,
    payload: result,
  };
};

export const saveTerminalFailure = (error) => {
  return {
    type: SAVE_TERMINAL_FAILURE,
    payload: error,
  };
};
// here mention action for save terminal
export const saveTerminals = (formData) => {
  try {
    return (dispatch) => {
      dispatch(saveTerminalRequest());
      let url = `/Terminal/create`;
      apiService
        .saveData(url, formData)
        .then((res) => {
          const result = res.status;
          if (result == 200) {
            dispatch(saveTerminalSuccess(result));
          } else {
            dispatch(saveTerminalFailure(res.response.status));
          }
        })
        .catch((err) => {
          dispatch(saveTerminalFailure(err));
        });
    };
  } catch (err) {}
};
// here mention action for update data by ID
export const updateTerminal = (formData, terminalId, params) => {
  try {
    return (dispatch) => {
      dispatch(saveTerminalRequest());
      let url = `/Terminal/update/${terminalId}`;
      apiService
        .saveData(url, formData)
        .then((res) => {
          const result = res.status;
          if (result == 200) {
            dispatch(saveTerminalSuccess(result));
          } else {
            dispatch(saveTerminalFailure(res.response.status));
          }
        })
        .catch((err) => {
          dispatch(saveTerminalFailure(err));
        });
    };
  } catch (err) {}
};
//Get data by id here mention return type
export const getTerminalDetailsRequest = () => {
  return {
    type: GET_TERMINALDETAILS_REQUEST,
  };
};

export const getTerminalDetailsSuccess = (accounts) => {
  return {
    type: GET_TERMINALDETAILS_SUCCESS,
    payload: accounts,
  };
};

export const getTerminalDetailsFailure = (error) => {
  return {
    type: GET_TERMINALDETAILS_FAILURE,
    payload: error,
  };
};
// get terminal data by ID
export const getTerminalDetails = (terminalId) => {
  try {
    return (dispatch) => {
      dispatch(getTerminalDetailsRequest());
      let url = `/Terminal/${terminalId}`;
      apiService
        .getData(url)
        .then((res) => {
          const accountsDetail = res.data;
          if (res.data) {
            dispatch(getTerminalDetailsSuccess(accountsDetail));
          } else {
            dispatch(getTerminalDetailsFailure("err"));
          }
        })
        .catch((err) => {
          dispatch(getTerminalDetailsFailure(err));
        });
    };
  } catch (err) {}
};
