import apiService from "../../Services/ApiServices";
import {
  GET_LICENSE_REQUEST,
  GET_LICENSE_FAILURE,
  GET_ACTIVE_LICENSE_SUCCESS,
  GET_INACTIVE_LICENSE_SUCCESS,
  GET_LICENSEDETAILS_REQUEST,
  GET_LICENSEDETAILS_SUCCESS,
  GET_LICENSEDETAILS_FAILURE,
  SAVE_LICENSE_REQUEST,
  SAVE_LICENSE_SUCCESS,
  SAVE_LICENSE_FAILURE,
} from "../Types";

// Using return type for get data
export const getLicenseRequest = () => {
  return {
    type: GET_LICENSE_REQUEST,
  };
};

export const getLicenseSuccess = (terminal) => {
  return {
    type: GET_ACTIVE_LICENSE_SUCCESS,
    payload: terminal,
  };
};

export const getInactiveLicenseSuccess = (accounts) => {
  return {
    type: GET_INACTIVE_LICENSE_SUCCESS,
    payload: accounts,
  };
};

export const getLicenseFailure = (error) => {
  return {
    type: GET_LICENSE_FAILURE,
    payload: error,
  };
};
// here mention action for get data
export const getDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getLicenseRequest());
      let url = `/License/all?skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId != "" && pageState.accountId != undefined) {
        url += `&accountId=${pageState.accountId}`;
      }
      apiService
        .getData(url)
        .then((res) => {
          const license = res.data;

          if (res.data.Data == "") {
            dispatch(getLicenseFailure("err"));
          } else {
            dispatch(getLicenseSuccess(license));
          }
        })
        .catch((err) => {
          dispatch(getLicenseFailure(err));
        });
    };
  } catch (err) {}
};

// save terminal return type
export const saveLicenseRequest = () => {
  return {
    type: SAVE_LICENSE_REQUEST,
  };
};

export const saveLicenseSuccess = (result) => {
  return {
    type: SAVE_LICENSE_SUCCESS,
    payload: result,
  };
};

export const saveLicenseFailure = (error) => {
  return {
    type: SAVE_LICENSE_FAILURE,
    payload: error,
  };
};
// here mention action for save terminal
export const saveLicense = (formData) => {
  try {
    return (dispatch) => {
      dispatch(saveLicenseRequest());
      let url = `/License/create`;
      apiService
        .saveData(url, formData)
        .then((res) => {
          const result = res.status;
          if (result == 200) {
            dispatch(saveLicenseSuccess(result));
          } else {
            dispatch(saveLicenseFailure(res.response.status));
          }
        })
        .catch((err) => {
          dispatch(saveLicenseFailure(err));
        });
    };
  } catch (err) {}
};
// here mention action for update data by ID
export const updateLicense = (formData, LicenseId, params) => {
  try {
    return (dispatch) => {
      dispatch(saveLicenseRequest());
      let url = `/License/update/${LicenseId}`;
      apiService
        .saveData(url, formData)
        .then((res) => {
          const result = res.status;
          if (result == 200) {
            dispatch(saveLicenseSuccess(result));
          } else {
            dispatch(saveLicenseFailure(res.response.status));
          }
        })
        .catch((err) => {
          dispatch(saveLicenseFailure(err));
        });
    };
  } catch (err) {}
};
//Get data by id here mention return type
export const getLicenseDetailsRequest = () => {
  return {
    type: GET_LICENSEDETAILS_REQUEST,
  };
};

export const getLicenseDetailsSuccess = (accounts) => {
  return {
    type: GET_LICENSEDETAILS_SUCCESS,
    payload: accounts,
  };
};

export const getLicenseDetailsFailure = (error) => {
  return {
    type: GET_LICENSEDETAILS_FAILURE,
    payload: error,
  };
};
// get terminal data by ID
export const getLicenseDetails = (licenseId) => {
  try {
    return (dispatch) => {
      dispatch(getLicenseDetailsRequest());
      let url = `/License/${licenseId}`;
      apiService
        .getData(url)
        .then((res) => {
          const licenseDetail = res.data;
          if (res.data) {
            dispatch(getLicenseDetailsSuccess(licenseDetail));
          } else {
            dispatch(getLicenseDetailsFailure("err"));
          }
        })
        .catch((err) => {
          dispatch(getLicenseDetailsFailure(err));
        });
    };
  } catch (err) {}
};
