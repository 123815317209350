import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import {TextField,Button,Card,Box, Grid,MenuItem } from "@mui/material";
import DefaultInput from "../../Component/TextField";
import DefaultButton from "../../Component/Button";
import { useNavigate, useParams } from "react-router-dom";
import { regexConstant, FileSizes } from "../../shared/constants";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getCountryList } from "../../Redux/Country/CountryAction";
import { getStateList } from "../../Redux/State/StateAction";
import { getCityList } from "../../Redux/City/CityAction";
import {
  getAccountDetails,
  saveAccounts,
  updateAccounts,
  getLogoImageData,
} from "../../Redux/Account/AccountAction";
import { styles } from "../../assets/scss/AddItem"


export default function BasicTable() {
  const [inputField, setInputField] = useState({
    AccountName: "",
    ContactPerson: "",
    Email: "",
    PrimaryPhoneNo: "",
    SecondaryPhoneNo: "",
    Address: "",
    country: "",
    State: "",
    City: "",
    CityId: "",
    PinCode: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [logoImage, setLogoImage] = useState("");
  const [logoImageURL, setLogoUrl] = useState(null);
  const [previewURL, setPreviewUrl] = useState(null);
  const [selectedFileName, setFileName] = useState("");
  const [country, setCountry] = useState([]);
  const [State, setState] = useState([]);
  const [City, setCity] = useState([]);
  const dispatch = useDispatch();
  const countryData = useSelector((s) => s.countryReducer);
  const stateData = useSelector((s) => s.stateReducer);
  const cityData = useSelector((s) => s.cityReducer);
  const accountDetail = useSelector((s) => s.accountReducer);
  const [currentUserDetails, setCurrentUser] = useState({});

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetail) {
      setCurrentUser(userDetail);
    }
  }, []);

  // get all country
  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  const params = useParams();
  useEffect(() => {
    if (params.id) {
      dispatch(getAccountDetails(params.id));
    }
  }, []);

  useEffect(() => {
    if (countryData) {
      setCountry(countryData.countries);
    }
  }, [countryData]);
  // get all States for selected country
  useEffect(() => {
    if (stateData) {
      setState(stateData.states);
    }
  }, [stateData]);
  // get all city for selected state
  useEffect(() => {
    if (cityData) {
      setCity(cityData.cities);
    }
  }, [cityData]);
  // Calls whene account details fetch  by account id
  useEffect(() => {
    if (accountDetail.accountDetails) {
      var accInfo = accountDetail.accountDetails;
      if (Object.keys(accInfo).length > 0) {
        var countryId = accInfo.City.State.CountryId;
        dispatch(getStateList(countryId));
        var stateId = accInfo.City.StateId;
        dispatch(getCityList(stateId));
        setInputField({
          AccountName: accInfo.AccountName != null ? accInfo.AccountName : "",
          ContactPerson:
            accInfo.ContactPerson != null ? accInfo.ContactPerson : "",
          Email: accInfo.Email != null ? accInfo.Email : "",
          PrimaryPhoneNo:
            accInfo.PrimaryPhoneNo != null ? accInfo.PrimaryPhoneNo : "",
          SecondaryPhoneNo:
            accInfo.SecondaryPhoneNo != null ? accInfo.SecondaryPhoneNo : "",
          Address: accInfo.Address != null ? accInfo.Address : "",

          country:
            accInfo.City.State.CountryId != null
              ? accInfo.City.State.CountryId
              : "",
          State: accInfo.City.StateId != null ? accInfo.City.StateId : "",
          City: accInfo.City.Id != null ? accInfo.City.Id : "",
          CityId: accInfo.City.Id != null ? accInfo.City.Id : "",
          PinCode: accInfo.PinCode != null ? accInfo.PinCode : "",
        });
        setPreviewUrl("data:image/jpeg;base64," + accInfo.ImageData);
      }
    } else if (accountDetail.result == 200) {
      if (params.id) {
        toast("Account updated successfully");
        setTimeout(() => {
          navigate("/app/ManageAccount");
          // window.location.reload();
        }, 1000);
      } else {
        toast("Account created successfully");
        setTimeout(() => {
          navigate("/app/ManageAccount");
          // window.location.reload();
        }, 1000);
      }
    } else if (accountDetail.error.status == 404) {
      toast(accountDetail.error.data);
    } else if (accountDetail.error.status == 401) {
      toast(accountDetail.error.data);
    } else if (accountDetail.error.status == 403) {
      toast(accountDetail.error.data);
    } else if (accountDetail.error.status == 400) {
      toast(accountDetail.error.data);
    } else if (accountDetail.error.status == undefined) {
    } else {
      if (accountDetail.error != "") {
        toast(accountDetail.error);
      }
    }
  }, [accountDetail]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  //Validate form
  const validate = (values) => {
    const errors = {};
    let formIsValid = true;

    if (values.AccountName.length < 3) {
      errors.AccountName = "Please enter greater then 3 character";
      formIsValid = false;
    }
    if (values.AccountName.length > 30) {
      errors.AccountName = "Please enter less than 30 character";
      formIsValid = false;
    }
    if (!regexConstant.regexForSpec.test(values.AccountName)) {
      errors.AccountName = "Please enter valid account name";
      formIsValid = false;
    }
    if (!values.AccountName) {
      errors.AccountName = "Account Name is required!";
      formIsValid = false;
    }
    if (!regexConstant.regexForSpec.test(values.Address)) {
      errors.Address = "Please enter valid address";
      formIsValid = false;
    }
    if (values.Address.length < 3) {
      errors.Address = "Please enter greater then 3 character";
      formIsValid = false;
    }
    if (values.Address.length > 50) {
      errors.Address = "Please enter less than 50 character";
      formIsValid = false;
    }
    if (!values.Address) {
      errors.Address = "Address is required!";
      formIsValid = false;
    }

    if (values.ContactPerson.length < 3) {
      errors.ContactPerson = "Please enter greater then 3 character";
      formIsValid = false;
    }
    if (values.ContactPerson.length > 30) {
      errors.ContactPerson = "Please enter less than 30 character";
      formIsValid = false;
    }
    if (!regexConstant.regexForSpec.test(values.ContactPerson)) {
      errors.ContactPerson = "Please enter valid contact person";
      formIsValid = false;
    }
    if (!values.ContactPerson) {
      errors.ContactPerson = "Contact Person is required!";
      formIsValid = false;
    }
    if (!regexConstant.regex.test(values.Email)) {
      errors.Email = "Email is not valid!";
      formIsValid = false;
    }
    if (!values.Email) {
      errors.Email = "Email is required!";
      formIsValid = false;
    }

    if (!regexConstant.onlyTennumber.test(values.PrimaryPhoneNo)) {
      errors.PrimaryPhoneNo = "Please enter a valid primary phone number";
      formIsValid = false;
    }
    if (!regexConstant.NumberRegex.test(values.PrimaryPhoneNo)) {
      errors.PrimaryPhoneNo = "Please enter a valid primary phone number";
      formIsValid = false;
    }
    if (!values.PrimaryPhoneNo) {
      errors.PrimaryPhoneNo = "Primary Phone No is required!";
      formIsValid = false;
    }
    if (values.SecondaryPhoneNo === values.PrimaryPhoneNo) {
      errors.SecondaryPhoneNo = "Primary Phone and Secondary Phone can not be same!";
      formIsValid = false;
    }
    if (!values.country) {
      errors.country = "Country is required!";
      formIsValid = false;
    }
    if (!values.State) {
      errors.State = "State is required!";
      formIsValid = false;
    }
    if (!values.City) {
      errors.City = "City is required!";
      formIsValid = false;
    }
    if (
      values.PinCode &&
      !regexConstant.pincodeValidRegex.test(values.PinCode)
    ) {
      errors.PinCode = "Please enter valid pincode";
      formIsValid = false;
    }
    if (values.PinCode && !regexConstant.numberOnly.test(values.PinCode)) {
      errors.PinCode = "Please enter number only";
      formIsValid = false;
    }
    if (values.PinCode.length > 6) {
      errors.PinCode = "Please enter 6 digit pincode";
      formIsValid = false;
    }
    if (
      values.SecondaryPhoneNo &&
      !regexConstant.NumberRegex.test(values.SecondaryPhoneNo)
    ) {
      errors.SecondaryPhoneNo = "Please enter a valid secondary phone number";
      formIsValid = false;
    }
    if (
      values.SecondaryPhoneNo &&
      !regexConstant.onlyTennumber.test(values.SecondaryPhoneNo)
    ) {
      errors.SecondaryPhoneNo = "Please enter a valid secondary phone number";
      formIsValid = false;
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // using for state
  const getAllStateInput = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      country: e.target.value,
      State: "",
      CityId: "",
      City: "",
    }));
    dispatch(getStateList(e.target.value));
    dispatch(getCityList(e.target.value));

    // getAllState(e.target.value);
  };
  // using for city
  const getAllCityInput = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      CityId: e.target.value,
      City: e.target.value,
    }));
  };

  const getCityByState = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      State: e.target.value,
      CityId: "",
      City: "",
    }));
    dispatch(getCityList(e.target.value));
  };

  // close all city

  const getLogoImage = (accountId) => {
    if (accountId) {
      dispatch(getLogoImageData(accountId));
    }
  };
  //    close data by ID here
  // Save Api
  let formData = new FormData();
  formData.append("AccountName", inputField.AccountName);
  formData.append("SecondaryPhoneNo", inputField.SecondaryPhoneNo);
  formData.append("Email", inputField.Email);
  formData.append("ContactPerson", inputField.ContactPerson);
  formData.append("PrimaryPhoneNo", inputField.PrimaryPhoneNo);
  formData.append("CityId", inputField.CityId);
  formData.append("Country", inputField.country);
  formData.append("Address", inputField.Address);
  formData.append("PinCode", inputField.PinCode);
  if (logoImage != null) {
    formData.append("file", logoImage);
  }

  // Save new account
  const saveAccountDetails = () => {
    dispatch(saveAccounts(formData));
  };

  // Update account
  const updateData = () => {
    if (logoImage != "") {
      formData.append("file", logoImage);
    }
    dispatch(updateAccounts(formData, params.id));
  };
  const [fileError, setFileError] = useState("");

  const navigate = useNavigate();
  // mention save method
  const submitForm = (e) => {
    e.preventDefault();
    if (validate(inputField)) {
      setIsSubmit(true);
      if (!params.id) {
        saveAccountDetails();
      } else {
        updateData();
      }
    }
  };
  // reset Data
  const resetData = () => {
    setInputField({
      AccountName: "",
      ContactPerson: "",
      Email: "",
      PrimaryPhoneNo: "",
      SecondaryPhoneNo: "",
      Address: "",
      country: "",
      State: "",
      PinCode: "",
    });
    navigate("/app/ManageAccount");
  };
  //Upload logo
  const uploadImage = (e) => {
    setFileError("");
    let file = e.target.files[0];
    var idxDot = file.name.lastIndexOf(".") + 1;
    var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
    if (extFile == "jpg" || extFile == "png") {
      if (file.type.indexOf("image") == -1) {
        setFileError("Invalid File!");
        return false;
      } else if (file.size >= FileSizes.sizeFiveMb) {
        setFileError("Please select a file less than 5 mb");
        return false;
      } else {
        setLogoImage(file);
        setFileName(file.name);
        setPreviewUrl(URL.createObjectURL(file));
      }
    } else {
      setFileError("Only jpg, png files are allowed!");
    }
  };

  return (
    <div style={{ marginBottom: "30px" }}>
      <ToastContainer autoClose={1000} />
      {/* --------------btn section----------- */}
      <Grid container sx={{ marginLeft: "-5px " }}>
        <Grid item xs={12} sm={12} md={11}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Box
                {...styles.tab}
              >
                <Box
                 {...styles.tabInside}
                >
                  {params.id ? "Update Account" : "New Account"}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
          <DefaultButton
            color="warning"
            variant="contained"
            size="small"
            Text="Back"
          />
        </Grid>
      </Grid>
      {/* --------------Form section----------- */}
      <form onSubmit={submitForm}>
        <Card className="border-radius-8 width-100" sx={{ marginTop: "-25px" }}>
          <div className="p-4">
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="AccountName"
                    label="Account Name *"
                    variant="standard"
                    name="AccountName"
                    value={inputField.AccountName}
                    onChange={inputsHandler}
                    placeHolder="Account Name"
                  />
                  <div className="errors">{formErrors.AccountName}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="ContactPerson"
                    label="Contact Person *"
                    variant="standard"
                    name="ContactPerson"
                    value={inputField.ContactPerson}
                    onChange={inputsHandler}
                    placeHolder="Contact Person"
                  />
                  <div className="errors">{formErrors.ContactPerson}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="Email"
                    label="Email Id *"
                    variant="standard"
                    name="Email"
                    value={inputField.Email}
                    onChange={inputsHandler}
                    placeHolder="Email Id"
                  />
                  <div className="errors">{formErrors.Email}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <DefaultInput
                        id="PrimaryPhoneNo"
                        label="Primary Phone Number *"
                        variant="standard"
                        name="PrimaryPhoneNo"
                        value={inputField.PrimaryPhoneNo}
                        onChange={inputsHandler}
                        inputProps={{ maxLength: 10 }}
                        placeHolder="Primary Phone Number"
                      />
                      <div className="errors">{formErrors.PrimaryPhoneNo}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <DefaultInput
                        id="SecondaryPhoneNo"
                        label="Secondary Phone Number"
                        variant="standard"
                        name="SecondaryPhoneNo"
                        inputProps={{ maxLength: 10 }}
                        value={inputField.SecondaryPhoneNo}
                        onChange={inputsHandler}
                        placeHolder="Secondary Phone Number"
                      />
                      <div className="errors">
                        {formErrors.SecondaryPhoneNo}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="Address"
                    label="Address*"
                    multiline
                    name="Address"
                    rows={4}
                    value={inputField.Address}
                    onChange={inputsHandler}
                  />
                  <div className="errors">{formErrors.Address}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        id="outlined-select-country"
                        select
                        size="small"
                        name="country"
                        label="Select Country *"
                        value={inputField.country}
                        onChange={getAllStateInput}
                      >
                        {country.map((option) => (
                          <MenuItem key={option.Id} value={option.Id}>
                            {option.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <div className="errors">{formErrors.country}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        label="State *"
                        name="State"
                        value={inputField.State}
                        onChange={getCityByState}
                      >
                        {State.map((option) => (
                          <MenuItem key={option.Id} value={option.Id}>
                            {option.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <div className="errors">{formErrors.State}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        id="outlined-select-City"
                        select
                        size="small"
                        label="City *"
                        name="City"
                        value={inputField.City}
                        onChange={getAllCityInput}
                      >
                        {City.map((option) => (
                          <MenuItem key={option.Id} value={option.Id}>
                            {option.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <div className="errors">{formErrors.City}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <DefaultInput
                        id="PinCode"
                        label="Pin Code"
                        variant="standard"
                        name="PinCode"
                        value={inputField.PinCode}
                        onChange={inputsHandler}
                        placeHolder="Pin code"
                      />
                      <div className="errors">{formErrors.PinCode}</div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <div class="drop_box">
                    <Grid item xs={12} sm={12} md={12} className="mb-12">
                      <label htmlFor="contained-button-file">
                        <input
                          type="file"
                          hidden
                          accept="image/png, image/jpeg"
                          id="logoImage"
                          onChange={uploadImage}
                        />
                        <label
                          for="logoImage"
                          class="btn btn-warning"
                          tabindex="0"
                        >
                          Upload Account Logo
                        </label>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}></Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {previewURL != null ? (
                        <div className="img-section" for="logoImage">
                          <img src={previewURL} width="200" height="100" />{" "}
                        </div>
                      ) : null}
                    </Grid>
                    <div className="text-align-center">
                      <p>Files Supported: JPG, PNG</p>
                    </div>
                  </div>
                  <div className="errors">{fileError}</div>
                </Grid>
              </Grid>
            </div>

            <div className="buttonSecton">
              <div className="mx-3" style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                 {...styles.SaveBtn}
                  className="btn-orange "
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </form>
    </div>
  );
}
