import React, { useState } from 'react';
import Dashboard from './Dashboard';
import { useMediaQuery } from 'react-responsive'

import MobileDashboard from '../Dashboard/MobileDashboard';
export default function BasicTable() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 760px)' })

    return (
        <div>
            <div>
                {isTabletOrMobile ? <div>You are on a tablet or a mobile p
                    <MobileDashboard/>
                </div> :
                <div><Dashboard /></div>
                }
            </div>
        </div>
    );
}
