import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TablePagination from "@mui/material/TablePagination";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Grid, Card, TextField, Menu, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import Filter from "../../Component/Filter";
import { useDispatch, useSelector } from "react-redux";

import ApiServices from "../../Services/ApiServices";
import moment from "moment";
import { getCountryList } from "../../Redux/Country/CountryAction";
import { getStateList } from "../../Redux/State/StateAction";
import { getCityList } from "../../Redux/City/CityAction";
import img from "../../assets/Images/eye.png";
import { userRoles } from "../../shared/constants";
import TuneIcon from "@mui/icons-material/Tune";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Pagination from "@mui/material/Pagination";
import { BorderStyle } from "@material-ui/icons";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  ul: {
    "& .Mui-selected": {
      backgroundColor: "transparent !important",
      color: "#9747FF !important",
    },
  },

  table: {
    minWidth: 600,
    "& .MuiTableCell-root": {
      padding: "9px",
    },
    "& tbody>.MuiTableRow-root:hover": {
      background: "#9747FF",
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      fontWeight: "700",
      borderBottom: "1px dashed #e0e0e0 !important",
    },
    "&:hover .MuiTableCell-root, &:hover .material-icons-outlined": {
      color: "#fff !important",
    },
    "&:hover .bg-ligtGrey": {
      background: "rgba(192,192,192,0.3); !important",
    },
    "&:hover .bg-darkGrey": {
      background: "rgba(192,192,192,0.3); !important",
    },
  },
  newPosOfDialog: {
    position: "absolute !important",
    top: "2%",
    left: "55%",
    width: "50% !important",
    borderRadius: "20px !important",
    height: "100% !important",
    boxShadow: "none!important",
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const pageState = { skip: 0, top: 10, isDeleted: false, query: "" };

const getReportsDetails = [
  {
    ProductName: "Mango",
    Quantity: "1 kg",
    Amount: "90.00",
  },
  {
    ProductName: "Apple",
    Quantity: "1 kg",
    Amount: "75.00",
  },
  {
    ProductName: "Mango",
    Quantity: "1 kg",
    Amount: "90.00",
  },
  {
    ProductName: "Apple",
    Quantity: "1 kg",
    Amount: "75.00",
  },
  {
    ProductName: "Mango",
    Quantity: "1 kg",
    Amount: "90.00",
  },
  {
    ProductName: "Apple",
    Quantity: "1 kg",
    Amount: "75.00",
  },
  {
    ProductName: "Mango",
    Quantity: "1 kg",
    Amount: "90.00",
  },
  {
    ProductName: "Apple",
    Quantity: "1 kg",
    Amount: "75.00",
  },
  {
    ProductName: "Mango",
    Quantity: "1 kg",
    Amount: "90.00",
  },
  {
    ProductName: "Apple",
    Quantity: "1 kg",
    Amount: "75.00",
  },
  {
    ProductName: "Mango",
    Quantity: "1 kg",
    Amount: "90.00",
  },
  {
    ProductName: "Apple",
    Quantity: "1 kg",
    Amount: "75.00",
  },
];
export default function BasicTable(props) {
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);
  const classes = useStyles();
  const [noFoundData, setnoFoundData] = useState(false);

  return (
    <div>
      <Card className="mt-5 border-radius-14 width-100">
        <div className="pa-2">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={2}>
              <div className="text-center-imp">
                <div className="text-color-grey font-14">
                  Total Number Of Bills
                </div>
                {props.saleWiseData?.TotalItems ? (
                  <div className="text-offPurple font-16 font-weight-bold">
                    {props.saleWiseData?.TotalItems}
                  </div>
                ) : (
                  <div className="text-offPurple font-16 font-weight-bold">
                    -
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <div className="text-center-imp">
                <div className="text-center-imp text-color-grey font-14">
                  Total Sales Amount
                </div>
                {props.saleWiseData?.OrderResponseHead?.TotalSales ? (
                  <div className="text-offPurple font-16 font-weight-bold">
                    {numberFormat(
                      props.saleWiseData?.OrderResponseHead?.TotalSales
                    )}
                  </div>
                ) : (
                  <div className="text-offPurple font-16 font-weight-bold">
                    -
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <div className="text-center-imp">
                <div>
                  <div className="text-color-grey font-14">Cash Sales</div>
                </div>
                {props.saleWiseData?.OrderResponseHead?.CashSales ? (
                  <div className="text-offPurple font-16 font-weight-bold">
                    {numberFormat(
                      props.saleWiseData?.OrderResponseHead?.CashSales
                    )}
                  </div>
                ) : (
                  <div className="text-offPurple font-16 font-weight-bold">
                    -
                  </div>
                )}
                <div className="text-color-grey font-12">
                  (Bill Count :{" "}
                  {props.saleWiseData?.OrderResponseHead ? (
                    <span className="text-offPurple font-12 font-weight-bold">
                      {props.saleWiseData?.OrderResponseHead?.CashSalesCount}
                    </span>
                  ) : (
                    <span className="text-offPurple font-12 font-weight-bold">
                      -
                    </span>
                  )}
                  )
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <div className="text-center-imp">
                <div>
                  <div className="text-color-grey font-14">Card Sales</div>
                </div>
                {props.saleWiseData?.OrderResponseHead?.CardSales ? (
                  <div className="text-offPurple font-16 font-weight-bold">
                    {numberFormat(
                      props.saleWiseData?.OrderResponseHead?.CardSales
                    )}
                  </div>
                ) : (
                  <div className="text-offPurple font-16 font-weight-bold">
                    -
                  </div>
                )}
                <div className="text-color-grey font-12">
                  (Bill Count :
                  {props.saleWiseData?.OrderResponseHead ? (
                    <span className="text-offPurple font-12 font-weight-bold">
                      {props.saleWiseData?.OrderResponseHead?.CardSalesCount}
                    </span>
                  ) : (
                    <span className="text-offPurple font-12 font-weight-bold">
                      -
                    </span>
                  )}
                  )
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <div className="text-center-imp">
                <div>
                  <div className="text-color-grey font-14">UPI Sales</div>
                </div>
                {props.saleWiseData?.OrderResponseHead?.UPISales ? (
                  <div className="text-offPurple font-16 font-weight-bold">
                    {numberFormat(
                      props.saleWiseData?.OrderResponseHead?.UPISales
                    )}
                  </div>
                ) : (
                  <div className="text-offPurple font-16 font-weight-bold">
                    -
                  </div>
                )}
                <div className="text-color-grey font-12">
                  (Bill Count :
                  {props.saleWiseData?.OrderResponseHead ? (
                    <span className="text-offPurple font-12 font-weight-bold">
                      {props.saleWiseData?.OrderResponseHead?.UPISalesCount}
                    </span>
                  ) : (
                    <span className="text-offPurple font-12 font-weight-bold">
                      -
                    </span>
                  )}
                  )
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <TableContainer component={Paper} className="mt-10 border-radius-20">
          <Table
            className={classes.table}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow className="text-offGrey text-center-imp font-14">
                <TableCell className="text-offGrey text-center-imp font-14">
                  Bill
                </TableCell>
                <TableCell className="text-offGrey text-center-imp font-14">
                  Bill Date
                </TableCell>
                <TableCell className="text-offGrey text-center-imp font-14">
                  Total Amount
                </TableCell>
                <TableCell className="text-offGrey text-center-imp font-14">
                  <div className="flexClass">
                    <div>Payment Mode</div>
                    <div className="mx-5">
                      <IconButton
                        aria-label="TuneIcon"
                        className="p-0"
                        id="basic-button"
                        variant="contained"
                        aria-controls={
                          props.openMenu ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={props.openMenu ? "true" : undefined}
                        onClick={props.handleClickMenu}
                      >
                        <TuneIcon />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={props.anchorEl}
                        open={props.openMenu}
                        onClose={props.handleCloseMenu}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        sx={{ width: 320 }}
                      >
                        <MenuItem id="ALL" onClick={props.handleCloseMenu}>
                          ALL
                        </MenuItem>
                        <MenuItem id="UPI" onClick={props.handleCloseMenu}>
                          UPI
                        </MenuItem>
                        <MenuItem id="CASH" onClick={props.handleCloseMenu}>
                          CASH
                        </MenuItem>
                        <MenuItem id="CARD" onClick={props.handleCloseMenu}>
                          CARD
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="text-offGrey text-center-imp font-14">
                  <div className="flexClass">
                    <div>Status</div>
                  </div>
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            {props.noFoundData == false ? (
              <TableBody>
                {props.saleWiseData?.Data?.map((row, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell className="text-center-imp text-color-grey">
                      {row.InvoiceNumberStr}
                    </TableCell>
                    <TableCell className="text-center-imp text-color-grey">
                      {moment(row.OrderDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell className="text-center-imp text-color-grey">
                      {numberFormat(row.OrderAmount)}
                    </TableCell>
                    <TableCell className="text-center-imp text-color-grey">
                      {row.PaymentMode}
                    </TableCell>
                    <TableCell className="text-center-imp text-offGreen">
                      <div className="bg-darkGrey p-8 border-radius-8  font-weight-bold">
                        {row.Status}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="eyes-button">
                        <Button
                          size="small"
                          onClick={(e) => {
                            props.handleOpen(row);
                          }}
                          style={{
                            width: "45%",
                            height: "25px",
                            zIndex: "1111",
                          }}
                        ></Button>
                        <div className="eyes-img">
                          <RemoveRedEyeIcon sx={{ color: "#999999" }} />
                        </div>
                      </div>
                      <Dialog
                        fullScreen
                        open={props.open}
                        onClose={props.handleClose}
                        TransitionComponent={Transition}
                        classes={{
                          paper: classes.newPosOfDialog,
                        }}
                        sx={{
                          "& .MuiPaper-elevation24": {
                            boxShadow: "none!important",
                          },
                          " .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
                            background: "rgba(0,0,0,0.1)!important",
                          },
                          "& .MuiBackdrop-root": {
                            opacity: "0.3!important",
                          },
                        }}
                      >
                        <div className="p-4">
                          <div className="mb-10">
                            <div>
                              <div className="text-color-grey font-18">
                                Bill Number:{" "}
                                <span className="text-offPurple font-18 font-weight-bold">
                                  {props.getDetailsByID?.InvoiceNumberStr}
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="text-color-grey font-18">
                                Bill Date:{" "}
                                <span className="text-offPurple font-18 font-weight-bold">
                                  {moment(
                                    props.getDetailsByID?.OrderDate
                                  ).format("DD-MM-YYYY")}
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="text-color-grey font-18">
                                Total Amount:{" "}
                                <span className="text-offPurple font-18 font-weight-bold">
                                  {props.getDetailsByID?.OrderAmount}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            {props.getDetailsData?.length == 0 ? (
                              <Grid container spacing={1} className="mb-10">
                                <Grid item sm={12} xs={12} md={12}>
                                  <div
                                    className="text-center-imp text-offGrey  font-18 font-weight-bold"
                                    style={{ marginTop: "150px" }}
                                  >
                                    No Data Found.
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <>
                                <Grid container spacing={1} className="mb-10">
                                  <Grid item sm={12} xs={12} md={4}>
                                    <div className="text-center-imp text-offGrey  font-18 font-weight-bold">
                                      Item Name
                                    </div>
                                  </Grid>
                                  <Grid item sm={12} xs={12} md={4}>
                                    <div className="text-center-imp text-offGrey  font-18 font-weight-bold">
                                      Quantity
                                    </div>
                                  </Grid>
                                  <Grid item sm={12} xs={12} md={4}>
                                    <div className="text-center-imp text-offGrey  font-18 font-weight-bold">
                                      Price
                                    </div>
                                  </Grid>
                                </Grid>
                                <div
                                  style={
                                    props.getDetailsData?.length > 10
                                      ? { overflow: "auto", height: "350px" }
                                      : null
                                  }
                                >
                                  {props.getDetailsData?.map(
                                    (rowData, index) => (
                                      <Grid container spacing={1}>
                                        <Grid
                                          item
                                          sm={12}
                                          xs={12}
                                          md={4}
                                          className="mb-10"
                                        >
                                          <div className="text-center-imp text-color-grey font-16 font-weight-700">
                                            {rowData.ProductName}
                                          </div>
                                        </Grid>
                                        <Grid item sm={12} xs={12} md={4}>
                                          <div className="text-center-imp text-color-grey font-16 font-weight-700">
                                            {rowData.Quantity.toFixed(3)}{" "}
                                            {rowData.UOM}
                                          </div>
                                        </Grid>
                                        <Grid item sm={12} xs={12} md={4}>
                                          <div className="text-center-imp text-color-grey font-16 font-weight-700">
                                            {rowData.Amount}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <div className="text-align-center font-18 font-bold py-15">
                No data found...
              </div>
            )}
          </Table>
          <TablePagination
            sx={{
              ".css-pdct74-MuiTablePagination-selectLabel": {
                fontFamily: "Lato !important",
              },
              ".css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input":
                {
                  fontFamily: "Lato !important",
                },
              ".css-levciy-MuiTablePagination-displayedRows": {
                fontFamily: "Lato !important",
              },
            }}
            component="div"
            count={props.TotalItems ? props.TotalItems : 0}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </div>
  );
}
