import React, { useState, useEffect } from "react";
import {
  Avatar,
  MenuItem,
  ListItem,
  Tooltip,
  DialogContent,
  DialogActions,
  Dialog,
  Box,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  DialogTitle,
  Menu,
  Button,
  Typography,
  Modal,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  Link,
  Outlet,
  useLocation,
  useParams,
  useHistory,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetails } from "../../Redux/User/UserAction";
import ApiServices from "../../Services/ApiServices";

import imgOne from "../../assets/Images/dashboard-menu/MicrosoftTeams-image.png";
import imgTwo from "../../assets/Images/CloudManagemnSystem.jpg";
import User from "../../assets/Images/account.png";
import passwordIcon from "../../assets/Images/reload.png";
import powerOff from "../../assets/Images/power-off.png";
import img1 from "../../assets/Images/dashboard-menu/AccountLogo.svg";
import img2 from "../../assets/Images/dashboard-menu/users svg.svg";
import img3 from "../../assets/Images/dashboard-menu/licenseLogo.svg";
import img5 from "../../assets/Images/dashboard-menu/ReportsLogo.svg";
import img7 from "../../assets/Images/dashboard-menu/GAICentral Logo.svg";
import storeicon from "../../assets/Images/dashboard-menu/store svg.svg";
import categoryIcon from "../../assets/Images/dashboard-menu/CategoryLogo.svg";
import packageIcon from "../../assets/Images/dashboard-menu/Product svg.svg";

import AccountCircle from "@mui/icons-material/AccountCircle";
import { userRoles } from "../../shared/constants";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import {
  addEventListeners,
  removeEventListeners,
} from "../../util/eventListenerUtil";

import { styles } from "./Style/DashboardStyle";

// -------Menu list-----------------
const menuName = [
  {
    text: "Account",
    activeIndex: 0,
    link: "/app/ManageAccount",
    img: img1,
    code: "account",
    isActive: true,
  },
  {
    text: "Stores",
    activeIndex: 1,
    link: "/app/ManageStore",
    img: storeicon,
    code: "store",
    isActive: false,
  },
  {
    text: "Users",
    activeIndex: 2,
    link: "/app/ManageUser",
    img: img2,
    code: "user",
    isActive: false,
  },
  {
    text: "Licenses",
    activeIndex: 3,
    link: "/app/License",
    img: img3,
    code: "licence",
    isActive: false,
  },
  {
    text: "Category",
    activeIndex: 4,
    link: "/app/Category",
    img: categoryIcon,
    code: "category",
    isActive: false,
  },
  {
    text: "Product",
    activeIndex: 5,
    link: "/app/Product",
    img: packageIcon,
    code: "product",
    isActive: false,
  },
  {
    text: "GAI Central",
    activeIndex: 6,
    link: "/app/MapItems",
    img: img7,
    code: "item",
    isActive: false,
  },
  {
    text: "Reports",
    activeIndex: 7,
    link: "/app/ReportsDashboard",
    img: img5,
    code: "reports",
    isActive: false,
  },
];

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MiniDrawer(props) {
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  const userDetailById = useSelector((s) => s.userReducer);

  const [open, setOpen] = React.useState(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [getOpenLog, setOpenLog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [currentUserDetails, setCurrentUser] = useState({});
  const [menuItems, setMenuItemsForRoles] = useState([]);

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [getIdByuser, setIdByUser] = useState({});
  const [acoountData, setAccountData] = useState([]);
  const [getModelId, setModelId] = useState("");

  useEffect(() => {
    const createTimeout1 = () =>
      setTimeout(() => {
        onLogOffCall();
      }, 300000);

    const createTimeout2 = () =>
      setTimeout(() => {
        onLogOffCall();
      }, 300000);

    const listener = () => {
      clearTimeout(timeout);
      timeout = createTimeout1();
    };

    // Initialization
    let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1();
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen]);

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));

    if (userDetail) {
      setCurrentUser(userDetail);
      bindMenuForUserRole(userDetail);
    }
    dispatch(getProfileDetails(userDetail.userId));
    var localObj = localStorage.getItem("currentActiveIndex");
    handleClick({}, localObj ? localObj : 0);
  }, []);

  useEffect(() => {
    if (userDetailById.UserDetailsProfile) {
      setIdByUser(userDetailById.UserDetailsProfile);
    }
  }, [userDetailById]);

  useEffect(() => {
    getAllAccount();
  }, []);

  const LogOut = () => {
    setOpenLog(!getOpenLog);
    setAnchorEl(null);
  };
  const onLogOffCall = () => {
    toast("Logout successfully");
    setTimeout(() => {
      localStorage.clear();
      navigate("/");
    }, 1000);
  };
  const closeLogout = () => {
    console.log("logout");
    setOpenLog(!getOpenLog);
  };
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    localStorage.removeItem("currentActiveIndex");
  };
  const bindMenuForUserRole = (currentuser) => {
    let getPermission = {};
    currentuser.permissions.forEach((element) => {
      getPermission[`${element}`] = element;
    });
    menuName.map((item) => {
      switch (item.code) {
        case "account":
          if (
            currentuser.userRole != userRoles.StoreManager &&
            currentuser.userRole != userRoles.StoreStaff &&
            getPermission.ViewAccount == "ViewAccount"
          )
            menuItems.push(item);
          break;
        case "user":
          if (
            currentuser.userRole != userRoles.SuperAdmin &&
            currentuser.userRole != userRoles.StoreManager &&
            currentuser.userRole != userRoles.StoreStaff &&
            getPermission.ViewUser == "ViewUser"
          )
            menuItems.push(item);
          break;
        case "store":
          if (
            currentuser.userRole != userRoles.SuperAdmin &&
            getPermission.ViewStore == "ViewStore"
          )
            menuItems.push(item);
          break;
        case "licence":
          if (getPermission.ViewLicense == "ViewLicense") menuItems.push(item);
          break;
        case "product":
          if (getPermission.ViewProducts == "ViewProducts")
            menuItems.push(item);
          break;
        case "category":
          if (getPermission.ViewCategory == "ViewCategory")
            menuItems.push(item);
          break;
        case "reports":
          if (
            currentuser.userRole != userRoles.SuperAdmin &&
            currentuser.userRole != userRoles.StoreManager &&
            currentuser.userRole != userRoles.StoreStaff
          )
            menuItems.push(item);
          break;
        case "item":
          menuItems.push(item);
          break;
      }
    });
  };

  const handleClick = (event, key) => {
    menuItems.map((activeTab, index) => {
      if (index == key) {
        activeTab.isActive = true;
        localStorage.setItem("currentActiveIndex", key);
      } else {
        activeTab.isActive = false;
      }
    });
  };

  const getModelDataId = (e) => {
    setModelId(e.target.value);
  };
  const [getAccountValueId, setAccountValue] = useState("");
  const getAllAccountId = (e) => {
    setAccountValue(e.target.value);
  };

  const getAllAccount = () => {
    ApiServices.getData(`/Account/all/dropdown`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((res) => {});
  };

  return (
    <Box sx={{ minWidth: "100%" }}>
      <ToastContainer autoClose={1000} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* ----------Appbar--------- */}
        <AppBar position="fixed" open={open} {...styles.Appbar}>
          <Toolbar {...styles.Toolbar}>
            <Box {...styles.imgBox}>
              <img src={imgOne} style={{ width: "50px" }} loading="lazy" />
              <img style={{ width: "250px" }} src={imgTwo} loading="lazy" />
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              {(location.pathname == "/app/MapItems" ||
                location.pathname == "/app/ReportsDashboard" ||
                (currentUserDetails?.userRole == 2 &&
                  location.pathname == "/app/Category") ||
                (currentUserDetails?.userRole == 2 &&
                  location.pathname == "/app/Product")) && (
                <Box>{getIdByuser.Account?.AccountName}</Box>
              )}
              {auth && (
                <Box>
                  <Avatar className="bg-white mx-5">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                    >
                      <AccountCircle />
                    </IconButton>
                  </Avatar>

                  <Menu
                    id="menu-appbar"
                    className="child-menu-drawer"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      {getIdByuser.FirstName} {getIdByuser.LastName}
                    </MenuItem>
                    <Link
                      to="/app/Profile"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <MenuItem onClick={handleClose}>
                        <img src={User} loading="lazy" />{" "}
                        <span className="mx-5">Profile</span>{" "}
                      </MenuItem>
                    </Link>
                    <Link
                      to="/app/ChangePassword"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <MenuItem onClick={handleClose}>
                        <img src={passwordIcon} loading="lazy" />{" "}
                        <span className="mx-5">Change Password</span>
                      </MenuItem>
                    </Link>
                    <MenuItem onClick={LogOut}>
                      <Box onClick={LogOut}>
                        <img src={powerOff} loading="lazy" />{" "}
                        <span className="mx-5">LogOut</span>
                      </Box>
                      <Dialog
                        open={getOpenLog}
                        fullWidth
                        maxWidth="sm"
                        sx={{
                          "& .MuiPaper-elevation24": {
                            boxShadow: "none!important",
                          },
                          " .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
                            background: "rgba(0,0,0,0.3)!important",
                          },
                          "& .MuiBackdrop-root": {
                            opacity: "0.3!important",
                          },
                        }}
                      >
                        <BootstrapDialogTitle
                          id="customized-dialog-title"
                          className="p-2"
                          onClose={closeLogout}
                        >
                          Logout
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                          <Typography gutterBottom>
                            Are you sure you want to logout
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            variant="contained"
                            className="border-radius-20 btn-orange w-100 margin-top-bottom-10"
                            onClick={onLogOffCall}
                          >
                            Yes
                          </Button>
                          <Button
                            variant="contained"
                            className="border-radius-20 btn-orange w-100 margin-top-bottom-10"
                            onClick={closeLogout}
                          >
                            No
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        {/*---------parent Box--------- */}
        <Box {...styles.parentBox}>
          {/* ----------List--------- */}
          <List sx={{ width: "8%", position: "fixed", top: 70, left: 5 }}>
            {menuItems.map((menuText, index) => (
              <ListItem
                key={menuText.text}
                component={Link}
                to={menuText.link}
                selected={menuText.link === location.pathname}
                onClick={(event) => handleClick(event, index)}
                sx={{
                  "&:hover": {
                    backgroundColor: "#f1f2f7",
                  },
                  "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor: "#f1f2f7",
                  },
                }}
              >
                <Tooltip title={menuText.text}>
                  <ListItemButton
                    sx={{
                      flexDirection: "column",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 0,
                      margin: 0,
                      "&:hover": {
                        backgroundColor: "#f1f2f7",
                      },
                      "&.Mui-selected, &.Mui-selected:hover": {
                        backgroundColor: "#f1f2f7",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <img
                        src={menuText.img}
                        className={menuText.isActive ? "activeTabColor" : ""}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={menuText.text}
                      primaryTypographyProps={{
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 500,
                        color: "#8B8D9D",
                        fontSize: "10px",
                      }}
                      sx={{}}
                      className={menuText.isActive ? "activeTabColor" : ""}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
          {/* ----------Outlet--------- */}
          <Box {...styles.outlet}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
