import apiService from "../../Services/ApiServices"
import {
  GET_STORE_REQUEST,
  GET_STORE_FAILURE,
  GET_ACTIVE_STORE_SUCCESS,
  GET_INACTIVE_STORE_SUCCESS,
  GET_STOREDETAIL_REQUEST,
  GET_STOREDETAIL_SUCCESS,
  GET_STOREDETAIL_FAILURE,
  SAVE_STORE_REQUEST,
   SAVE_STORE_SUCCESS,
   SAVE_STORE_FAILURE,
  GET_IMAGE_REQUEST,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_FAILURE

} from '../Types'

export const saveStore = (formData) => {
  try {

    return (dispatch) => {
      dispatch( saveStoreRequest())
      let url =`/Store/create`;
      apiService.saveData(url,formData)
        .then(res => {
          
          const result = res.status;
          if (result==200) {
            dispatch( saveStoreSuccess(result))
          }
          else {
            
            dispatch( saveStoreFailure(res.response.status))
          }

        }).catch(err => { dispatch( saveStoreFailure(err)) });
    }
  } catch (err) {

  }
};

export const updateStore = (formData,accountId) => {
  try {

    return (dispatch) => {
      dispatch( saveStoreRequest())
      let url =`/Store/update/${accountId}`;
      apiService.saveData(url,formData)
        .then(res => {
          
          const result = res.status;
          if (result==200) {
            dispatch( saveStoreSuccess(result))
          }
          else {
            
            dispatch( saveStoreFailure(res.response.status))
          }

        }).catch(err => { dispatch( saveStoreFailure(err)) });
    }
  } catch (err) {

  }
};
export const getStoreDetails = (accountId) => {
  try {

    return (dispatch) => {
      dispatch( getStoreDetailRequest())
      let url = `/Store/${accountId}`;
      apiService.getData(url)
        .then(res => {
          
          const StoreDetail = res.data
          if (res.data) {
            dispatch( getStoreDetailSuccess(StoreDetail))
          }
          else {
            dispatch( getStoreDetailFailure('err'))
          }

        }).catch(err => { dispatch( getStoreDetailFailure(err)) });
    }
  } catch (err) {

  }
};

export const getDataByPagination = (pageState) => {
  try {
   
    return (dispatch) => {
      dispatch( getStoreRequest())
      let url = `/Store/all?skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId != "" && pageState.accountId != undefined) {
        url += `&accountId=${pageState.accountId}`;
      }
      apiService.getData(url)
        .then(res => {
          const store = res.data
          if (res.data.Data == "") {
            dispatch( getStoreFailure('err'))
          }
          else {
            dispatch( getStoreSuccess(store))
          }

        }).catch(err => { dispatch( getStoreFailure(err)) });
    }
  } catch (err) {

  }
};


export const getInactiveDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch( getStoreRequest())
      let url = `/Store/all?deleted=${true}&skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId != "") {
        url += `&accountId=${pageState.accountId}`;
      }
      apiService.getData(url)
        .then(res => {
          const store = res.data
          if (res.data.Data == "") {
            dispatch( getStoreFailure('err'))
          }
          else {
            dispatch( getInactiveStoreSuccess(store))
          }

        }).catch(err => { dispatch( getStoreFailure(err)) });
    }
  } catch (err) {

  }
};

export const getLogoImageData= (storeId) => {
  try {
    return (dispatch) => {
      dispatch(getImageRequest())
      let url = `/Store/logo/${storeId}`;
      apiService.getData(url)
        .then(res => {
          const imageData = res.data
          if (res.data) {
            dispatch(getImageSuccess(imageData))
          }
          else {
            dispatch(getImageFailure('err'))
          }

        }).catch(err => { dispatch(getImageFailure(err)) });
    }
  } catch (err) {

  }
}


export const  getStoreRequest = () => {
  return {
    type: GET_STORE_REQUEST
  }
}

export const  getStoreSuccess = store => {

  return {
    type: GET_ACTIVE_STORE_SUCCESS,
    payload: store
  }
}

export const  getInactiveStoreSuccess = store => {

  return {
    type: GET_INACTIVE_STORE_SUCCESS,
    payload: store
  }
}

export const  getStoreFailure = error => {
  return {
    type: GET_STORE_FAILURE,
    payload: error
  }
}

//Get account Details
export const  getStoreDetailRequest = () => {
  return {
    type: GET_STOREDETAIL_REQUEST
  }
}

export const  getStoreDetailSuccess = store => {

  return {
    type: GET_STOREDETAIL_SUCCESS,
    payload: store
  }
}

export const  getStoreDetailFailure = error => {
  return {
    type: GET_STOREDETAIL_FAILURE,
    payload: error
  }
}

//Save account Details
export const  saveStoreRequest = () => {
  return {
    type: SAVE_STORE_REQUEST
  }
}

export const  saveStoreSuccess = result => {

  return {
    type:  SAVE_STORE_SUCCESS,
    payload: result
  }
}

export const  saveStoreFailure = error => {
  return {
    type:  SAVE_STORE_FAILURE,
    payload: error
  }
}
  //Get logo image
  export const getImageRequest = () => {
    return {
      type: GET_IMAGE_REQUEST
    }
  }
  
  export const getImageSuccess = result => {
  
    return {
      type: GET_IMAGE_SUCCESS,
      payload: result
    }
  }
  
  export const getImageFailure = error => {
    return {
      type: GET_IMAGE_FAILURE,
      payload: error
    }
}




