import apiService from "../../Services/ApiServices";
import {
  GET_USER_REQUEST,
  GET_USER_FAILURE,
  GET_ACTIVE_USER_SUCCESS,
  GET_INACTIVE_USER_SUCCESS,
  GET_USERDETAILS_REQUEST,
  GET_USERDETAILS_SUCCESS,
  GET_USERDETAILS_FAILURE,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  GET_STORE_USER_REQUEST,
  GET_USERDETAILSPROFILE_REQUEST,
  GET_USERDETAILSPROFILE_SUCCESS,
  GET_USERDETAILSPROFILE_FAILURE,
} from "../Types";
export const getUserRequest = () => {
  return {
    type: GET_USER_REQUEST,
  };
};

export const getUserSuccess = (terminal) => {
  return {
    type: GET_ACTIVE_USER_SUCCESS,
    payload: terminal,
  };
};

export const getInactiveUserSuccess = (accounts) => {
  return {
    type: GET_INACTIVE_USER_SUCCESS,
    payload: accounts,
  };
};

export const getUserFailure = (error) => {
  return {
    type: GET_USER_FAILURE,
    payload: error,
  };
};
// here mention action for get data
export const getDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getUserRequest());
      let url = `/User/account?skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId) {
        url += `&accountId=${pageState.accountId}`;
      }
      apiService
        .getData(url)
        .then((res) => {
          const user = res.data;
          if (res.data.Data == "") {
            dispatch(getUserFailure("err"));
          } else {
            dispatch(getUserSuccess(user));
          }
        })
        .catch((err) => {
          dispatch(getUserFailure(err));
        });
    };
  } catch (err) {}
};

export const getInactiveDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getUserRequest());
      let url = `/User/account?deleted=${true}&skip=${pageState.skip}&top=${
        pageState.top
      }`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId != "" && pageState.accountId != undefined) {
        url += `&accountId=${pageState.accountId}`;
      }
      apiService
        .getData(url)
        .then((res) => {
          const user = res.data;
          if (res.data.Data == "") {
            dispatch(getUserFailure("err"));
          } else {
            dispatch(getInactiveUserSuccess(user));
          }
        })
        .catch((err) => {
          dispatch(getUserFailure(err));
        });
    };
  } catch (err) {}
};
// save terminal return type
export const saveUserRequest = () => {
  return {
    type: SAVE_USER_REQUEST,
  };
};

export const saveUserSuccess = (result) => {
  return {
    type: SAVE_USER_SUCCESS,
    payload: result,
  };
};

export const saveUserFailure = (error) => {
  return {
    type: SAVE_USER_FAILURE,
    payload: error,
  };
};
// here mention action for save terminal
export const saveUser = (formData) => {
  try {
    return (dispatch) => {
      dispatch(saveUserRequest());
      let url = `/User/account/create`;
      apiService
        .saveData(url, formData)
        .then((res) => {
          const result = res.status;
          if (result == 200) {
            dispatch(saveUserSuccess(result));
          } else {
            dispatch(saveUserFailure(res.response.status));
          }
        })
        .catch((err) => {
          dispatch(saveUserFailure(err));
        });
    };
  } catch (err) {}
};
// here mention action for update data by ID
export const updateUser = (formData, accountId, params) => {
  try {
    return (dispatch) => {
      dispatch(saveUserRequest());
      let url = `/User/account/update/${accountId}`;
      apiService
        .saveData(url, formData)
        .then((res) => {
          const result = res.status;
          if (result == 200) {
            dispatch(saveUserSuccess(result));
          } else {
            dispatch(saveUserFailure(res.response.status));
          }
        })
        .catch((err) => {
          dispatch(saveUserFailure(err));
        });
    };
  } catch (err) {}
};
//Get data by id here mention return type
export const getUserDetailsRequest = () => {
  return {
    type: GET_USERDETAILS_REQUEST,
  };
};

export const getUserDetailsSuccess = (accounts) => {
  return {
    type: GET_USERDETAILS_SUCCESS,
    payload: accounts,
  };
};

export const getUserDetailsFailure = (error) => {
  return {
    type: GET_USERDETAILS_FAILURE,
    payload: error,
  };
};
// get terminal data by ID
export const getUserDetails = (accountId) => {
  try {
    return (dispatch) => {
      dispatch(getUserDetailsRequest());
      let url = `/User/${accountId}`;

      apiService
        .getData(url)
        .then((res) => {
          const userDetail = res.data;
          if (res.data) {
            dispatch(getUserDetailsSuccess(userDetail));
          } else {
            dispatch(getUserDetailsFailure("err"));
          }
        })
        .catch((err) => {
          dispatch(getUserDetailsFailure(err));
        });
    };
  } catch (err) {}
};
//Get data by id here mention return type
export const getUserDetailsProfileRequest = () => {
  return {
    type: GET_USERDETAILSPROFILE_REQUEST,
  };
};

export const getUserDetailsProfileSuccess = (UserDetailsProfile) => {
  return {
    type: GET_USERDETAILSPROFILE_SUCCESS,
    payload: UserDetailsProfile,
  };
};

export const getUserDetailsProfileFailure = (error) => {
  return {
    type: GET_USERDETAILS_FAILURE,
    payload: error,
  };
};
// ----------------------for profile---------------------
export const getProfileDetails = (id) => {
  try {
    return (dispatch) => {
      dispatch(getUserDetailsProfileRequest());
      let url = `/User/profile/${id}`;
      apiService
        .getData(url)
        .then((res) => {
          const UserDetailsProfile = res.data;
          if (res.data) {
            dispatch(getUserDetailsProfileSuccess(UserDetailsProfile));
          } else {
            dispatch(getUserDetailsProfileFailure("err"));
          }
        })
        .catch((err) => {
          dispatch(getUserDetailsProfileFailure(err));
        });
    };
  } catch (err) {}
};

export const getStoreUserRequest = (user) => {
  return {
    type: GET_STORE_USER_REQUEST,
    payload: user,
  };
};
export const getStoreData = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getUserRequest());
      let url = `/UserStore/account?skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.accountId != "" && pageState.accountId != undefined) {
        url += `&accountId=${pageState.accountId}&userId=${pageState.userId}`;
      }
      apiService
        .getData(url)
        .then((res) => {
          const user = res.data;
          if (res.data.Data == "") {
            dispatch(getUserFailure("err"));
          } else {
            dispatch(getStoreUserRequest(user));
          }
        })
        .catch((err) => {
          dispatch(getUserFailure(err));
        });
    };
  } catch (err) {}
};
