import {
    GET_ITEM_WISE_REQUEST,GET_ITEM_WISE_SUCCESS,GET_ITEM_WISE_FAILURE} from '../Types'  

    
    
  const initialState = {  
    loading: false, 
    noFoundData:false, 
    itemWiseData:[],
    error: ''  
   
  }  
    
  const reducer = (state = initialState, action) => {  
    
    switch (action.type) {  
        case GET_ITEM_WISE_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false
        }  
      case GET_ITEM_WISE_SUCCESS:
        return {  
          loading: false,  
          itemWiseData: action.payload,  
          noFoundData:false,
          error: ''           
        }  
       
      case GET_ITEM_WISE_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          itemWiseData: [],  
          error: action.payload  
        }    
      default: return state  
    }  
  }  
    
  export default reducer  