import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link } from 'react-router-dom'
import ListItemIcon from '@mui/material/ListItemIcon';
import { Outlet } from "react-router-dom";
import storeicon from "../../assets/Images/dashboard-menu/storeicon.png"
import categoryIcon from "../../assets/Images/dashboard-menu/classification.png";
import packageIcon from "../../assets/Images/dashboard-menu/package.png";
import { userRoles } from '../../shared/constants';
import Tooltip from '@mui/material/Tooltip';
import {useLocation, useParams, useHistory } from "react-router-dom";

import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import BadgeIcon from '@mui/icons-material/Badge';
import Storefront from '@mui/icons-material/Storefront';
import Group from '@mui/icons-material/Group';
import vector from "../../assets/Images/Vector.svg"
import vectorOne from "../../assets/Images/Vector (1).svg"
import vectorTwo from "../../assets/Images/report 2 (Traced).svg"
import vectorThree from "../../assets/Images/Vector (2).svg"
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';


import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import User from "../../assets/Images/account.png";
import passwordIcon from "../../assets/Images/reload.png";
import powerOff from "../../assets/Images/power-off.png";
import img1 from "../../assets/Images/dashboard-menu/AccountLogo.svg";
import img2 from "../../assets/Images/dashboard-menu/users svg.svg";
import img3 from "../../assets/Images/dashboard-menu/licenseLogo.svg";
import img4 from "../../assets/Images/dashboard-menu/terminal.png";
import img5 from "../../assets/Images/dashboard-menu/ReportsLogo.svg";
import img6 from "../../assets/Images/dashboard-menu/settings.png";
import img7 from "../../assets/Images/dashboard-menu/GAICentral Logo.svg";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];
const menuName = [
  {
    text: "Account",
    activeIndex: 0,
    link: "/app/ManageAccount",
    img: img1,
    code: "account",
    isActive: true,
  },
  {
    text: "Licenses",
    activeIndex: 1,
    link: "/app/License",
    img: img3,
    code: "licence",
    isActive: false,
  },
  {
    text: "GAI Central",
    activeIndex: 2,
    link: "/app/MapItems",
    img: img7,
    code: "item",
    isActive: false,
  },
  {
    text: "Reports",
    activeIndex: 3,
    link: "/app/ReportsDashboard",
    img: img5,
    code: "reports",
    isActive: false,
  },
  {
    text: "Users",
    activeIndex: 4,
    link: "/app/ManageUser",
    img: img2,
    code: "user",
    isActive: false,
  },
  {
    text: "Stores",
    activeIndex: 5,
    link: "/app/ManageStore",
    img: storeicon,
    code: "store",
    isActive: false,
  },
  {
    text: "Product",
    activeIndex: 6,
    link: "/app/Product",
    img: packageIcon,
    code: "product",
    isActive: false,
  },
  {
    text: "Category",
    activeIndex: 7,
    link: "/app/Category",
    img: categoryIcon,
    code: "category",
    isActive: false,
  },

  // {
  //   text: "Configuration",
  //   activeIndex: 8,
  //   link: "/app/Configuration",
  //   img: img6,
  //   code: "config",
  //   isActive: false
  // }
];
export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openMenu, setMenu] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [auth, setAuth] = React.useState(true);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleClose = () => {
    setAnchorEl(null);
    localStorage.removeItem("currentActiveIndex")
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleClick = () => {
    setMenu(!openMenu);
  };
  const [currentUserDetails, setCurrentUser] = useState({});
  const [menuItems, setMenuItemsForRoles] = useState([]);
  const location = useLocation()

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetail) {
      setCurrentUser(userDetail);
      bindMenuForUserRole(userDetail);
    };
    var localObj = localStorage.getItem("currentActiveIndex")
    handleClick({}, localObj ? localObj : 0);
    // userAccess()
    // handleClick(localObj)
  }, []);
  const bindMenuForUserRole = (currentuser) => {
    let getPermission = {}
    currentuser.permissions.forEach(element => {
      getPermission[`${element}`] = element
    });
    menuName.map(item => {
      switch (item.code) {
        case 'account':
          if (currentuser.userRole != userRoles.StoreManager && currentuser.userRole != userRoles.StoreStaff && getPermission.ViewAccount == "ViewAccount")
            menuItems.push(item);
          break;
        case 'user':
          if (currentuser.userRole != userRoles.SuperAdmin && currentuser.userRole != userRoles.StoreManager && currentuser.userRole != userRoles.StoreStaff && getPermission.ViewUser == "ViewUser")
            menuItems.push(item);
          break;
        case 'store':
          if (getPermission.ViewStore == "ViewStore")
            menuItems.push(item);
          break;
        case 'licence':
          if (getPermission.ViewLicense == "ViewLicense")
            menuItems.push(item);
          break;
        case 'product':
          // console.log("getPermission.ViewProduct == ViewProduct",getPermission.ViewProduct == "ViewProduct")
          if (getPermission.ViewProducts == "ViewProducts")
            menuItems.push(item);
          break;
        case 'category':
          if (getPermission.ViewCategory == "ViewCategory")
            menuItems.push(item);
          break;
        case 'reports':
          menuItems.push(item);
          break;
        // case 'item':
        //   menuItems.push(item);
        //   break;
        case 'config':
          menuItems.push(item);
          break;

      }
    }
    )
  };
  const params = useParams();
  // const handleClick = (event, key) => {
  //   menuItems.map(activeTab => {
  //     if (activeTab.activeIndex == key) {
  //       activeTab.isActive = true
  //       localStorage.setItem("currentActiveIndex", key)
  //     }
  //     else {
  //       activeTab.isActive = false
  //     }
  //   })
  // };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        <List>
          {menuItems.map((menuText, key, index) => (
            <ListItem key={menuText} disablePadding component={Link} to={menuText.link}
              selected={menuText.link === location.pathname} style={{ textDecoration: 'none' }} onClick={event => handleClick(event, key)} className={menuText.isActive ? 'activeTabSection' : 'activeTabSectionOne'}>
              <Tooltip title={menuText.text} >
                <ListItemButton >
                  <ListItemIcon  className={menuText.isActive ? "activeTabColor" : ""} >
                    <img src={menuText.img}  className={menuText.isActive ? "activeTabColor" : ""} />
                  </ListItemIcon>
                  <ListItemText primary={menuText.text}  className={menuText.isActive ? "activeTabColor" : ""} />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        {/* <ListItemButton
          key="Management Account"
          sx={{
            minHeight: 48,
            px: 2.5,
          }}
        >
          <Link to="/app/ManageAccount" style={{ textDecoration: 'none' }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
              }}
            >
              <DashboardIcon />
              <ListItemText primary="Manage Account" sx={{ paddingLeft: 3 }} />
            </ListItemIcon>
          </Link>
        </ListItemButton> */}
        {/* <ListItemButton
          key="Management Account"
          sx={{
            minHeight: 48,
            px: 2.5,
          }}
        >
          <Link to="/app/ManageAccount" style={{ textDecoration: 'none' }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
              }}
            >
              {/* <DashboardIcon /> */}
        {/* <img
          style={{ height: '3%', width: '12%', margin: 'auto' }}
          src={vector}
          loading="lazy"
        />
        <ListItemText primary="Manage Account" sx={{ paddingLeft: 3 }} />
      </ListItemIcon>
    </Link>
        </ListItemButton >
    <ListItemButton
      key="Management Users"
      sx={{
        minHeight: 48,
        px: 2.5,
      }}
    >
      <Link to="/app/ManageUser" style={{ textDecoration: 'none' }}>
        <ListItemIcon
          sx={{
            minWidth: 0,
          }}
        >
          <Group style={{ fontSize: "25px" }} />
          <ListItemText primary="Manage Users" sx={{ paddingLeft: 3 }} />
        </ListItemIcon>
      </Link>
    </ListItemButton> * /} */}
  {/* <ListItemButton
          key="Management Store"
          sx={{
            minHeight: 48,
            px: 2.5,
          }}
        >
          <Link to="/app/ManageStore" style={{ textDecoration: 'none' }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
              }}
            >
              <Storefront style={{ fontSize: "25px" }} />
              <ListItemText primary="Manage Stores" sx={{ paddingLeft: 3 }} />
            </ListItemIcon>
          </Link>
        </ListItemButton> */}
  {/* <ListItemButton
          key="Manage Licenses"
          sx={{
            minHeight: 48,
            px: 2.5,
          }}
        >
          <Link to="/app/Licence" style={{ textDecoration: 'none' }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
              }}
            >
              <CreditCardIcon />
              <img
                style={{ height: '3%', width: '12%', margin: 'auto' }}
                src={vectorOne}
                loading="lazy"
              />
              <ListItemText primary="Manage Licenses" sx={{ paddingLeft: 3 }} />
            </ListItemIcon>
          </Link>
        </ListItemButton> */}
  {/* <ListItemButton
              key="Employee"
              sx={{
                minHeight: 48,
                px: 2.5,
              }}
            >
              <Link to="/app/ManageTerminal" style={{ textDecoration: 'none' }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center',

                  }}
                >
                  <BadgeIcon style={{ fontSize: "25px" }} />
                  <ListItemText primary="Manage Terminal" sx={{  paddingLeft: 3 }} />

                </ListItemIcon>

              </Link>
            </ListItemButton>
        <ListItemButton
          key="Manage Reports"
          sx={{
            minHeight: 48,
            px: 2.5,
          }}
        >
          <Link to="/app/Reports" style={{ textDecoration: 'none' }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
              }}
            >
              <BadgeIcon />
              <img
                style={{ height: '3%', width: '12%', margin: 'auto' }}
                src={vectorTwo}
                loading="lazy"
              />
              <ListItemText primary="Manage Reports" sx={{ paddingLeft: 3 }} />
            </ListItemIcon>
          </Link>
        </ListItemButton>
        <ListItemButton
          key="Configuration"
          sx={{
            minHeight: 48,
            px: 2.5,
          }}
        >
          <Link to="/app/Configuration" style={{ textDecoration: 'none' }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
              }}
            >
              <img
                style={{ height: '3%', width: '15%', margin: 'auto' }}
                src={vectorThree}
                loading="lazy"
              />
              <PersonAddAlt1Icon />
              <ListItemText primary="Configuration" sx={{ paddingLeft: 3 }} />
            </ListItemIcon>
          </Link>
        </ListItemButton> */}
  {/* {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List >
    </Box >
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" style={{ backgroundColor: 'white', color: 'black' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          
          <div style={{
              display: "flex",
              position: 'absolute',
              right: 5,
              top: 5
            }}>
              {auth && (
                <div >
                  <Avatar className='bg-white mx-5'>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}

                    >
                      <AccountCircle />
                    </IconButton>
                  </Avatar>
                  <Menu
                    id="menu-appbar"
                    className='child-menu-drawer'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>Sachin Mahajan</MenuItem>
                    <Link to='/app/Profile' style={{ textDecoration: 'none', color: "#000" }}>
                      <MenuItem onClick={handleClose}>
                        <img
                          src={User}
                          loading="lazy"
                        /> <span className='mx-5'>Profile</span> </MenuItem>
                    </Link>
                    <Link to='/app/ChangePassword' style={{ textDecoration: 'none', color: "#000" }}>
                      <MenuItem onClick={handleClose}>
                        <img
                          src={passwordIcon}
                          loading="lazy"
                        /> <span className='mx-5'>Change Password</span></MenuItem>
                    </Link>
                    <Link to='/' style={{ textDecoration: 'none', color: "#000" }}>
                      <MenuItem onClick={handleClose}>  <img
                        src={powerOff}
                        loading="lazy"
                      /> <span className='mx-5'>Logout</span></MenuItem>
                    </Link>
                  </Menu>
                </div>
              )}
            </div>

          <div className='font-20'><span className='text-orange font-24'>QVAI</span>  Cloud Management System</div>
          {/* <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            MUI
          </Typography> */}
          {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: '#fff' }}>
                {item}
              </Button>
            ))}
          </Box> */}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
