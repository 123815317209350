import React, { useState, useEffect, Link } from "react";
import {
  TextField,
  Button,
  Card,
  Box,
  Grid,
  MenuItem,
  Breadcrumbs,
} from "@mui/material";
import DefaultInput from "../../Component/TextField";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../Services/ApiServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultButton from "../../Component/Button";
import { styles } from "../../assets/scss/AddItem";

export default function BasicTable() {
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [currentUserDetails, setCurrentUser] = useState({});
  const [userId, setUserId] = useState();
  const [inputField, setInputField] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    Roles: "",
  });
  const [Roles, setRoles] = React.useState([]);

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetail) {
      setCurrentUser(userDetail);
    }
    setIsLoading(true);
    getUserData(userDetail.userId);
    getRoles();
  }, []);

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const resetData = () => {
    setInputField({
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      Roles: "",
    });
    navigate(`/app/ManageAccount`);
  };
  const getUserData = (obj) => {
    ApiServices.getData(`/User/${obj}`)
      .then((res) => {
        var accInfo = res.data;
        setUserId(accInfo.Id);
        setInputField({
          accountId: accInfo.AccountId,
          UserName: accInfo.UserName,
          FirstName: accInfo.FirstName,
          LastName: accInfo.LastName,
          Email: accInfo.Email,
          accountName: accInfo.AccountId,
          Roles: accInfo.RoleId,
          PhoneNumber: accInfo.PhoneNumber,
        });
      })
      .catch((e) => {
        return e;
      });
  };
  const handleChange = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      rolesId: e.target.value,
      Roles: e.target.value,
    }));
  };
  const getRoles = () => {
    ApiServices.getData("/Role/all")
      .then((res) => {
        setRoles(res.data);
      })
      .catch((res) => {});
  };
  const updateData = () => {
    ApiServices.saveData(`/User/account/update/${userId}`, {
      userName: inputField.UserName,
      PhoneNumber: inputField.PhoneNumber,
      firstName: inputField.FirstName,
      lastName: inputField.LastName,
      email: inputField.Email,
      PhoneNumber: inputField.PhoneNumber,
      accountId: inputField.accountId,
      roleId: inputField.Roles,
      RequestType: "Profile",
    })
      .then((res) => {
        if (res.status == 200) {
          toast("Profile update successfully");
          setTimeout(() => {
            navigate(`/app/ManageAccount`);
          }, 1000);
        }
      })
      .catch((res) => {
        if (res.response.status == 404) {
          toast(res.response.data);
        } else if (res.response.status == 401) {
          toast(res.response.data);
        } else if (res.response.status == 403) {
          toast(res.response.data);
        } else if (res.response.status == 400) {
          toast(res.response.data);
        } else {
          toast("Something went wrong. Please try again later. ");
        }
      });
  };
  const submitForm = (e) => {
    e.preventDefault();
    updateData();
  };
  return (
    <Box>
      <ToastContainer autoClose={1000} />
      <Grid container sx={{ marginLeft: "-5px " }}>
        <Grid item xs={12} sm={12} md={11}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Box {...styles.tab}>
                <Box {...styles.tabInside}>Profile</Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
          <DefaultButton
            color="warning"
            variant="contained"
            size="small"
            Text="Back"
          />
        </Grid>
      </Grid>
      <form onSubmit={submitForm}>
        <Card
          style={{ width: "100%" }}
          className="border-radius-8"
          sx={{ marginTop: "-25px" }}
        >
          <Box style={{ padding: "4%" }}>
            <Box>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="FirstName"
                    label="First Name"
                    variant="standard"
                    name="FirstName"
                    value={inputField.FirstName}
                    onChange={inputsHandler}
                    placeHolder="First Name"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="LastName"
                    label="Last Name"
                    variant="standard"
                    name="LastName"
                    value={inputField.LastName}
                    onChange={inputsHandler}
                    placeHolder="Last Name"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="Email"
                    label="Email"
                    variant="standard"
                    name="Email"
                    value={inputField.Email}
                    onChange={inputsHandler}
                    placeHolder="Email"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="PhoneNumber"
                    label="Phone Number"
                    variant="standard"
                    name="PhoneNumber"
                    value={inputField.PhoneNumber}
                    onChange={inputsHandler}
                    placeHolder="Phone Number"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    disabled
                    size="small"
                    label="Roles *"
                    value={inputField.Roles}
                    Name="Roles"
                    onChange={handleChange}
                  >
                    {Roles.map((option) => (
                      <MenuItem key={option.value} value={option.Id}>
                        {option.Name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
            <Box className="buttonSecton" style={{ marginTop: "20px" }}>
              <Box className="mx-3">
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  {...styles.SaveBtn}
                  className="btn-orange "
                >
                  Save
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  onClick={resetData}
                  {...styles.SaveBtn}
                  className="btn-orange "
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      </form>
    </Box>
  );
}
