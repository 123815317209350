import React, { useState, useEffect, useRef } from 'react';
import { useDownloadExcel } from "react-export-table-to-excel";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import Box from '@mui/material/Box';

export default function SampleHook(props) {
    const getExcelItemData = props.getItemWiseExportData
    useEffect(() => {
        if (getExcelItemData) {
            onDownload()
        }
    }, [getExcelItemData]);
    const { onDownload } = useDownloadExcel({
        currentTableRef: props.tableRef.current,
        filename: "Item Wise Reports",
        sheet: "Item Wise Reports"
    },
    );
    return (
        <div>
            <Table aria-label="simple table" size="small" ref={props.tableRef}>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Account Name :</TableCell>
                    {
                        (getExcelItemData?.OrderResponseHead?.AccountName) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{getExcelItemData?.OrderResponseHead?.AccountName}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }

                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>State :</TableCell>
                    {
                        (getExcelItemData?.OrderResponseHead?.State) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{getExcelItemData?.OrderResponseHead?.State}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>City :</TableCell>
                    {
                        (getExcelItemData?.OrderResponseHead?.City) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{getExcelItemData?.OrderResponseHead?.City}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Store Name :</TableCell>
                    {
                        (getExcelItemData?.OrderResponseHead?.StoreName) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{getExcelItemData?.OrderResponseHead?.StoreName}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Terminal :</TableCell>
                    {
                        (getExcelItemData?.OrderResponseHead?.TerminalName) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{getExcelItemData?.OrderResponseHead?.TerminalName}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Item Sales report from :</TableCell>
                    {
                        (getExcelItemData?.OrderResponseHead?.FromDate) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{moment(getExcelItemData?.OrderResponseHead?.FromDate).format("DD-MM-YYYY")}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                    {
                        (getExcelItemData?.OrderResponseHead?.ToDate) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>to {moment(getExcelItemData?.OrderResponseHead?.ToDate).format("DD-MM-YYYY")}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Total No. of bills :</TableCell>
                    {
                        (getExcelItemData?.TotalItems) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{getExcelItemData?.TotalItems}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Total Sales Amount(INR) :</TableCell>
                    {
                        (getExcelItemData?.OrderResponseHead?.TotalSales) ?

                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{getExcelItemData?.OrderResponseHead?.TotalSales}</TableCell>
                            :
                            <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>-</TableCell>
                    }
                </TableRow>
                <TableRow></TableRow>
                <TableHead style={{ position: "sticky", top: 0, background: "#fff" }}>

                    <TableRow>
                        <TableCell className="headerContent">
                            Bill Number
                        </TableCell>
                        <TableCell className="headerContent">Bill Date</TableCell>
                        <TableCell className="headerContent">Category Name</TableCell>
                        <TableCell className="headerContent">Item Name</TableCell>
                        <TableCell className="headerContent">Item Prediction Time (Speed in milliseconds)</TableCell>
                        <TableCell className="headerContent">HSN code</TableCell>
                        <TableCell className="headerContent">Item Code</TableCell>
                        <TableCell className="headerContent">Quantity</TableCell>
                        <TableCell className="headerContent">Total Amount</TableCell>
                        <TableCell className="headerContent">
                            Payment Mode
                        </TableCell>
                    </TableRow>
                </TableHead>
                {getExcelItemData?.Data.map((row, i) => {
                    return (
                        <TableBody >
                            {row.OrderDetail.map((historyRow, index) => (
                                <>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    {index == 0 ?
                                        <TableCell component="td" scope="row" className="ContentTableExcel">
                                            {row.InvoiceNumberStr}
                                        </TableCell>
                                        :

                                        <TableCell component="td" scope="row" className="ContentTableExcel"></TableCell>

                                    }
                                   
                                    {index == 0 ?
                                        <TableCell component="td" scope="row" className="ContentTableExcel">
                                            {moment(row.OrderDate).format("DD-MM-YYYY")}
                                        </TableCell>
                                        :
                                        <TableCell component="td" scope="row" className="ContentTableExcel"></TableCell>
                                    }

                                    <TableCell component="td" scope="row" className="ContentTableExcel">

                                        {historyRow.Category}
                                    </TableCell>
                                    <TableCell component="td" scope="row" className="ContentTableExcel">
                                        {historyRow.ProductName}
                                    </TableCell>
                                    <TableCell component="td" scope="row" className="ContentTableExcel">
                                        -
                                    </TableCell>
                                    <TableCell component="td" scope="row" className="ContentTableExcel">
                                        {historyRow.HSN}
                                    </TableCell>

                                    <TableCell component="td" scope="row" className="ContentTableExcel">
                                        {historyRow.PluCode}
                                    </TableCell>

                                    <TableCell component="td" scope="row" className="ContentTableExcel">
                                        {historyRow.Quantity}
                                    </TableCell>
                                    {index == 0 ?
                                        <TableCell component="td" scope="row" className="ContentTableExcel">
                                            {row.OrderAmount}
                                        </TableCell>
                                        :
                                        <TableCell component="td" scope="row" className="ContentTableExcel">
                                        </TableCell>
                                    }
                                    {index == 0 ?
                                        <TableCell component="td" scope="row" className="ContentTableExcel">
                                            {row.PaymentMode}
                                        </TableCell>
                                        :
                                        <TableCell component="td" scope="row" className="ContentTableExcel">
                                        </TableCell>
                                    }
                                </TableRow>
                                
                                </>
                            ))}
                        </TableBody>

                    )
                }
                )}
                <TableRow></TableRow>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Total :</TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ fontSize: '16px' }}>{getExcelItemData?.OrderResponseHead?.TotalSales}</TableCell>
                </TableRow>
                <TableRow></TableRow>
            </Table>
        </div >
    );
}
