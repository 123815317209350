import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  styled,
  Backdrop,
  CircularProgress,
  Modal,
  Avatar,
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Card,
  Button,
  InputAdornment,
  TextField,
  Switch,
  IconButton,
  TablePagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate, useParams } from "react-router-dom";
import PersonAddAltRounded from "@mui/icons-material/PersonAddAltRounded";
import apiService from "../../Services/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import { getDataByPagination } from "../../Redux/Store/StoreAction";
import { getInactiveDataByPagination } from "../../Redux/Store/StoreAction";
import { userRoles, regexConstant } from "../../shared/constants";
import { toast, ToastContainer } from "react-toastify";
import DefaultButton from "../../Component/Button";
import Loader from "../../Component/Loader";
import DeactiveDialog from "../../Component/DeactiveDialog";
import ActivateDialog from "../../Component/ActivateDialog";
import editImg from "../../assets/Images/edit.png";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}
function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`,
  };
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    "&.MuiTableCell-root": {
      border: "none!important",
      padding: "10px",
    },
  },
});

const pageState = {
  skip: 0,
  top: 10,
  isDeleted: false,
  query: "",
  accountId: "",
};

export default function BasicTable(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const storeData = useSelector((s) => s.storeReducer);
  const { loading } = useSelector((state) => ({
    
    loading: state.storeReducer.loading,
  }));
  const [isLoading, setIsLoading] = useState(false);
  const [getData, setData] = useState([]);
  const [InactiveData, setInactiveData] = useState([]);
  const [search, setSearch] = useState("");
  const [totalItemData, setTotalItemData] = useState(1);
  const [noFoundData, setnoFoundData] = useState(true);
  const [currentUserDetails, setCurrentUser] = useState({});
  // tab section
  const [value, setValue] = React.useState(0);
  const [currentUserRole, setRole] = useState("");
  const [handleIcon, setIcon] = useState(true);
  const [getPermission, setPermission] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [ModalDataId, setModalDataId] = useState();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [MenuDataId, setMenuDataId] = useState();
  const OpenMenu = Boolean(anchorEl);

  const intialListCall = () => {
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    if (value == 0) {
      // setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    let newObj = {};
    userDetail.permissions.forEach((element) => {
      newObj[`${element}`] = element;
    });
    setPermission(newObj);
    if (userDetail) {
      setRole(userDetail.userRole);
    }
    if (
      userDetail.userRole == userRoles.SuperAdmin &&
      userDetail.accountId == ""
    ) {
      pageState.accountId = params.id;
      pageState.skip = 0;
      pageState.top = 10;
      dispatch(getDataByPagination(pageState));
    } else {
      pageState.skip = 0;
      pageState.top = 10;
      dispatch(getDataByPagination(pageState));
    }
    intialListCall();
  }, []);
  useEffect(() => {
    if (!storeData.noFoundData) {
      if (storeData.isAccountActive == true) {
        setData(storeData.store.Data);
        setTotalItemData(
          storeData.store.TotalItems ? storeData.store.TotalItems : 1
        );
      } else if (storeData.isAccountActive == false) {
        setInactiveData(storeData.store.Data);
        setTotalItemData(
          storeData.store.TotalItems ? storeData.store.TotalItems : 1
        );
      } else {
      }
      // setIsLoading(false);
      setnoFoundData(true);
    } else {
      // setIsLoading(false);
      setnoFoundData(false);
    }
  }, [storeData]);
// ----------Pegination event----------
  const handleChange = (event, newValue) => {
    const indexValue = event.target.id.split("-")[2];
    setValue(newValue);
    if (indexValue > 0) {
      pageState.isDeleted = true;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      setRowsPerPage(parseInt(pageState.top));
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      pageState.isDeleted = false;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      dispatch(getDataByPagination(pageState));
    }
  };
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    if (value == 0) {
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);

    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      dispatch(getDataByPagination(pageState));
    }
  };

  // -----------Model event-----------------
  const handleOpenModal = (e) => {
    setOpen(true);
    setModalDataId(e.target.id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteDataId = () => {
    apiService
      .deleteData(`/Store/${ModalDataId}`)
      .then((res) => {
        setOpen(false);
        dispatch(getDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
// -----------Search event------------
  const SearchData = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    pageState.query = inputValue;
    if (
      pageState.query == "" ||
      !regexConstant.searchSpace.test(pageState.query)
    ) {
      toast("Please Enter the search key");
    }
    if (value == 0) {
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  const resetInputField = (e) => {
    setSearch("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setInputValue("");
    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      dispatch(getDataByPagination(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };
  const handleUserInput = (e) => {
    if (e.target.value) {
      setIcon(false);
    }
    if (e.target.value === "") {
      setIcon(true);
    }
    setInputValue(e.target.value);
  };


  const getActiveDataById = () => {
    apiService
      .saveData(`/Store/activate`, { storeId: ModalDataId })
      .then((res) => {
        setOpen(false);
        dispatch(getInactiveDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuDataId(event.currentTarget.id);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {/* ------------Header--------------- */}
      <Box>
        <ToastContainer autoClose={2000} />
        <Box className="justify-content-space">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer }}
            open={isLoading}
          >
            <CircularProgress style={{ color: "#ea7626" }} />
          </Backdrop>
          <Box className="poppins-bold">Manage Store</Box>
          <Box>
            <Box className="text-align-end showMobile my-8">
              <Link to="/app/NewStore" className="textDecoration">
                <Button>
                  <Avatar
                    sx={{ border: "1px solid #9747FF" }}
                    style={{ backgroundColor: "white" }}
                  >
                    <PersonAddAltRounded sx={{ color: "#9747FF" }} />
                  </Avatar>
                </Button>
              </Link>
            </Box>
            {currentUserRole == userRoles.SuperAdmin ? (
              <Box className="text-align-end mb-10">
                <DefaultButton
                  color="warning"
                  variant="contained"
                  size="small"
                  Text="Back"
                />
              </Box>
            ) : null}
            {getPermission.AddEditStore == "AddEditStore" ? (
              <Box className="showINWindow">
                <Link
                  to={{ pathname: `/app/NewStore/${params.id}` }}
                  className="textDecoration"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    className="border-radius-20 roboto"
                    style={{
                      boxShadow: 3,
                      borderRadius: "15px",
                      background: "#fff",
                      color: "#8B8D9D",
                      border: "none",
                      fontWeight: "100 !important",
                      padding: "5px 16px",
                    }}
                  >
                    <AddIcon
                      color="warning"
                      className="mx-5"
                      sx={{ color: "#9747FF" }}
                    />
                    ADD NEW STORE
                  </Button>
                </Link>
              </Box>
            ) : (
              <Box></Box>
            )}
          </Box>
        </Box>
        {/* ----------Search and filter------------- */}
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={5}>
            <Box>
              <Box className="pt-10 search-section">
                <Box>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={11}>
                      <TextField
                        fullWidth
                        id="userName"
                        size="small"
                        type="Text"
                        value={inputValue}
                        onChange={handleUserInput}
                        classes={{ root: classes.customTextField }}
                        placeholder="Search Here"
                        sx={{
                          boxShadow: 5,
                          borderRadius: "10px",
                          background: "white",
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none !important",
                            },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="set-icon-right"
                              position="start"
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={resetInputField}
                                color="warning"
                              >
                                {handleIcon ? <SearchIcon /> : <CancelIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            SearchData();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                      <Box className="mt-5">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={SearchData}
                          className="border-radius-20 btn-orange w-100"
                        >
                          Search
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Box>
              <Box className=" pt-10">
                <Box>
                  <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="horizontal tabs example"
                  >
                    <Tab
                      label="Active"
                      {...a11yProps(0)}
                      className="roboto-700"
                    />
                    <Tab
                      label="InActive"
                      {...a11yProps(1)}
                      className="roboto-700"
                    />
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/*-------------Table--------------------  */}
      <Box>
        <TabPanel value={value} index={0}>
          <TableContainer
            component={Paper}
            className="mt-10 border-radius-20 table-scroll"
          >
            <Table
              className={classes.table}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow className="tableHeadCss">
                  <TableCell className={classes.tableCell}>Sr no</TableCell>
                  <TableCell className={classes.tableCell}>Store Id</TableCell>
                  <TableCell className={classes.tableCell}>
                    Store Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Account Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Store Type
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Contact Person
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Contact Number
                  </TableCell>
                  <TableCell className={classes.tableCell}>Email ID</TableCell>
                  <TableCell className={classes.tableCell}>GSTIN</TableCell>
                  <TableCell className={classes.tableCell}>
                    City,State
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Status
                  </TableCell>
                  {getPermission.AddEditStore == "AddEditStore" ? (
                    <TableCell className={classes.tableCell} align="center">
                      Edit
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableCell}></TableCell>
                  )}
                  <TableCell className={classes.tableCell}></TableCell>
                </TableRow>
              </TableHead>

              {loading ? (
                <Loader open={loading} />
              ) : (
                <>
                  {noFoundData ? (
                    <TableBody>
                      {getData?.map((row, index) => (
                        <TableRow key={row.Id}>
                          <TableCell className={classes.tableCell}>
                            {index + 1 + pageState.skip}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Id}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.StoreName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Account?.AccountName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.StoreType ? (
                              <Box>{row.StoreType["Name"]}</Box>
                            ) : (
                              <Box>-</Box>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.ContactPerson}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.PrimaryPhoneNo}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Email}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.GSTIN}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.City["Name"]},{row.City.State["Name"]}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <DeactiveDialog
                              id={row.Id.toString()}
                              handleClickOpen={handleOpenModal}
                              open={open}
                              text="Store"
                              subtext="store"
                              handleClickClose={handleClose}
                              handleClickSave={deleteDataId}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {getPermission.AddEditStore == "AddEditStore" ? (
                              <Box
                                className="buttonSecton"
                                style={{ marginTop: "0px" }}
                              >
                                <Box className="submitButton">
                                  <Link
                                    to={{
                                      pathname: `/app/UpdateStores/${row.Id}`,
                                    }}
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      color="warning"
                                    >
                                      <img src={editImg} />
                                    </IconButton>
                                  </Link>
                                </Box>
                              </Box>
                            ) : (
                              <Box></Box>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {getPermission.ViewTerminal == "ViewTerminal" ? (
                              <Box className="Menu-section">
                                <Box>
                                  <Button
                                    id={row.Id}
                                    onClick={handleClick}
                                    aria-controls={
                                      OpenMenu ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      OpenMenu ? "true" : undefined
                                    }
                                  >
                                    <MoreVertIcon sx={{ color: "#9747FF" }} />
                                  </Button>
                                  <Menu
                                    id={ModalDataId}
                                    anchorEl={anchorEl}
                                    open={OpenMenu}
                                    elevation={1}
                                    borderRadius="50%"
                                    style={{ borderRadius: 20 }}
                                    onClose={handleCloseMenu}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                  >
                                    <Link
                                      to={{
                                        pathname: `/app/ManageTerminal/${MenuDataId}`,
                                      }}
                                      className="textDecoration"
                                    >
                                      <MenuItem
                                        className="border-radius-20"
                                        style={{ color: "#000" }}
                                      >
                                        Manage Terminal
                                      </MenuItem>
                                    </Link>
                                  </Menu>
                                </Box>
                              </Box>
                            ) : (
                              <Box></Box>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <Box className="text-align-center font-18 font-bold py-15">
                      No data found...
                    </Box>
                  )}
                </>
              )}
            </Table>
            <TablePagination
              component="Box"
              count={totalItemData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <TableContainer component={Paper} className="mt-10 border-radius-20">
            <Table
              className={classes.table}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow className="tableHeadCss">
                  <TableCell className={classes.tableCell}>sr no</TableCell>
                  <TableCell className={classes.tableCell}>Store Id</TableCell>
                  <TableCell className={classes.tableCell}>
                    Store Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Account Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Store Type
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Contact Person
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Contact Number
                  </TableCell>
                  <TableCell className={classes.tableCell}>Email ID</TableCell>
                  <TableCell className={classes.tableCell}>GSTIN</TableCell>
                  <TableCell className={classes.tableCell}>
                    City,State
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loader open={loading} />
              ) : (
                <>
                  {noFoundData ? (
                    <TableBody>
                      {InactiveData?.map((row, index) => (
                        <TableRow key={row.Sno}>
                          <TableCell className={classes.tableCell}>
                            {index + 1 + pageState.skip}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Id}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.StoreName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Account?.AccountName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.StoreType ? (
                              <Box>{row.StoreType["Name"]}</Box>
                            ) : (
                              <Box>-</Box>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.ContactPerson}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.PrimaryPhoneNo}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.Email}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.GSTIN}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.City["Name"]},{row.City.State["Name"]}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <ActivateDialog
                              id={row.Id.toString()}
                              handleClickOpen={handleOpenModal}
                              open={open}
                              text="Store"
                              subtext="store"
                              handleClickClose={handleClose}
                              handleClickSave={getActiveDataById}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <Box className="text-align-center font-18 font-bold py-15">
                      No data found...
                    </Box>
                  )}
                </>
              )}
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="Box"
              count={totalItemData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </TabPanel>
      </Box>
    </Box>
  );
}
