import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Slide,
  Toolbar,
  AppBar,
  Divider,
  List,
  ListItem,
  ListItemText,
  Modal,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Avatar,
  Grid,
  Typography,
  Box,
  Card,
  Button,
  Switch,
  TablePagination,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
} from "@mui/material";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { useNavigate, useParams } from "react-router-dom";
import PersonAddAltRounded from "@mui/icons-material/PersonAddAltRounded";
import { useDispatch, useSelector } from "react-redux";
import { getDataByPagination } from "../../Redux/Terminal/TerminalAction";
import { getInactiveDataByPagination } from "../../Redux/Terminal/TerminalAction";
import { getLicenseData } from "../../Redux/Terminal/TerminalAction";
import apiService from "../../Services/ApiServices";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { userRoles, regexConstant } from "../../shared/constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { PanoramaSharp } from "@material-ui/icons";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DefaultButton from "../../Component/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Loader from "../../Component/Loader";
const theme2 = createTheme({
  palette: {
    primary: {
      main: "#9747FF !important",
    },
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function createData(
  srNo: Number,
  licenseKey: number,
  activationDate: number,
  expiryDate: number,
  status: number
) {
  return { srNo, licenseKey, activationDate, expiryDate, status };
}

const rows = [
  createData(1, "JLRO949PLE", "15-09-2022", "15-09-2022", "Available"),
  createData(2, "JLRO949PLE", "15-09-2022", "15-09-2022", "Available"),
  createData(3, "JLRO949PLE", "16.0, 24", "15-09-2022", "Available"),
  createData(4, "JLRO949PLE", "15-09-2022", "15-09-2022", "Available"),
  createData(5, "JLRO949PLE", "15-09-2022", "15-09-2022", "Available"),
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
// Using for Dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`,
  };
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  dialogPaper: {
    maxWidth: "900px !important",
    // height: '400px'
  },
  tableCell: {
    "&.MuiTableCell-root": {
      border: "none!important",
      padding: "10px",
    },
  },
});

const pageState = {
  skip: 0,
  top: 10,
  isDeleted: false,
  query: "",
  storeId: "",
  accountId: "",
};
export default function BasicTable() {
  const params = useParams();
  pageState.storeId = params.terminalId;
  const { loading } = useSelector((state) => ({
    loading: state.terminalReducer.loading,
  }));
  const classes = useStyles();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [inputValue, setInputValue] = useState("");
  const [ModalDataId, setModalDataId] = useState();
  const [open, setOpen] = React.useState(false);
  // calling for page state
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const [isLoading, setIsLoading] = useState(false);

  const [getData, setData] = useState([]);
  const [InactiveData, setInactiveData] = useState([]);
  const [search, setSearch] = useState("");
  const [totalItemData, setTotalItemData] = useState(1);
  const [noFoundData, setnoFoundData] = useState(true);
  const [currentUserDetails, setCurrentUser] = useState({});
  const dispatch = useDispatch();

  const terminalData = useSelector((s) => s.terminalReducer);
  // on click tab section
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    const indexValue = event.target.id.split("-")[2];
    setValue(newValue);
    if (indexValue > 0) {
      pageState.isDeleted = true;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      setRowsPerPage(parseInt(pageState.top));
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      pageState.isDeleted = false;
      pageState.top = 10;
      pageState.skip = 0;
      setPage(0);
      dispatch(getDataByPagination(pageState));
    }
  };

  // handle change for next and previous page
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    if (value == 0) {
      // setIsLoading
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
    dispatch(getLicenseData(pageState));
  };
  // handle change for page size data
  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);

    if (pageState.isDeleted) {
   
      dispatch(getInactiveDataByPagination(pageState));
    } else {
     
      dispatch(getDataByPagination(pageState));
    }
    dispatch(getLicenseData(pageState));
  };
  const [getPermission, setPermission] = useState({});
  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    let newObj = {};
    userDetail.permissions.forEach((element) => {
      newObj[`${element}`] = element;
    });
    setPermission(newObj);
  }, []);

  useEffect(() => {
    pageState.skip = 0;
    pageState.top = 10;
    dispatch(getDataByPagination(pageState));
    intialListCall();
  }, []);

  const [getLicenseList, setLicenseData] = useState();
  useEffect(() => {
    if (!terminalData.noFoundData) {
      if (terminalData.isAccountActive == true) {
        setData(terminalData.terminal.Data);
        setTotalItemData(terminalData.terminal.TotalItems);
      } else if (terminalData.getLicensValue) {
        setLicenseData(terminalData.terminal.Data);
        setTotalItemData(terminalData.terminal.TotalItems);
      } else if (terminalData.isAccountActive == false) {
        setInactiveData(terminalData.terminal.Data);
        setTotalItemData(terminalData.terminal.TotalItems);
      } else {
      }
      setnoFoundData(true);
      // setIsLoading(false);
    } else {
      setnoFoundData(false);
      // setIsLoading(true);
    }
    
  
  }, [terminalData]);

 
  

  // using for search section
  const SearchData = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    pageState.query = inputValue;
    if (
      pageState.query == "" ||
      !regexConstant.searchSpace.test(pageState.query)
    ) {
      toast("Please Enter the search key");
    }
    if (value == 0) {
      // setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    } else {
      // setIsLoading(false);
      dispatch(getInactiveDataByPagination(pageState));
    }
  };
  // delete deactive data by ID
  const deleteDataId = (obj) => {
    apiService
      .deleteData(`/Terminal/${obj}`)
      .then((res) => {
        setOpen(false);
        dispatch(getDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };

  // get active data data by ID
  const getActiveDataById = (obj) => {
    apiService
      .saveData(`/Terminal/activate`, { terminalId: obj })
      .then((res) => {
        setOpen(false);
        // setIsLoading(true);
        dispatch(getInactiveDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };

  // intial List Call
  const intialListCall = () => {
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    if (value == 0) {
      dispatch(getDataByPagination(pageState));
    } else {
      dispatch(getInactiveDataByPagination(pageState));
    }
  };

  const resetInputField = (e) => {
    setSearch("");
    pageState.query = "";
    pageState.top = 10;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setInputValue("");
    if (pageState.isDeleted) {
      dispatch(getInactiveDataByPagination(pageState));
    } else {
      // setIsLoading(true);
      dispatch(getDataByPagination(pageState));
    }
    setnoFoundData(true);
    setIcon(true);
  };
  const [openRemoveLicense, setOpenRemoveLicense] = React.useState(false);
  const handleOpenModalLicense = (e) => {
    setOpenRemoveLicense(true);
    setTerminalId(e.target.value);
    setModalDataId(e.target.id);
  };
  const handleOpenModal = (e) => {
    setOpen(true);
    setModalDataId(e.target.id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenTerminal(false);
    setOpenRemoveLicense(false);
    setAssignOpenLicense(false);
  };
  const [handleIcon, setIcon] = useState(true);

  const handleUserInput = (e) => {
    if (e.target.value) {
      setIcon(false);
    }
    setInputValue(e.target.value);
  };
  // Menu bar

  const [openTerminal, setOpenTerminal] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [getDialogId, setDialogId] = React.useState(false);
  const [getTerminalId, setTerminalId] = useState("");

  const handleClickOpenDialog = (e) => {
    setDialogId(e.target.id);
    setTerminalId(e.target.value);
    pageState.accountId = e.target.id;
    if (pageState.accountId) {
      dispatch(getLicenseData(pageState));
    }
    setOpenDialog(true);
  };
  // assign license
  const [allowLicense, setAllowLicense] = useState(false);
  const assignLicense = (obj) => {
    apiService
      .saveData(`/Terminal/assign/license`, {
        terminalId: getTerminalId,
        licenseId: obj,
      })
      .then((res) => {
        // setIsLoading(true);
        setOpen(false);
        setOpenDialog(false);
        dispatch(getDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
  const removeLicense = (obj) => {
    apiService
      .saveData(`/Terminal/remove/license`, {
        terminalId: obj,
        licenseId: getTerminalId,
      })
      .then((res) => {
        setOpenRemoveLicense(false);
        setOpenDialog(false);
        // setIsLoading(true);
        dispatch(getDataByPagination(pageState));
      })
      .catch((e) => {
        return e;
      });
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleCloseDialog = () => {
    // setIsLoading(true);
    setOpenDialog(false);
    dispatch(getDataByPagination(pageState));
  };
  const [assignOpenLicense, setAssignOpenLicense] = React.useState(false);

  const assignLiceseModal = (e) => {
    setAssignOpenLicense(true);
    setModalDataId(e.target.id);
  };
  return (
    <div>
      <ToastContainer autoClose={2000} />
      <div>
        <div className="justify-content-space">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer }}
            open={isLoading}
          >
            <CircularProgress style={{ color: "#ea7626" }} />
          </Backdrop>

          <div className="poppins-bold">Manage Terminal</div>
          <div>
            <div className="text-align-end mb-10">
              <DefaultButton
                color="warning"
                variant="contained"
                size="small"
                startIcon={<ArrowBackIosNewIcon />}
                Text="Back"
              />
            </div>
            {getPermission.AddEditTerminal == "AddEditTerminal" ? (
              <div>
                <div className="text-align-end showMobile my-8">
                  <Link to="/app/CreateTerminal" className="textDecoration">
                    <Button>
                      <Avatar
                        className="border-orange"
                        style={{ backgroundColor: "white" }}
                      >
                        <PersonAddAltRounded className="text-orange" />
                      </Avatar>
                    </Button>
                  </Link>
                </div>
                <div className="showINWindow">
                  <Link
                    to={{
                      pathname: `/app/CreateTerminal/${params.terminalId}`,
                    }}
                    className="textDecoration"
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      className="border-radius-20 roboto"
                      style={{
                        boxShadow: 3,
                        borderRadius: "15px",
                        background: "#fff",
                        color: "#8B8D9D",
                        border: "none",
                        fontWeight: "100 !important",
                        padding: "5px 16px",
                      }}
                    >
                      <AddIcon sx={{ color: "#9747FF" }} className="mx-5" />
                      Create Terminal
                    </Button>
                  </Link>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={5}>
            <div>
              <div className="pt-10 search-section">
                <div>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={11}>
                      <TextField
                        fullWidth
                        label="Search Here"
                        id="userName"
                        size="small"
                        type="text"
                        value={inputValue}
                        onChange={handleUserInput}
                        sx={{
                          boxShadow: 5,
                          borderRadius: "10px",
                          background: "white",
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none !important",
                            },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              className="set-icon-right"
                              position="start"
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={resetInputField}
                                color="warning"
                              >
                                {handleIcon ? <SearchIcon /> : <CancelIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            SearchData();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                      <div className="mt-5">
                        <Button
                          variant="contained"
                          size="small"
                          className="border-radius-20 btn-orange w-100"
                          onClick={SearchData}
                        >
                          Search
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <div>
              <div className=" pt-10">
                <Box>
                  <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="horizontal tabs example"
                  >
                    <Tab
                      label="Active"
                      {...a11yProps(0)}
                      className="roboto-700"
                    />
                    <Tab
                      label="InActive"
                      {...a11yProps(1)}
                      className="roboto-700"
                    />
                  </Tabs>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* --------tABLE-------- */}
      <div>
      <TabPanel value={value} index={0}>
        <TableContainer 
        component={Paper} 
        className="mt-10 border-radius-20">
          <Table
            className={classes.table}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow className="tableHeadCss">
                <TableCell className={classes.tableCell}>Id</TableCell>
                <TableCell className={classes.tableCell}>
                  Terminal Name
                </TableCell>
                <TableCell className={classes.tableCell}>
                  Device Model
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Status
                </TableCell>
                {getPermission.AddEditTerminal == "AddEditTerminal" ? (
                  <TableCell className={classes.tableCell} align="center">
                    Edit
                  </TableCell>
                ) : (
                  <TableCell className={classes.tableCell}></TableCell>
                )}
              </TableRow>
            </TableHead>

            {loading ? (
                <Loader open={loading} />
              ) : (
                <>
            {noFoundData ? (
              <TableBody>
                {getData?.map((row) => (
                  <TableRow key={row.Id}>
                    <TableCell className={classes.tableCell}>
                      {row.Id}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.TerminalName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.TerminalModel?.Name}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <ThemeProvider theme={theme2}>
                        {getPermission.ActiveInactiveTerminal ==
                        "ActiveInactiveTerminal" ? (
                          <Switch
                            {...label}
                            defaultChecked
                            name="switch"
                            id={row.Id}
                            onClick={handleOpenModal}
                            inputProps={{ "aria-label": "controlled" }}
                            checked={true}
                          />
                        ) : (
                          <Switch
                            {...label}
                            defaultChecked
                            name="switch"
                            id={row.Id}
                            disabled
                            onClick={handleOpenModal}
                            inputProps={{ "aria-label": "controlled" }}
                            color="warning"
                            checked={true}
                          />
                        )}
                      </ThemeProvider>
                      <Dialog
                        open={open}
                        id={ModalDataId}
                        sx={{
                          "& .MuiPaper-elevation24": {
                            boxShadow: "none!important",
                          },
                          " .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
                            background: "rgba(0,0,0,0.2)!important",
                          },
                          "& .MuiBackdrop-root": {
                            opacity: "0.3!important",
                          },
                        }}
                      >
                        <BootstrapDialogTitle
                          id="customized-dialog-title"
                          onClose={handleClose}
                        >
                          Deactive Terminal
                        </BootstrapDialogTitle>
                        <DialogContent dividers className="my-10">
                          <Typography gutterBottom>
                            Are you sure you want to deactivate this terminal.
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => deleteDataId(ModalDataId)}
                            variant="contained"
                            className="border-radius-20 btn-orange w-100 margin-top-bottom-10"
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={handleClose}
                            variant="contained"
                            className="border-radius-20 btn-orange w-100"
                          >
                            No
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {getPermission.AddEditTerminal == "AddEditTerminal" ? (
                        <div
                          className="buttonSecton"
                          style={{ marginTop: "0px" }}
                        >
                          <div className="submitButton">
                            <Link
                              to={{ pathname: `/app/UpdateTerminal/${row.Id}` }}
                            >
                              <IconButton aria-label="delete" color="warning">
                                <EditIcon sx={{ color: "#9747FF" }} />
                              </IconButton>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <div className="text-align-center font-18 font-bold py-15">
                No data found...
              </div>
            )}
            </>)}

          </Table>
          <TablePagination
            component="div"
            count={totalItemData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </TabPanel>
      



      <TabPanel value={value} index={1}>
        <TableContainer component={Paper} className="mt-10 border-radius-20">
          <Table
            className={classes.table}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  Terminal Name
                </TableCell>
                <TableCell className={classes.tableCell}>
                  Device Model
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
                <Loader open={loading} />
              ) : (
                <>
            {noFoundData ? (
              <TableBody>
                {InactiveData?.map((row) => (
                  <TableRow key={row.TerminalCode}>
                    <TableCell className={classes.tableCell}>
                      {row.TerminalName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.TerminalModel?.Name}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {getPermission.ActiveInactiveTerminal ==
                      "ActiveInactiveTerminal" ? (
                        <Switch
                          {...label}
                          defaultChecked
                          name="switch"
                          id={row.Id}
                          onClick={handleOpenModal}
                          inputProps={{ "aria-label": "controlled" }}
                          color="warning"
                          checked={false}
                        />
                      ) : (
                        <Switch
                          {...label}
                          defaultChecked
                          name="switch"
                          disabled
                          id={row.Id}
                          onClick={handleOpenModal}
                          inputProps={{ "aria-label": "controlled" }}
                          color="warning"
                          checked={false}
                        />
                      )}
                      <Dialog
                        open={open}
                        fullWidth
                        BackdropProps={{ style: { opacity: 0.2 } }}
                        maxWidth="sm"
                        sx={{
                          "& .MuiPaper-elevation24": {
                            boxShadow: "none!important",
                          },
                          " .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
                            background: "rgba(0,0,0,0.2)!important",
                          },
                          "& .MuiBackdrop-root": {
                            opacity: "0.3!important",
                          },
                        }}
                      >
                        <BootstrapDialogTitle
                          id="customized-dialog-title"
                          onClose={handleClose}
                        >
                          Active Terminal
                        </BootstrapDialogTitle>
                        <DialogContent dividers className="my-10">
                          <Typography gutterBottom>
                            Are you sure you want to activate this terminal.
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => getActiveDataById(ModalDataId)}
                            className="border-radius-20 btn-orange w-100 margin-top-bottom-10"
                            variant="contained"
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={handleClose}
                            className="border-radius-20 btn-orange w-100"
                            variant="contained"
                          >
                            No
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <div className="text-align-center font-18 font-bold py-15">
                No data found...
              </div>
            )}
            </>)}
          </Table>
          <TablePagination
            component="div"
            count={totalItemData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </TabPanel>
      </div>
    </div>
  );
}
