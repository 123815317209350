import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Container,
  Card,
  CardActions,
  CardContent,
  Button,
  Grid,
  TextField,
  Link,
  Box
} from "@mui/material";
import imgTwo from "../../assets/Images/Animation 1.gif";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "../../assets/scss/login.css";
import { config } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import { userRoles } from "../../shared/constants";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function BasicCard() {
  const navigate = useNavigate();
  const [loginDisable, setLoginDisable] = useState(false);
  const [showUi,setShowUi]= useState(true);
  const [getDisbleValue, setDisableValue] = useState(true);

  const ValidationSchema = Yup.object().shape({
    userName: Yup.string().required("User Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setDisableValue(false);
      axios
        .post(config.USER_API_URL, {
          userName: values.userName,
        })
        .then((response) => {
          localStorage.setItem("accessToken", response.data.AccessToken);
          localStorage.setItem("refreshToken", response.data.RefreshToken);
          if (response.data == "User not found") {
            setShowUi(false);
          } else {
            if (response.status == 200) {
              toast(
                "verification code has been sent your registered mobile number"
              );
              setTimeout(() => {
                navigate(`/SetOtp`);
                setShowUi(true);
              }, 2000);
            }
          }
        })
        .catch((error) => {
          if (error.response.status == 404) {
            toast(error.response.data);
          } else if (error.response.status == 401) {
            toast(error.response.data);
          } else if (error.response.status == 403) {
            toast(error.response.data);
          } else if (error.response.status == 400) {
            toast(error.response.data);
          } else {
            toast("Something went wrong. Please try again later. ");
          }
        });
    },
  });
  const { handleSubmit, handleChange, handleBlur, errors, touched, values } =
    formik;
  return (
  <>
      <ToastContainer autoClose={2000} />
      <Box sx={{ width: "100%", height: "100vh", background: "white" }}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          height={"90vh"}
        >
          <Grid item>
            <img src={imgTwo} alt="login gif" />
          </Grid>
          <Grid item className="mt-2">
            <Card className="card">
              <CardContent sx={{ padding: "0" }}>
                <Typography
                  component={"h2"}
                  variant="body1"
                  className="subTagtitle"
                >
                  Enter user name to get verification code
                </Typography>
              </CardContent>
              <form onSubmit={handleSubmit}>
                <Box mt={4}>
                  <TextField
                    fullWidth
                    variant="standard"
                    style={{ marginTop: "12px" }}
                    label="Enter User Name"
                    id="username"
                    name="userName"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userName}
                  />
                  {errors.userName && touched.userName ? (
                    <Box className="errors">{errors.userName}</Box>
                  ) : null}
                </Box>
                <Box mt={3}></Box>

                <CardActions
                  sx={{ marginTop: "36px", padding: "0", marginBottom: "40px" }}
                >
                  <Grid container justifyContent={"space-between"}>
                    <Grid item md={12} mt={5}>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        disabled={loginDisable}
                        className={
                          getDisbleValue == true ? "btn-orange" : "btn-gray"
                        }
                        style={{ width: "100%" }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
                <p className="poweredBy">
                  Powered By
                  <Link className="text-orange" underline="none" ml={1}>
                    Quinta
                  </Link>
                </p>
              </form>
            </Card>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            className="copyright"
            px={5}
          >
            <Grid item pl={2}>
              <p>
                2023 <span>Quinta</span> Systems Pvt. Ltd.
              </p>
            </Grid>
            <Grid item pr={3}>
              <p>
                <Link underline="none">Terms & Conditions</Link>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      </>
   
  );
}
