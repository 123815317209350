
 import apiService from "../../Services/ApiServices"
 import {  
  GET_CITY_REQUEST,GET_CITY_SUCCESS,GET_CITY_FAILURE,SAVE_CITY_REQUEST,SAVE_CITY_SUCCESS,SAVE_CITY_FAILURE
 } from '../Types'  
 
 export const getCityList = (stateId) =>  {
    try {
      return (dispatch) => {  
        dispatch(getCityRequest())  
        let url = `/City/all/${stateId}`;
        apiService.getData(url)
            .then(res => {
              const cities = res.data  
              if (res.data) {
                dispatch(getCitySuccess(cities))  
            }
            else {
                dispatch(getCityFailure('err'))
            }
              
            }).catch(err =>{ dispatch(getCityFailure(err)) });
      }  
    } catch (err) {
      
    }
  };

export const getCityRequest = () => {  
    return {  
      type: GET_CITY_REQUEST  
    }  
  }  
    
  export const getCitySuccess = cities => {  
    return {  
      type: GET_CITY_SUCCESS,  
      payload: cities  
    }  
  } 
    
  export const getCityFailure = error => {  
    return {  
      type: GET_CITY_FAILURE,  
      payload: error  
    }  
  }  

  export const getCityListByAccount = (AccountId,stateId) => {
    try {
      return (dispatch) => {  
        dispatch(getCityRequest())  
        let url = `/Account/cities?AccountId=${AccountId}&StateId=${stateId}`;
        apiService.getData(url)
            .then(res => {
              const cities = res.data  
              if (res.data) {
                dispatch(getCitySuccess(cities))  
            }
            else {
                dispatch(getCityFailure('err'))
            }
              
            }).catch(err =>{ dispatch(getCityFailure(err)) });
      }  
    } catch (err) {
      
    }
  };
  //Save account Details
export const saveCityRequest = () => {
  return {
    type: SAVE_CITY_REQUEST
  }
}

export const saveCitySuccess = result => {

  return {
    type: SAVE_CITY_SUCCESS,
    payload: result
  }
}

export const saveCityFailure = error => {
  return {
    type: SAVE_CITY_FAILURE,
    payload: error
  }
}