import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TablePagination from "@mui/material/TablePagination";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import Filter from "../../../Component/Filter";
import { useDispatch, useSelector } from "react-redux";
import ApiServices from "../../../Services/ApiServices";
import moment from "moment";
import { getCountryList } from "../../../Redux/Country/CountryAction";
import { getStateList } from "../../../Redux/State/StateAction";
import { getCityList } from "../../../Redux/City/CityAction";
import img from "../../../assets/Images/eye.png";
import DefaultButton from "../../../Component/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const useStyles = makeStyles({
  table: {
    minWidth: 950,
    "& .MuiTableCell-root": {
      padding: "9px",
    },
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const pageState = { skip: 0, top: 10, isDeleted: false, query: "" };

export default function BasicTable() {
  const [inputField, setInputField] = useState({
    country: "",
    State: "",
    City: "",
    accountName: "",
    storeName: "",
    terminalName: "",
  });
  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [country, setCountry] = useState([]);
  const [City, setCity] = useState([]);
  const [State, setState] = useState([]);
  const dispatch = useDispatch();
  const countryData = useSelector((s) => s.countryReducer);
  const stateData = useSelector((s) => s.stateReducer);
  const cityData = useSelector((s) => s.cityReducer);
  const [values, setValues] = useState([
    new DateObject().subtract(0, "days"),
    new DateObject().add(1, "days"),
  ]);
  // get all country
  useEffect(() => {
    dispatch(getCountryList());
    getAllAccount();
  }, []);
  useEffect(() => {
    if (countryData) {
      setCountry(countryData.countries);
    }
  }, [countryData]);
  // get all States for selected country
  useEffect(() => {
    if (stateData) {
      setState(stateData.states);
    }
  }, [stateData]);
  // get all city for selected state
  useEffect(() => {
    if (cityData) {
      setCity(cityData.cities);
    }
  }, [cityData]);
  useEffect(() => {
    if (cityData) {
      setCity(cityData.cities);
    }
  }, [cityData]);
  // using for state
  const getAllStateInput = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      country: e.target.value,
    }));
    dispatch(getStateList(e.target.value));
  };
  // using for city
  const getAllCityInput = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      City: e.target.value,
    }));
  };
  const getCityByState = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      State: e.target.value,
    }));

    dispatch(getCityList(e.target.value));
  };
  const [accounts, setAccountData] = useState([]);
  const getAllAccountId = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      accountId: e.target.value,
      accountName: e.target.value,
    }));
    getAllStores(e.target.value);
  };
  const getAllAccount = () => {
    ApiServices.getData(`/Account/all/dropdown`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((res) => {});
  };
  const [stores, setStores] = useState([]);
  const getAllStoresData = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      storeId: e.target.value,
      storeName: e.target.value,
    }));
    getAllTerminal(e.target.value);
  };
  const getAllStores = (id) => {
    ApiServices.getData(`/Store/getByAccount/${id}`)
      .then((res) => {
        setStores(res.data);
      })
      .catch((res) => {});
  };
  const [terminal, setTerminal] = useState([]);
  const getAllTerminalData = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      terminalId: e.target.value,
      terminalName: e.target.value,
    }));
  };
  const getAllTerminal = (id) => {
    ApiServices.getData(`/Terminal/getByStore/${id}`)
      .then((res) => {
        setTerminal(res.data);
      })
      .catch((res) => {});
  };
  const [isLoading, setIsLoading] = useState(false);
  const [getData, setData] = useState([]);
  const [getDetailsData, setDetailsData] = useState([]);
  const [totalItemData, setTotalItemData] = useState(1);
  const [noFoundData, setnoFoundData] = useState(true);
  useEffect(() => {
    getRecognizedReports(pageState);
  }, []);

  const getRecognizedReports = () => {
    var fromDate = new Date(values[0]).toISOString();
    var toDate = new Date(values[1]).toISOString();
    ApiServices.getData(
      `/Reports/notRecogniseReport?FromeDate=${fromDate}&ToDate=${toDate}&skip=${pageState.skip}&top=${pageState.top}`
    )
      .then((res) => {
        if (res.data.Data == "") {
          setnoFoundData(false);
        } else {
          setData(res.data.Data);
          setTotalItemData(res.data.TotalItems);
          setnoFoundData(true);
        }
      })
      .catch((err) => {});
  };

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [currentPageState, setCurrentPageState] = useState(pageState);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    getRecognizedReports(pageState);
  };
  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    getRecognizedReports(pageState);
  };

  const filterFunction = () => {
    var fromDate = new Date(values[0]).toISOString();
    var toDate = new Date(values[1]).toISOString();
    ApiServices.getData(
      `/Reports/notRecogniseReport?Country=${inputField.country}&StateId=${inputField.State}&CityId=${inputField.City}&StoreId=${inputField.storeName}&TerminalId=${inputField.terminalName}&FromeDate=${fromDate}&ToDate=${toDate}`
    )
      .then((res) => {
        if (res.data.Data == "") {
          setnoFoundData(false);
        } else {
          setData(res.data.Data);
          setTotalItemData(res.data.TotalItems);
          setnoFoundData(true);
        }
      })
      .catch((err) => {});
  };
  const cancelFilter = () => {
    getRecognizedReports(pageState);
    setInputField({
      City: "",
      country: "",
      State: "",
      accountName: "",
      storeName: "",
      terminalName: "",
    });
    setValues([
      new DateObject().subtract(0, "days"),
      new DateObject().add(1, "days"),
    ]);
  };

  return (
    <div>
      <div>
        <div role="presentation">
          <Breadcrumbs
            separator="<"
            aria-label="breadcrumb"
            className="text-black"
          >
            <Link
              color="inherit"
              component={RouterLink}
              to="/app/ReportsDashboard"
              className="font-14"
              underline="hover"
            >
              Reports
            </Link>
            <Link color="inherit" className="textDecoration">
              <span className="font-14 font-weight-900">
                Item not-recognized reports
              </span>
            </Link>
          </Breadcrumbs>
        </div>
        <div>
          <Box display="flex" justifyContent="flex-end">
            <div className="mx-3"></div>
            <div>
              <DefaultButton
                color="warning"
                variant="contained"
                size="small"
                startIcon={<ArrowBackIosNewIcon />}
                Text="Back"
              />
            </div>
          </Box>

          <Grid container spacing={3} className="pt-10">
            <Grid item xs={12} sm={12} md={12}>
              <Filter
                data="Click here"
                country={country}
                City={City}
                State={State}
                getAllStateInput={getAllStateInput}
                getCityByState={getCityByState}
                getAllCityInput={getAllCityInput}
                getCountry={inputField.country}
                getCity={inputField.City}
                getState={inputField.State}
                accounts={accounts}
                getAccount={inputField.accountName}
                getAllAccountId={getAllAccountId}
                stores={stores}
                getAllStoresData={getAllStoresData}
                getStore={inputField.storeName}
                terminal={terminal}
                getAllTerminalData={getAllTerminalData}
                getTerminal={inputField.terminalName}
                getValue={values}
                setValue={setValues}
                onChildClick={filterFunction}
                onCancelClick={cancelFilter}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      {/* </div> */}
      <TableContainer component={Paper} className="mt-10 border-radius-20">
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "#dfdfdf",
                fontWeight: "bold",
              }}
            >
              <TableCell>S No</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>No of item not-recognized reports</TableCell>
              <TableCell align="center">View More</TableCell>
            </TableRow>
          </TableHead>
          {noFoundData ? (
            <TableBody>
              {getData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {moment(row.ReportDate).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>{row.Count}</TableCell>
                  <TableCell align="center">
                    <div className="eyes-button">
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={{
                          pathname: `/app/ItemNotRecogDetails/${row.ReportDate}`,
                        }}
                        className="font-14"
                        underline="hover"
                      >
                        <Button
                          size="small"
                          style={{
                            width: "45%",
                            height: "25px",
                            zIndex: "1111",
                          }}
                        >
                          <img src={img} />
                        </Button>
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <div className="text-align-center font-18 font-bold py-15">
              No data found...
            </div>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalItemData}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}
