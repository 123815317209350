import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Divider, Grid, TextField, MenuItem } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
    return (
        <div>
            <Button onClick={props.handleOpenDetachLicense} className="text-orange"  sx={{ textTransform:'capitalize',fontWeight: "400",fontSize:'14px'}}>
            Detach MAC address from key
            </Button>
            <BootstrapDialog
                onClose={props.handleDetachLicenseClose}
                aria-labelledby="customized-dialog-title"
                open={props.openDetachLicense}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleDetachLicenseClose}>
                    Detach MAC Adress
                </BootstrapDialogTitle>
                <DialogContent dividers className='my-10'>
                    <Typography gutterBottom>
                        Are you sure you want to detach MAC address.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.removeLicense}  variant="contained" className='border-radius-20 btn-orange w-100 margin-top-bottom-10'>Yes</Button>
                    <Button  onClick={props.handleDetachLicenseClose} variant="contained" className='border-radius-20 btn-orange w-100'>No</Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}