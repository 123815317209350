
import apiService from "../../Services/ApiServices"
import {
  GET_SUBCATEGORY_REQUEST,
  GET_SUBCATEGORY_FAILURE,
  GET_ACTIVE_SUBCATEGORY_SUCCESS,
  GET_SUBCATEGORYDETAIL_REQUEST,
  GET_SUBCATEGORYDETAIL_SUCCESS,
  GET_SUBCATEGORYDETAIL_FAILURE,
  SAVE_SUBCATEGORY_REQUEST,
  SAVE_SUBCATEGORY_SUCCESS,
  SAVE_SUBCATEGORY_FAILURE,
  GET_IMAGE_REQUEST,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_FAILURE,
  GET_INACTIVE_SUBCATEGORY_SUCCESS
} from '../Types'
export const  getSubCategoryDetailRequest = () => {
  return {
    type: GET_SUBCATEGORYDETAIL_REQUEST
  }
}
export const  getSubCategoryDetailSuccess = SubCategory => {
  return {
    type: GET_SUBCATEGORYDETAIL_SUCCESS,
    payload: SubCategory
  }
}

export const  getSubCategoryDetailFailure = error => {
  return {
    type: GET_SUBCATEGORYDETAIL_FAILURE,
    payload: error
  }
}
export const  getSubCategoryRequest = () => {
  return {
    type: GET_SUBCATEGORY_REQUEST
  }
}

export const  getSubCategorySuccess = SubCategory => {

  return {
    type: GET_ACTIVE_SUBCATEGORY_SUCCESS,
    payload: SubCategory
  }
}


export const  getSubCategoryFailure = error => {
  return {
    type: GET_SUBCATEGORY_FAILURE,
    payload: error
  }
}
// save Data return type
export const  saveSubCategoryRequest = () => {
  return {
    type: SAVE_SUBCATEGORY_REQUEST
  }
}

export const  saveSubCategorySuccess = result => {

  return {
    type:  SAVE_SUBCATEGORY_SUCCESS,
    payload: result
  }
}

export const  saveSubCategoryFailure = error => {
  return {
    type:  SAVE_SUBCATEGORY_FAILURE,
    payload: error
  }
}
export const getInactiveSubCategorySuccess = subcategory => {

  return {
    type: GET_INACTIVE_SUBCATEGORY_SUCCESS,
    payload: subcategory
  }
}

// get data 
export const getDataByPagination = (pageState) => {
  try {
   
    return (dispatch) => {
      dispatch( getSubCategoryRequest())
      let url = `/AccountSubCategory/all?skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if (pageState.categoryId != "") {
        url += `&categoryId=${pageState.categoryId}`;
      }
      apiService.getData(url)
        .then(res => {
          const Category = res.data
          if (res.data.Data == "") {
            dispatch( getSubCategoryFailure('err'))
          }
          else {
            dispatch( getSubCategorySuccess(Category))
          }

        }).catch(err => { dispatch( getSubCategoryFailure(err)) });
    }
  } catch (err) {

  }
};
export const getInactiveDataByPagination = (pageState) => {
  try {
    return (dispatch) => {
      dispatch(getSubCategoryRequest())
      let url = `/AccountSubCategory/all?deleted=${true}&skip=${pageState.skip}&top=${pageState.top}`;
      if (pageState.query != "") {
        url += `&query=${pageState.query}`;
      }
      if(pageState.categoryId != ""){
        url += `&categoryId=${pageState.query}`;
      }
      if(pageState.SubCategoryId != ""){
        url += `&subCategoryId=${pageState.SubCategoryId}`;
      }
      apiService.getData(url)
        .then(res => {
          const subcategory = res.data
          if (res.data.Data == "") {
            dispatch(getSubCategoryFailure('err'))
          }
          else {
            dispatch(getInactiveSubCategorySuccess(subcategory))
          }

        }).catch(err => { dispatch(getSubCategoryFailure(err)) });
    }
  } catch (err) {

  }
};
  //Get logo image
  export const getImageRequest = () => {
    return {
      type: GET_IMAGE_REQUEST
    }
  }
  
  export const getImageSuccess = result => {
  
    return {
      type: GET_IMAGE_SUCCESS,
      payload: result
    }
  }
  
  export const getImageFailure = error => {
    return {
      type: GET_IMAGE_FAILURE,
      payload: error
    }
}


// save data
export const saveSubCategory = (formData) => {
  try {

    return (dispatch) => {
      dispatch( saveSubCategoryRequest())
      let url =`/AccountSubCategory/create`;
      apiService.saveData(url,formData)
        .then(res => {
          
          const result = res.status;
          if (result==200) {
            dispatch( saveSubCategorySuccess(result))
          }
          else {
            
            dispatch( saveSubCategoryFailure(res.response.status))
          }

        }).catch(err => { dispatch( saveSubCategoryFailure(err)) });
    }
  } catch (err) {

  }
};

export const getLogoImageData= (subcategoryId) => {
  try {
    return (dispatch) => {
      dispatch(getImageRequest())
      let url = `/AccountSubCategory/logo/${subcategoryId}`;
      apiService.getData(url)
        .then(res => {
          const imageData = res.data
          if (res.data) {
            dispatch(getImageSuccess(imageData))
          }
          else {
            dispatch(getImageFailure('err'))
          }

        }).catch(err => { dispatch(getImageFailure(err)) });
    }
  } catch (err) {

  }
}
export const updateSubCategory = (formData,subcategoryId) => {
  try {

    return (dispatch) => {
      dispatch( saveSubCategoryRequest())
      let url =`/AccountSubCategory/update/${subcategoryId}`;
      apiService.saveData(url,formData)
        .then(res => {
          
          const result = res.status;
          if (result==200) {
            dispatch( saveSubCategorySuccess(result))
          }
          else {
            
            dispatch( saveSubCategoryFailure(res.response.status))
          }

        }).catch(err => { dispatch( saveSubCategoryFailure(err)) });
    }
  } catch (err) {

  }
};
export const getSubCategoryDetails = (accountId) => {
  try {

    return (dispatch) => {
      dispatch( getSubCategoryDetailRequest())
      let url = `/AccountSubCategory/${accountId}`;
      apiService.getData(url)
        .then(res => {
          
          const CategoryDetail = res.data
          if (res.data) {
            dispatch( getSubCategoryDetailSuccess(CategoryDetail))
          }
          else {
            dispatch( getSubCategoryDetailFailure('err'))
          }

        }).catch(err => { dispatch( getSubCategoryDetailFailure(err)) });
    }
  } catch (err) {

  }
};