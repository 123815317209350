import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Card,
  Box,
  Grid,
  MenuItem,
  Breadcrumbs,
  Link,
  Dialog,
  DialogTitle,
} from "@mui/material";
import DefaultInput from "../../Component/TextField";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../Services/ApiServices";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import DefaultButton from "../../Component/Button";
import { useLocation, Link as RouterLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  saveUser,
  getUserDetails,
  updateUser,
} from "../../Redux/User/UserAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PropTypes from "prop-types";
import { regexConstant } from "../../shared/constants";
import { styles } from "../../assets/scss/AddItem";

// Using for Dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function BasicTable() {
  const userDetail = useSelector((s) => s.userReducer);
  console.log("userDetail",userDetail)
  const dispatch = useDispatch();
  const [Roles, setRoles] = React.useState([]);
  const [getRoleArray, setRoleArray] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentUserDetails, setCurrentUser] = useState({});
  const [currentUserId, setCurrentUserId] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const [acoountData, setAccountData] = useState([]);
  const [getAccountId, setAccountId] = useState();
  const [getUserId, setUserId] = useState();
  //validation Type
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const location = useLocation();
  const [ModalDataId, setModalDataId] = useState();
  const [open, setOpen] = React.useState(false);
  const [inputField, setInputField] = useState({
    UserName: "",
    PhoneNumber: "",
    Roles: "",
    FirstName: "",
    LastName: "",
    Email: "",
    password: "",
    confirmpassword: "",
    Address: "",
    accountName: params.newUserId,
  });
  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChange = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      rolesId: e.target.value,
      Roles: e.target.value,
    }));
    setRoleArray(e.target.value);
  };
  useEffect(() => {
    if (params.id) {
      dispatch(getUserDetails(params.id));
    }
    getRoles();
    getAllAccount();
  }, []);
  useEffect(() => {
    if (userDetail.UserDetails) {
      var accInfo = userDetail.UserDetails;
      setAccountId(accInfo.AccountId);
      setUserId(accInfo.Id);
      if (Object.keys(accInfo).length > 0) {
        setInputField({
          accountId: accInfo.AccountId != null ? accInfo.AccountId : "",
          UserName: accInfo.UserName != null ? accInfo.UserName : "",
          FirstName: accInfo.FirstName != null ? accInfo.FirstName : "",
          LastName: accInfo.LastName != null ? accInfo.LastName : "",
          Email: accInfo.Email != null ? accInfo.Email : "",
          accountName: accInfo.AccountId != null ? accInfo.AccountId : "",
          Roles: accInfo.RoleId != null ? accInfo.RoleId : "",
          PhoneNumber: accInfo.PhoneNumber != null ? accInfo.PhoneNumber : "",
          password: accInfo.Password != null ? accInfo.Password : "",
          confirmpassword: accInfo.Password != null ? accInfo.Password : "",
          Address: accInfo.Address != null ? accInfo.Address : "",
        });
      }
    } else if (userDetail.result == 200) {
      if ((params.id && getAccountId) || params.id) {
        toast("User update successfully");
        setTimeout(() => {
          if (currentUserId != 1) {
            navigate(`/app/ManageUser/${getAccountId}`);
          } else {
            navigate(`/app/ManageUser`);
          }
        }, 1000);
      } else {
        toast("User save successfully");
        setTimeout(() => {
          if (currentUserId != 1) {
            navigate(`/app/ManageUser/${params.newUserId}`);
          } else {
            navigate(`/app/ManageUser`);
          }
        }, 1000);
      }
    } else if (userDetail.error.status == 404) {
      toast(userDetail.error.data);
    } else if (userDetail.error.status == 401) {
      toast(userDetail.error.data);
    } else if (userDetail.error.status == 403) {
      toast(userDetail.error.data);
    } else if (userDetail.error.status == 400) {
      toast(userDetail.error.data);
    } else if (userDetail.error.status == undefined) {
    } else {
      toast("Something went wrong. Please try again later. ");
    }
  }, [userDetail]);
  const resetData = () => {
    setInputField({
      UserName: "",
      PhoneNumber: "",
      Roles: "",
      FirstName: "",
      LastName: "",
      Email: "",
      accountName: "",
    });
    if (params.id && getAccountId) {
      navigate(`/app/ManageUser/${getAccountId}`);
    } else if (params.newUserId) {
      navigate(`/app/ManageUser/${params.newUserId}`);
    } else {
      navigate(`/app/ManageUser`);
    }
  };
  const getRoles = () => {
    ApiServices.getData("/Role/all")
      .then((res) => {
        //Doubt
        let data = res.data.filter((item) => item.Id !== 1);
        setRoles(data);
      })
      .catch((res) => {});
  };

  const getAllAccountId = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      // Roles: event.target.value
      accountId: e.target.value,
      accountName: e.target.value,
    }));
  };
  const getAllAccount = () => {
    ApiServices.getData(`/Account/all/dropdown`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((res) => {});
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);
  const [passwordType, setPasswordType] = useState("password");
  const [ConfirmPasswordType, setComPasswordType] = useState("password");

  const onPasswordTypeChange = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  const onConfirmPasswordChange = () => {
    if (ConfirmPasswordType === "password") {
      setComPasswordType("text");
    } else {
      setComPasswordType("password");
    }
  };
  const validate = (values) => {
    const errors = {};
    let formIsValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexNumber = /^[0-9\b]+$/;
    const letter = /^[a-zA-Z ]*$/;
    const onlyTennumber = /^\d{10}$/;
    const alphanumeric = /^[a-z0-9]+$/i;

    if (!regexConstant.regexForSpec.test(values.UserName)) {
      errors.UserName = "Please enter valid user name";
      formIsValid = false;
    }
    if (values.UserName.length < 3) {
      errors.UserName = "Please enter greater then 3 character";
      formIsValid = false;
    }
    if (values.UserName.length > 50) {
      errors.UserName = "Please enter less than 50 character";
      formIsValid = false;
    }
    if (!values.UserName) {
      errors.UserName = "User Name is required!";
      formIsValid = false;
    }

    if (!regexConstant.regexForSpec.test(values.FirstName)) {
      errors.FirstName = "Please enter valid first name";
      formIsValid = false;
    }
    if (values.FirstName.length < 3) {
      errors.FirstName = "Please enter greater then 3 character";
      formIsValid = false;
    }
    if (values.FirstName.length > 30) {
      errors.FirstName = "Please enter less than 30 character";
      formIsValid = false;
    }
    if (!values.FirstName) {
      errors.FirstName = "First Name is required!";
      formIsValid = false;
    }
    if (!regexConstant.regexForSpec.test(values.LastName)) {
      errors.LastName = "Please enter valid last name";
      formIsValid = false;
    }

    if (values.LastName.length > 30) {
      errors.LastName = "Please enter less than 30 character";
      formIsValid = false;
    }

    if (!values.LastName) {
      errors.LastName = "Last Name is required!";
      formIsValid = false;
    }
    if (!values.Roles) {
      errors.Roles = "Roles is required!";
      formIsValid = false;
    }
    if (!regex.test(values.Email)) {
      errors.Email = "Email is not valid!";
      formIsValid = false;
    }
    if (!values.Email) {
      errors.Email = "Email is required!";
      formIsValid = false;
    }
    if (!values.Email) {
      errors.Email = "Email is required!";
      formIsValid = false;
    }
    if (!regexConstant.NumberRegex.test(values.PhoneNumber)) {
      errors.PhoneNumber = "Please enter a valid primary phone number";
      formIsValid = false;
    }
    if (!onlyTennumber.test(values.PhoneNumber)) {
      errors.PhoneNumber = "Please enter 10 digit number!";
      formIsValid = false;
    }
    if (!values.PhoneNumber) {
      errors.PhoneNumber = "Phone Number is required!";
      formIsValid = false;
    }
    if (values.password.length < 4) {
      errors.password = "Please enter greater then 3 character";
      formIsValid = false;
    }
    if (values.password.length > 12) {
      errors.password = "Please enter less than 12 character";
      formIsValid = false;
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    if (values.password !== values.confirmpassword) {
      errors.confirmpassword = "Passwords does not match";
      formIsValid = false;
    }
    if (!values.confirmpassword) {
      errors.confirmpassword = "Confirm password is required";
    }
    if (!regexConstant.regexForSpec.test(values.Address)) {
      errors.Address = "Please enter valid address";
      formIsValid = false;
    }
    setFormErrors(errors);
    return formIsValid;
  };
  const saveStoreDetails = () => {
    var accountId;
    if (currentUserDetails != 1) {
      accountId = currentUserId;
    } else if (currentUserDetails == 1) {
      accountId = params.newUserId;
    } else if (getRoleArray == 1) {
      accountId = params.newUserId;
    } else {
      accountId = params.newUserId;
    }
    dispatch(
      saveUser({
        userName: inputField.UserName,
        PhoneNumber: inputField.PhoneNumber,
        firstName: inputField.FirstName,
        lastName: inputField.LastName,
        email: inputField.Email,
        address: inputField.Address,
        accountId: accountId,
        roleId: inputField.Roles,
        password: inputField.password,
        conformpassword:inputField.confirmpassword,
        RequestType: "User",
      })
    );
  };
  // Update Store
  const updateData = () => {
    var accountId;
    if (currentUserDetails != 1) {
      accountId = currentUserId;
    } else if (currentUserDetails == 1) {
      accountId = getAccountId;
    } else if (getRoleArray == 1) {
      accountId = getAccountId;
    } else {
      accountId = getAccountId;
    }
    dispatch(
      updateUser(
        {
          userName: inputField.UserName,
          PhoneNumber: inputField.PhoneNumber,
          firstName: inputField.FirstName,
          lastName: inputField.LastName,
          address: inputField.Address,
          email: inputField.Email,
          accountId: accountId,
          password: inputField.password,
          conformpassword:inputField.confirmpassword,
          roleId: inputField.Roles,
          RequestType: "User",
        },
        params.id
      )
    );
  };
  const submitForm = (e) => {
    e.preventDefault();
    console.log("inputField",inputField)
    if (validate(inputField)) {
      setIsSubmit(true);
      if ((params.id && getAccountId) || params.id) {
        updateData();
      } else {
        saveStoreDetails();
      }
    }
  };

  const handleOpenModal = (e) => {
    setOpen(true);
    setModalDataId(e.target.id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const restPassword = (Obj) => {
    ApiServices.saveData(`/User/resetPassword`, {
      userId: Obj,
    })
      .then((res) => {
        alert(
          "User Name : " +
            res.data.UserName +
            "\n" +
            "User Password : " +
            res.data.Password
        );
        if (res.status == 200) {
          toast("Password update successfully");
        }
        setOpen(false);
      })
      .catch((res) => {});
  };

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetail) {
      setCurrentUser(userDetail.userRole);
      setCurrentUserId(userDetail.accountId);
    }
    setIsLoading(true);
  }, []);

  return (
    <div style={{ marginBottom: "30px" }}>
      <ToastContainer autoClose={1000} />
      {/* --------------btn section----------- */}
      <Grid container sx={{ marginLeft: "-5px " }}>
        <Grid item xs={12} sm={12} md={11}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Box {...styles.tab}>
                <Box {...styles.tabInside}>
                  {params.id ? "Update User" : "New User"}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
          <DefaultButton
            color="warning"
            variant="contained"
            size="small"
            Text="Back"
          />
        </Grid>
      </Grid>
      {/* ------------form-------------- */}
      <form onSubmit={submitForm}>
        <Card className="border-radius-8 width-100" sx={{ marginTop: "-25px" }}>
          <div className="p-4">
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    label="Roles *"
                    value={inputField.Roles}
                    Name="Roles"
                    onChange={handleChange}
                  >
                    {Roles.filter((roleName) => {
                      return (
                        (roleName.Name != "SuperAdministrator" ||
                          currentUserDetails == 1) &&
                        roleName.Name != "Store Manager"
                      );
                    }).map((option) => (
                      <MenuItem key={option.value} value={option.Id}>
                        {option.Name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className="errors">{formErrors.Roles}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {currentUserDetails == 1 ? (
                    <div>
                      {getRoleArray != 1 ? (
                        <div>
                          <TextField
                            id="outlined-select-currency"
                            select
                            disabled
                            size="small"
                            label="Account *"
                            name="accountName"
                            value={inputField.accountName}
                            onChange={getAllAccountId}
                          >
                            {acoountData.map((option) => (
                              <MenuItem key={option.value} value={option.Id}>
                                {option.AccountName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div>
                      {getRoleArray != 1 ? (
                        <div>
                          <TextField
                            id="outlined-select-currency"
                            disabled
                            select
                            size="small"
                            label="Account *"
                            name="accountWithId"
                            value={currentUserId}
                            onChange={getAllAccountId}
                          >
                            {acoountData.map((option) => (
                              <MenuItem key={option.value} value={option.Id}>
                                {option.AccountName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <DefaultInput
                        id="FirstName"
                        label="First Name *"
                        variant="standard"
                        name="FirstName"
                        value={inputField.FirstName}
                        onChange={inputsHandler}
                        placeHolder="First Name"
                      />
                      <div className="errors">{formErrors.FirstName}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <DefaultInput
                        id="LastName"
                        label="Last Name *"
                        variant="standard"
                        name="LastName"
                        value={inputField.LastName}
                        onChange={inputsHandler}
                        placeHolder="Last Name"
                      />
                      <div className="errors">{formErrors.LastName}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="Email"
                    label="Email Id*"
                    variant="standard"
                    name="Email"
                    value={inputField.Email}
                    onChange={inputsHandler}
                    placeHolder="Email Id"
                  />
                  <div className="errors">{formErrors.Email}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="UserName"
                    label="User Name *"
                    variant="standard"
                    name="UserName"
                    value={inputField.UserName}
                    onChange={inputsHandler}
                    placeHolder="User Name"
                  />
                  <div className="errors">{formErrors.UserName}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="password-input">
                        <TextField
                          fullWidth
                          label="Enter Password*"
                          type={passwordType}
                          id="password"
                          name="password"
                          value={inputField.password}
                          onChange={inputsHandler}
                          size="small"
                          className="border-radius-20"
                        />
                        <span
                          className="password-type-button"
                          onClick={onPasswordTypeChange}
                        >
                          {passwordType === "password" ? (
                            <VisibilityOffIcon
                              style={{ width: "0.8em", height: "0.8em" }}
                            />
                          ) : (
                            <VisibilityIcon
                              style={{ width: "0.8em", height: "0.8em" }}
                            />
                          )}
                        </span>
                        <div className="errors">{formErrors.password}</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="password-input">
                        <TextField
                          fullWidth
                          label="Re-enter Password*"
                          type={ConfirmPasswordType}
                          id="confirmpassword"
                          name="confirmpassword"
                          value={inputField.confirmpassword}
                          onChange={inputsHandler}
                          size="small"
                          className="border-radius-20"
                        />
                        <span
                          className="password-type-button"
                          onClick={onConfirmPasswordChange}
                        >
                          {ConfirmPasswordType === "password" ? (
                            <VisibilityOffIcon
                              style={{ width: "0.8em", height: "0.8em" }}
                            />
                          ) : (
                            <VisibilityIcon
                              style={{ width: "0.8em", height: "0.8em" }}
                            />
                          )}
                        </span>
                        <div className="errors">
                          {formErrors.confirmpassword}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="Address"
                    label="Address"
                    multiline
                    name="Address"
                    rows={4}
                    value={inputField.Address}
                    onChange={inputsHandler}
                  />
                  <div className="errors">{formErrors.Address}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DefaultInput
                    id="PhoneNumber"
                    label="Phone Number *"
                    variant="standard"
                    name="PhoneNumber"
                    value={inputField.PhoneNumber}
                    onChange={inputsHandler}
                    placeHolder="Phone Number"
                    inputProps={{ maxLength: 10 }}
                  />
                  <div className="errors">{formErrors.PhoneNumber}</div>
                </Grid>
              </Grid>
            </div>
            <div className="buttonSecton">
              <div className="mx-3 " style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  {...styles.SaveBtn}
                  className="btn-orange "
                >
                  Save
                </Button>
              </div>
              <div></div>
            </div>
          </div>
        </Card>
      </form>
    </div>
  );
}
