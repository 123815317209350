import {  
    GET_LICENSE_REQUEST,  
    GET_ACTIVE_LICENSE_SUCCESS,
    GET_INACTIVE_LICENSE_SUCCESS, 
    GET_LICENSE_FAILURE ,
    GET_LICENSEDETAILS_REQUEST,
    GET_LICENSEDETAILS_SUCCESS,
    GET_LICENSEDETAILS_FAILURE,
    SAVE_LICENSE_REQUEST,
    SAVE_LICENSE_SUCCESS,
    SAVE_LICENSE_FAILURE,
  } from '../Types'
  
    
  const initialState = {  
    loading: false, 
    noFoundData:false, 
    license:{},  
    error: ''  ,
    isAccountActive:true,
    LicenseDetails:{},
    result:'',
    imageData:{}
  }  
    
  const reducer = (state = initialState, action) => {  
    
    switch (action.type) {  
      case GET_LICENSE_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false
        }  
      case GET_ACTIVE_LICENSE_SUCCESS:
             
        return {  
          loading: false,  
          license: action.payload,  
          noFoundData:false,
          error: ''  ,
          isAccountActive:true,
        }  
        case GET_INACTIVE_LICENSE_SUCCESS:
        return {  
          loading: false,  
          license: action.payload,  
          noFoundData:false,
          error: ''  ,
          isAccountActive:false
        }  
      case GET_LICENSE_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          license: {},  
          error: action.payload  
        }  
        case GET_LICENSEDETAILS_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false
        }  
        case GET_LICENSEDETAILS_SUCCESS:
        return {  
          loading: false,  
          LicenseDetails: action.payload,  
          noFoundData:false,
          error: ''  ,
          isAccountActive:false
        }  
      case GET_LICENSEDETAILS_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          LicenseDetails: {},  
          error: action.payload  
        }  
        case SAVE_LICENSE_REQUEST:  
        return {  
          ...state,  
          loading: true ,
          noFoundData:false,
          error:''
        }  
        case SAVE_LICENSE_SUCCESS:
        return {  
          loading: false,  
          result: action.payload,  
          noFoundData:false,
          error: ''  ,
          isAccountActive:false
        }  
      case SAVE_LICENSE_FAILURE:  
        return {  
          loading: false, 
          noFoundData:true, 
          result:action.payload,  
          error: action.payload  
        } 
        
      default: return state  
    }  
  }  
    
  export default reducer  