import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TablePagination from "@mui/material/TablePagination";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import Filter from "../../../Component/Filter";
import { useDispatch, useSelector } from "react-redux";

import ApiServices from "../../../Services/ApiServices";
import moment from "moment";
import { getCountryList } from "../../../Redux/Country/CountryAction";
import { getStateList } from "../../../Redux/State/StateAction";
import { getCityList } from "../../../Redux/City/CityAction";
import img from "../../../assets/Images/eye.png";
import { userRoles } from "../../../shared/constants";
import DefaultButton from "../../../Component/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    minWidth: 950,
    "& .MuiTableCell-root": {
      padding: "9px",
    },
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const pageState = {
  skip: 0,
  top: 10,
  isDeleted: false,
  query: "",
  accountId: "",
};

export default function BasicTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [getDetailsData, setDetailsData] = useState([]);
  const [totalItemData, setTotalItemData] = useState(1);
  const [noFoundData, setnoFoundData] = useState(true);
  useEffect(() => {
    getRecognizedDetailsReports(params.id);
  }, []);

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [currentPageState, setCurrentPageState] = useState(pageState);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      pageState.skip = parseInt(pageState.skip + pageState.top);
      setCurrentPageState({
        skip: pageState.skip + pageState.top,
        top: pageState.top,
      });
    } else {
      pageState.skip = parseInt(pageState.skip - pageState.top);
      setCurrentPageState({
        skip: pageState.skip - pageState.top,
        top: pageState.top,
      });
    }
    setPage(newPage);
    getRecognizedDetailsReports(pageState);
  };
  const handleChangeRowsPerPage = (event) => {
    pageState.top = event.target.value;
    pageState.skip = 0;
    setRowsPerPage(parseInt(pageState.top));
    setPage(0);
    getRecognizedDetailsReports(pageState);
  };

  const params = useParams();

  const getRecognizedDetailsReports = () => {
    var getToDate = new Date(params.id).toISOString();
    ApiServices.getData(
      `/Reports/weightChangedReportDetails?reportDate=${getToDate}`
    )
      .then((res) => {
        if (res.data.Data == "") {
          setnoFoundData(false);
        } else {
          setDetailsData(res.data.Data);
          setTotalItemData(res.data.TotalItems);
          setnoFoundData(true);
        }
      })
      .catch((err) => {});
  };
  return (
    <div>
      <div>
        <span className="font-14 font-weight-900">
          No of times weight change error details reports
        </span>
        <div className="mx-2 text-align-end">
          <DefaultButton
            color="warning"
            variant="contained"
            size="small"
            startIcon={<ArrowBackIosNewIcon />}
            Text="Back"
          />
        </div>
      </div>

      {/* </div> */}
      <TableContainer component={Paper} className="mt-10 border-radius-20">
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableHead>
            <TableRow className="font-weight-bold bg-white">
              <TableCell align="left">S. No</TableCell>
              <TableCell align="left">Store Name</TableCell>
              <TableCell align="left">Terminal Name</TableCell>
              <TableCell align="left">Terminal Code</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Count</TableCell>
            </TableRow>
          </TableHead>
          {noFoundData ? (
            <TableBody>
              {getDetailsData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">{row.StoreName}</TableCell>
                  <TableCell align="left">{row.TerminalName}</TableCell>
                  <TableCell align="left">{row.TerminalCode}</TableCell>
                  <TableCell align="left">
                    {" "}
                    {moment(row.Date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="left">{row.Count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <div className="text-align-center font-18 font-bold py-15">
              No data found...
            </div>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalItemData}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}
